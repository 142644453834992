import { HttpErrorResponse } from '@angular/common/http';
import * as api from '@dki/api-client';
import { createAction, props } from '@ngrx/store';
import { DateTime } from 'luxon';

import { STORE_SLICE_KEY } from './state';

export const getSynthesisReport = createAction(
	`[ ${STORE_SLICE_KEY} / GET ] -> Synthesis Report`,
	props<{
		from: DateTime;
		to: DateTime;
		singleRestaurant?: boolean;
	}>()
);
export const getSynthesisReportSuccess = createAction(
	`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> Synthesis Report`,
	props<{
		data: api.SynthesisReport;
	}>()
);
export const getSynthesisReportFail = createAction(
	`[ ${STORE_SLICE_KEY} / GET FAIL ] -> Synthesis Report`,
	props<{
		error: HttpErrorResponse;
	}>()
);

export const getSynthesisVatReport = createAction(
	`[ ${STORE_SLICE_KEY} / GET ] -> Synthesis VAT Report`,
	props<{
		from: DateTime;
		to: DateTime;
	}>()
);
export const getSynthesisVatReportSuccess = createAction(
	`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> Synthesis VAT Report`,
	props<{
		data: api.Synthesis2Report;
	}>()
);
export const getSynthesisVatReportFail = createAction(
	`[ ${STORE_SLICE_KEY} / GET FAIL ] -> Synthesis VAT Report`,
	props<{
		error: HttpErrorResponse;
	}>()
);
