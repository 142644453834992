export enum ChannelsOrder {
	KIOSK_EAT_IN,
	KIOSK_TAKE_OUT,
	EAT_IN,
	TAKE_OUT,
	SALLE,
	DRIVE_THROUGH,
	DELIVERY,
	PICKUP,
	PARKING,
}

export const getChannelOrder = (channel: string) => {
	return ChannelsOrder[channel] == undefined ? 100 : ChannelsOrder[channel];
};

export const sortChannels = (a, b) => {
	return getChannelOrder(a) - getChannelOrder(b);
};
