import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DomWindowConfigurationServiceProvider } from '@bk/frontend-common';
import * as dkiApi from '@dki/api-client';
import * as gapApi from '@gap/api-client';
import { ConfigurationService } from '@merim/utils';
import { AuthHttpInterceptor } from '../guards';
import { ErrorDialogModule } from '../shared/error-dialog/error-dialog.module';
import { RootStoreModule } from '../store';

const API_MODULES = [dkiApi.ApiModule, gapApi.ApiModule];
const API_CONFIGS = [
	{
		provide: dkiApi.Configuration,
		useFactory: (configService: ConfigurationService) =>
			// TODO:
			new dkiApi.Configuration({
				basePath: configService.getApiUrl('dki-api'),
				// accessToken: '8332-xF9OLGEWQFwiD4LLJjNd7lZ0s4dff-L3hiNeL6ZxK_s', <- TODO: Check if this is still relevant
				apiKeys: {},
			}),
		deps: [ConfigurationService],
		multi: false,
	},
	{
		provide: gapApi.Configuration,
		useFactory: (configService: ConfigurationService) =>
			// TODO:
			new gapApi.Configuration({
				basePath: configService.getApiUrl('gap-api'),
				apiKeys: {},
			}),
		deps: [ConfigurationService],
		multi: false,
	},
];

export const coreProviders: Provider[] = [
	API_CONFIGS,
	DomWindowConfigurationServiceProvider,
	{
		provide: HTTP_INTERCEPTORS,
		useClass: AuthHttpInterceptor,
		multi: true,
	},
];

@NgModule({
	imports: [CommonModule, RootStoreModule, ErrorDialogModule, MatSnackBarModule, API_MODULES],
	providers: [...coreProviders],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
		if (parentModule) {
			throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
		}
	}
}
