/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { Artifact } from '../model/models';
import { ArtifactInput } from '../model/models';
import { ArtifactUpdateInput } from '../model/models';
import { HTTPValidationError } from '../model/models';
import { Media } from '../model/models';
import { MediaData } from '../model/models';
import { MediaHighlight } from '../model/models';
import { MediaHighlightInput } from '../model/models';
import { MediaHighlightUpdateInput } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable()
export class MediasApiClient {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Clone Media
     * @param mediaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cloneMediaV1MediasMediaIdCloneGet(mediaId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Media>;
    public cloneMediaV1MediasMediaIdCloneGet(mediaId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Media>>;
    public cloneMediaV1MediasMediaIdCloneGet(mediaId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Media>>;
    public cloneMediaV1MediasMediaIdCloneGet(mediaId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling cloneMediaV1MediasMediaIdCloneGet.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Media>(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/clone`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Media Artifact
     * @param mediaId 
     * @param artifactInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMediaArtifactV1MediasMediaIdArtifactsPost(mediaId: number, artifactInput: ArtifactInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Artifact>;
    public createMediaArtifactV1MediasMediaIdArtifactsPost(mediaId: number, artifactInput: ArtifactInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Artifact>>;
    public createMediaArtifactV1MediasMediaIdArtifactsPost(mediaId: number, artifactInput: ArtifactInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Artifact>>;
    public createMediaArtifactV1MediasMediaIdArtifactsPost(mediaId: number, artifactInput: ArtifactInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling createMediaArtifactV1MediasMediaIdArtifactsPost.');
        }
        if (artifactInput === null || artifactInput === undefined) {
            throw new Error('Required parameter artifactInput was null or undefined when calling createMediaArtifactV1MediasMediaIdArtifactsPost.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Artifact>(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/artifacts`,
            artifactInput,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Media Highlight
     * @param mediaId 
     * @param mediaHighlightInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMediaHighlightV1MediasMediaIdHighlightsPost(mediaId: number, mediaHighlightInput: MediaHighlightInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MediaHighlight>;
    public createMediaHighlightV1MediasMediaIdHighlightsPost(mediaId: number, mediaHighlightInput: MediaHighlightInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MediaHighlight>>;
    public createMediaHighlightV1MediasMediaIdHighlightsPost(mediaId: number, mediaHighlightInput: MediaHighlightInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MediaHighlight>>;
    public createMediaHighlightV1MediasMediaIdHighlightsPost(mediaId: number, mediaHighlightInput: MediaHighlightInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling createMediaHighlightV1MediasMediaIdHighlightsPost.');
        }
        if (mediaHighlightInput === null || mediaHighlightInput === undefined) {
            throw new Error('Required parameter mediaHighlightInput was null or undefined when calling createMediaHighlightV1MediasMediaIdHighlightsPost.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<MediaHighlight>(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/highlights`,
            mediaHighlightInput,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Media
     * @param file 
     * @param name Name of media
     * @param fallback 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMediaV1MediasPost(file: Blob, name: string, fallback?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Media>;
    public createMediaV1MediasPost(file: Blob, name: string, fallback?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Media>>;
    public createMediaV1MediasPost(file: Blob, name: string, fallback?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Media>>;
    public createMediaV1MediasPost(file: Blob, name: string, fallback?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling createMediaV1MediasPost.');
        }
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling createMediaV1MediasPost.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (fallback !== undefined) {
            formParams = formParams.append('fallback', <any>fallback) as any || formParams;
        }
        if (name !== undefined) {
            formParams = formParams.append('name', <any>name) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Media>(`${this.configuration.basePath}/v1/medias`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Media Artifact
     * @param mediaId 
     * @param artifactId Ids of media artifacts to be deleted.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMediaArtifactV1MediasMediaIdArtifactsDelete(mediaId: number, artifactId: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteMediaArtifactV1MediasMediaIdArtifactsDelete(mediaId: number, artifactId: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteMediaArtifactV1MediasMediaIdArtifactsDelete(mediaId: number, artifactId: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteMediaArtifactV1MediasMediaIdArtifactsDelete(mediaId: number, artifactId: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling deleteMediaArtifactV1MediasMediaIdArtifactsDelete.');
        }
        if (artifactId === null || artifactId === undefined) {
            throw new Error('Required parameter artifactId was null or undefined when calling deleteMediaArtifactV1MediasMediaIdArtifactsDelete.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (artifactId) {
            artifactId.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'artifact_id');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/artifacts`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Media Highlight
     * @param highlightId Ids of highlights to be deleted.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMediaHighlightV1MediasHighlightsDelete(highlightId: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteMediaHighlightV1MediasHighlightsDelete(highlightId: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteMediaHighlightV1MediasHighlightsDelete(highlightId: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteMediaHighlightV1MediasHighlightsDelete(highlightId: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (highlightId === null || highlightId === undefined) {
            throw new Error('Required parameter highlightId was null or undefined when calling deleteMediaHighlightV1MediasHighlightsDelete.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (highlightId) {
            highlightId.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'highlight_id');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/medias/highlights`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Media Zip
     * @param mediaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMediaZipV1MediasMediaIdZipDelete(mediaId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteMediaZipV1MediasMediaIdZipDelete(mediaId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteMediaZipV1MediasMediaIdZipDelete(mediaId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteMediaZipV1MediasMediaIdZipDelete(mediaId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling deleteMediaZipV1MediasMediaIdZipDelete.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/zip`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Medias
     * @param id Ids of medias to be deleted.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteVatTariffV1MediasDelete(id: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteVatTariffV1MediasDelete(id: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteVatTariffV1MediasDelete(id: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteVatTariffV1MediasDelete(id: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteVatTariffV1MediasDelete.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (id) {
            id.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'id');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/medias`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download Media Zip
     * @param mediaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadMediaZipV1MediasMediaIdZipGet(mediaId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/zip' | 'application/json'}): Observable<Blob>;
    public downloadMediaZipV1MediasMediaIdZipGet(mediaId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/zip' | 'application/json'}): Observable<HttpResponse<Blob>>;
    public downloadMediaZipV1MediasMediaIdZipGet(mediaId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/zip' | 'application/json'}): Observable<HttpEvent<Blob>>;
    public downloadMediaZipV1MediasMediaIdZipGet(mediaId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/zip' | 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling downloadMediaZipV1MediasMediaIdZipGet.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/zip',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/zip`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Medias
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllMediasV1MediasGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Media>>;
    public getAllMediasV1MediasGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Media>>>;
    public getAllMediasV1MediasGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Media>>>;
    public getAllMediasV1MediasGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Media>>(`${this.configuration.basePath}/v1/medias`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Media Artifact File
     * @param mediaId 
     * @param artifactId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaArtifactFileV1MediasMediaIdArtifactsArtifactIdFileGet(mediaId: number, artifactId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/jpeg' | 'image/png' | 'application/json'}): Observable<Blob>;
    public getMediaArtifactFileV1MediasMediaIdArtifactsArtifactIdFileGet(mediaId: number, artifactId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/jpeg' | 'image/png' | 'application/json'}): Observable<HttpResponse<Blob>>;
    public getMediaArtifactFileV1MediasMediaIdArtifactsArtifactIdFileGet(mediaId: number, artifactId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/jpeg' | 'image/png' | 'application/json'}): Observable<HttpEvent<Blob>>;
    public getMediaArtifactFileV1MediasMediaIdArtifactsArtifactIdFileGet(mediaId: number, artifactId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'image/jpeg' | 'image/png' | 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling getMediaArtifactFileV1MediasMediaIdArtifactsArtifactIdFileGet.');
        }
        if (artifactId === null || artifactId === undefined) {
            throw new Error('Required parameter artifactId was null or undefined when calling getMediaArtifactFileV1MediasMediaIdArtifactsArtifactIdFileGet.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'image/jpeg',
                'image/png',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/artifacts/${encodeURIComponent(String(artifactId))}/file`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Media Artifact
     * @param mediaId 
     * @param artifactId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaArtifactV1MediasMediaIdArtifactsArtifactIdGet(mediaId: number, artifactId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Artifact>;
    public getMediaArtifactV1MediasMediaIdArtifactsArtifactIdGet(mediaId: number, artifactId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Artifact>>;
    public getMediaArtifactV1MediasMediaIdArtifactsArtifactIdGet(mediaId: number, artifactId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Artifact>>;
    public getMediaArtifactV1MediasMediaIdArtifactsArtifactIdGet(mediaId: number, artifactId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling getMediaArtifactV1MediasMediaIdArtifactsArtifactIdGet.');
        }
        if (artifactId === null || artifactId === undefined) {
            throw new Error('Required parameter artifactId was null or undefined when calling getMediaArtifactV1MediasMediaIdArtifactsArtifactIdGet.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Artifact>(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/artifacts/${encodeURIComponent(String(artifactId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Media Artifacts
     * @param mediaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaArtifactsV1MediasMediaIdArtifactsGet(mediaId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Artifact>>;
    public getMediaArtifactsV1MediasMediaIdArtifactsGet(mediaId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Artifact>>>;
    public getMediaArtifactsV1MediasMediaIdArtifactsGet(mediaId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Artifact>>>;
    public getMediaArtifactsV1MediasMediaIdArtifactsGet(mediaId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling getMediaArtifactsV1MediasMediaIdArtifactsGet.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Artifact>>(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/artifacts`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Media Fallback
     * @param mediaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaFallbackV1MediasMediaIdFallbackGet(mediaId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png' | 'image/jpeg' | 'application/json'}): Observable<Blob>;
    public getMediaFallbackV1MediasMediaIdFallbackGet(mediaId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png' | 'image/jpeg' | 'application/json'}): Observable<HttpResponse<Blob>>;
    public getMediaFallbackV1MediasMediaIdFallbackGet(mediaId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/png' | 'image/jpeg' | 'application/json'}): Observable<HttpEvent<Blob>>;
    public getMediaFallbackV1MediasMediaIdFallbackGet(mediaId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'image/png' | 'image/jpeg' | 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling getMediaFallbackV1MediasMediaIdFallbackGet.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'image/png',
                'image/jpeg',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/fallback`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Media File
     * @param mediaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaFileV1MediasMediaIdFileGet(mediaId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/jpeg' | 'image/png' | 'text/html' | 'video/mp4' | 'application/json'}): Observable<Blob>;
    public getMediaFileV1MediasMediaIdFileGet(mediaId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/jpeg' | 'image/png' | 'text/html' | 'video/mp4' | 'application/json'}): Observable<HttpResponse<Blob>>;
    public getMediaFileV1MediasMediaIdFileGet(mediaId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'image/jpeg' | 'image/png' | 'text/html' | 'video/mp4' | 'application/json'}): Observable<HttpEvent<Blob>>;
    public getMediaFileV1MediasMediaIdFileGet(mediaId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'image/jpeg' | 'image/png' | 'text/html' | 'video/mp4' | 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling getMediaFileV1MediasMediaIdFileGet.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'image/jpeg',
                'image/png',
                'text/html',
                'video/mp4',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/file`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Media Highlights
     * @param mediaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaHighlightsV1MediasMediaIdHighlightsGet(mediaId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<MediaHighlight>>;
    public getMediaHighlightsV1MediasMediaIdHighlightsGet(mediaId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<MediaHighlight>>>;
    public getMediaHighlightsV1MediasMediaIdHighlightsGet(mediaId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<MediaHighlight>>>;
    public getMediaHighlightsV1MediasMediaIdHighlightsGet(mediaId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling getMediaHighlightsV1MediasMediaIdHighlightsGet.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<MediaHighlight>>(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/highlights`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Media
     * @param mediaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaV1MediasMediaIdGet(mediaId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Media>;
    public getMediaV1MediasMediaIdGet(mediaId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Media>>;
    public getMediaV1MediasMediaIdGet(mediaId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Media>>;
    public getMediaV1MediasMediaIdGet(mediaId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling getMediaV1MediasMediaIdGet.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Media>(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Medias Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediasDataV1MediasDataGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MediaData>;
    public getMediasDataV1MediasDataGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MediaData>>;
    public getMediasDataV1MediasDataGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MediaData>>;
    public getMediasDataV1MediasDataGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<MediaData>(`${this.configuration.basePath}/v1/medias/data`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify Media Artifact
     * @param mediaId 
     * @param artifactId 
     * @param artifactUpdateInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyMediaArtifactV1MediasMediaIdArtifactsArtifactIdPatch(mediaId: number, artifactId: number, artifactUpdateInput: ArtifactUpdateInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Artifact>;
    public modifyMediaArtifactV1MediasMediaIdArtifactsArtifactIdPatch(mediaId: number, artifactId: number, artifactUpdateInput: ArtifactUpdateInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Artifact>>;
    public modifyMediaArtifactV1MediasMediaIdArtifactsArtifactIdPatch(mediaId: number, artifactId: number, artifactUpdateInput: ArtifactUpdateInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Artifact>>;
    public modifyMediaArtifactV1MediasMediaIdArtifactsArtifactIdPatch(mediaId: number, artifactId: number, artifactUpdateInput: ArtifactUpdateInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling modifyMediaArtifactV1MediasMediaIdArtifactsArtifactIdPatch.');
        }
        if (artifactId === null || artifactId === undefined) {
            throw new Error('Required parameter artifactId was null or undefined when calling modifyMediaArtifactV1MediasMediaIdArtifactsArtifactIdPatch.');
        }
        if (artifactUpdateInput === null || artifactUpdateInput === undefined) {
            throw new Error('Required parameter artifactUpdateInput was null or undefined when calling modifyMediaArtifactV1MediasMediaIdArtifactsArtifactIdPatch.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<Artifact>(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/artifacts/${encodeURIComponent(String(artifactId))}`,
            artifactUpdateInput,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify Media Highlight
     * @param highlightId 
     * @param mediaHighlightUpdateInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modifyMediaHighlightV1MediasHighlightsHighlightIdPatch(highlightId: number, mediaHighlightUpdateInput: MediaHighlightUpdateInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MediaHighlight>;
    public modifyMediaHighlightV1MediasHighlightsHighlightIdPatch(highlightId: number, mediaHighlightUpdateInput: MediaHighlightUpdateInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MediaHighlight>>;
    public modifyMediaHighlightV1MediasHighlightsHighlightIdPatch(highlightId: number, mediaHighlightUpdateInput: MediaHighlightUpdateInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MediaHighlight>>;
    public modifyMediaHighlightV1MediasHighlightsHighlightIdPatch(highlightId: number, mediaHighlightUpdateInput: MediaHighlightUpdateInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (highlightId === null || highlightId === undefined) {
            throw new Error('Required parameter highlightId was null or undefined when calling modifyMediaHighlightV1MediasHighlightsHighlightIdPatch.');
        }
        if (mediaHighlightUpdateInput === null || mediaHighlightUpdateInput === undefined) {
            throw new Error('Required parameter mediaHighlightUpdateInput was null or undefined when calling modifyMediaHighlightV1MediasHighlightsHighlightIdPatch.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<MediaHighlight>(`${this.configuration.basePath}/v1/medias/highlights/${encodeURIComponent(String(highlightId))}`,
            mediaHighlightUpdateInput,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post Media Artifact File
     * @param mediaId 
     * @param artifactId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMediaArtifactFileV1MediasMediaIdArtifactsArtifactIdFilePost(mediaId: number, artifactId: number, file: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Artifact>;
    public postMediaArtifactFileV1MediasMediaIdArtifactsArtifactIdFilePost(mediaId: number, artifactId: number, file: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Artifact>>;
    public postMediaArtifactFileV1MediasMediaIdArtifactsArtifactIdFilePost(mediaId: number, artifactId: number, file: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Artifact>>;
    public postMediaArtifactFileV1MediasMediaIdArtifactsArtifactIdFilePost(mediaId: number, artifactId: number, file: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling postMediaArtifactFileV1MediasMediaIdArtifactsArtifactIdFilePost.');
        }
        if (artifactId === null || artifactId === undefined) {
            throw new Error('Required parameter artifactId was null or undefined when calling postMediaArtifactFileV1MediasMediaIdArtifactsArtifactIdFilePost.');
        }
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling postMediaArtifactFileV1MediasMediaIdArtifactsArtifactIdFilePost.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Artifact>(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/artifacts/${encodeURIComponent(String(artifactId))}/file`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Media
     * @param mediaId 
     * @param file 
     * @param fallback 
     * @param name Name of the media
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMediaV1MediasMediaIdPatch(mediaId: number, file?: Blob, fallback?: Blob, name?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Media>;
    public updateMediaV1MediasMediaIdPatch(mediaId: number, file?: Blob, fallback?: Blob, name?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Media>>;
    public updateMediaV1MediasMediaIdPatch(mediaId: number, file?: Blob, fallback?: Blob, name?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Media>>;
    public updateMediaV1MediasMediaIdPatch(mediaId: number, file?: Blob, fallback?: Blob, name?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling updateMediaV1MediasMediaIdPatch.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (fallback !== undefined) {
            formParams = formParams.append('fallback', <any>fallback) as any || formParams;
        }
        if (name !== undefined) {
            formParams = formParams.append('name', <any>name) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<Media>(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload Media Zip
     * @param mediaId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadMediaZipV1MediasMediaIdZipPost(mediaId: number, file: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public uploadMediaZipV1MediasMediaIdZipPost(mediaId: number, file: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public uploadMediaZipV1MediasMediaIdZipPost(mediaId: number, file: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public uploadMediaZipV1MediasMediaIdZipPost(mediaId: number, file: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling uploadMediaZipV1MediasMediaIdZipPost.');
        }
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling uploadMediaZipV1MediasMediaIdZipPost.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyCookie) required
        credential = this.configuration.lookupCredential('APIKeyCookie');
        if (credential) {
        }

        // authentication (HTTPBasic) required
        credential = this.configuration.lookupCredential('HTTPBasic');
        if (credential) {
            headers = headers.set('Authorization', 'Basic ' + credential);
        }

        // authentication (OAuth2PasswordBearer) required
        credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/medias/${encodeURIComponent(String(mediaId))}/zip`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
