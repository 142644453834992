import { isAlignedVatUsable, isItemIncludedInVatAlignment, updatePriceWithVat } from "@bk/price-management-common";
import { WeborderChannel } from "@gap/api-client-nestjs/models";
import { BrandName, BrandNameNew, isBk, isBkFR } from "@merim/utils";
import { DrinkCodesDto } from "@rap/api-client/models";
import { BKOrderSourceEnum } from "./BKOrderSourceEnum";
import {
	EmployeeMealEventArguments,
	IBKDiscountInOrderData,
	IBKIngredientData,
	IBKItemInOrderBase,
	IBKItemPriceInfo,
	IBKOrderEventData,
	IBKPickUpTypeParkingDetails,
	IBKProductBase,
	IBKProductGroupData,
	IBKProductIngredientSelectedData,
	IBKPublishedOrderData,
	ICsiUserSession,
} from "./myBKCommonDatas";
import {
	BKDeliveryBrandName,
	BKDeliveryModeEnum,
	BKDeliverySandboxName,
	BKDiscountTypeEnum,
	BKOrderEventType,
	BKPickUpTypeEnum,
	BKTableAllocationLocationSpace,
	BKTableAllocationType,
} from "./myBKCommonEnums";
import { _BK, BKKingdomUtils, BKOrderEventUtilities, BKOrderUtilities, IBKTableAllocationAssignment } from "./myBKCommonPatterns";
import { CsiBKPNs, CsiItemTypeEnum, CsiLines, CsiLineTextFormatConstants, CsiResponses, CsiState, CsiUnit, ICsiLineTextFormat, ItemInOrderState } from "./myBKCsi";
import {
	CsiComplementCle,
	ICsiComplement,
	ICsiDeliverLaterInfo,
	ICsiIngredient,
	ICsiLine,
	ICsiLineGenerator,
	ICsiMenuInOrder,
	ICsiOrder,
	ICsiProductInOrder,
	ICsiProductProcessedIngredient,
	ICsiTicket,
	ICsiTicketSettings,
} from "./myBKSchemasCsi";

export class CsiManager {
	private constructor(
		private readonly itemIdentifier: (item: IBKItemInOrderBase) => CsiItemTypeEnum,
		private readonly productConverter: (item: IBKItemInOrderBase) => ICsiProductInOrder,
		private readonly menuConverter: (item: IBKItemInOrderBase) => ICsiMenuInOrder
	) {}

	/**
	 * Check for a valid OK response
	 */
	public static isValidOKResponse(r: any): boolean {
		if (CsiManager.isUndefined(r.code) || CsiManager.isUndefined(r.response)) return false;
		return r.code === CsiResponses.STATUS_OK;
	}

	/**
	 * Check for a valid pay response
	 */
	public static isValidPayResponse(r: any): boolean {
		if (CsiManager.isUndefined(r.code) || CsiManager.isUndefined(r.response)) return false;
		const code: number = parseInt(r.code);
		if (isNaN(code)) return false;
		return (
			code === CsiResponses.STATUS_OK || code === CsiResponses.STATUS_TICKET_NON_SOLDE || code === CsiResponses.STATUS_TICKET_SOLDE || code === CsiResponses.STATUS_TICKET_RENDU
		);
	}

	private static composeLibelleTVA(percent: number): string {
		return "TVA " + percent.toString().replace(".", ",") + "%";
	}

	/**
	 * Add a product to the ticket
	 */
	private addProductToTicket(
		settings: ICsiTicketSettings,
		ticket: ICsiTicket,
		pio: ICsiProductInOrder /*BKProductInOrder*/,
		order: ICsiOrder /*BKOrder*/,
		deliverLater: ICsiDeliverLaterInfo[],
		parentUUID: string | null,
		quantity: number,
		productStatus: ItemInOrderState,
		deleteTicket: boolean,
		isMenuExtraStep = false,
		isProductInMenu = false,
		isProductFree = false,
		alignedVat: number = undefined
	): void {
		quantity = isProductInMenu ? quantity : pio.qty;
		//  Ensure the values are inited
		if (pio.line <= 0) {
			pio.line = order.getNextLineIndex();
		}
		if (pio.lineuuid === null || pio.lineuuid === "" || CsiManager.isDuplicateLineUuid(pio.lineuuid, ticket)) {
			pio.lineuuid = CsiManager.generateUUID();
		}
		//  Check for the time
		if (pio.timestamp <= 0) pio.timestamp = CsiManager.now();
		//  Check for the type if line
		let typeLine: string = CsiLines.ARTICLE;
		switch (productStatus) {
			case ItemInOrderState.DELETED_AFTER_KITCHEN:
				typeLine = CsiLines.ARTICLE_DELETE_AFTER_KITCHEN_SCREEN;
				break;
			case ItemInOrderState.DELETED_AFTER_ORDER_SUB_TOTAL:
				typeLine = CsiLines.ARTICLE_DELETE_AFTER_ORDER_SUB_TOTAL;
				break;
			case ItemInOrderState.DELETED_BEFORE_KITCHEN:
				typeLine = CsiLines.ARTICLE_DELETE_BEFORE_KITCHEN_SCREEN;
				break;
		}
		if (deleteTicket) {
			typeLine = CsiLines.ARTICLE_DELETE_AFTER_ORDER_SUB_TOTAL;
		}
		//  Compose the tag ( Check for the BKPN )
		let bkpn: number = parseInt(pio.ref.bkpn);
		if (isNaN(bkpn)) bkpn = 7414;
		const tagElements: { [key: string]: string } = {
			product_id: pio.id.toString(),
			third_party_id: bkpn.toString(),
			a_la_carte_price: pio.aLaCartePrice.ttc.toString(),
		};
		const libelle: string = pio.sysName;
		if (CsiManager.isDefined(pio._altName) && pio._altName !== "") {
			tagElements["libelle_substitution"] = CsiManager.truncateToCSISize(pio._altName);
		}

		//  Compose
		const tag: string = CsiManager.object2URLEncoded(tagElements);
		//  Get the price of the product
		let exportedPrice: IBKItemPriceInfo = CsiManager.pickupPriceForDeliveryMode(pio.aLaCartePrice, order.deliveryMode);
		if (isAlignedVatUsable(pio, alignedVat, exportedPrice.pc)) {
			exportedPrice = updatePriceWithVat(exportedPrice, alignedVat);
		}
		// function to calculate price based on aligned tva;
		const priceExtraMenuTTC = _BK.isDefined(pio.priceExtraMenuTTC) ? pio.priceExtraMenuTTC : pio._priceExtraMenuTTC;
		const priceSuggestionMenuTTC = _BK.isDefined(pio.priceSuggestionMenuTTC) ? pio.priceSuggestionMenuTTC : pio._priceSuggestionMenuTTC;
		const menuExtra: number = Math.max(
			0,
			isMenuExtraStep ? (CsiManager.isUndefined(priceSuggestionMenuTTC) ? 0 : priceSuggestionMenuTTC) : CsiManager.isUndefined(priceExtraMenuTTC) ? 0 : priceExtraMenuTTC
		);
		const machineIp = pio.machine?.ip || (order.machine === null ? "" : order.machine.ip);
		const machineIdx = pio.machine?.idx || (order.machine === null ? 0 : order.machine.idx);
		//  Create the line
		const line: ICsiLine = {
			lineUuid: pio.lineuuid,
			numero: pio.id,
			horodatage: pio.timestamp,

			libelleTarif: "",
			numTarif: 0,

			libelle: CsiManager.truncateToCSISize(/*pio.sysName*/ libelle),
			code: pio.ref.plu,
			prix: isProductInMenu ? menuExtra : exportedPrice.ttc,
			prixUnitaireCarte: isMenuExtraStep ? menuExtra : exportedPrice.ttc,
			qte: quantity,
			poids: 1,
			unite: CsiUnit.PIECE,
			ticketUuid: ticket.ticketUuid,
			typeLigne: typeLine,
			numClef: pio.user?.id || order.user.id,
			nomClef: pio.user?.login || order.user.login,
			codeManager: pio.manager?.id || order.manager.id,
			nomManager: pio.manager?.login || order.manager.login,
			codeVendeur: pio.user?.id || order.user.id,
			nomVendeur: pio.user?.login || order.user.login,
			//  TODO
			libFamille: pio._productFamily == null ? "" : pio._productFamily._name?.fr || pio._productFamily.name, //! TEMP, should be sysName later
			libSousFamille: pio._productSubFamily == null ? "" : pio._productSubFamily._name?.fr || pio._productSubFamily.name, //! TEMP, should be sysName later
			libGroupe: pio._productGroup == null ? "" : pio._productGroup.name,
			numFamille: pio._productFamily == null ? 0 : pio._productFamily.id,
			numSousFamille: pio._productSubFamily == null ? 0 : pio._productSubFamily.id,
			numGroupe: pio._productGroup == null ? 0 : pio._productGroup.id,
			//  TVA
			libelleTVA: CsiManager.composeLibelleTVA(exportedPrice.pc /*pio.aLaCartePrice.pc*/),
			numTVA: Math.round(100 * exportedPrice.pc /*pio.aLaCartePrice.pc*/),
			tauxTVA: exportedPrice.pc /*pio.aLaCartePrice.pc*/,
			//  Machine infos
			libCaisse: machineIp,
			numCaisse: machineIdx,

			//  TODO
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: "AUCUN",
			serviceMontant: 0,

			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: "euro",
			deviseTaux: 1,
			deviseLibelle: "euro",
			deviseMontant: pio._crown.crownEnable && !isProductInMenu ? pio._crown.crown : 0,
			tag: tag,
			libelleModifier: "",
			libelleQualifier: "",
			change: false,
			filter: pio._excludeFromGlobalDiscount ? 0 : 1,
		};

		if (settings.addProfitCenter) {
			line.centreProfit = settings.addProfitCenter;
		}
		//  Check for the parent line
		if (parentUUID !== null) {
			line.parentLineUuid = parentUUID;
		}

		// BKFR - free item is inserted with price and discount is added later
		if (isProductFree === true && settings.brandName !== BrandName.BurgerKing_France && settings.brandName !== BrandNameNew.BurgerKing_France) {
			line.prix = 0;
			line.prixUnitaireCarte = 0;
		}
		//  Append the line
		ticket.lines.push(line);
		//  Check for modifiers
		if (pio.recipe && pio.recipe.length > 0) {
			for (let i = 0; i < pio.recipe.length; i++) {
				const line = pio.recipe[i];
				if (line.lineuuid === null || line.lineuuid === "") line.lineuuid = CsiManager.generateUUID();
				//  Check for modified
				if (!line.modified) continue;
				//  Check for a replacement
				if (pio.isComposable) {
					CsiManager.addRecipeGroup(settings, ticket, line, pio._processedIngredients[i], pio, order, quantity, deleteTicket, productStatus);
				} else if (line.initial > 0 && line.selected !== line.initial && line.amount == line.initAmount && line.priceTTC <= 0) {
					CsiManager.addRecipeModifierReplace(settings, ticket, line, pio._processedIngredients[i], pio, order, quantity, deleteTicket, productStatus, alignedVat);
				} else {
					CsiManager.addRecipeModifier(settings, ticket, line, pio._processedIngredients[i], pio, order, quantity, deleteTicket, productStatus, alignedVat);
				}
			}
		}
		// Add removed ingredient modifiers for statistical purposes
		const addRemovedRecipeModificatorToOrder = (ingredient: IBKProductIngredientSelectedData, state: ItemInOrderState) => {
			const modifiedIngredient = pio._processedIngredients.find((procesedIngredient) => {
				return procesedIngredient._availableIngredients.find((availableIngredient) => {
					if (availableIngredient.id === ingredient.selected) {
						return true;
					}
					return ingredient?.selectedIngredients?.some((selectedIngredient) => selectedIngredient.id === availableIngredient.id);
				});
			});

			if (pio.isComposable) {
				CsiManager.addRecipeGroup(settings, ticket, ingredient, modifiedIngredient, pio, order, quantity, deleteTicket, state);
			} else {
				CsiManager.addRecipeModifier(settings, ticket, ingredient, modifiedIngredient, pio, order, quantity, deleteTicket, state, alignedVat);
			}
		};
		pio.removedRecipeModificatorsBeforeKitchenScreen?.forEach((ingredient: IBKProductIngredientSelectedData) => {
			addRemovedRecipeModificatorToOrder(ingredient, ItemInOrderState.DELETED_BEFORE_KITCHEN);
		});
		pio.removedRecipeModificatorsAfterKitchenScreen?.forEach((ingredient: IBKProductIngredientSelectedData) => {
			addRemovedRecipeModificatorToOrder(ingredient, ItemInOrderState.DELETED_AFTER_KITCHEN);
		});
		pio.removedRecipeModificatorsAfterSubtotal?.forEach((ingredient: IBKProductIngredientSelectedData) => {
			addRemovedRecipeModificatorToOrder(ingredient, ItemInOrderState.DELETED_AFTER_ORDER_SUB_TOTAL);
		});
		//  Add the discount
		if (productStatus === ItemInOrderState.ACTIVE && !deleteTicket) {
			(pio.itemAppliedDiscount || []).forEach(function (d: IBKDiscountInOrderData) {
				CsiManager.addOrderDiscountLinetoTicket(settings, ticket, d, pio.lineuuid, pio.timestamp, order, pio, null, false, deleteTicket);
			});
		}
		//  Check for deliver later
		if (productStatus === ItemInOrderState.ACTIVE && !deleteTicket && pio.later) {
			//  Add it to the array of delivered later
			deliverLater.push({ menuQte: quantity, pio: pio });
			//  Add the comment
			CsiManager.addCommentToTicket(settings, ticket, order, line.lineUuid, "**Remis plus tard**");
		}
		//  Check for the free items
		if (pio.freeItems && pio.freeItems.length > 0) {
			pio.freeItems.forEach((freepio: IBKItemInOrderBase) => {
				const csifreepio = this.productConverter(freepio);
				if (csifreepio !== null) {
					const freeProductParentUuid = parentUUID ? parentUUID : line.lineUuid;
					this.addFreeProduct(settings, ticket, csifreepio, order, deliverLater, freeProductParentUuid, quantity, deleteTicket, productStatus);
				}
			});
		}
	}

	/**
	 * Add a menu to the ticket
	 */
	private addMenuToTicket(
		settings: ICsiTicketSettings,
		ticket: ICsiTicket,
		csiMenuInOrder: ICsiMenuInOrder,
		order: ICsiOrder,
		deliverLater: ICsiDeliverLaterInfo[],
		productState: ItemInOrderState,
		deleteTicket: boolean
	) {
		//  Ensure the values are inited
		if (csiMenuInOrder.line <= 0) {
			csiMenuInOrder.line = order.getNextLineIndex();
		}
		if (csiMenuInOrder.lineuuid === null || csiMenuInOrder.lineuuid === "" || CsiManager.isDuplicateLineUuid(csiMenuInOrder.lineuuid, ticket)) {
			csiMenuInOrder.lineuuid = CsiManager.generateUUID();
		}
		//  Check for the time
		if (csiMenuInOrder.timestamp <= 0) csiMenuInOrder.timestamp = CsiManager.now();
		//  Check for the type if line
		let typeLine: string = CsiLines.VALUEMEAL;
		switch (productState) {
			case ItemInOrderState.DELETED_AFTER_KITCHEN:
				typeLine = CsiLines.VALUEMEAL_DELETE_AFTER_KITCHEN_SCREEN;
				break;
			case ItemInOrderState.DELETED_AFTER_ORDER_SUB_TOTAL:
				typeLine = CsiLines.VALUEMEAL_DELETE_AFTER_ORDER_SUB_TOTAL;
				break;
			case ItemInOrderState.DELETED_BEFORE_KITCHEN:
				typeLine = CsiLines.VALUEMEAL_DELETE_BEFORE_KITCHEN_SCREEN;
				break;
		}
		if (deleteTicket) {
			typeLine = CsiLines.VALUEMEAL_DELETE_AFTER_ORDER_SUB_TOTAL;
		}
		//  Compose the tag
		const tagElements: { [key: string]: string } = {
			menu_id: csiMenuInOrder.id.toString(),
			third_party_id: CsiBKPNs.getMenuBKPN(csiMenuInOrder),
			a_la_carte_price: csiMenuInOrder.aLaCartePrice.ttc.toString(),
		};
		const tag: string = CsiManager.object2URLEncoded(tagElements);
		//  Get the group
		let pgroup: IBKProductGroupData /*BKProductGroup*/ = csiMenuInOrder.large ? csiMenuInOrder._productGroupL! : csiMenuInOrder._productGroup;
		if (csiMenuInOrder?._productGroupXL && csiMenuInOrder.ref.plu === csiMenuInOrder?._refXL?.plu && csiMenuInOrder?.xl) {
			pgroup = csiMenuInOrder._productGroupXL;
		}
		//  Get the price of the menu (useless)
		const exportedPrice: IBKItemPriceInfo = CsiManager.pickupPriceForDeliveryMode(csiMenuInOrder.aLaCartePrice, order.deliveryMode);

		//  Lookup for an item with a negative price
		let elementDiscount = 0;
		for (let i = 0; i < (csiMenuInOrder.selection || []).length; i++) {
			const itemInOrderBase: IBKItemInOrderBase = csiMenuInOrder.selection![i];
			const csiProductInOrder = this.productConverter(itemInOrderBase);
			if (csiProductInOrder !== null) {
				const priceExtraMenuTTC = _BK.isDefined(csiProductInOrder.priceExtraMenuTTC) ? csiProductInOrder.priceExtraMenuTTC : csiProductInOrder._priceExtraMenuTTC;
				if (priceExtraMenuTTC < 0) {
					elementDiscount += priceExtraMenuTTC;
				}
			}
		}
		const machineIp = csiMenuInOrder.machine?.ip || (order.machine === null ? "" : order.machine.ip);
		const machineIdx = csiMenuInOrder.machine?.idx || (order.machine === null ? 0 : order.machine.idx);

		//  Create the line
		const line: ICsiLine = {
			lineUuid: csiMenuInOrder.lineuuid,
			numero: csiMenuInOrder.id,
			horodatage: csiMenuInOrder.timestamp,

			libelleTarif: "",
			numTarif: 0,

			libelle: CsiManager.truncateToCSISize(csiMenuInOrder.sysName),
			code: csiMenuInOrder.ref.plu,
			prix: csiMenuInOrder.aLaCartePrice.ttc + elementDiscount,
			prixUnitaireCarte: csiMenuInOrder.aLaCartePrice.ttc + elementDiscount,
			qte: csiMenuInOrder.qty,
			poids: 1,
			unite: CsiUnit.PIECE,
			ticketUuid: ticket.ticketUuid,
			/*parentLineUuid: parentUUID === null ? '' : parentUUID,*/
			typeLigne: typeLine,
			numClef: csiMenuInOrder.user?.id || order.user.id,
			nomClef: csiMenuInOrder.user?.login || order.user.login,
			codeManager: csiMenuInOrder.manager?.id || order.manager.id,
			nomManager: csiMenuInOrder.manager?.login || order.manager.login,
			codeVendeur: csiMenuInOrder.user?.id || order.user.id,
			nomVendeur: csiMenuInOrder.user?.login || order.user.login,
			//  TODO
			libFamille: "",
			libSousFamille: "",
			libGroupe: pgroup === null ? "" : pgroup.name,
			numFamille: 0,
			numSousFamille: 0,
			numGroupe: pgroup === null ? 0 : pgroup.id,
			//  TVAok,
			libelleTVA: CsiManager.composeLibelleTVA(exportedPrice.pc /*mio.aLaCartePrice.pc*/),
			numTVA: Math.round(100 * exportedPrice.pc /*mio.aLaCartePrice.pc*/),
			tauxTVA: exportedPrice.pc /*mio.aLaCartePrice.pc*/,
			//  Machine infos
			libCaisse: machineIp,
			numCaisse: machineIdx,

			//  TODO
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: "AUCUN",
			serviceMontant: 0,

			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: "euro",
			deviseTaux: 1,
			deviseLibelle: "euro",
			deviseMontant: 0,
			tag: tag,
			libelleModifier: "",
			libelleQualifier: "",
			change: false,
			filter: csiMenuInOrder._excludeFromGlobalDiscount ? 0 : 1,
		};

		if (settings.addProfitCenter) line.centreProfit = settings.addProfitCenter;

		//  Check for employee meal
		if (csiMenuInOrder._crown.crownEnable) {
			line.deviseMontant = line.prix;
		}
		//  Append the line
		ticket.lines.push(line);
		const highestAlignedVat: number = this.getHighestAlignedVat(csiMenuInOrder.selection, order.deliveryMode);
		//  Now push the content
		for (let i = 0; i < (csiMenuInOrder.selection || []).length; i++) {
			const itemInOrderBase: IBKItemInOrderBase = csiMenuInOrder.selection![i];
			const csiiio: ICsiProductInOrder = this.productConverter(itemInOrderBase);
			const updateItemWithAlignedVat: boolean = isAlignedVatUsable(
				csiiio,
				highestAlignedVat,
				order.deliveryMode !== BKDeliveryModeEnum.DELIVERY_LOCAL && (csiiio.aLaCartePrice.pcto || csiiio.aLaCartePrice.pcto === 0)
					? csiiio.aLaCartePrice.pcto
					: csiiio.aLaCartePrice.pc
			);
			if (csiiio !== null) {
				if (itemInOrderBase.timestamp <= 0) itemInOrderBase.timestamp = csiMenuInOrder.timestamp;
				this.addProductToTicket(
					settings,
					ticket,
					csiiio,
					order,
					deliverLater,
					line.lineUuid,
					csiMenuInOrder.qty,
					productState,
					deleteTicket,
					!(i < csiMenuInOrder._steps.length),
					true,
					false,
					updateItemWithAlignedVat ? highestAlignedVat : undefined
				);
			}
		}
		//  Apply the discounts only if not deleted
		if (productState === ItemInOrderState.ACTIVE && !deleteTicket) {
			//  Add the discount
			(csiMenuInOrder.itemAppliedDiscount || []).forEach(function (discount: IBKDiscountInOrderData) {
				CsiManager.addOrderDiscountLinetoTicket(settings, ticket, discount, csiMenuInOrder.lineuuid, csiMenuInOrder.timestamp, order, null, csiMenuInOrder, false, deleteTicket);
			});
		}
	}

	private static addRecipeModifierReplace(
		settings: ICsiTicketSettings,
		ticket: ICsiTicket,
		ingredientData: IBKProductIngredientSelectedData,
		productIngredientData: ICsiProductProcessedIngredient /*BKProductProcessedIngredient*/,
		product: ICsiProductInOrder,
		order: IBKPublishedOrderData /*BKOrder*/,
		quantity: number,
		deleteTicket: boolean,
		productStatus: ItemInOrderState,
		alignedVat: number = undefined
	): void {
		//  Check for the two uuid
		if (ingredientData.lineuuid === null || ingredientData.lineuuid === "") ingredientData.lineuuid = CsiManager.generateUUID();
		if (ingredientData.lineuuid2 === null || ingredientData.lineuuid2 === "" || _BK.isUndefined(ingredientData.lineuuid2)) ingredientData.lineuuid2 = CsiManager.generateUUID();
		//  Get initial ingredient
		let ingredientInitial: IBKIngredientData | null = null;
		for (let i = 0; i < productIngredientData._availableIngredients.length; i++) {
			if (productIngredientData._availableIngredients[i].id === ingredientData.initial) {
				ingredientInitial = productIngredientData._availableIngredients[i];
				break;
			}
		}
		if (ingredientInitial === null) {
			return;
		}
		//  Get selected ingredient
		let ingredientSelected: IBKIngredientData | null = null;
		for (let i = 0; i < productIngredientData._availableIngredients.length; i++) {
			if (productIngredientData._availableIngredients[i].id === ingredientData.selected) {
				ingredientSelected = productIngredientData._availableIngredients[i];
				break;
			}
		}

		// exit only if ingredient is not found and its not a replace-removal
		if (ingredientSelected === null && ingredientData.selected !== -1) {
			return;
		}

		//  Remove the ingredient
		const ingredientToRemove: IBKProductIngredientSelectedData = {
			selected: ingredientData.initial,
			initial: ingredientData.initial,
			modified: true,
			amount: ingredientData.amount,
			initAmount: ingredientData.initAmount,
			qty: 0,
			initQty: ingredientData.initQty,
			priceTTC: 0,
			sysName: ingredientInitial.sysName,
			line: ingredientData.line,
			lineuuid: ingredientData.lineuuid,
		};
		//  Generate the line
		CsiManager.addRecipeModifier(settings, ticket, ingredientToRemove, productIngredientData, product, order, quantity, deleteTicket, productStatus, alignedVat);

		// add ingredient if required (if just removed do nothing)
		if (ingredientSelected !== null) {
			//  Add the ingredient
			const ingredientToAdd: IBKProductIngredientSelectedData = {
				selected: ingredientData.selected,
				initial: ingredientData.initial,
				modified: true,
				amount: ingredientData.amount,
				initAmount: ingredientData.initAmount,
				qty: ingredientData.qty,
				initQty: 0,
				priceTTC: 0,
				sysName: ingredientSelected.sysName,
				line: ingredientData.line,
				lineuuid: ingredientData.lineuuid2,
			};
			//  Generate the line
			CsiManager.addRecipeModifier(settings, ticket, ingredientToAdd, productIngredientData, product, order, quantity, deleteTicket, productStatus, alignedVat);
			return;
		}
	}

	private static addRecipeGroup(
		settings: ICsiTicketSettings,
		ticket: ICsiTicket,
		ingredientData: IBKProductIngredientSelectedData,
		productIngredientData: ICsiProductProcessedIngredient /*BKProductProcessedIngredient*/,
		product: ICsiProductInOrder,
		order: IBKPublishedOrderData /*BKOrder*/,
		quantity: number,
		deleteTicket: boolean,
		productStatus: ItemInOrderState
	): void {
		const minfos: {
			label: string;
			bkpn: string;
			plumod: number;
		} | null = CsiBKPNs.getModifierInfos(ingredientData, productIngredientData);
		if (minfos === null) return;

		let typeLine: string = CsiLines.MODIFIER_QUALIFIER;
		switch (productStatus) {
			case ItemInOrderState.DELETED_AFTER_KITCHEN:
				typeLine = CsiLines.MODIFIER_QUALIFIER_DELETE_AFTER_KITCHEN_SCREEN;
				break;
			case ItemInOrderState.DELETED_AFTER_ORDER_SUB_TOTAL:
				typeLine = CsiLines.MODIFIER_QUALIFIER_DELETE_AFTER_ORDER_SUB_TOTAL;
				break;
			case ItemInOrderState.DELETED_BEFORE_KITCHEN:
				typeLine = CsiLines.MODIFIER_QUALIFIER_DELETE_BEFORE_KITCHEN_SCREEN;
				break;
		}
		if (deleteTicket) {
			typeLine = CsiLines.MODIFIER_QUALIFIER_DELETE_AFTER_ORDER_SUB_TOTAL;
		}

		ingredientData.selectedIngredients.forEach((selectedIngredient) => {
			const ingredient = productIngredientData._availableIngredients.find((ingredient) => ingredient.id === selectedIngredient.id);
			//  Fix the extension
			let qtp: string = ingredient.refI.bkpn;
			if (qtp.substr(-3, 3) === "000") {
				qtp = qtp.substr(0, qtp.length - 3);
			}

			//  Compose the tag
			const tagElements: { [key: string]: string } = {
				a_la_carte_price: selectedIngredient.price.ttc.toString(),
				modifier_third_party_id: minfos.bkpn,
				qualifier_third_party_id: qtp,
				qualifier_id: selectedIngredient.id.toString(),
			};
			const tag: string = CsiManager.object2URLEncoded(tagElements);
			//  Get the price of the product
			const exportedPrice: IBKItemPriceInfo = CsiManager.pickupPriceForDeliveryMode(product.aLaCartePrice, order.deliveryMode);

			// create new line uuid for removed ingredients, because line uuid needs to be unique
			const lineUuid = typeLine === CsiLines.MODIFIER_QUALIFIER ? selectedIngredient.lineuuid : CsiManager.generateUUID();
			//  Create the line
			const line: ICsiLine = {
				lineUuid: lineUuid,
				numero: parseInt(minfos.bkpn),
				horodatage: ingredientData.timestamp || product.timestamp,
				libelleTarif: "",
				numTarif: 0,
				libelle: "",
				code: CsiManager.patchIngredientCode(ingredient, minfos),
				prix: selectedIngredient.price.ttc,
				prixUnitaireCarte: selectedIngredient.price.ttc,
				qte: selectedIngredient.qty * quantity,
				poids: 1,
				unite: CsiUnit.PIECE,
				ticketUuid: ticket.ticketUuid,
				parentLineUuid: product.lineuuid,
				typeLigne: typeLine,
				numClef: ingredientData.csiUserSession?.numClef || product.user?.id || order.user.id,
				nomClef: ingredientData.csiUserSession?.nomClef || product.user?.login || order.user.login,
				codeManager: ingredientData.csiUserSession?.codeManager || product.manager?.id || order.manager.id,
				nomManager: ingredientData.csiUserSession?.nomManager || product.manager?.login || order.manager.login,
				codeVendeur: ingredientData.csiUserSession?.codeVendeur || product.user?.id || order.user.id,
				nomVendeur: ingredientData.csiUserSession?.nomVendeur || product.user?.login || order.user.login,
				libFamille: "",
				libSousFamille: "",
				libGroupe: ingredient._productGroup === null ? "" : ingredient._productGroup.name,
				numFamille: 0,
				numSousFamille: 0,
				numGroupe: ingredient._productGroup === null ? 0 : ingredient._productGroup.id,
				libelleTVA: CsiManager.composeLibelleTVA(exportedPrice.pc),
				numTVA: Math.round(100 * exportedPrice.pc),
				tauxTVA: exportedPrice.pc,
				libCaisse: ingredientData.csiUserSession?.libCaisse || product.machine?.ip || (order.machine === null ? "" : order.machine.ip),
				numCaisse: ingredientData.csiUserSession?.numCaisse || product.machine?.idx || (order.machine === null ? 0 : order.machine.idx),
				remiseMontantTTC: 0,
				remiseMontantHT: 0,
				remiseTaux: 0,
				serviceTaux: 0,
				serviceMode: "AUCUN",
				serviceMontant: 0,
				montantTotalTTC: 0,
				montantTotalHT: 0,
				deviseCode: "euro",
				deviseTaux: 1,
				deviseLibelle: "euro",
				deviseMontant: 0,
				tag: tag,
				libelleModifier: CsiManager.truncateToCSISizeShort(minfos.label),
				libelleQualifier: CsiManager.truncateToCSISizeShort(selectedIngredient.name),
				change: false,
				filter: product._excludeFromGlobalDiscount ? 0 : 1,
			};

			if (settings.addProfitCenter) line.centreProfit = settings.addProfitCenter;

			//  Check for employee meal in euro
			if (ingredient._crown.crownEnable) {
				line.deviseMontant = line.prix;
			}
			ticket.lines.push(line);
		});
	}

	/**
	 * Add a recipe modifier to a product
	 */
	private static addRecipeModifier(
		settings: ICsiTicketSettings,
		ticket: ICsiTicket,
		ingredientData: IBKProductIngredientSelectedData,
		productIngredientData: ICsiProductProcessedIngredient /*BKProductProcessedIngredient*/,
		product: ICsiProductInOrder,
		order: IBKPublishedOrderData /*BKOrder*/,
		quantity: number,
		deleteTicket: boolean,
		productStatus: ItemInOrderState,
		alignedVat: number = undefined
	): void {
		if (ingredientData.lineuuid === null || ingredientData.lineuuid === "") ingredientData.lineuuid = CsiManager.generateUUID();
		//  Get the modifiers infos
		const minfos: {
			label: string;
			bkpn: string;
			plumod: number;
		} | null = CsiBKPNs.getModifierInfos(ingredientData, productIngredientData);
		if (minfos === null) return;
		//  Lookup for the ingredient
		let ingredient: ICsiIngredient | null = null;
		for (let i = 0; i < productIngredientData._availableIngredients.length; i++) {
			if (productIngredientData._availableIngredients[i].id === ingredientData.selected) {
				ingredient = productIngredientData._availableIngredients[i];
				break;
			}
		}
		//  Check for the ingredient to have been found
		if (ingredient === null) return;

		//  Fix the extension
		let qtp: string = ingredient.refI.bkpn;
		if (qtp.substr(-3, 3) === "000") {
			qtp = qtp.substr(0, qtp.length - 3);
		}

		//  Compose the tag
		const tagElements: { [key: string]: string } = {
			a_la_carte_price: ingredientData.priceTTC.toString(),
			modifier_third_party_id: minfos.bkpn,
			qualifier_third_party_id: qtp,
			qualifier_id: ingredient.id.toString(), //Identifiant du qualifier
		};
		const tag: string = CsiManager.object2URLEncoded(tagElements);
		//  Get the price of the product
		let exportedPrice: IBKItemPriceInfo = CsiManager.pickupPriceForDeliveryMode(product.aLaCartePrice, order.deliveryMode);
		if (isAlignedVatUsable(product, alignedVat, exportedPrice.pc)) {
			exportedPrice = updatePriceWithVat(exportedPrice, alignedVat);
		}
		let typeLine: string = CsiLines.MODIFIER_QUALIFIER;
		switch (productStatus) {
			case ItemInOrderState.DELETED_AFTER_KITCHEN:
				typeLine = CsiLines.MODIFIER_QUALIFIER_DELETE_AFTER_KITCHEN_SCREEN;
				break;
			case ItemInOrderState.DELETED_AFTER_ORDER_SUB_TOTAL:
				typeLine = CsiLines.MODIFIER_QUALIFIER_DELETE_AFTER_ORDER_SUB_TOTAL;
				break;
			case ItemInOrderState.DELETED_BEFORE_KITCHEN:
				typeLine = CsiLines.MODIFIER_QUALIFIER_DELETE_BEFORE_KITCHEN_SCREEN;
				break;
		}
		if (deleteTicket) {
			typeLine = CsiLines.MODIFIER_QUALIFIER_DELETE_AFTER_ORDER_SUB_TOTAL;
		}
		// create new line uuid for removed ingredients, because line uuid needs to be unique
		const lineUuid = typeLine === CsiLines.MODIFIER_QUALIFIER ? ingredientData.lineuuid : CsiManager.generateUUID();
		//  Create the line
		const line: ICsiLine = {
			lineUuid: lineUuid,
			numero: parseInt(minfos.bkpn),
			horodatage: ingredientData.timestamp || product.timestamp,

			libelleTarif: "",
			numTarif: 0,

			libelle: "",
			code: CsiManager.patchIngredientCode(ingredient, minfos),
			prix: ingredientData.qty <= ingredientData.initQty ? 0 : ingredientData.priceTTC,
			prixUnitaireCarte: ingredientData.qty <= ingredientData.initQty ? 0 : ingredientData.priceTTC,
			qte: /*Math.max(0, ingredientData.qty - ingredientData.initQty),*/ Math.abs(ingredientData.qty - ingredientData.initQty) * quantity,
			poids: 1,
			unite: CsiUnit.PIECE,
			ticketUuid: ticket.ticketUuid,
			parentLineUuid: product.lineuuid,
			typeLigne: typeLine,
			numClef: ingredientData.csiUserSession?.numClef || product.user?.id || order.user.id,
			nomClef: ingredientData.csiUserSession?.nomClef || product.user?.login || order.user.login,
			codeManager: ingredientData.csiUserSession?.codeManager || product.manager?.id || order.manager.id,
			nomManager: ingredientData.csiUserSession?.nomManager || product.manager?.login || order.manager.login,
			codeVendeur: ingredientData.csiUserSession?.codeVendeur || product.user?.id || order.user.id,
			nomVendeur: ingredientData.csiUserSession?.nomVendeur || product.user?.login || order.user.login,
			//  TODO
			libFamille: "",
			libSousFamille: "",
			libGroupe: ingredient._productGroup === null ? "" : ingredient._productGroup.name,
			numFamille: 0,
			numSousFamille: 0,
			numGroupe: ingredient._productGroup === null ? 0 : ingredient._productGroup.id,
			//  TVA
			libelleTVA: CsiManager.composeLibelleTVA(/*product.aLaCartePrice.pc*/ exportedPrice.pc),
			numTVA: Math.round(100 * /*product.aLaCartePrice.pc*/ exportedPrice.pc),
			tauxTVA: exportedPrice.pc /*product.aLaCartePrice.pc*/,
			//  Machine infos
			libCaisse: ingredientData.csiUserSession?.libCaisse || product.machine?.ip || (order.machine === null ? "" : order.machine.ip),
			numCaisse: ingredientData.csiUserSession?.numCaisse || product.machine?.idx || (order.machine === null ? 0 : order.machine.idx),

			//  TODO
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: "AUCUN",
			serviceMontant: 0,

			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: "euro",
			deviseTaux: 1,
			deviseLibelle: "euro",
			deviseMontant: 0,
			tag: tag,
			libelleModifier: CsiManager.truncateToCSISizeShort(minfos.label),
			libelleQualifier: CsiManager.truncateToCSISizeShort(ingredientData.sysName),
			change: false,
			filter: product._excludeFromGlobalDiscount ? 0 : 1,
		};

		if (settings.addProfitCenter) line.centreProfit = settings.addProfitCenter;

		//  Check for employee meal in euro
		if (ingredient._crown.crownEnable) {
			line.deviseMontant = line.prix;
		}
		//  Append the line
		ticket.lines.push(line);
	}

	/**
	 * Add a discount line
	 */
	private static addOrderDiscountLinetoTicket(
		settings: ICsiTicketSettings,
		ticket: ICsiTicket,
		discount: IBKDiscountInOrderData,
		parentLine: string | null,
		timestamp: number,
		order: IBKPublishedOrderData /*BKOrder*/,
		relatedProduct: IBKItemInOrderBase | null,
		relatedMenu: IBKItemInOrderBase | null,
		deletedDiscount: boolean,
		deleteTicket: boolean
	): void {
		const employeeMealEvent = order.event.find((event) => event.eventType === BKOrderEventType.EMPLOYEE_MEAL);
		let typeLine = "";
		if (deletedDiscount || deleteTicket) {
			typeLine = deleteTicket ? CsiLines.REMISE_DELETE_AFTER_ORDER_SUB_TOTAL : CsiLines.REMISE_DELETE_BEFORE_KITCHEN_SCREEN;
		} else if (employeeMealEvent && settings.brandName === BrandName.BurgerKing_France) {
			typeLine = CsiLines.REPAS_EMPLOYE;
		} else {
			typeLine = discount.discountInPercent ? CsiLines.REMISE_TAUX : CsiLines.REMISE_MONTANT;
		}
		const tagElements: { [key: string]: string } = {
			third_party_id: discount.ref.bkpn,
		};
		if (employeeMealEvent && settings.brandName === BrandName.BurgerKing_France) {
			const args: EmployeeMealEventArguments = JSON.parse(employeeMealEvent.arg);
			tagElements["beneficiaireCode"] = args.employee.id.toString();
			tagElements["beneficiaireNom"] = args.employee.firstName + " " + args.employee.lastName;
			tagElements["managerCode"] = args.manager.id.toString();
			tagElements["managerNom"] = args.manager.firstName + " " + args.manager.lastName;
		}
		const tag: string = CsiManager.object2URLEncoded(tagElements);
		//  Create the line
		let excludedDiscountItems = [];
		if (order.excludedDiscountItems?.[discount.id]) {
			const menuTypeLines = [
				CsiLines.VALUEMEAL,
				CsiLines.VALUEMEAL_DELETE_AFTER_KITCHEN_SCREEN,
				CsiLines.VALUEMEAL_DELETE_AFTER_ORDER_SUB_TOTAL,
				CsiLines.VALUEMEAL_DELETE_BEFORE_KITCHEN_SCREEN,
			];
			const filteredMenuLinesUuids = ticket.lines
				.filter((item) => menuTypeLines.includes(item.typeLigne))
				.filter((menu) => {
					return order.excludedDiscountItems?.[discount.id].excludedMenuIds.includes(menu.numero);
				})
				.map((menu) => menu.lineUuid);

			const productTypeLines = [
				CsiLines.ARTICLE,
				CsiLines.ARTICLE_DELETE_AFTER_KITCHEN_SCREEN,
				CsiLines.ARTICLE_DELETE_AFTER_ORDER_SUB_TOTAL,
				CsiLines.ARTICLE_DELETE_BEFORE_KITCHEN_SCREEN,
			];
			const filteredProductLinesUuids = ticket.lines
				.filter((item) => productTypeLines.includes(item.typeLigne))
				.filter((product) => {
					return order.excludedDiscountItems?.[discount.id].excludedProductIds.includes(product.numero);
				})
				.map((product) => product.lineUuid);
			excludedDiscountItems = excludedDiscountItems.concat(...filteredProductLinesUuids, ...filteredMenuLinesUuids);
		}

		const line: ICsiLine = {
			lineUuid: discount.uuid,
			numero: discount.id,
			horodatage: timestamp,

			libelleTarif: "",
			numTarif: 0,

			libelle: CsiManager.truncateToCSISize(discount.sysName),
			code: discount.ref.plu,
			prix: 0,
			prixUnitaireCarte: 0,
			qte: 1,
			poids: 1,
			unite: CsiUnit.PIECE,
			ticketUuid: ticket.ticketUuid,
			typeLigne: typeLine,
			numClef: order.user.id,
			nomClef: order.user.login,
			codeManager: CsiManager.isUndefined(discount.manager) || discount.manager === null ? order.manager.id : discount.manager.id,
			nomManager: CsiManager.isUndefined(discount.manager) || discount.manager === null ? order.manager.login : discount.manager.login,
			codeVendeur: order.user.id,
			nomVendeur: order.user.login,
			//  TODO
			libFamille: "",
			libSousFamille: "",
			libGroupe: "",
			numFamille: 0,
			numSousFamille: 0,
			numGroupe: 0,
			//  TVA
			libelleTVA: "",
			numTVA: 0,
			tauxTVA: 0,
			//  Machine infos
			libCaisse: order.machine === null ? "" : order.machine.ip,
			numCaisse: order.machine === null ? 0 : order.machine.idx,

			//  TODO
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: "AUCUN",
			serviceMontant: 0,

			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: "euro",
			deviseTaux: 1,
			deviseLibelle: "euro",
			deviseMontant: 1,
			tag: tag,
			libelleModifier: "",
			libelleQualifier: "",
			change: false,
			linesExcludedFromDiscount: [CsiLines.REMISE_TAUX, CsiLines.REMISE_MONTANT].includes(typeLine) ? excludedDiscountItems : [],
		};
		if (!relatedProduct && !relatedMenu) {
			// CSI global discount needs to have this
			line["filter"] = 1;
		}

		let coefficient = 1; // handling csi fiscal core

		if (settings.addProfitCenter) {
			line.centreProfit = settings.addProfitCenter;
		}

		if (isBkFR(settings.brandName as BrandName) || settings.brandName === BrandNameNew.BurgerKing_France) {
			// if discount is related to product we assign quantity to coef
			if (relatedProduct) {
				coefficient = relatedProduct.qty;
			} else if (relatedMenu) {
				coefficient = relatedMenu.qty;
			}
		}

		//  Patch line depending on the type of discount
		switch (discount.discountType) {
			case BKDiscountTypeEnum.MENU_DISCOUNT:
			case BKDiscountTypeEnum.PRODUCT_DISCOUNT:
			case BKDiscountTypeEnum.MENU_EXCLUSIVE:
			case BKDiscountTypeEnum.PRODUCT_EXCLUSIVE:
			case BKDiscountTypeEnum.ORDER_DISCOUNT:
			case BKDiscountTypeEnum.REFUND_DISCOUNT:
				line.remiseMontantTTC = discount.discountInPercent ? 0 : -1 * Math.abs(discount.discountValue) * coefficient;
				line.remiseTaux = discount.discountInPercent ? -1 * Math.abs(discount.discountValue) : 0;
				if (discount.discountType === BKDiscountTypeEnum.REFUND_DISCOUNT) {
					/**
					 * This discount should apply on all the lines. It is actually a refund processed as a discount and refund
					 * may be 100 % of full price of the order including for example a delivery charge.
					 */
					line.filter = 0;
				}
				break;
			case BKDiscountTypeEnum.MENU_SINGLE_PRICE:
				if (relatedMenu == null) {
					//console.log("[DISCOUNT ERROR] : This type (" + discount.discountType+") requires a related menu");
					throw new Error("[DISCOUNT ERROR] : This type (" + discount.discountType + ") requires a related menu");
				} else {
					line.remiseMontantTTC = (discount.discountValue - relatedMenu.aLaCartePrice.ttc) * coefficient;
					line.remiseTaux = 0;
				}
				break;
			case BKDiscountTypeEnum.PRODUCT_SINGLE_PRICE:
				if (relatedProduct == null) {
					//console.log("[DISCOUNT ERROR] : This type (" + discount.discountType+") requires a related product");
					throw new Error("[DISCOUNT ERROR] : This type (" + discount.discountType + ") requires a related product");
				} else {
					// for bkfr, remiseMontantTTC formula changes to discount.discountValue * relatedProduct.qty
					line.remiseMontantTTC = (discount.discountValue - relatedProduct.aLaCartePrice.ttc) * coefficient;
					line.remiseTaux = 0;
				}
				break;
			case BKDiscountTypeEnum.PRODUCT_AND_EXTRA_DISCOUNT:
				if (relatedProduct == null) {
					//console.log("[DISCOUNT ERROR] : This type (" + discount.discountType+") requires a related product");
					throw new Error("[DISCOUNT ERROR] : This type (" + discount.discountType + ") requires a related product");
				}
				//  Check for some extra, if none, default
				if (relatedProduct.extraPrice.ttc <= 0) {
					line.remiseMontantTTC = discount.discountInPercent ? 0 : -1 * Math.abs(discount.discountValue) * coefficient;
					line.remiseTaux = discount.discountInPercent ? -1 * Math.abs(discount.discountValue) : 0;
				} else {
					//   Check for the type of discount
					const total: number = relatedProduct.aLaCartePrice.ttc + relatedProduct.extraPrice.ttc;
					if (discount.discountInPercent) {
						const discounted: number = (Math.abs(discount.discountValue) * total) / 100;
						line.remiseMontantTTC = -1 * Math.abs(discounted) * coefficient;
					} else {
						line.remiseMontantTTC = -1 * Math.abs(discount.discountValue) * coefficient;
					}
					line.remiseTaux = 0;
					line.typeLigne = CsiLines.REMISE_MONTANT;
				}
				//console.log("[DISCOUNT ERROR] : This type (" + discount.discountType+") can not be applied for the moment");
				break;
			case BKDiscountTypeEnum.MENU_AND_EXTRA_DISCOUNT:
				if (relatedMenu == null) {
					//console.log("[DISCOUNT ERROR] : This type (" + discount.discountType+") requires a related product");
					throw new Error("[DISCOUNT ERROR] : This type (" + discount.discountType + ") requires a related menu");
				}
				//  Check for some extra, if none, default
				if (relatedMenu.extraPrice.ttc <= 0) {
					line.remiseMontantTTC = discount.discountInPercent ? 0 : -1 * Math.abs(discount.discountValue) * coefficient;
					line.remiseTaux = discount.discountInPercent ? -1 * Math.abs(discount.discountValue) : 0;
				} else {
					//   Check for the type of discount
					const total: number = relatedMenu.aLaCartePrice.ttc + relatedMenu.extraPrice.ttc;
					if (discount.discountInPercent) {
						const discounted: number = (Math.abs(discount.discountValue) * total) / 100;
						line.remiseMontantTTC = -1 * Math.abs(discounted) * coefficient;
					} else {
						line.remiseMontantTTC = -1 * Math.abs(discount.discountValue) * coefficient;
					}
					line.remiseTaux = 0;
					line.typeLigne = CsiLines.REMISE_MONTANT;
				}
				//console.log("[DISCOUNT ERROR] : This type (" + discount.discountType+") can not be applied for the moment");
				break;
			default:
				//console.log("[DISCOUNT ERROR] : This type (" + discount.discountType+") can not be apply to a product");
				throw new Error("[DISCOUNT ERROR] : This type (" + discount.discountType + ") can not be applied to a product");
			//break;
		}
		//  Check for a parent line
		if (parentLine !== null) line.parentLineUuid = parentLine;
		//  Append the line
		ticket.lines.push(line);
	}

	private static addEmployeeMealLineToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData): void {
		const employeeMealEvent = order.event.find((event) => event.eventType === BKOrderEventType.EMPLOYEE_MEAL);
		if (!employeeMealEvent) {
			return;
		}

		const content: ICsiLineTextFormat = {
			text: "",
			style: CsiLineTextFormatConstants.STYLE_NORMAL,
			size: CsiLineTextFormatConstants.SIZE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_LEFT,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_ALL,
			title: "",
		};

		const title: ICsiLineTextFormat = {
			...content,
			text: "**Repas employé**",
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			lineBreakBefore: 2,
		};
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, title, order, settings);

		const args: EmployeeMealEventArguments = JSON.parse(employeeMealEvent.arg);
		const beneficiaryName: ICsiLineTextFormat = {
			...content,
			text: `BENEFICIAIRE : ${args.employee.firstName} ${args.employee.lastName}`,
		};

		const managerName: ICsiLineTextFormat = {
			...content,
			text: `MANAGER : ${args.manager.firstName} ${args.manager.lastName}`,
		};

		const beneficiaryCode: ICsiLineTextFormat = {
			...content,
			text: `MATRICULE : ${args.employee.id}`,
			lineBreakAfter: 2,
		};

		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, beneficiaryName, order, settings);
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, managerName, order, settings);
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, beneficiaryCode, order, settings);
	}

	private addFreeProduct(
		settings: ICsiTicketSettings,
		ticket: ICsiTicket,
		pio: ICsiProductInOrder,
		order: IBKPublishedOrderData & ICsiLineGenerator,
		deliverLater: ICsiDeliverLaterInfo[],
		parentUUID: string,
		quantity: number,
		deleteTicket: boolean,
		productStatus: ItemInOrderState
	) {
		//  Check for deleted, if so, do not print
		if (productStatus !== ItemInOrderState.ACTIVE || deleteTicket) {
			return;
		}

		const isMenuExtraStep = false;
		const isProductInMenu = false;
		const isProductFree = true;
		this.addProductToTicket(settings, ticket, pio, order, deliverLater, parentUUID, quantity, productStatus, deleteTicket, isMenuExtraStep, isProductInMenu, isProductFree);

		// for Bkre Quick a product line with price 0  will be added
		// in other cases a product line with default price and another line with remiseTaux -100 will be added
		if (pio.aLaCartePrice.ttc > 0 && (settings.brandName === BrandName.BurgerKing_France || settings.brandName === BrandNameNew.BurgerKing_France)) {
			CsiManager.addOrderFreeProductDiscountLinetoTicket(settings, ticket, pio, pio.timestamp, order);
		}
	}

	/**
	 * Add a discount line which should contain original product price (P.U.) and new price or proper text (FREE/OFFERT)
	 */
	private static addOrderFreeProductDiscountLinetoTicket(
		settings: ICsiTicketSettings,
		ticket: ICsiTicket,
		freeProduct: IBKProductBase & IBKItemInOrderBase /*BKProductInOrder*/,
		timestamp: number,
		order: IBKPublishedOrderData & ICsiLineGenerator /*BKOrder*/
	): void {
		if (freeProduct.lineuuid === null || freeProduct.lineuuid === "") freeProduct.lineuuid = CsiManager.generateUUID();
		//  Free product is a 100% discount
		const typeLine: string = CsiLines.REMISE_TAUX;
		const tagElements: { [key: string]: string } = {
			third_party_id: "42545802", // TODO discount.ref.bkpn
		};
		const tag: string = CsiManager.object2URLEncoded(tagElements);
		//  Create the line
		const line: ICsiLine = {
			lineUuid: CsiManager.generateUUID(),
			numero: order.getNextLineIndex(),
			horodatage: timestamp,

			libelleTarif: "",
			numTarif: 0,

			libelle: "Produit offert",
			code: "",
			prix: 0,
			prixUnitaireCarte: 0,
			qte: 1,
			poids: 1,
			unite: CsiUnit.PIECE,
			ticketUuid: ticket.ticketUuid,
			typeLigne: typeLine,
			numClef: order.user.id,
			nomClef: order.user.login,
			codeManager: order.manager.id,
			nomManager: order.manager.login,
			codeVendeur: order.user.id,
			nomVendeur: order.user.login,
			//  TODO
			libFamille: "",
			libSousFamille: "",
			libGroupe: "",
			numFamille: 0,
			numSousFamille: 0,
			numGroupe: 0,
			//  TVA
			libelleTVA: "",
			numTVA: 0,
			tauxTVA: 0,
			//  Machine infos
			libCaisse: order.machine === null ? "" : order.machine.ip,
			numCaisse: order.machine === null ? 0 : order.machine.idx,

			//  TODO
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: -100,
			serviceTaux: 0,
			serviceMode: "AUCUN",
			serviceMontant: 0,

			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: "euro",
			deviseTaux: 1,
			deviseLibelle: "euro",
			deviseMontant: 1,
			tag: tag,
			libelleModifier: "",
			libelleQualifier: "",
			change: false,
		};

		if (settings.addProfitCenter) {
			line.centreProfit = settings.addProfitCenter;
		}

		//  Check for a parent line
		line.parentLineUuid = freeProduct.lineuuid;
		//  Append the line
		ticket.lines.push(line);
	}

	/**
	 * Add a date line to the ticket
	 */
	private static addDateLineToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData /*BKOrder*/): void {
		const line: ICsiLine = {
			lineUuid: CsiManager.generateUUID(),
			numero: 0,
			horodatage: CsiManager.now(),
			libelleTarif: "",
			numTarif: 0,
			libelle: "",
			code: "",
			prix: 0,
			prixUnitaireCarte: 0,
			qte: 0,
			poids: 0,
			unite: CsiUnit.PIECE,
			ticketUuid: ticket.ticketUuid,
			typeLigne: CsiLines.DATE,
			numClef: order.user.id,
			nomClef: order.user.login,
			codeManager: order.manager.id,
			nomManager: order.manager.login,
			codeVendeur: order.user.id,
			nomVendeur: order.user.login,
			libFamille: "",
			libSousFamille: "",
			libGroupe: "",
			numFamille: -1,
			numSousFamille: -1,
			numGroupe: -1,
			libelleTVA: "",
			numTVA: -1,
			tauxTVA: 0,
			libCaisse: order.machine === null ? "" : order.machine.ip,
			numCaisse: order.machine === null ? 0 : order.machine.idx,
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: "AUCUN",
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: "",
			deviseTaux: 0,
			deviseLibelle: "",
			deviseMontant: 0,
			libelleModifier: "",
			libelleQualifier: "",
			change: false,
		};

		if (settings.addProfitCenter) {
			line.centreProfit = settings.addProfitCenter;
		}
		//  Append the line
		ticket.lines.push(line);
	}

	/**
	 * Add a date line to the ticket
	 */
	private static addCommentToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData /*BKOrder*/, parentLine: string, text: string): void {
		const line: ICsiLine = {
			lineUuid: CsiManager.generateUUID(),
			numero: 0,
			horodatage: CsiManager.now(),
			libelleTarif: "",
			numTarif: 0,
			libelle: CsiManager.truncateToCSISize(text),
			code: "",
			prix: 0,
			prixUnitaireCarte: 0,
			qte: 0,
			poids: 0,
			unite: CsiUnit.PIECE,
			ticketUuid: ticket.ticketUuid,
			typeLigne: CsiLines.COMMENTAIRE,
			numClef: order.user.id,
			nomClef: order.user.login,
			codeManager: order.manager.id,
			nomManager: order.manager.login,
			codeVendeur: order.user.id,
			nomVendeur: order.user.login,
			libFamille: "",
			libSousFamille: "",
			libGroupe: "",
			numFamille: -1,
			numSousFamille: -1,
			numGroupe: -1,
			libelleTVA: "",
			numTVA: -1,
			tauxTVA: 0,
			libCaisse: order.machine === null ? "" : order.machine.ip,
			numCaisse: order.machine === null ? 0 : order.machine.idx,
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: "AUCUN",
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: "",
			deviseTaux: 0,
			deviseLibelle: "",
			deviseMontant: 0,
			libelleModifier: "",
			libelleQualifier: "",
			change: false,
		};

		if (settings.addProfitCenter) {
			line.centreProfit = settings.addProfitCenter;
		}

		//  Check for a parent line
		if (parentLine !== null) {
			line.parentLineUuid = parentLine;
		}
		//  Append the line
		ticket.lines.push(line);
	}

	/**
	 * Add a text line to the ticket
	 */
	public static addTextLineToTicket(
		ticket: ICsiTicket,
		/** Placement - Where the line should be displayed on the ticket, Middle vs Footer... */
		typeLine: string,
		content: ICsiLineTextFormat,
		order: IBKPublishedOrderData /*BKOrder*/,
		settings: ICsiTicketSettings
	): void {
		//  Compose the line
		const line: ICsiLine = {
			lineUuid: CsiManager.generateUUID(),
			numero: 0,
			horodatage: CsiManager.now(),
			libelleTarif: "",
			numTarif: 0,
			libelle: "",
			code: "",
			prix: 0,
			prixUnitaireCarte: 0,
			qte: 0,
			poids: 0,
			unite: CsiUnit.PIECE,
			ticketUuid: ticket.ticketUuid,
			typeLigne: typeLine,
			numClef: order.user.id,
			nomClef: order.user.login,
			codeManager: order.manager.id,
			nomManager: order.manager.login,
			codeVendeur: order.user.id,
			nomVendeur: order.user.login,
			libFamille: "",
			libSousFamille: "",
			libGroupe: "",
			numFamille: -1,
			numSousFamille: -1,
			numGroupe: -1,
			libelleTVA: "",
			numTVA: -1,
			tauxTVA: 0,
			libCaisse: order.machine === null ? "" : order.machine.ip,
			numCaisse: order.machine === null ? 0 : order.machine.idx,
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: "AUCUN",
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: "",
			deviseTaux: 0,
			deviseLibelle: "",
			deviseMontant: 0,
			libelleModifier: "",
			libelleQualifier: "",
			change: false,
			tag: JSON.stringify(content),
		};

		if (settings.addProfitCenter) {
			line.centreProfit = settings.addProfitCenter;
		}

		//  Append the line
		ticket.lines.push(line);
	}

	private static addMarketingAlertOnTicketFooterText(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData /*BKOrder*/, messageOnTicket: string): void {
		if (settings.alertOnTicket && settings.alertOnTicket.length > 0) {
			const alertOnTicketLine: ICsiLineTextFormat = {
				text: settings.alertOnTicket,
				style: CsiLineTextFormatConstants.STYLE_BOLD,
				size: CsiLineTextFormatConstants.SIZE_WX2HX2,
				align: CsiLineTextFormatConstants.ALIGN_CENTER,
				separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
				lineBreakBefore: 0,
				lineBreakAfter: 0,
				qrcode: "",
				document: CsiLineTextFormatConstants.DOCUMENT_TICKET,
				title: "",
			};
			//  Add the line
			CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, alertOnTicketLine, order, settings);
		}

		if (messageOnTicket === null || CsiManager.isUndefined(messageOnTicket)) return;
		if (messageOnTicket.length <= 0) return;
		//  Compose
		const messageOnTicketLine: ICsiLineTextFormat = {
			text: messageOnTicket,
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.STYLE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_TICKET,
			title: "",
		};
		//  Add the line
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, messageOnTicketLine, order, settings);
	}

	private static addOrderSourceAndDestination(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData /*BKOrder*/): void {
		let resultString = "";
		const isMultiPaymentOrder = BKOrderEventUtilities.isKioskOrderCreatedToBePaidInPos(order.event);
		if (order.source === BKOrderSourceEnum.ORDER_SOURCE_CASH_MACHINE) {
			resultString = "** CAISSE";
		} else if (order.source === BKOrderSourceEnum.ORDER_SOURCE_KIOSK) {
			resultString = isMultiPaymentOrder ? "** COMPTOIR" : "** KIOSK";
		} else if (order.source === BKOrderSourceEnum.ORDER_SOURCE_DRIVE) {
			resultString = "** DRIVE";
		} else if (order.source === BKOrderSourceEnum.ORDER_SOURCE_WALK) {
			resultString = "** WALK";
		} else if (order.source === BKOrderSourceEnum.ORDER_SOURCE_CLICK_AND_COLLECT) {
			resultString = "** CC";
		} else if (order.source === BKOrderSourceEnum.ORDER_SOURCE_THIRD_PARTY_DELIVERY) {
			resultString = "** WEB";
		}
		let kiosk = '';
		if (order.source === BKOrderSourceEnum.ORDER_SOURCE_CLICK_AND_COLLECT &&
			(settings.brandName === BrandName.Quick || settings.brandName === BrandNameNew.Quick)
		) {
			kiosk = '';
		} else {
			kiosk = isMultiPaymentOrder ? "KIOSK " : "";
		}
		if (order.deliveryMode === BKDeliveryModeEnum.DELIVERY_LOCAL) {
			const tableAllocationEvent = BKOrderEventUtilities.getTableAllocationAssignment(order.event);
			if (tableAllocationEvent?.code === null) {
				resultString += ' - SERVICE À TABLE **';
			} else {
				const locationFromOrder: string | null = CsiManager.getTableLocationSpaceTextFromOrder(order);
				const locationText: string = locationFromOrder ? `- ${locationFromOrder} ` : "";
				resultString += ` - ${kiosk}SUR PLACE ${locationText}**`;
			}
		} else if (order.deliveryMode === BKDeliveryModeEnum.DELIVERY_TAKE_OVER) {
			resultString += ` - ${kiosk}A EMPORTER **`;
		}
		if (resultString === null || resultString === "") return;
		//  Compose
		const c: ICsiLineTextFormat = {
			text: resultString,
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.STYLE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_TICKET,
			title: "",
		};
		//  Add the line
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, c, order, settings);
	}

	private static getSourcePrefix(source: BKOrderSourceEnum): string {
		switch (source) {
			case BKOrderSourceEnum.ORDER_SOURCE_CASH_MACHINE:
				return "C";
			case BKOrderSourceEnum.ORDER_SOURCE_THIRD_PARTY_DELIVERY:
				return "T";
			case BKOrderSourceEnum.ORDER_SOURCE_CLICK_AND_COLLECT:
				return "E";
			case BKOrderSourceEnum.ORDER_SOURCE_WALK:
				return "W";
		}
		return "";
	}

	/**
	 * Add an ORB line
	 */
	private static addORBLineToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, raworb: number, order: IBKPublishedOrderData /*BKOrder*/): void {
		//  Ensure the orb code is in the range
		const orb: number = CsiManager.fixORBNumber(raworb);
		const sourceLetter = CsiManager.getSourcePrefix(order.source);
		//  Print out
		const s: string = orb <= 0 ? "PAS DE NUMERO DE PREPARATION\nUTILISEZ LE NUMERO DE TICKET" : "PREPARATION : " + sourceLetter + orb.toString();

		//  Compose
		const c: ICsiLineTextFormat = {
			text: s,
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_WX2HX2,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_TICKET,
			title: "",
		};
		//  Add the line
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, c, order, settings);
	}

	private static addQuickORBLineToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, raworb: number, order: IBKPublishedOrderData): void {
		const orb: number = CsiManager.fixORBNumber(raworb);

		const text: string = orb <= 0 ? "PAS DE NUMERO DE PREPARATION\nUTILISEZ LE NUMERO DE TICKET" : "PREPARATION";
		const line1: ICsiLineTextFormat = {
			text: text,
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_WX3HX3,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_TICKET,
			title: "",
		};
		CsiManager.addTextLineToTicket(ticket, CsiLines.HEADER_TEXT, line1, order, settings);

		if (!orb) {
			return;
		}
		const sourceLetter = CsiManager.getSourcePrefix(order.source);
		const line2: ICsiLineTextFormat = {
			text: sourceLetter + orb.toString(),
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_WX5HX5,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_TICKET,
			title: "",
		};
		CsiManager.addTextLineToTicket(ticket, CsiLines.HEADER_TEXT, line2, order, settings);
	}

	// Compose the text for the table location space depends on a table allocation assignment
	private static getTableLocationSpaceTextFromOrder(order: IBKPublishedOrderData): string | null {
		if (!order) return null;
		const tableAlloc: IBKTableAllocationAssignment | null = BKOrderEventUtilities.getTableAllocationAssignment(order.event);
		switch (tableAlloc?.locationSpace) {
			case BKTableAllocationLocationSpace.INDOORS:
				return "Salle";
			case BKTableAllocationLocationSpace.TERRACE:
				return "Terrasse";
			default:
				return null;
		}
	}

	// Compose the CSI line for the table number depends on a table allocation assignment
	private static getTableNumberCSILineFromTableAlloc(settings: ICsiTicketSettings, tableAlloc: IBKTableAllocationAssignment): ICsiLineTextFormat | null {
		let textPrefix = "";
		switch (tableAlloc?.allocationType) {
			case BKTableAllocationType.TABLE_NUMBER:
				textPrefix = "N° table: ";
				break;
			case BKTableAllocationType.EASEL_NUMBER:
				textPrefix = "N° chevalet: ";
				break;
			default:
				break;
		}
		const resultString: string = textPrefix + tableAlloc?.code;
		if (!resultString || !tableAlloc) return null;
		// Compose
		return {
			text: resultString,
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_WX2HX2,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_ALL,
			title: "",
		};
	}

	/**
	 * Add a table allocation data line
	 */
	private static addTableAllocationNumberLineToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData): void {
		if (!ticket || !order) return;
		const tableAlloc: IBKTableAllocationAssignment | null = BKOrderEventUtilities.getTableAllocationAssignment(order.event);
		if (!tableAlloc || (order.deliveryMode === BKDeliveryModeEnum.DELIVERY_LOCAL && tableAlloc.code === null)) {
			return;
		}
		// Compose CSI lines
		const tableNumberLine = CsiManager.getTableNumberCSILineFromTableAlloc(settings, tableAlloc);
		//  Add the lines
		if (tableNumberLine) {
			CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, tableNumberLine, order, settings);
		}
	}

	private static addPagerNumberLineToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData): void {
		if (!ticket || !order) {
			return;
		}
		const event = order.event.find((event) => event.eventType === BKOrderEventType.PAGER_NUMBER);
		const pagerNumber = event?.arg;
		if (!pagerNumber) {
			return;
		}

		const line: ICsiLineTextFormat = {
			text: "N° pager: " + pagerNumber,
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_WX2HX2,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_ALL,
			title: "",
		};
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line, order, settings);
	}

	/**
	 * Add an ORB line
	 */
	private static addCommandORBLineToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, raworb: number, order: IBKPublishedOrderData /*BKOrder*/): void {
		//  Ensure the orb code is in the range
		const orb: number = CsiManager.fixORBNumber(raworb);
		//  Print out
		//let s: string = orb <= 0 ? "-" : orb.toString();
		//  Print out
		const s: string = orb <= 0 ? "PAS DE NUMERO DE PREPARATION\nUTILISEZ LE NUMERO DE TICKET" : "A PAYER :" + orb.toString();
		//  Compose
		const c: ICsiLineTextFormat = {
			text: s,
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_WX2HX2,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_ORDER,
			title: "",
		};
		//  Add the line
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, c, order, settings);
	}

	private static addQuickCommandORBLineToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, raworb: number, order: IBKPublishedOrderData /*BKOrder*/): void {
		const orb: number = CsiManager.fixORBNumber(raworb);

		const text: string = orb <= 0 ? "PAS DE NUMERO DE PREPARATION\nUTILISEZ LE NUMERO DE TICKET" : "A PAYER";
		const line1: ICsiLineTextFormat = {
			text: text,
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_WX3HX3,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_ORDER,
			title: "",
		};
		CsiManager.addTextLineToTicket(ticket, CsiLines.HEADER_TEXT, line1, order, settings);

		if (!orb) {
			return;
		}
		const sourceLetter = CsiManager.getSourcePrefix(order.source);
		const line2: ICsiLineTextFormat = {
			text: sourceLetter + orb.toString(),
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_WX5HX5,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_ORDER,
			title: "",
		};
		CsiManager.addTextLineToTicket(ticket, CsiLines.HEADER_TEXT, line2, order, settings);
	}

	/**
	 * Add the toilet code
	 */
	private static addKioskPayDelayedLineToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, message: string, order: IBKPublishedOrderData /*BKOrder*/): void {
		if (message === null || CsiManager.isUndefined(message)) return;
		if (message.length <= 0) return;
		if (order.source !== BKOrderSourceEnum.ORDER_SOURCE_KIOSK) return;
		//  Compose
		const c: ICsiLineTextFormat = {
			text: message,
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_ORDER,
			title: "",
		};
		//  Add the line
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, c, order, settings);
	}

	/**
	 * Add the fidelity card id if exists
	 */
	private static addFidelityCardIdLineToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData): void {
		if (!ticket || !order) return;
		const fidCardId: string | undefined = BKKingdomUtils.extractKingdomClientIdsFromOrderEvents(order.event || [])[0];
		if (!fidCardId) {
			return; // No fidelity card id found so nothing to add to the ticket
		}
		let text = "CARTE DE FIDÉLITÉ KINGDOM"; // Do not add the card ID for confidentiality reasons
		if (settings.brandName === BrandName.Quick || settings.brandName === BrandNameNew.Quick) {
			text = "PROGRAMME FIDELITE QLUB";
		}

		//  Compose
		const c: ICsiLineTextFormat = {
			text: text,
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_ALL,
			title: "",
		};
		//  Add the line
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, c, order, settings);
	}

	private static addFidelityPointsToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData): void {
		if (!ticket || !order) {
			return;
		}
		if (!settings.fidelityPoints) {
			return;
		}
		if (settings.brandName !== BrandName.Quick && settings.brandName !== BrandNameNew.Quick) {
			return;
		}

		const csiLine: ICsiLineTextFormat = {
			text: "",
			style: CsiLineTextFormatConstants.STYLE_NORMAL,
			size: CsiLineTextFormatConstants.SIZE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_ALL,
			title: "",
		};

		// When not connected, show how much points the user could have earned
		if (!BKKingdomUtils.isKingdomRelatedOrder(order)) {
			const line1 = { ...csiLine };
			line1.text = "PROGRAMME FIDELITE QLUB :";

			const line2 = { ...csiLine };
			line2.text = `Vous auriez pu gagner ${settings.fidelityPoints.earned} points`;
			line2.style = CsiLineTextFormatConstants.STYLE_BOLD;

			const line3 = { ...csiLine };
			line3.text = "avec votre commande.";

			const line4 = { ...csiLine };
			line4.text = "Téléchargez l'app mobile QUICK FR !";
			line4.style = CsiLineTextFormatConstants.STYLE_BOLD;

			[line1, line2, line3, line4].forEach((i) => CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, i, order, settings));
			return;
		}

		const line1 = { ...csiLine };
		line1.text = `Gagné: ${settings.fidelityPoints.earned} points`;
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line1, order, settings);

		const line2 = { ...csiLine };
		line2.text = `Utilisé: ${settings.fidelityPoints.burned} points`;
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line2, order, settings);

		const line3 = { ...csiLine };
		line3.text = "Solde de points: " + settings.fidelityPoints.balance;
		line3.style = CsiLineTextFormatConstants.STYLE_BOLD;
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line3, order, settings);
	}

	/**
	 * Add the toilet code
	 */
	private static addToiletCodeLineToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, toiletCode: string, order: IBKPublishedOrderData /*BKOrder*/): void {
		if (toiletCode === null || CsiManager.isUndefined(toiletCode) || toiletCode.length <= 0) {
			return;
		}

		const text: string = "CODE TOILETTES : " + toiletCode;

		//  Compose the line
		const line: ICsiLineTextFormat = {
			text: text,
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_TICKET,
			title: "",
		};

		//  Add the line
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line, order, settings);
	}

	/***
	 * If order is from Click-n-collect and pickup is at Parking place,
	 * add lines instructing the waiters to bring it to specific vehicle at the parking place
	 */
	private static addPickupParkingInfoToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData): void {
		const orderEvents = order.event || [];
		const pickupType: BKPickUpTypeEnum = BKOrderEventUtilities.getPickUpTypeForWebOrder(orderEvents);
		if (pickupType !== BKPickUpTypeEnum.PARKING) {
			return;
		}

		const parkingDetails: IBKPickUpTypeParkingDetails | null = BKOrderEventUtilities.getPickUpTypeParkingDetails(orderEvents);
		if (!parkingDetails) {
			return;
		}

		const parkingSlot = parkingDetails.parkingSlot ? parkingDetails.parkingSlot.trim().toUpperCase() : "";
		const pickupParkingSlot = `Pickup Parking: ${parkingSlot}`;

		const licencePlateLine: ICsiLineTextFormat = {
			text: pickupParkingSlot,
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_WX2HX2,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_TICKET,
			title: "",
		};
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, licencePlateLine, order, settings);

		// Add the Licence plate - with inverse colors
		if (parkingDetails.licensePlateNumber && parkingDetails.licensePlateNumber.trim().length > 0) {
			CsiManager.addCarLicencePlateToTicket(settings, ticket, order, parkingDetails.licensePlateNumber);
		}

		// Add car's model and car's color if available
		if (parkingDetails.carMakeAndModel || parkingDetails.carColour) {
			CsiManager.addCarDetailsToTicket(settings, ticket, order, parkingDetails.carMakeAndModel, parkingDetails.carColour);
		}
	}

	private static addCarLicencePlateToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData, licencePlate: string): void {
		// The original request was to "add spaces between numbers and letter"
		// but we can not do that for following reasons:
		// - The customer might have foreign licence plate with different format
		// - Customer might use some custom format (for example BIGBOSS123)
		// - France has 2 valid systems of licence plates
		// - ecocea might already applied some validation rules, even though that is unlikely due to above reasons
		//
		// Conclusion: Assume that customer entered the licence plate in correct format. He should know what his licence plate looks like.
		const line: ICsiLineTextFormat = {
			text: licencePlate.toUpperCase(),
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_WX2HX2,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_TICKET,
			title: "",
		};

		//  Add the line
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line, order, settings);
	}

	private static addFidelityPoints(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData) {
		const event = order.event.find((event) => event.eventType === BKOrderEventType.FIDELITY_POINTS);
		const points = event?.arg;
		if (!points) {
			return;
		}
		const line: ICsiLineTextFormat = {
			text: "vos PO gagnés avec cette commande: " + event.arg,
			style: CsiLineTextFormatConstants.STYLE_NORMAL,
			size: CsiLineTextFormatConstants.SIZE_NORMAL,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_TICKET,
			title: "",
		};
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line, order, settings);
	}

	private static addComplements(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData): void {
		const event = order.event.find((event) => event.eventType === BKOrderEventType.CSI_COMPLEMENTS);
		if (!event) {
			return;
		}
		const complements = JSON.parse(event.arg);
		if (!complements.length) {
			return;
		}
		ticket.complements = ticket.complements.concat(complements);
	}

	private static addOrbNumber(ticket: ICsiTicket, order: IBKPublishedOrderData): void {
		const complement: ICsiComplement = {
			cle: CsiComplementCle.orbNumber,
			valeur: order.orb.toString(),
			type: "STRING",
			complementId: 0,
		};
		ticket.complements.push(complement);
	}

	private static addCarDetailsToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData, carType?: string, carColor?: string) {
		// First normalize user input
		if (!carType) {
			carType = "";
		}

		if (!carColor) {
			carColor = "";
		}

		carType = carType.trim().toUpperCase();
		carColor = carColor.trim().toUpperCase();

		// compose the text of the line
		const lineText = `${carType}    ${carColor}`;

		let align = CsiLineTextFormatConstants.ALIGN_CENTER;
		if (carType === "" && carColor !== "") {
			align = CsiLineTextFormatConstants.ALIGN_RIGHT;
		}
		if (carType !== "" && carColor === "") {
			align = CsiLineTextFormatConstants.ALIGN_LEFT;
		}

		const line: ICsiLineTextFormat = {
			text: lineText,
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_WX2HX2,
			align,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_TICKET,
			title: "",
		};

		//  Add the line
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line, order, settings);
	}

	private static addCallOfDutyCodesToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, codes: string[], order: IBKPublishedOrderData) {
		const line: ICsiLineTextFormat = {
			text: "------------------------------------------",
			style: CsiLineTextFormatConstants.STYLE_NORMAL,
			size: CsiLineTextFormatConstants.SIZE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_ALL,
			title: "",
		};
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line, order, settings);

		const line2 = { ...line };
		line2.text = "";
		line2.imageUrl = "/var/www/APP/assets/images/logo-cod.png";
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line2, order, settings);

		const line3 = { ...line };
		line3.text = "Rendez-vous sur\n www.callofduty.com/bkredeem pour activer\n votre offre.";
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line3, order, settings);

		const line4 = { ...line };
		line4.text = "CODE(S):\n" + codes.join("\n");
		line4.lineBreakAfter = 3;
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line4, order, settings);
	}

	private static addBKREFeedbackLink(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData) {
		const line: ICsiLineTextFormat = {
			text: "Complétez notre questionnaire de satisfaction ici :",
			style: CsiLineTextFormatConstants.STYLE_NORMAL,
			size: CsiLineTextFormatConstants.SIZE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_LEFT,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			document: CsiLineTextFormatConstants.DOCUMENT_TICKET,
		};
		CsiManager.addTextLineToTicket(ticket, CsiLines.FOOTER_TEXT, line, order, settings);

		const line2: ICsiLineTextFormat = {
			text: "",
			style: CsiLineTextFormatConstants.STYLE_NORMAL,
			size: CsiLineTextFormatConstants.SIZE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			qrcode: "https://reu.tellburgerking.com",
			document: CsiLineTextFormatConstants.DOCUMENT_TICKET,
		};
		CsiManager.addTextLineToTicket(ticket, CsiLines.FOOTER_TEXT, line2, order, settings);
	}

	private static addWhopperScratcherHeaderToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData) {
		const line: ICsiLineTextFormat = {
			text: "------------------------------------------",
			style: CsiLineTextFormatConstants.STYLE_NORMAL,
			size: CsiLineTextFormatConstants.SIZE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_ALL,
			title: "",
		};
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line, order, settings);

		const line2 = { ...line };
		line2.text = "WHOPPER À GRATTER";
		line2.size = CsiLineTextFormatConstants.SIZE_WX2HX2;
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line2, order, settings);

		const line3 = { ...line };
		line3.text = "Scannez ce QR code sur votre application";
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line3, order, settings);
		const line4 = { ...line };
		line4.text = "Burger King pour obtenir";
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line4, order, settings);
		const line5 = { ...line };
		line5.text = "vos tickets 100% gagnants !";
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line5, order, settings);
	}

	private static addPlainTextToTicket(text: string, settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData) {
		const line: ICsiLineTextFormat = {
			text: text,
			style: CsiLineTextFormatConstants.STYLE_NORMAL,
			size: CsiLineTextFormatConstants.SIZE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_ALL,
			title: "",
		};
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line, order, settings);
	}

	private static addWhopperScratcherFooterToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData) {
		const line: ICsiLineTextFormat = {
			text: "OU CODE À SAISIR MANUELLEMENT:",
			style: CsiLineTextFormatConstants.STYLE_NORMAL,
			size: CsiLineTextFormatConstants.SIZE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_ALL,
			title: "",
		};
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line, order, settings);

		const coupon = BKOrderUtilities.getWhopperScratcherCouponFromOrder(order);
		const line2 = { ...line };
		line2.text = coupon;
		line2.style = CsiLineTextFormatConstants.STYLE_BOLD;
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, line2, order, settings);
	}

	/**
	 * Add a recall QR code to the ticket
	 */
	private static addRecallQRToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, recallQR: string, order: IBKPublishedOrderData /*BKOrder*/): void {
		if (!recallQR) return;

		//  Compose
		const c: ICsiLineTextFormat = {
			text: "",
			style: CsiLineTextFormatConstants.STYLE_BOLD,
			size: CsiLineTextFormatConstants.SIZE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_CENTER,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: 0,
			lineBreakAfter: 0,
			qrcode: recallQR,
			document: CsiLineTextFormatConstants.DOCUMENT_ALL,
			title: "",
		};
		//  Add the line
		CsiManager.addTextLineToTicket(ticket, CsiLines.MIDDLE_TEXT, c, order, settings);
	}

	/**
	 * Generate the deliver later ticket
	 */
	private static addDeliverLaterToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, products: ICsiDeliverLaterInfo[], order: IBKPublishedOrderData /*BKOrder*/): void {
		let s = "Remis plus tard : \n";
		if (settings.deliveryLaterUpperCased) {
			s = s.toUpperCase();
		}
		products.forEach(function (di: ICsiDeliverLaterInfo) {
			const p: IBKItemInOrderBase /*BKProductInOrder*/ = di.pio;
			s += di.menuQte.toString() + " x " + p.sysName + "\n";
			//  Check for specific elements added
			if (p.recipe && p.recipe.length > 0) {
				for (let i = 0; i < p.recipe.length; i++) {
					const pi = p.recipe[i];
					if (pi.modified && !p.isComposable) {
						//  Compose the extra
						let extra = "";
						if (pi.qty > 0 && pi.qty !== pi.initQty) {
							const diff: number = pi.qty - pi.initQty;
							if (diff > 0) extra += "+";
							extra += diff.toString() + " x ";
						}
						extra += "     " + pi.sysName + "\n";
						//  Add it to the list
						s += extra;
					}
					if (p.isComposable) {
						let extra = "";
						pi.selectedIngredients.forEach((ing) => {
							extra += `+${ing.qty} x     ${ing.name}\n`;
						});
						s += extra;
					}
				}
			}
		});

		//  Compose
		const c: ICsiLineTextFormat = {
			text: s,
			style: isBk(settings.brandName as BrandName) ? CsiLineTextFormatConstants.STYLE_BOLD : CsiLineTextFormatConstants.STYLE_NORMAL,
			size: CsiLineTextFormatConstants.SIZE_NORMAL,
			align: CsiLineTextFormatConstants.ALIGN_LEFT,
			separator: CsiLineTextFormatConstants.SEPARATOR_NONE,
			lineBreakBefore: isBk(settings.brandName as BrandName) ? 1 : 0,
			lineBreakAfter: 0,
			qrcode: "",
			document: CsiLineTextFormatConstants.DOCUMENT_TICKET,
			title: "",
		};
		//  Add the line
		CsiManager.addTextLineToTicket(ticket, CsiLines.COUPON_TEXT, c, order, settings);
	}

	private static addRetrieveToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData): void {
		let retrievedOnPosTimestamp: number;
		let retrievedOnPosArg: string;
		order.event.forEach((event) => {
			if (event.eventType === BKOrderEventType.RETRIEVE_ON_POS) {
				//get timestamp from arg query
				retrievedOnPosTimestamp = parseInt(_BK.getKeyValueFromQuery(event.arg, BKOrderEventUtilities.REAL_TIMESTAMP));
				retrievedOnPosArg = event.arg;
			}
		});
		if (!retrievedOnPosTimestamp) {
			return;
		}
		const csiUserSession: ICsiUserSession = JSON.parse(_BK.getKeyValueFromQuery(retrievedOnPosArg, BKOrderEventUtilities.CSI_USER_SESSION));

		const line: ICsiLine = {
			lineUuid: CsiManager.generateUUID(),
			numero: 111,
			horodatage: retrievedOnPosTimestamp,
			libelleTarif: "",
			numTarif: 0,
			libelle: "RAPPEL COMMANDE",
			code: "",
			prix: 0,
			prixUnitaireCarte: 0,
			qte: 0,
			poids: 0,
			unite: CsiUnit.PIECE,
			ticketUuid: ticket.ticketUuid,
			typeLigne: CsiLines.STATISTIQUES,
			numClef: csiUserSession?.numClef || order.user.id,
			nomClef: csiUserSession?.nomClef || order.user.login,
			codeManager: csiUserSession?.codeManager || order.manager.id,
			nomManager: csiUserSession?.nomManager || order.manager.login,
			codeVendeur: csiUserSession?.codeVendeur || order.user.id,
			nomVendeur: csiUserSession?.nomVendeur || order.user.login,
			libFamille: "GENERAL",
			libSousFamille: "",
			libGroupe: "",
			numFamille: -1,
			numSousFamille: -1,
			numGroupe: -1,
			libelleTVA: "",
			numTVA: -1,
			tauxTVA: 0,
			libCaisse: csiUserSession?.libCaisse || (order.machine === null ? "" : order.machine.ip),
			numCaisse: csiUserSession?.numCaisse || (order.machine === null ? 0 : order.machine.idx),
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: "AUCUN",
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: "",
			deviseTaux: 0,
			deviseLibelle: "",
			deviseMontant: 0,
			libelleModifier: "",
			libelleQualifier: "",
			change: false,
		};
		if (settings.addProfitCenter) {
			line.centreProfit = settings.addProfitCenter;
		}
		//  Append the line
		ticket.lines.push(line);
	}

	private static addReceivedOnDrivePay(settings: ICsiTicketSettings, ticket: ICsiTicket, order: IBKPublishedOrderData): void {
		let receivedOnDrivePayTimestamp: number;
		let receivedOnDrivePayArg: string;
		order.event.forEach((event) => {
			if (event.eventType === BKOrderEventType.RECEIVED_ON_DRIVEPAY) {
				//get timestamp from arg query
				receivedOnDrivePayTimestamp = parseInt(_BK.getKeyValueFromQuery(event.arg, BKOrderEventUtilities.REAL_TIMESTAMP));
				receivedOnDrivePayArg = event.arg;
			}
		});
		if (!receivedOnDrivePayTimestamp) {
			return;
		}
		const csiUserSession: ICsiUserSession = JSON.parse(_BK.getKeyValueFromQuery(receivedOnDrivePayArg, BKOrderEventUtilities.CSI_USER_SESSION));

		const line: ICsiLine = {
			lineUuid: CsiManager.generateUUID(),
			numero: 111,
			horodatage: receivedOnDrivePayTimestamp,
			libelleTarif: "",
			numTarif: 0,
			libelle: "RAPPEL COMMANDE",
			code: "",
			prix: 0,
			prixUnitaireCarte: 0,
			qte: 0,
			poids: 0,
			unite: CsiUnit.PIECE,
			ticketUuid: ticket.ticketUuid,
			typeLigne: CsiLines.STATISTIQUES,
			numClef: csiUserSession?.numClef || order.user.id,
			nomClef: csiUserSession?.nomClef || order.user.login,
			codeManager: csiUserSession?.codeManager || order.manager.id,
			nomManager: csiUserSession?.nomManager || order.manager.login,
			codeVendeur: csiUserSession?.codeVendeur || order.user.id,
			nomVendeur: csiUserSession?.nomVendeur || order.user.login,
			libFamille: "GENERAL",
			libSousFamille: "",
			libGroupe: "",
			numFamille: -1,
			numSousFamille: -1,
			numGroupe: -1,
			libelleTVA: "",
			numTVA: -1,
			tauxTVA: 0,
			libCaisse: csiUserSession?.libCaisse || (order.machine === null ? "" : order.machine.ip),
			numCaisse: csiUserSession?.numCaisse || (order.machine === null ? 0 : order.machine.idx),
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: "AUCUN",
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: "",
			deviseTaux: 0,
			deviseLibelle: "",
			deviseMontant: 0,
			libelleModifier: "",
			libelleQualifier: "",
			change: false,
		};
		if (settings.addProfitCenter) {
			line.centreProfit = settings.addProfitCenter;
		}
		ticket.lines.push(line);
	}

	/**
	 * Retrieves information like "KING TABLE" or "click & collect" to be displayed on a ticket for the given order.
	 * Depends on reboot API configuration USE_LEGACY_SANDBOXES.
	 * @param {IBKPublishedOrderData & ICsiLineGenerator} order - The order object to retrieve the alert for.
	 * @returns {string} The alert to be displayed on the ticket.
	 */
	private static getAlertOnTicket(order: IBKPublishedOrderData & ICsiLineGenerator /*BKOrder*/): string {
		let alertOnTicket = "";

		let sandbox = CsiManager.lookupForSandboxName(order, undefined);
		const alienId = CsiManager.lookupForAlienOrderID(order, undefined);
		const alienDisplayId = CsiManager.lookupForAlienDisplayName(order, alienId);

		switch (sandbox?.toLowerCase()) {
			case BKDeliveryBrandName.ECOCEA:
				sandbox = order.deliveryMode === BKDeliveryModeEnum.DELIVERY_TAKE_OVER ? BKDeliverySandboxName.CC : BKDeliverySandboxName.KT;
				break;
			case BKDeliveryBrandName.DELIVERY_BK:
				sandbox = BKDeliverySandboxName.DELIVERY_BK;
				break;
			case WeborderChannel.ClickCollect.toLowerCase():
				sandbox = "";
				break;
		}
		alertOnTicket += sandbox;

		if (alienDisplayId && alienDisplayId.length > 0) {
			alertOnTicket += "\n" + alienDisplayId;
		}

		if (alertOnTicket.toLowerCase().indexOf("null") !== -1 || alertOnTicket.toLowerCase().indexOf("undefined") !== -1) {
			alertOnTicket = "";
		}

		return alertOnTicket;
	}

	/**
	 * Function called in order to convert an order. Called from BO-LOCAL-MANAGER with full order data and from BKKDS with fake order data.
	 */
	public static orderToTicketWithCallback(
		order: IBKPublishedOrderData & ICsiLineGenerator /*BKOrder*/,
		orb: number,
		recallQR: string,
		deleteTicket: boolean,
		settings: ICsiTicketSettings /*BKRestoSettings*/,
		footerCallback: (ticket: ICsiTicket) => void,
		itemIdentifier: (item: IBKItemInOrderBase) => CsiItemTypeEnum,
		productConverter: (item: IBKItemInOrderBase) => ICsiProductInOrder,
		menuConverter: (item: IBKItemInOrderBase) => ICsiMenuInOrder,
		baseTags: { [key: string]: string } = {}
	): ICsiTicket {
		settings = { ...settings, ...{ alertOnTicket: undefined } };
		// Alert on ticket is later printed via addMarketingAlertOnTicketFooterText()
		settings.alertOnTicket = CsiManager.getAlertOnTicket(order);
		settings.deliveryLaterUpperCased = isBk(settings.brandName as BrandName);

		const tagElements: { [key: string]: string } = baseTags;
		tagElements["destination"] = BKOrderUtilities.getLocalisation(order);

		const fidCardId: string | undefined = BKKingdomUtils.extractKingdomClientIdsFromOrderEvents(order.event || [])[0];
		if (fidCardId) {
			tagElements["idFid"] = fidCardId;
		}

		const tag: string = CsiManager.object2URLEncoded(tagElements);
		const now: number = CsiManager.now();
		//  Create the root of the ticket
		const ticket: ICsiTicket = {
			ticketUuid: order.orderUUID,
			localisation: tagElements["destination"],
			codeVendeur: order.user.id,
			nomVendeur: order.user.login,
			codeOperateur: order.manager.id,
			nomOperateur: order.manager.login,
			clefEcole: false,
			nbClient: 1,
			commandeNameCaisse: order.machine === null ? "" : order.machine.name,
			commandeNumber: 0,
			sourceTicketNameCaisse: "",
			sourceTicketNumber: 0,
			destinationTicketNameCaisse: "",
			destinationTicketNumber: 0,
			sourceTicketState: CsiState.NONE,
			destinationTicketState: CsiState.NONE,

			horodatage: now,
			regroupementConsommation: "",
			tag: tag,
			dateFiscale: now,

			commandeId: order.csiOrder || 0,
			generator: order.csiSrc || "",
			lines: [],
			complements: [],
		};

		//  Add the date line
		CsiManager.addDateLineToTicket(settings, ticket, order);

		//  Array to contain the "deliver later"
		const deliverLater: ICsiDeliverLaterInfo[] = [];

		//  Create the manager
		const csiManager: CsiManager = new CsiManager(itemIdentifier, productConverter, menuConverter);

		//  Now process the content of the order
		order.orderContent.forEach((itemInOrderBase: IBKItemInOrderBase) => {
			const itemtype: CsiItemTypeEnum = csiManager.itemIdentifier(itemInOrderBase);
			switch (itemtype) {
				case CsiItemTypeEnum.PRODUCT:
					csiManager.addProductToTicket(settings, ticket, csiManager.productConverter(itemInOrderBase), order, deliverLater, null, 1, ItemInOrderState.ACTIVE, deleteTicket);
					break;
				case CsiItemTypeEnum.MENU:
					csiManager.addMenuToTicket(settings, ticket, csiManager.menuConverter(itemInOrderBase), order, deliverLater, ItemInOrderState.ACTIVE, deleteTicket);
					break;
				case CsiItemTypeEnum.FEE_WITH_FLEXIBLE_PRICE:
					csiManager.addFeeToTicket(settings, ticket, itemInOrderBase, order);
					break;
			}
		});

		//  Add the discount
		order.orderDiscount.forEach(function (d: IBKDiscountInOrderData) {
			CsiManager.addOrderDiscountLinetoTicket(settings, ticket, d, null, order.creationdate, order, null, null, false, deleteTicket);
		});

		//  Now process the deleted content of the order
		order.orderDelContent.forEach((iio: IBKItemInOrderBase) => {
			const itemtype: CsiItemTypeEnum = csiManager.itemIdentifier(iio);
			switch (itemtype) {
				case CsiItemTypeEnum.PRODUCT:
					csiManager.addProductToTicket(settings, ticket, csiManager.productConverter(iio), order, deliverLater, null, 1, ItemInOrderState.DELETED_BEFORE_KITCHEN, deleteTicket);
					break;
				case CsiItemTypeEnum.MENU:
					csiManager.addMenuToTicket(settings, ticket, csiManager.menuConverter(iio), order, deliverLater, ItemInOrderState.DELETED_BEFORE_KITCHEN, deleteTicket);
					break;
			}
		});

		//process deleted content after kitchen screen
		order.orderDelContentAfterKitchenScreen?.forEach((iio: IBKItemInOrderBase) => {
			const itemtype: CsiItemTypeEnum = csiManager.itemIdentifier(iio);
			switch (itemtype) {
				case CsiItemTypeEnum.PRODUCT:
					csiManager.addProductToTicket(settings, ticket, csiManager.productConverter(iio), order, deliverLater, null, 1, ItemInOrderState.DELETED_AFTER_KITCHEN, deleteTicket);
					break;
				case CsiItemTypeEnum.MENU:
					csiManager.addMenuToTicket(settings, ticket, csiManager.menuConverter(iio), order, deliverLater, ItemInOrderState.DELETED_AFTER_KITCHEN, deleteTicket);
					break;
			}
		});

		order.orderDelContentAfterDecl.forEach((iio: IBKItemInOrderBase) => {
			const itemtype: CsiItemTypeEnum = csiManager.itemIdentifier(iio);
			switch (itemtype) {
				case CsiItemTypeEnum.PRODUCT:
					csiManager.addProductToTicket(
						settings,
						ticket,
						csiManager.productConverter(iio),
						order,
						deliverLater,
						null,
						1,
						ItemInOrderState.DELETED_AFTER_ORDER_SUB_TOTAL,
						deleteTicket
					);
					break;
				case CsiItemTypeEnum.MENU:
					csiManager.addMenuToTicket(settings, ticket, csiManager.menuConverter(iio), order, deliverLater, ItemInOrderState.DELETED_AFTER_ORDER_SUB_TOTAL, deleteTicket);
					break;
			}
		});

		// init settings skip if unset
		settings.skip = settings.skip || {};
		if (!settings.skip.source) {
			//  Add the delivery and source
			CsiManager.addOrderSourceAndDestination(settings, ticket, order);
		}

		if (settings.brandName !== BrandName.BurgerKing_France) {
			CsiManager.addEmployeeMealLineToTicket(settings, ticket, order);
		}

		if (!settings.skip.orb) {
			//  Add the ORB
			if (settings.brandName === BrandName.Quick || settings.brandName === BrandNameNew.Quick) {
				CsiManager.addQuickORBLineToTicket(settings, ticket, orb, order);
			} else {
				CsiManager.addORBLineToTicket(settings, ticket, orb, order);
			}
		}

		//  Pay delayed
		CsiManager.addKioskPayDelayedLineToTicket(settings, ticket, settings.kioskMultipayMessage, order);

		if (!settings.skip.tableNum) {
			//  Add the table allocation number if it exists
			CsiManager.addTableAllocationNumberLineToTicket(settings, ticket, order);
		}

		CsiManager.addPagerNumberLineToTicket(settings, ticket, order);

		//  Add the fidelity card if if it exists
		CsiManager.addFidelityCardIdLineToTicket(settings, ticket, order);

		CsiManager.addFidelityPointsToTicket(settings, ticket, order);

		//  Add the Toilet code if it exists
		CsiManager.addToiletCodeLineToTicket(settings, ticket, settings.toiletCode, order);

		// Add Parking info if needed
		CsiManager.addPickupParkingInfoToTicket(settings, ticket, order);

		//  Marketing
		CsiManager.addMarketingAlertOnTicketFooterText(settings, ticket, order, settings.messageOnTicket);

		//  Check for some active coupons
		if (footerCallback !== null) {
			footerCallback(ticket);
		}

		const drinkFountainCodesEvent = order.event.find((event) => event.eventType === BKOrderEventType.DRINK_FOUNTAIN_CODES);
		const drinkFountainCodes: DrinkCodesDto = drinkFountainCodesEvent ? JSON.parse(drinkFountainCodesEvent.arg) : {};

		if (drinkFountainCodes?.smallDrinks?.length) {
			CsiManager.addPlainTextToTicket("20cl", settings, ticket, order);
			drinkFountainCodes.smallDrinks.forEach((code) => {
				CsiManager.addRecallQRToTicket(settings, ticket, code, order);
			});
		}
		if (drinkFountainCodes?.mediumDrinks?.length) {
			CsiManager.addPlainTextToTicket("40cl", settings, ticket, order);
			drinkFountainCodes.mediumDrinks.forEach((code) => {
				CsiManager.addRecallQRToTicket(settings, ticket, code, order);
			});
		}
		if (drinkFountainCodes?.bigDrinks?.length) {
			CsiManager.addPlainTextToTicket("50cl", settings, ticket, order);
			drinkFountainCodes.bigDrinks.forEach((code) => {
				CsiManager.addRecallQRToTicket(settings, ticket, code, order);
			});
		}

		const whopperScratcherCoupon = BKOrderUtilities.getWhopperScratcherCouponFromOrder(order);
		if (whopperScratcherCoupon) {
			CsiManager.addWhopperScratcherHeaderToTicket(settings, ticket, order);
		}
		//  Add the recall QR
		CsiManager.addRecallQRToTicket(settings, ticket, recallQR, order);
		if (whopperScratcherCoupon) {
			CsiManager.addWhopperScratcherFooterToTicket(settings, ticket, order);
		}

		if (!settings.skip.payLine) {
			//  Add the ORB for command
			if (settings.brandName === BrandName.Quick || settings.brandName === BrandNameNew.Quick) {
				CsiManager.addQuickCommandORBLineToTicket(settings, ticket, orb, order);
			} else {
				CsiManager.addCommandORBLineToTicket(settings, ticket, orb, order);
			}
		}

		//  Deliver later
		if (deliverLater.length > 0 && !deleteTicket) CsiManager.addDeliverLaterToTicket(settings, ticket, deliverLater, order);

		CsiManager.addRetrieveToTicket(settings, ticket, order);
		CsiManager.addReceivedOnDrivePay(settings, ticket, order);
		CsiManager.addFidelityPoints(settings, ticket, order);
		CsiManager.addComplements(settings, ticket, order);
		CsiManager.addOrbNumber(ticket, order);

		const callOfDutyCodes = BKOrderEventUtilities.getCallOfDutyCodesFromEvents(order.event);
		if (callOfDutyCodes.length) {
			CsiManager.addCallOfDutyCodesToTicket(settings, ticket, callOfDutyCodes, order);
		}

		if (settings.brandName === BrandName.BurgerKing_Reunion || settings.brandName === BrandNameNew.BurgerKing_Reunion) {
			CsiManager.addBKREFeedbackLink(settings, ticket, order);
		}

		//  Finished, return the ticket
		return ticket;
	}

	/**
	 * Truncate the libelle to the correct size
	 */
	public static truncateToCSISize(s: string): string {
		return s.substr(0, 50);
	}

	/**
	 * Truncate the libelle to the correct size
	 */
	public static truncateToCSISizeShort(s: string): string {
		return s.substr(0, 24);
	}

	/**
	 * @ngdoc function
	 * @name CsiManager.isUndefined
	 * @module ng
	 * @kind function
	 *
	 * @description
	 * Determines if a reference is undefined.
	 *
	 * @param {*} value Reference to check.
	 * @returns {boolean} True if `value` is undefined.
	 */
	private static isUndefined(value: any): value is undefined {
		return typeof value === "undefined";
	}

	/**
	 * @ngdoc function
	 * @name CsiManager.isDefined
	 * @module ng
	 * @kind function
	 *
	 * @description
	 * Determines if a reference is defined.
	 *
	 * @param {*} value Reference to check.
	 * @returns {boolean} True if `value` is defined.
	 */
	public static isDefined<T>(value: T | null | undefined): value is T {
		return typeof value !== "undefined";
	}

	private static generateUUID(): string {
		let d = new Date().getTime();
		const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			const r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
		});
		return uuid;
	}

	public static getGeneratedUUID(): string {
		return this.generateUUID();
	}

	/**
	 * Now function
	 */
	private static readonly now: () => number =
		Date.now ||
		function (): number {
			return new Date().getTime();
		};

	/** Encode an object to its urlencoded version  **/
	private static object2URLEncoded(obj: any): string {
		const a: Array<string> = [];
		for (const key in obj) {
			if (obj[key] !== null && typeof obj[key] !== "object") {
				a.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
			}
		}
		return a.join("&");
	}

	// TODO: DEV-2579 - This is the prefered implementation. There is duplication in bk-js-lib
	/**
	 * This is for the LEGACY PriceManagement.
	 *
	 * Pickup the price for the delivery mode.
	 */
	private static pickupPriceForDeliveryMode(aLaCartePrice: IBKItemPriceInfo, deliveryMode: BKDeliveryModeEnum): IBKItemPriceInfo {
		if (deliveryMode === BKDeliveryModeEnum.DELIVERY_LOCAL) {
			//  Local delivery means normal and default behaviour
			return {
				ht: aLaCartePrice.ht,
				tva: aLaCartePrice.tva,
				ttc: aLaCartePrice.ttc,
				pc: aLaCartePrice.pc,
			};
		} else {
			const pcto: number = CsiManager.isDefined(aLaCartePrice.pcto) ? aLaCartePrice.pcto : aLaCartePrice.pc;
			const htto: number = CsiManager.isDefined(aLaCartePrice.htto) ? aLaCartePrice.htto : aLaCartePrice.ht;
			//  Local delivery means normal and default behaviour
			return {
				ht: htto <= 0 ? aLaCartePrice.ht : htto,
				tva: aLaCartePrice.tva,
				ttc: aLaCartePrice.ttc,
				pc: pcto <= 0 ? aLaCartePrice.pc : pcto,
			};
		}
	}

	/**
	 * Fix the ORB number
	 */
	private static fixORBNumber(orb: number): number {
		const finalOrb: number = orb % 1000;
		return finalOrb <= 0 ? 1000 : finalOrb;
	}

	private static patchIngredientCode(ingredient: ICsiIngredient, minfos: { label: string; bkpn: string; plumod: number }): string {
		const p = /^([a-zA-Z0]*)([0-9]+)$/g;
		const a: RegExpExecArray | null = p.exec(ingredient.refI.plu);
		if (a === null) {
			return ingredient.refI.plu;
		}
		const v: number = parseInt(a[2]) + minfos.plumod;
		if (isNaN(v)) {
			return ingredient.refI.plu;
		}
		return a[1] + v.toString();
		//isNaN(parseInt(ingredient.refI.plu)) ? ingredient.refI.plu : (parseInt(ingredient.refI.plu) + minfos.plumod).toString(),
	}

	/**
	 * Lookup for the first event of a specified type
	 */
	public static lookupForFirstOrderEventOfType(order: IBKPublishedOrderData, eventType: BKOrderEventType): IBKOrderEventData | null {
		if (!Array.isArray(order.event) || order.event.length <= 0) {
			return null;
		}
		for (let i = 0; i < order.event.length; i++) {
			if (order.event[i].eventType === eventType) {
				return order.event[i];
			}
		}
		return null;
	}

	/**
	 * Lookup for an alien ID
	 */
	public static lookupForAlienOrderID(order: IBKPublishedOrderData, defaultValue: string | null = null): string | null {
		const ev = CsiManager.lookupForFirstOrderEventOfType(order, BKOrderEventType.WEBORDER_ALIEN_ORDER_ID);
		return ev === null ? defaultValue : ev.arg;
	}

	/**
	 * Lookup for a sandbox name
	 */
	public static lookupForSandboxName(order: IBKPublishedOrderData, defaultValue: string | null = null): string | null {
		const ev = CsiManager.lookupForFirstOrderEventOfType(order, BKOrderEventType.WEBORDER_SANDBOX_NAME);
		return ev === null ? defaultValue : ev.arg;
	}

	/**
	 * Lookup for a alien display ID (order Id)
	 */
	public static lookupForAlienDisplayName(order: IBKPublishedOrderData, defaultValue: string | null = null): string | null {
		const ev = CsiManager.lookupForFirstOrderEventOfType(order, BKOrderEventType.WEBORDER_ALIEN_ORDER_ID_DISPLAY);
		return ev === null ? defaultValue : ev.arg;
	}

	public static lookupForCustomerDetail(order: IBKPublishedOrderData): string | null {
		const ev = CsiManager.lookupForFirstOrderEventOfType(order, BKOrderEventType.CUSTOMER_DETAIL);
		return ev === null ? undefined : ev.arg;
	}

	public static lookupForDeliveryDetail(order: IBKPublishedOrderData): string | null {
		const ev = CsiManager.lookupForFirstOrderEventOfType(order, BKOrderEventType.DELIVERY_DETAIL);
		return ev === null ? undefined : ev.arg;
	}

	public static lookupForOrderNote(order: IBKPublishedOrderData): string | null {
		const ev = CsiManager.lookupForFirstOrderEventOfType(order, BKOrderEventType.ORDER_NOTE);
		return ev === null ? undefined : ev.arg;
	}

	public static getCsiUserSession(session: any /*:BKSessionState*/): ICsiUserSession {
		return {
			numClef: session.currentUser.id,
			nomClef: session.currentUser.login,
			codeManager: session.currentManager.id,
			nomManager: session.currentManager.login,
			codeVendeur: session.currentUser.id,
			nomVendeur: session.currentUser.login,
			libCaisse: session.currentMachine.ip,
			numCaisse: session.currentMachine.idx,
		};
	}

	public static isDuplicateLineUuid(lineuuid: string, ticket: ICsiTicket): boolean {
		if (!lineuuid || ticket.lines?.length <= 0) {
			return false;
		}
		return !!ticket.lines.find((ticketLine: ICsiLine): boolean => ticketLine.lineUuid === lineuuid);
	}

	/**
	 * Implemented due to DELIVERECT requirements.
	 * Fees are supposed to have any price so they may not be base on existing
	 * BigData or Availability information. That's why they are built differently.
	 *
	 * Fees are identified by 0 id and "flexiblePrice" flag.
	 */
	private addFeeToTicket(settings: ICsiTicketSettings, ticket: ICsiTicket, feeItemInOrderBase: IBKItemInOrderBase, order: ICsiOrder): void {
		if (feeItemInOrderBase.line <= 0) {
			feeItemInOrderBase.line = order.getNextLineIndex();
		}
		if (feeItemInOrderBase.lineuuid === null || feeItemInOrderBase.lineuuid === "") {
			feeItemInOrderBase.lineuuid = CsiManager.generateUUID();
		}
		if (feeItemInOrderBase.timestamp <= 0) {
			feeItemInOrderBase.timestamp = CsiManager.now();
		}

		let bkpn: number = parseInt(feeItemInOrderBase.ref.bkpn);
		if (isNaN(bkpn)) bkpn = 7414;
		const tagElements: { [key: string]: string } = {
			product_id: feeItemInOrderBase.id.toString(),
			third_party_id: bkpn.toString(),
			a_la_carte_price: feeItemInOrderBase.aLaCartePrice.ttc.toString(),
		};
		const libelle: string = feeItemInOrderBase.sysName;
		if (CsiManager.isDefined(feeItemInOrderBase._altName) && feeItemInOrderBase._altName !== "") {
			tagElements["libelle_substitution"] = CsiManager.truncateToCSISize(feeItemInOrderBase._altName);
		}

		const line: ICsiLine = {
			lineUuid: feeItemInOrderBase.lineuuid,
			numero: feeItemInOrderBase.id,
			horodatage: feeItemInOrderBase.timestamp,
			libelleTarif: "",
			numTarif: 0,
			libelle: CsiManager.truncateToCSISize(libelle),
			code: feeItemInOrderBase.ref.plu,
			prix: feeItemInOrderBase.price.ttc,
			prixUnitaireCarte: feeItemInOrderBase.price.ttc,
			qte: feeItemInOrderBase.qty,
			poids: 1,
			unite: CsiUnit.PIECE,
			ticketUuid: ticket.ticketUuid,
			typeLigne: CsiLines.ARTICLE,
			numClef: order.user.id,
			nomClef: order.user.login,
			codeManager: order.manager.id,
			nomManager: order.manager.login,
			codeVendeur: order.user.id,
			nomVendeur: order.user.login,
			libFamille: "",
			libSousFamille: "",
			libGroupe: "",
			numFamille: 0,
			numSousFamille: 0,
			numGroupe: 0,
			libelleTVA: CsiManager.composeLibelleTVA(feeItemInOrderBase.price.pc),
			numTVA: Math.round(100 * feeItemInOrderBase.price.pc),
			tauxTVA: feeItemInOrderBase.price.pc,
			libCaisse: order.machine.ip,
			numCaisse: order.machine.idx,
			remiseMontantTTC: 0,
			remiseMontantHT: 0,
			remiseTaux: 0,
			serviceTaux: 0,
			serviceMode: "AUCUN",
			serviceMontant: 0,
			montantTotalTTC: 0,
			montantTotalHT: 0,
			deviseCode: "euro",
			deviseTaux: 1,
			deviseLibelle: "euro",
			deviseMontant: 0,
			tag: CsiManager.object2URLEncoded(tagElements),
			libelleModifier: "",
			libelleQualifier: "",
			change: false,
			filter: 0 /** Filter 0 excludes the line from all the discounts (no discount will be applied on this line) */,
		};
		if (settings.addProfitCenter) {
			line.centreProfit = settings.addProfitCenter;
		}
		ticket.lines.push(line);
	}

	private getHighestAlignedVat(items: IBKItemInOrderBase[], deliveryMode: BKDeliveryModeEnum): number {
		if (!items?.length || !this.productConverter) {
			return 0;
		}
		const csiItems: ICsiProductInOrder[] = items.map((item: IBKItemInOrderBase) => this.productConverter(item));

		return csiItems.reduce((highestVat: number, csiItem: ICsiProductInOrder): number => {
			if (csiItem !== null && isItemIncludedInVatAlignment(csiItem)) {
				const priceKey = deliveryMode === BKDeliveryModeEnum.DELIVERY_LOCAL || !csiItem.aLaCartePrice?.pcto || csiItem.aLaCartePrice?.pcto <= 0 ? "pc" : "pcto";
				return csiItem.aLaCartePrice[priceKey] > highestVat ? csiItem.aLaCartePrice[priceKey] : highestVat;
			} else {
				return highestVat;
			}
		}, 0);
	}
}
