import * as api from '@dki/api-client';

import { Range } from '@libs/dash/core/entity';
import { DateTime } from 'luxon';

export const STORE_SLICE_KEY = 'SessionHistory';

export interface SessionHistoryState {
	data: Array<api.PosRecord> | any;
	isLoading?: boolean;
	from?: DateTime;
	to?: DateTime;
}

export const SESSION_HISTORY_INITIAL_STATE: SessionHistoryState = {
	data: [],
	from: DateTime.now().startOf(Range.Week),
	to: DateTime.now().endOf(Range.Week),
};
