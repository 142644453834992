import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageISOCode } from '@libs/dash/core/entity';
import { getPreferredLanguage, reloadCurrentRoute, setPreferredLanguage } from '../../core/utils/functions';

@Component({
	selector: 'dk-language-switcher',
	templateUrl: './language-switcher.component.html',
	styleUrls: ['./language-switcher.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherComponent {
	currentLanguage = getPreferredLanguage();
	defautLang = this.isDefaultLang();

	constructor(private router: Router) {}

	isDefaultLang() {
		return this.currentLanguage === LanguageISOCode.FR;
	}

	changeLang(event: Event) {
		this.defautLang = !event.target['checked'];
		setPreferredLanguage(this.defautLang ? LanguageISOCode.FR : LanguageISOCode.EN);
		reloadCurrentRoute(this.router);
	}
}
