export enum PaymentMethods {
	'',
	'CREDIT CARD',
	'CB',
	'CASH',
	'ESPECES',
	'TPE MOBILE',
	'TPE_MOBILE',
	'ANCV',
	'BANK_CHECK',
	'CHEQUE',
	'CHEQUES',
	'CRT',
	'HOLIDAYS TICKET',
	'DEFERRED',
	'UBER_EATS',
	'UBER EATS',
	'UBEREATS',
	'DELIVEROO',
	'JUST EAT',
	'EDENRED',
	'SODEXO',
	'APETIZ',
	'CONECSAPETIZ',
	'CHEQDEJ',
	'CONECSCHEQDEJ',
	'CONECSEDENRED',
	'CONECSSODEXO',
	'PLEASE',
	'ZOT LIVRAISON',
	'REPAS EMPLOYES',
	'EMPLOYEE_MEAL',
	'NON RENDU',
	'NOT_RETURNED',
	'RENDU',
}

export const getOrder = (value: string) => {
	return PaymentMethods[value] ? PaymentMethods[value] : 100; // any high enough value
};
