import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import { filter, map } from 'rxjs/operators';

export const MY_FORMATS = {
	parse: {
		dateInput: 'LL',
	},
	display: {
		dateInput: 'LL',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@UntilDestroy()
@Component({
	selector: 'dk-page-header',
	templateUrl: './page-header.component.html',
	styleUrls: ['./page-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	// TODO:
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	],
})
export class PageHeaderComponent {
	maxDate = DateTime.local().endOf('day').toJSDate();
	date = new FormControl(new Date());

	languages;
	selectedLanguageCode: string;
	localeText$ = this.translocoService.selectTranslateObject('operatingReportTile');

	@Input() title: string;
	@Input() editButton: boolean;
	@Input() datePicker: boolean;

	@Output() dateSelectionChange: EventEmitter<{ year: number; month: number; date: number }> = new EventEmitter();
	@Output() todaySelection: EventEmitter<string> = new EventEmitter<string>();

	constructor(private readonly translocoService: TranslocoService) {
		this.languages = translocoService.getAvailableLangs();
		this.selectedLanguageCode = translocoService.getActiveLang();
		this.date.valueChanges
			.pipe(
				untilDestroyed(this),
				filter((x) => !!x),
				map((selectedDate: any) => {
					if (DateTime.fromJSDate(selectedDate._d).hasSame(DateTime.local(), 'day')) {
						return this.setDateToday();
					}
					return this.dateSelectionChange.emit(selectedDate);
				})
			)
			.subscribe();
	}

	setDateToday() {
		const today = DateTime.local().startOf('day').toJSDate();
		this.todaySelection.emit(JSON.stringify(today));
		this.date.setValue(today);
	}
}
