import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { FeatureMapService } from '@libs/dash/guards';

@Component({
	selector: 'dk-page-container',
	templateUrl: './page-container.component.html',
	styleUrls: ['./page-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageContainerComponent {
	@Input() title: string;
	@Input() editButton: boolean;
	@Input() datePicker: boolean;
	@Input() isLoading: boolean;
	@Input() sidebar = true;
	@Input() sourced = false;
	@Input() figma = false;

	@Output() dateSelectionChange: EventEmitter<{ year: number; month: number; date: number }> = new EventEmitter();
	@Output() todaySelection: EventEmitter<string> = new EventEmitter<string>();

	enableRestaurantSelection: boolean;

	emitDashboardTimeRange(selectedDate: { year: number; month: number; date: number }): void {
		this.dateSelectionChange.emit(selectedDate);
	}

	emitTodaySelection(event): void {
		this.todaySelection.emit(event);
	}

	constructor(private readonly fm: FeatureMapService) {
		this.enableRestaurantSelection = this.fm.featureMap?.restaurantSelection;
	}
}
