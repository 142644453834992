import { createFeatureSelector, createSelector } from '@ngrx/store';

import { STORE_SLICE_KEY, SynthesisReportState } from './state';

export const reducerState = createFeatureSelector<SynthesisReportState>(STORE_SLICE_KEY);

export const synthesisReport = createSelector(reducerState, (state) => state.generalSynthesisReport);
export const vatSynthesisReport = createSelector(reducerState, (state) => state.vatSynthesisReport);
export const isLoading = createSelector(reducerState, (state) => state.isLoading);

export const depositSynthesis = createSelector(synthesisReport, (report) => {
	if (report && report.by_staff && report.by_staff.by_employee) {
		const by_manager = report.by_staff.by_manager;
		const by_employee = report.by_staff.by_employee;
		const by_manager_data_set = [];
		Object.entries(by_manager).forEach((entry) => {
			const data = {
				staff: entry[0],
				by_label: entry[1],
				total: Object.values(entry[1]).reduce((total, entry) => total + entry.amount.real, 0),
			};
			by_manager_data_set.push(data);
		});
		const by_employee_data_set = [];
		Object.entries(by_employee).forEach((entry) => {
			const data = {
				staff: entry[0],
				by_label: entry[1],
				total: Object.values(entry[1]).reduce((total, entry) => total + entry.amount.real, 0),
			};
			by_employee_data_set.push(data);
		});
		let labels = Object.keys(Object.values(by_employee)[0] || []);
		// optimisation ahead
		if (labels.length < 3) {
			// if by chance the first session was a kiosk or a web user we won't have all payment methods, so we'll do a deep search for them in the resultset
			const paymentMethods = new Set<string>();
			Object.values(by_employee)
				.map(Object.keys)
				.forEach((session) => {
					session.forEach((paymentMethod) => paymentMethods.add(paymentMethod));
				});
			labels = Array.from(paymentMethods);
		}
		const hidden = [];
		labels.forEach((label) => {
			const totalByLabel = report.by_label.find((entry) => entry.label === label);
			if (totalByLabel) {
				if (totalByLabel.amount.real === 0) {
					hidden.push(label);
				}
			}
		});
		return { labels, by_manager_data_set, by_employee_data_set, hidden };
	}
	return null;
});
