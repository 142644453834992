import * as api from '@dki/api-client';

export const STORE_SLICE_KEY = 'My Restaurants';

export interface MyRestaurantsState {
	listOfMyRestaurants?: api.Restaurant[];
	longTermOrders: api.OrdersByTime[];
	isLoading: boolean;
	mySelectedRestaurant?: api.Restaurant;
	mySelectedRestaurants?: api.Restaurant[];
	isMultiSelectionEnabled: boolean;
	loadingDefaultRestaurant: boolean;
}

export const MY_RESTAURANT_INITIAL_STATE: MyRestaurantsState = {
	listOfMyRestaurants: [],
	longTermOrders: [],
	isLoading: true,
	isMultiSelectionEnabled: false,
	loadingDefaultRestaurant: true,
};
