/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';

import { OAuth2AccessToken, ResetPasswordInfo, ResetPasswordRequestInfo } from '../model/models';

import { BASE_PATH } from '../variables';


@Injectable()
export class AuthApiClient {

	protected basePath = 'http://localhost';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	public encoder: HttpParameterCodec;

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (configuration) {
			this.configuration = configuration;
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (typeof basePath !== 'string') {
				basePath = this.basePath;
			}
			this.configuration.basePath = basePath;
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}

	private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
		if (typeof value === "object" && value instanceof Date === false) {
			httpParams = this.addToHttpParamsRecursive(httpParams, value);
		} else {
			httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
		}
		return httpParams;
	}

	private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
		if (value == null) {
			return httpParams;
		}

		if (typeof value === "object") {
			if (Array.isArray(value)) {
				(value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
			} else if (value instanceof Date) {
				if (key != null) {
					httpParams = httpParams.append(key,
						(value as Date).toISOString().substr(0, 10));
				} else {
					throw Error("key may not be null if value is Date");
				}
			} else {
				Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
					httpParams, value[k], key != null ? `${key}.${k}` : k));
			}
		} else if (key != null) {
			httpParams = httpParams.append(key, value);
		} else {
			throw Error("key may not be null if value is not object or array");
		}
		return httpParams;
	}

	/**
	 * Login
	 * Log in with username and password.  Use email as &#x60;username&#x60; and a &#x60;password&#x60;. Other fields are currently not used, they are provided only for compatibility with [OAuth2 password grant](https://tools.ietf.org/html/rfc6749#section-4.3.2).  The returned access token can be used in the &#x60;Authorization&#x60; header:      Authorization: Bearer {access_token}  This API endpoint also sets the &#x60;session_id&#x60; cookie which can be used for authentication instead of the &#x60;Authorization&#x60; header.
	 * @param username
	 * @param password
	 * @param grantType
	 * @param scope
	 * @param clientId
	 * @param clientSecret
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public loginV1AuthLoginPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<OAuth2AccessToken>;
	public loginV1AuthLoginPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<OAuth2AccessToken>>;
	public loginV1AuthLoginPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<OAuth2AccessToken>>;
	public loginV1AuthLoginPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (username === null || username === undefined) {
			throw new Error('Required parameter username was null or undefined when calling loginV1AuthLoginPost.');
		}
		if (password === null || password === undefined) {
			throw new Error('Required parameter password was null or undefined when calling loginV1AuthLoginPost.');
		}

		let headers = this.defaultHeaders;

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		const consumes: string[] = [
			'application/x-www-form-urlencoded'
		];

		const canConsumeForm = this.canConsumeForm(consumes);

		let formParams: { append(param: string, value: any): any; };
		let useForm = false;
		let convertFormParamsToString = false;
		if (useForm) {
			formParams = new FormData();
		} else {
			formParams = new HttpParams({encoder: this.encoder});
		}

		if (grantType !== undefined) {
			formParams = formParams.append('grant_type', <any>grantType) as any || formParams;
		}
		if (username !== undefined) {
			formParams = formParams.append('username', <any>username) as any || formParams;
		}
		if (password !== undefined) {
			formParams = formParams.append('password', <any>password) as any || formParams;
		}
		if (scope !== undefined) {
			formParams = formParams.append('scope', <any>scope) as any || formParams;
		}
		if (clientId !== undefined) {
			formParams = formParams.append('client_id', <any>clientId) as any || formParams;
		}
		if (clientSecret !== undefined) {
			formParams = formParams.append('client_secret', <any>clientSecret) as any || formParams;
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.post<OAuth2AccessToken>(`${this.configuration.basePath}/v1/auth/login`,
			convertFormParamsToString ? formParams.toString() : formParams,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Logout
	 * Log out and invalidate the current session token.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public logoutV1AuthLogoutPost(observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined }): Observable<any>;
	public logoutV1AuthLogoutPost(observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined }): Observable<HttpResponse<any>>;
	public logoutV1AuthLogoutPost(observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined }): Observable<HttpEvent<any>>;
	public logoutV1AuthLogoutPost(observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: undefined }): Observable<any> {

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.post<any>(`${this.configuration.basePath}/v1/auth/logout`,
			null,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Reset Password
	 * Set a new password using a password reset token. The token can be obtained with &#x60;/v1/auth/send-password-reset-token&#x60;.
	 * @param resetPasswordInfo
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public resetPasswordV1AuthResetPasswordPost(resetPasswordInfo: ResetPasswordInfo, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<any>;
	public resetPasswordV1AuthResetPasswordPost(resetPasswordInfo: ResetPasswordInfo, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<any>>;
	public resetPasswordV1AuthResetPasswordPost(resetPasswordInfo: ResetPasswordInfo, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<any>>;
	public resetPasswordV1AuthResetPasswordPost(resetPasswordInfo: ResetPasswordInfo, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (resetPasswordInfo === null || resetPasswordInfo === undefined) {
			throw new Error('Required parameter resetPasswordInfo was null or undefined when calling resetPasswordV1AuthResetPasswordPost.');
		}

		let headers = this.defaultHeaders;

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.post<any>(`${this.configuration.basePath}/v1/auth/reset-password`,
			resetPasswordInfo,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Send Password Reset Token
	 * Send an email message with a password reset token that can be used with &#x60;/v1/auth/reset-password&#x60;.  This endpoint always returns &#x60;204 No Content&#x60;, even if the given email does not exist in the database. This is to prevent disclosing the emails of registered users and email enumeration attacks.
	 * @param resetPasswordRequestInfo
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public sendPasswordResetTokenV1AuthSendPasswordResetTokenPost(resetPasswordRequestInfo: ResetPasswordRequestInfo, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<any>;
	public sendPasswordResetTokenV1AuthSendPasswordResetTokenPost(resetPasswordRequestInfo: ResetPasswordRequestInfo, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<any>>;
	public sendPasswordResetTokenV1AuthSendPasswordResetTokenPost(resetPasswordRequestInfo: ResetPasswordRequestInfo, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<any>>;
	public sendPasswordResetTokenV1AuthSendPasswordResetTokenPost(resetPasswordRequestInfo: ResetPasswordRequestInfo, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (resetPasswordRequestInfo === null || resetPasswordRequestInfo === undefined) {
			throw new Error('Required parameter resetPasswordRequestInfo was null or undefined when calling sendPasswordResetTokenV1AuthSendPasswordResetTokenPost.');
		}

		let headers = this.defaultHeaders;

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.post<any>(`${this.configuration.basePath}/v1/auth/send-password-reset-token`,
			resetPasswordRequestInfo,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
