import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HorizontalBarsComponent } from './component/horizontal-bars.component';

@NgModule({
	declarations: [HorizontalBarsComponent],
	imports: [CommonModule],
	exports: [HorizontalBarsComponent],
})
export class HorizontalBarsModule {}
