import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// import { FeatureMap, featureMap } from '../../../../assets/brand/FeatureMap';

@Injectable({
	providedIn: 'root',
})
export class FeatureMapService {
	featureMap: any;

	constructor(private http: HttpClient) {}

	loadFeatureMap() {
		return this.http
			.get('/assets/configs/featuremap/fm.json')
			.toPromise()
			.then((data) => {
				this.featureMap = data;
			});
	}
}
