import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ActionButtons {
	positive: {
		label: string;
		disabled: boolean;
		routerLink?: string;
	};
	negative?: {
		label: string;
		routerLink?: string;
	};
}

@Component({
	selector: 'dk-action-buttons',
	templateUrl: './action-buttons.component.html',
	styleUrls: ['./action-buttons.component.scss'],
})
export class ActionButtonsComponent {
	@Input() buttons: ActionButtons;

	@Output() negativeAction = new EventEmitter<void>();
	@Output() positiveAction = new EventEmitter<void>();

	emitNegativeAction(): void {
		this.negativeAction.emit();
	}

	emitPositiveAction(): void {
		this.positiveAction.emit();
	}
}
