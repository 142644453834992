// New unified 4 characters long brand names
// will be used in new kiosk and in new applications
// suitable for projects in 'ts' folder
export enum BrandNameNew {
	BurgerKing_France = 'bkfr',
	BurgerKing_Reunion = 'bkre',

	HollysDiner = 'hdfr',
	Quick = 'qrfr',
	Cora_France = 'cofr',
	PokeMama_France = 'pmfr',
	CapMerchant_Reunion = 'cmre',
	BeChef_France = 'bcfr',

	Default = 'Default',

	Mqsr = 'mqsr',
	/**
	 * Fallback for whitelabelled apps.
	 */
	Unknown = 'unknown',
}

export enum BrandNameNewLong {
	BurgerKing_France = 'BurgerKing_France',
	BurgerKing_Reunion = 'BurgerKing_La_Reunion',

	HollysDiner = 'Hollys_Dinner',
	Quick = 'Quick',
	Quick_old = 'Quick',
	Cora_France = 'Cora',
	PokeMama_France = 'Pokemama',
	CapMerchant_Reunion = 'CapMerchant',
	BeChef_France = 'BeChef',

	Default = 'Default',

	Mqsr = 'mqsr',
	/**
	 * Fallback for whitelabelled apps.
	 */
	Unknown = 'unknown',
}
