export enum TableDataSourceName {
	REVENUES = 'revenueTableData',
	ORDERS_BY_CHANNEL = 'ordersByChannel',
	ORDERS_BY_TIME = 'ordersByTime',
	ORDERS_BY_PRODUCT = 'ordersByProduct',
	TOP_TEN_PRODUCTS = 'topTenProducts',
	ORDERS_BY_CHANNEL_WITH_AVERAGES = 'ordersByChannelWithAverages',
	SOS = 'sos',
	KIOSK_SOS = 'kioskSos',
}
