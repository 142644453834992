import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AccountingEffects } from './effects';
import { AccountingReportReducer } from './reducer';
import { STORE_SLICE_KEY } from './state';

@NgModule({
	declarations: [],
	imports: [CommonModule, StoreModule.forFeature(STORE_SLICE_KEY, AccountingReportReducer), EffectsModule.forFeature([AccountingEffects])],
	providers: [AccountingEffects],
})
export class AccountingStoreModule {}
