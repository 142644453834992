import { ConfigurationService } from '@merim/utils';

const DomWindowConfigurationServiceFactory = () => {
	const configurationService = new ConfigurationService();
	const configStorage = window ? window['__CONFIG'] : {};

	for (const key in configStorage) {
		const hasOwnProperty = Object.prototype.hasOwnProperty.call(configStorage, key);
		if (hasOwnProperty) {
			configurationService[key] = configStorage[key];
		}
	}

	return configurationService;
};

/**
 * Read configuration from custom window.__CONFIG object
 */
export const DomWindowConfigurationServiceProvider = {
	provide: ConfigurationService,
	useFactory: DomWindowConfigurationServiceFactory
};
