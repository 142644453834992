/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type ReportChannel = 'DRIVE_THROUGH' | 'DELIVERY' | 'EAT_IN' | 'TAKE_OUT';

export const ReportChannel = {
	DriveThrough: 'DRIVE_THROUGH' as ReportChannel,
	Delivery: 'DELIVERY' as ReportChannel,
	EatIn: 'EAT_IN' as ReportChannel,
	TakeOut: 'TAKE_OUT' as ReportChannel
};

