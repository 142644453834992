import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { QUERY_PARAM_RESTO_UUID } from '@libs/dash/shared';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { set } from 'lodash-es';
import { map, withLatestFrom } from 'rxjs/operators';
import { getPreferredLanguage, getRestoUuidFromMap } from '../../core/utils/functions';
import { FeatureMapService } from '../../guards/feature-map/feature-map.service';
import { refreshSessionFromCookie } from '../auth/actions';
import { isAuthenticated } from '../auth/selectors';
import { RootStoreState } from '../state';

@Injectable()
export class RouterEffects {
	interceptNavigationChange = createEffect(
		() =>
			this._actions.pipe(
				ofType(ROUTER_NAVIGATION),
				withLatestFrom(this._store.pipe(select(isAuthenticated))),
				map(([action, authenticated]) => {
					this._translocoService.setActiveLang(getPreferredLanguage());
					if (action.payload.routerState['queryParams']['utm_source'] === 'b_manager') {
						this._featureService.featureMap['sidebar'] ??= {}; // init if null
						this._featureService.featureMap['sidebar'].sales = false;
						this._featureService.featureMap['sidebar'].history = false;
						this._featureService.featureMap['sidebar'].globalOverview = true;
						this._featureService.featureMap['sidebar'].dtt = false;
						this._featureService.featureMap['globalOverview'] ??= {};
						this._featureService.featureMap['globalOverview']['todays'] ??= {};
						this._featureService.featureMap['globalOverview']['todays'].revenue = false;
						this._featureService.featureMap['globalOverview']['todays'].waitingTime = false;
						this._featureService.featureMap['globalOverview']['todays'].ratioChannel = false;
						this._featureService.featureMap['globalOverview']['todays'].channelPerformance = false;
						this._featureService.featureMap['globalOverview']['todays'].topTenProducts = false;
						this._featureService.featureMap['globalOverview']['todays'].drive = true;
						this._featureService.featureMap['globalOverview']['todays'].kiosk = true;
					}
					if (action.payload.routerState['queryParams']['features']) {
						const params = action.payload.routerState['queryParams']['features'].split(',');
						params.forEach((featureFlagPath) => {
							set(this._featureService.featureMap, featureFlagPath, true);
						});
						console.log(this._featureService.featureMap);
					}
					// TODO
					// WORKAROUND TO REFRESH SESSION SINCE THE BE DOES NOT PROVIDE REFRESH TOKEN
					const accessToken = sessionStorage.getItem('dki_session_id');

					// save resto uuid if we have it
					const restoUuid = getRestoUuidFromMap(action.payload.routerState['queryParams']);
					if (restoUuid) {
						sessionStorage.setItem(QUERY_PARAM_RESTO_UUID, restoUuid);
					}

					const url = action.payload.routerState.url;
					// TODO
					if (!url.includes('account/login') && !url.includes('account/refresh') && !authenticated && !!accessToken) {
						this._router.navigateByUrl('/account/refresh');
						this._store.dispatch(refreshSessionFromCookie({ accessToken, url }));
					}
				})
			),
		{ dispatch: false }
	);

	constructor(
		private _router: Router,
		private _actions: Actions<RouterNavigationAction>,
		private _store: Store<RootStoreState>,
		private _translocoService: TranslocoService,
		private _featureService: FeatureMapService
	) {}
}
