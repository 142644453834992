/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type WeekDay = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export const WeekDay = {
	Monday: 'monday' as WeekDay,
	Tuesday: 'tuesday' as WeekDay,
	Wednesday: 'wednesday' as WeekDay,
	Thursday: 'thursday' as WeekDay,
	Friday: 'friday' as WeekDay,
	Saturday: 'saturday' as WeekDay,
	Sunday: 'sunday' as WeekDay
};

