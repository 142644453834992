/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';
import { Format } from '../model/models';

import { BASE_PATH } from '../variables';


@Injectable()
export class ExportApiClient {

	protected basePath = 'http://localhost';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	public encoder: HttpParameterCodec;

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (configuration) {
			this.configuration = configuration;
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (typeof basePath !== 'string') {
				basePath = this.basePath;
			}
			this.configuration.basePath = basePath;
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
	}


	private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
		if (typeof value === "object" && value instanceof Date === false) {
			httpParams = this.addToHttpParamsRecursive(httpParams, value);
		} else {
			httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
		}
		return httpParams;
	}

	private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
		if (value == null) {
			return httpParams;
		}

		if (typeof value === "object") {
			if (Array.isArray(value)) {
				(value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
			} else if (value instanceof Date) {
				if (key != null) {
					httpParams = httpParams.append(key,
						(value as Date).toISOString().substr(0, 10));
				} else {
					throw Error("key may not be null if value is Date");
				}
			} else {
				Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
					httpParams, value[k], key != null ? `${key}.${k}` : k));
			}
		} else if (key != null) {
			httpParams = httpParams.append(key, value);
		} else {
			throw Error("key may not be null if value is not object or array");
		}
		return httpParams;
	}

	/**
	 * Quick Data Export Payments
	 * Get payments data as XML.
	 * @param date Date (YYYY-MM-DD)
	 * @param format Output format (only &#x60;xml&#x60; supported at the moment
	 * @param restaurantNumber Numeric restaurant id (&#x60;bknb&#x60;)
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public quickDataExportPaymentsV1ExportQuickDataPaymentsGet(date: string, format: Format, restaurantNumber: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/xml' | 'application/json' }): Observable<string>;
	public quickDataExportPaymentsV1ExportQuickDataPaymentsGet(date: string, format: Format, restaurantNumber: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/xml' | 'application/json' }): Observable<HttpResponse<string>>;
	public quickDataExportPaymentsV1ExportQuickDataPaymentsGet(date: string, format: Format, restaurantNumber: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/xml' | 'application/json' }): Observable<HttpEvent<string>>;
	public quickDataExportPaymentsV1ExportQuickDataPaymentsGet(date: string, format: Format, restaurantNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'text/xml' | 'application/json' }): Observable<any> {
		if (date === null || date === undefined) {
			throw new Error('Required parameter date was null or undefined when calling quickDataExportPaymentsV1ExportQuickDataPaymentsGet.');
		}
		if (format === null || format === undefined) {
			throw new Error('Required parameter format was null or undefined when calling quickDataExportPaymentsV1ExportQuickDataPaymentsGet.');
		}
		if (restaurantNumber === null || restaurantNumber === undefined) {
			throw new Error('Required parameter restaurantNumber was null or undefined when calling quickDataExportPaymentsV1ExportQuickDataPaymentsGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (date !== undefined && date !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>date, 'date');
		}
		if (format !== undefined && format !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>format, 'format');
		}
		if (restaurantNumber !== undefined && restaurantNumber !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>restaurantNumber, 'restaurant_number');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/xml',
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<string>(`${this.configuration.basePath}/v1/export/quick-data/payments`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Quick Data Export Sales
	 * Get sales data as XML.
	 * @param date Date (YYYY-MM-DD)
	 * @param format Output format (only &#x60;xml&#x60; supported at the moment
	 * @param restaurantNumber Numeric restaurant id (&#x60;bknb&#x60;)
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public quickDataExportSalesV1ExportQuickDataSalesGet(date: string, format: Format, restaurantNumber: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/xml' | 'application/json' }): Observable<string>;
	public quickDataExportSalesV1ExportQuickDataSalesGet(date: string, format: Format, restaurantNumber: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/xml' | 'application/json' }): Observable<HttpResponse<string>>;
	public quickDataExportSalesV1ExportQuickDataSalesGet(date: string, format: Format, restaurantNumber: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/xml' | 'application/json' }): Observable<HttpEvent<string>>;
	public quickDataExportSalesV1ExportQuickDataSalesGet(date: string, format: Format, restaurantNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'text/xml' | 'application/json' }): Observable<any> {
		if (date === null || date === undefined) {
			throw new Error('Required parameter date was null or undefined when calling quickDataExportSalesV1ExportQuickDataSalesGet.');
		}
		if (format === null || format === undefined) {
			throw new Error('Required parameter format was null or undefined when calling quickDataExportSalesV1ExportQuickDataSalesGet.');
		}
		if (restaurantNumber === null || restaurantNumber === undefined) {
			throw new Error('Required parameter restaurantNumber was null or undefined when calling quickDataExportSalesV1ExportQuickDataSalesGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (date !== undefined && date !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>date, 'date');
		}
		if (format !== undefined && format !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>format, 'format');
		}
		if (restaurantNumber !== undefined && restaurantNumber !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>restaurantNumber, 'restaurant_number');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/xml',
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<string>(`${this.configuration.basePath}/v1/export/quick-data/sales`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Sales
	 * Get sales data as XML.
	 * @param date Date (YYYY-MM-DD)
	 * @param format Output format (only &#x60;xml&#x60; supported at the moment
	 * @param restaurantNumber Numeric restaurant id (&#x60;bknb&#x60;)
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public salesV1ExportSalesGet(date: string, format: Format, restaurantNumber: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/xml' | 'application/json' }): Observable<string>;
	public salesV1ExportSalesGet(date: string, format: Format, restaurantNumber: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/xml' | 'application/json' }): Observable<HttpResponse<string>>;
	public salesV1ExportSalesGet(date: string, format: Format, restaurantNumber: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'text/xml' | 'application/json' }): Observable<HttpEvent<string>>;
	public salesV1ExportSalesGet(date: string, format: Format, restaurantNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'text/xml' | 'application/json' }): Observable<any> {
		if (date === null || date === undefined) {
			throw new Error('Required parameter date was null or undefined when calling salesV1ExportSalesGet.');
		}
		if (format === null || format === undefined) {
			throw new Error('Required parameter format was null or undefined when calling salesV1ExportSalesGet.');
		}
		if (restaurantNumber === null || restaurantNumber === undefined) {
			throw new Error('Required parameter restaurantNumber was null or undefined when calling salesV1ExportSalesGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (date !== undefined && date !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>date, 'date');
		}
		if (format !== undefined && format !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>format, 'format');
		}
		if (restaurantNumber !== undefined && restaurantNumber !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>restaurantNumber, 'restaurant_number');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'text/xml',
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<string>(`${this.configuration.basePath}/v1/export/sales`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
