import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export interface ReportOptions {
	setupHeaders: (sheet: ExcelJS.Worksheet) => ExcelJS.Row;
	prepareDataRows: (sheet: ExcelJS.Worksheet) => void;
	generateFileName: () => string;
}

export function setupWorkbookAndSheet(
	title: string,
	details: string,
	titleMergeRange: string,
	detailsMergeRange: string
): {
	workbook: ExcelJS.Workbook;
	sheet: ExcelJS.Worksheet;
} {
	const workbook = new ExcelJS.Workbook();
	workbook.created = new Date();

	const sheet = workbook.addWorksheet(title);

	sheet.mergeCells(titleMergeRange);
	const titleCell = sheet.getCell(titleMergeRange.split(':')[0]);
	titleCell.value = title;
	titleCell.alignment = { horizontal: 'left', wrapText: true };
	titleCell.font = { size: 24, bold: true };
	titleCell.border = {
		top: { style: 'thin', color: { argb: '000000' } },
		left: { style: 'thin', color: { argb: '000000' } },
		right: { style: 'thin', color: { argb: '000000' } },
	};

	sheet.mergeCells(detailsMergeRange);
	const detailsCell = sheet.getCell(detailsMergeRange.split(':')[0]);
	detailsCell.value = details;
	detailsCell.alignment = { horizontal: 'left', wrapText: true };
	detailsCell.font = { size: 12, bold: false };
	detailsCell.border = {
		left: { style: 'thin', color: { argb: '000000' } },
		right: { style: 'thin', color: { argb: '000000' } },
		bottom: { style: 'thin', color: { argb: '000000' } },
	};

	sheet.getRow(parseInt(detailsMergeRange.split(':')[0].substring(1))).height = 60;
	sheet.addRow([]);

	return { workbook, sheet };
}

export function generateXlsx(reportTitle: string, detailsText: string, reportOptions: ReportOptions): void {
	const { setupHeaders, prepareDataRows, generateFileName } = reportOptions;

	const { workbook, sheet } = setupWorkbookAndSheet(reportTitle, detailsText, 'A1:G1', 'A2:G2');

	setupHeaders(sheet);
	prepareDataRows(sheet);

	workbook.xlsx.writeBuffer().then((data) => {
		const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		saveAs(blob, generateFileName());
	});
}
