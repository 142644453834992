import * as gapApi from '@gap/api-client';

export const authKey = 'Auth';

export interface CurrentUser {
	id: number;
	legalEntityType: string;
	roles: string[];
	authorities: string[];
	name: {
		full: string;
		first: string;
		middle: string;
		last: string;
		title: {
			prefix: string;
			sufix: string;
		};
		initials: string;
	};
	contactDetails: {
		// to do: change to arrays with enumerated types
		email: {
			personal: string;
			administrative: string;
		};
		phoneNumber: {
			personal: string;
			administrative: string;
		};
		address: {
			residence: {
				streetName: string;
				houseNumber: string;
				city: {
					name: string;
					postalCode: string;
				};
				country: {
					name: string;
					code: string;
				};
			};
			correspondence: {
				sameAsResidence: boolean;
				streetName: string;
				houseNumber: string;
				city: {
					name: string;
					postalCode: string;
				};
				country: {
					name: string;
					code: string;
				};
			};
			billing: {
				sameAsResidence: boolean;
				streetName: string;
				houseNumber: string;
				city: {
					name: string;
					postalCode: string;
				};
				country: {
					name: string;
					code: string;
				};
			};
			administrative: {
				sameAsResidence: boolean;
				streetName: string;
				houseNumber: string;
				city: {
					name: string;
					postalCode: string;
				};
				country: {
					name: string;
					code: string;
				};
			};
		};
	};
	preferredLanguage: string;
	has_all_restaurants: boolean;
	permissions: Array<gapApi.PermissionInfoReadWrite | gapApi.PermissionInfoLegacyAccess | gapApi.PermissionInfoApplyDefine>;
	is_admin: boolean;
}

export interface AuthState {
	currentUser?: CurrentUser;
	accessToken?: string;
	expiration?: string;
	session?: {
		previous: {
			duration: string;
			device: {
				os: string;
				browser: {
					name: string;
					language: string;
				};
				screenResolution: string;
			};
			ip: string;
			geolocation: {
				ltd: number;
				lng: number;
				city: {
					name: string;
					postalCode: string;
				};
				country: {
					name: string;
					code: string;
				};
			};
			login: {
				timestamp: string;
				entrypoint: string;
			};
			logout: {
				timestamp: string;
				exitpoint: string;
			};
		};
		current: {
			device: {
				os: string;
				browser: {
					name: string;
					language: string;
				};
				screenResolution: string;
			};
			ip: string;
			geolocation: {
				ltd: number;
				lng: number;
				city: {
					name: string;
					postalCode: string;
				};
				country: {
					name: string;
					code: string;
				};
			};
			login: {
				timestamp: string;
				entrypoint: string;
			};
		};
	};
	isLoading: boolean;
}
