import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { KioskSosTileComponent } from './component/kiosk-sos-tile.component';

@NgModule({
	declarations: [KioskSosTileComponent],
	imports: [CommonModule, MatProgressSpinnerModule],
	exports: [KioskSosTileComponent],
})
export class KioskSosTileModule {}
