import { NgModule, Optional, Self } from '@angular/core';
import { Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { RouterEffects } from './effects';
import { MergedRouterStateSerializer } from './serializer';

export const routerStateConfig = {
	stateKey: 'router',
};

@NgModule({
	imports: [
		StoreModule.forFeature(routerStateConfig.stateKey, routerReducer),
		StoreRouterConnectingModule.forRoot(routerStateConfig),
		EffectsModule.forFeature([RouterEffects]),
	],
	exports: [StoreModule, StoreRouterConnectingModule],
	providers: [
		{
			provide: RouterStateSerializer,
			useClass: MergedRouterStateSerializer,
		},
	],
})
export class NgrxRouterStoreModule {
	constructor(@Self() @Optional() router: Router) {
		if (router) {
			// console.log('All good, NgrxRouterStoreModule');
		} else {
			console.error('NgrxRouterStoreModule must be imported in the same same level as RouterModule');
		}
	}
}
