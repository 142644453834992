/* eslint-disable @typescript-eslint/no-explicit-any */

import { stringAsBoolean } from '../utils/boolean-utils';
import { isNil } from '../utils/object-utils';
import { trimChar } from '../utils/string-utils';

export class ConfigurationService {
	/*
	 * These are properties are always present in the merged dynamic config-env.js file.
	 */

	version: string;

	build: {
		number: string;
		date: Date;
	};

	features: { [key: string]: any };

	apis: { [key: string]: any };

	constructor() {
		this.version = '';
		this.build = {
			date: new Date(),
			number: ''
		};
		this.features = {};
		this.apis = {};
	}

	hasFeature(name: string, defaultValue?: boolean): boolean {
		return this.features && !isNil(this.features[name]) ? this.features[name] === true : defaultValue || false;
	}

	getValue(name: string, defaultValue?: string | { [key: string]: any }): string | { [key: string]: any } {
		const value: any = (<any>this)[name];
		return !isNil(value) ? value : defaultValue;
	}

	getBooleanValue(propertyName: string, defaultValue: boolean = false): boolean {
		const stringValue = this.getValue(propertyName, defaultValue.toString()) as string;
		const boolValue = stringAsBoolean(stringValue);
		return boolValue;
	}

	getApiUrl(api: string): string | undefined {
		const url: string = this.apis && !isNil(this.apis[api]) ? this.apis[api].url : undefined;

		if (url) {
			return url.length > 1 ? trimChar(url, '/') : url;
		}

		console.warn(`Url for "${api}" API not set.`);

		return undefined;
	}

	getApiPort(api: string): string | undefined {
		const port: string = this.apis && !isNil(this.apis[api]) ? this.apis[api].port : undefined;

		if (port) {
			return port.length > 1 ? port.trim() : port;
		}

		console.warn(`Port for "${api}" API not set.`);

		return undefined;
	}

	// TODO: DAVID DEV-2280 - Deprecated. Should not be needed anymore
	getApiNamespace(api: string): string | undefined {
		console.warn('Using deprecated configuration getApiNamespace() for ', api);

		const apiNamespace: string = this.apis && !isNil(this.apis[api]) ? this.apis[api].apiNamespace : undefined;

		if (apiNamespace) {
			return apiNamespace.length > 1 ? apiNamespace.trim() : apiNamespace;
		}

		console.warn(`Api Namespace for "${api}" API not set.`);

		return undefined;
	}

	getApiKey(api: string): string | undefined {
		const key: string = this.apis && !isNil(this.apis[api]) ? this.apis[api].key : undefined;

		if (key) {
			return key.length > 1 ? key.trim() : key;
		}

		console.warn(`Api Key for "${api}" API not set.`);

		return undefined;
	}
}
