import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { ErrorDialogComponent } from '../component/error-dialog.component';
import { ErrorDialogProvider } from './error-dialog.provider';

@Injectable()
export class ErrorDialogService implements ErrorDialogProvider {
	constructor(private dialog: MatDialog) {}

	popUp(title: string, message: string): Observable<boolean> {
		const dialogRef = this.dialog.open(ErrorDialogComponent, {
			panelClass: 'dk-error-dialog-container',
			disableClose: true,
		});

		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.message = message;

		return dialogRef.afterClosed();
	}
}
