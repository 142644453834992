export enum Period {
	Morning = 'morning',
	Lunch = 'lunch',
	Afternoon = 'afternoon',
	Dinner = 'dinner',
	Evening = 'evening',
	Night = 'night',
	PeakNight = 'peak_night',
}

export enum TimeStep {
	PreparationTime = 'preparation_time',
	DepartTime = 'depart_time',
	ServeTime = 'serve_time',
	Total = 'total',
}

export enum ValueType {
	Preparation = 'preparation',
	Depart = 'depart',
	Total = 'total',
}

export enum SalesChannel {
	Kiosk = 'KIOSK',
	Web = 'WEB',
	Pos = 'POS',
}
