export enum ProductPriceType {
	/**
	 * The regular price of the product.
	 * The "Solo" value is also used also for Ingredients
	 * (Ingredients have only the "Solo" value)
	 */
	Solo = 'Solo',
	/**
	 * Upgraded product in a menu. For example "fries + bacon" instead of regular "fries"
	 */
	SupplementMenu = 'SupplementMenu',
	/**
	 * A discount price to motive customer to add something else on top of the order
	 */
	InSuggestion = 'InSuggestion',
}
