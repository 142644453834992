import { createFeatureSelector, createSelector } from '@ngrx/store';

import { authKey, AuthState, CurrentUser } from './state';

export const getAuthReducerState = createFeatureSelector<AuthState>(authKey);

export const getIsLoading = createSelector(getAuthReducerState, (state: AuthState) => (state ? state.isLoading : true));

export const getAccessToken = createSelector(getAuthReducerState, (state: AuthState) => (state.accessToken ? state.accessToken : null));

export const getCurrentUser = createSelector(getIsLoading, getAuthReducerState, (isLoading: boolean, state: AuthState) =>
	!isLoading ? state.currentUser : null
);

export const getCurrentUserId = createSelector(getCurrentUser, (currentUser: CurrentUser) =>
	currentUser && currentUser.id ? currentUser.id : null
);

export const isAuthenticated = createSelector(getCurrentUserId, (currentUserId: number) => !!currentUserId);

export const hasAllRestaurants = createSelector(getCurrentUser, (currentUser: CurrentUser) =>
	currentUser && currentUser.has_all_restaurants ? currentUser.has_all_restaurants : null
);
