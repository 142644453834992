/**
 * Some Options are available only for some SalesChannels
 */
export enum SalesChannelOption {
	TakeAway = 'TakeAway',
	EatIn = 'EatIn',
	/**
	 * Order contains items which should be delivered later.
	 * Example: Hot coffee after lunch.
	 *
	 * Depends on EatIn.
	 */
	WaitingAtTable = 'WaitingAtTable',
	/**
	 * Waiter will bring food to a table inside a Restaurant.
	 *
	 * Depends on EatIn.
	 */
	TableServiceInside = 'TableServiceInside',
	/**
	 * Waiter will bring food to a table on terasse outside of Restaurant.
	 *
	 * Depends on EatIn.
	 */
	TableServiceTerrasse = 'TableServiceTerrasse',
	DriveIn = 'DriveIn',
	// Used mostly in click-and-collect. Special case of TakeAway.
	PickupParking = 'PickupParking',

	////////////////////////
	// Suboptions only for SalesChannel.THIRD_PARTY_DELIVERY
	// See also ThirdPartySalesChannelOptions
	////////////////////////
	DeliveryBK = 'DeliveryBK',
	Deliveroo = 'Deliveroo',
	UberEats = 'UberEats',
	JustEat = 'JustEat',
	ZOT = 'ZOT',
	Lyveat = 'Lyveat',
	Please = 'Please',
	DeliverectClickAndCollect = 'DeliverectClickAndCollect',
}

/**
 * Currently used only for the purpose of new PriceManagement, where DriveIn etc are not used, because they are kinds of either EatIn or TakeAway
 */
export const RestaurantSalesChannelOptions = [SalesChannelOption.TakeAway, SalesChannelOption.EatIn];

// If we add new Third Party delivery, then this needs to be updated too
export const ThirdPartySalesChannelOptions = [
	SalesChannelOption.DeliveryBK,
	SalesChannelOption.Deliveroo,
	SalesChannelOption.UberEats,
	SalesChannelOption.JustEat,
	SalesChannelOption.ZOT,
	SalesChannelOption.Lyveat,
	SalesChannelOption.Please,
];
