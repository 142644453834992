import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { hasAccessPermission, hasReportingPermission } from '@libs/dash/core';

import { Error } from '@libs/dash/core/entity';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getCurrentUser } from '../../store/auth/selectors';
import { CurrentUser } from '../../store/auth/state';
import { State } from '../../store/router/state';

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate {
	constructor(
		private _store: Store<State>,
		private _router: Router
	) {}

	canActivate(): Observable<boolean> {
		return this._store.pipe(select(getCurrentUser)).pipe(
			map((user) => {
				if (this.isAdmin(user)) {
					return true;
				} else if (!hasReportingPermission(user.permissions)) {
					this._router.navigate(['/account/authentication-failed', { error: Error.forbidden }]);
				} else if (!hasAccessPermission(user.permissions)) {
					this._router.navigate(['/noaccess']);
				}
				return true;
			})
		);
	}

	isAdmin(user: CurrentUser) {
		return user.is_admin;
	}
}
