import { Action, createReducer, on } from '@ngrx/store';

import { AuthStoreActions } from '../auth';
import * as Actions from './actions';
import { REPORTS_INITIAL_STATE, ReportsState } from './state';

export const reportsReducer = createReducer<ReportsState, Action>(
	REPORTS_INITIAL_STATE,
	on(Actions.getHourlySales, (state, action) => ({
		...state,
		status: { ...state.status, hourlySales: { dateFrom: action.from, dateTo: action.to, isLoading: true, error: undefined } },
	})),
	on(Actions.getOperatingReport, (state, action) => ({
		...state,
		status: { ...state.status, operatingReport: { dateFrom: action.from, dateTo: action.to, isLoading: true, error: undefined } },
	})),
	on(Actions.getWeeklyReport, (state, action) => ({
		...state,
		status: { ...state.status, weeklyReport: { weekNumber: action.weekNumber, isLoading: true, error: undefined } },
	})),
	on(Actions.getProductsReport, (state, action) => ({
		...state,
		status: { ...state.status, productsReport: { dateFrom: action.from, dateTo: action.to, isLoading: true, error: undefined } },
	})),

	on(Actions.getEmployeeMealsReport, (state, action) => ({
		...state,
		status: { ...state.status, employeeMealsReport: { dateFrom: action.from, dateTo: action.to, isLoading: true, error: undefined } },
	})),

	on(Actions.getDiscountsReport, (state, action) => ({
		...state,
		status: { ...state.status, discountsReport: { dateFrom: action.from, dateTo: action.to, isLoading: true, error: undefined } },
	})),

	on(Actions.getDTTReport, (state, action) => ({
		...state,
		status: { ...state.status, dttReport: { dateFrom: action.from, dateTo: action.to, isLoading: true, error: undefined } },
	})),

	on(Actions.getFinancialMovements, (state, action) => ({
		...state,
		status: { ...state.status, financialMovements: { dateFrom: action.from, dateTo: action.to, isLoading: true, error: undefined } },
	})),

	on(Actions.getVouchersReport, (state, action) => ({
		...state,
		status: { ...state.status, vouchersReport: { dateFrom: action.from, dateTo: action.to, isLoading: true, error: undefined } },
	})),

	on(Actions.getHourlySalesFail, (state, action) => ({
		...state,
		status: { ...state.status, hourlySales: { ...state.status.hourlySales, isLoading: false, error: action.error } },
	})),
	on(Actions.getOperatingReportFail, (state, action) => ({
		...state,
		status: { ...state.status, operatingReport: { ...state.status.operatingReport, isLoading: false, error: action.error } },
	})),
	on(Actions.getWeeklyReportFail, (state, action) => ({
		...state,
		status: { ...state.status, weeklyReport: { ...state.status.weeklyReport, isLoading: false, error: action.error } },
	})),
	on(Actions.getProductsReportFail, (state, action) => ({
		...state,
		status: { ...state.status, productsReport: { ...state.status.productsReport, isLoading: false, error: action.error } },
	})),

	on(Actions.getEmployeeMealsReportFail, (state, action) => ({
		...state,
		status: { ...state.status, employeeMealsReport: { ...state.status.productsReport, isLoading: false, error: action.error } },
	})),

	on(Actions.getDiscountsReportFail, (state, action) => ({
		...state,
		status: { ...state.status, discountReport: { ...state.status.discountsReport, isLoading: false, error: action.error } },
	})),

	on(Actions.getDTTReportFail, (state, action) => ({
		...state,
		status: { ...state.status, dttReport: { ...state.status.dttReport, isLoading: false, error: action.error } },
	})),

	on(Actions.getFinancialMovementsFail, (state, action) => ({
		...state,
		status: { ...state.status, financialMovements: { ...state.status.financialMovements, isLoading: false, error: action.error } },
	})),

	on(Actions.getVouchersReportFail, (state, action) => ({
		...state,
		status: { ...state.status, vouchersReport: { ...state.status.vouchersReport, isLoading: false, error: action.error } },
	})),

	on(Actions.getHourlySalesSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			hourlySales: action.hourlySales,
		},
		status: { ...state.status, hourlySales: { ...state.status.hourlySales, isLoading: false, error: undefined } },
	})),
	on(Actions.getOperatingReportSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			operatingReport: action.operatingReport,
		},
		status: { ...state.status, operatingReport: { ...state.status.operatingReport, isLoading: false, error: undefined } },
	})),
	on(Actions.getWeeklyReportSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			weeklyReport: action.weeklyReport,
		},
		status: { ...state.status, weeklyReport: { ...state.status.weeklyReport, isLoading: false, error: undefined } },
	})),
	on(Actions.getProductsReportSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			productsReport: action.productsReport,
		},
		status: { ...state.status, productsReport: { ...state.status.productsReport, isLoading: false, error: undefined } },
	})),

	on(Actions.getEmployeeMealsReportSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			employeeMealsReport: action.employeeMealsReport,
		},
		status: { ...state.status, employeeMealsReport: { ...state.status.employeeMealsReport, isLoading: false, error: undefined } },
	})),

	on(Actions.getDiscountsReportSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			discountsReport: action.discountsReport,
		},
		status: { ...state.status, discountsReport: { ...state.status.discountsReport, isLoading: false, error: undefined } },
	})),

	on(Actions.getDTTReportSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			dttReport: action.dttReport,
		},
		status: { ...state.status, dttReport: { ...state.status.dttReport, isLoading: false, error: undefined } },
	})),

	on(Actions.getFinancialMovementsSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			financialMovements: action.movements,
		},
		status: { ...state.status, financialMovements: { ...state.status.financialMovements, isLoading: false, error: undefined } },
	})),

	on(Actions.getVouchersReportSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			vouchersReport: action.vouchersReport,
		},
		status: { ...state.status, vouchersReport: { ...state.status.vouchersReport, isLoading: false, error: undefined } },
	})),

	on(AuthStoreActions.logout, () => REPORTS_INITIAL_STATE)
);
