import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { PassedSosTileComponent } from './component/passed-sos-tile.component';

@NgModule({
	declarations: [PassedSosTileComponent],
	imports: [CommonModule, MatProgressSpinnerModule],
	exports: [PassedSosTileComponent],
})
export class PassedSosTileModule {}
