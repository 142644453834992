export enum Range {
	Today = 'today',
	Day = 'day',
	Week = 'week',
	Month = 'month',
	Period = 'period',
	Second = 'second',
	Next = 'next',
	Prev = 'prev',
	Date = 'date',
}

export enum DayOfWeek {
	Monday = 'monday',
	Tuesday = 'tuesday',
	Wednesday = 'wednesday',
	Thursday = 'thursday',
	Friday = 'friday',
	Saturday = 'saturday',
	Sunday = 'sunday',
}

export enum DatePeriod {
	Today = 'today',
	Week = 'week',
	Month = 'month',
	Year = 'year',
}
