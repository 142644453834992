@if (sidebar && !sourced) {
	<dk-page-aside-navigation></dk-page-aside-navigation>
}
<div class="dk-page-content-wrapper" [class.sourced]="sourced">
	@if (!sourced && !figma) {
		<dk-page-header
			[datePicker]="datePicker"
			[editButton]="editButton"
			[title]="title"
			(dateSelectionChange)="emitDashboardTimeRange($event)"
			(todaySelection)="emitTodaySelection($event)"
		>
			@if (enableRestaurantSelection) {
				<dk-restaurant-select [hidden]="sourced"></dk-restaurant-select>
			}
		</dk-page-header>
	}

	<ng-content></ng-content>
</div>

@if (isLoading) {
	<dk-progress-overlay></dk-progress-overlay>
}
