import * as api from '@dki/api-client';

export const STORE_SLICE_KEY = 'SynthesisReport';

export interface SynthesisReportState {
	generalSynthesisReport?: api.SynthesisReport;
	vatSynthesisReport?: api.Synthesis2Report;
	isLoading?: boolean;
}

export const SYNTHESIS_REPORT_INITIAL_STATE: SynthesisReportState = {};
