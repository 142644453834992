import { IBKIngredientData, IBKMenuBase, IBKProductBase } from "@bk/jscommondatas";
import { GlobalPriceDefinition, LocalPriceDefinition, LocalPriceDefinitionMap, SalesChannelOption } from "@bk/price-management-common";
import { LocalPriceUsage } from "../models";

export const updateAvailabilityItemPrices = (
	availabilityItems: LocalPriceDefinitionMap,
	bigDataItems: { [id: string]: IBKIngredientData | IBKProductBase | IBKMenuBase },
	localPriceUsage: LocalPriceUsage = {
		inRestaurant: true,
		delivery: true,
	}
) => {
	if (!availabilityItems || !bigDataItems) {
		return;
	}

	Object.keys(availabilityItems).forEach((availabilityItem) => {
		if (bigDataItems[availabilityItem]) {
			availabilityItems[availabilityItem] = updatePriceBasedOnRestaurantSettigns(
				bigDataItems[availabilityItem].globalPriceDefinitions as GlobalPriceDefinition[],
				availabilityItems[availabilityItem],
				localPriceUsage
			);
		}
	});
};

export const updatePriceBasedOnRestaurantSettigns = (
	globalPriceDefinitions: GlobalPriceDefinition[],
	localPriceDefinitions: LocalPriceDefinition,
	localPriceUsage: LocalPriceUsage = { inRestaurant: true, delivery: true }
): LocalPriceDefinition => {
	const inRestaurantColumns = [SalesChannelOption.EatIn, SalesChannelOption.TakeAway];
	if (localPriceUsage.inRestaurant && localPriceUsage.delivery) {
		return localPriceDefinitions;
	}

	if(!globalPriceDefinitions) {
		return localPriceDefinitions;
	}

	if (!localPriceUsage.inRestaurant) {
		localPriceDefinitions.values = localPriceDefinitions.values.map((item) => {
			if (inRestaurantColumns.includes(item.salesChannelOption)) {
				return {
					...item,
					localPrice: globalPriceDefinitions.find((globalPrice) => globalPrice.salesChannelOption === item.salesChannelOption && globalPrice.rowIdentifier === item.rowIdentifier)?.price,
				};
			} else {
				return item;
			}
		});
	}

	if (!localPriceUsage.delivery) {
		localPriceDefinitions.values = localPriceDefinitions.values.map((item) => {
			if (!inRestaurantColumns.includes(item.salesChannelOption)) {
				return {
					...item,
					localPrice: globalPriceDefinitions.find((globalPrice) => globalPrice.salesChannelOption === item.salesChannelOption && globalPrice.rowIdentifier === item.rowIdentifier)?.price,
				};
			} else {
				return item;
			}
		});
	}

	return localPriceDefinitions;
};
