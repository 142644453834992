import { Delivery, Drive, Eatin, Kiosk, Pos } from '@libs/dash/core/entity';

import { getLocalZone } from '@libs/dash/shared';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';
import { sortChannels } from '../../core/entity/enums/exported/channels.enum';
import { MyRestaurantsSelectors } from '../my-restaurants';
import { getSelectedDataSourceNameQueryParam, getSelectedItemNameQueryParam } from '../router/selectors';
import { STORE_SLICE_KEY, TodaysStatisticsState } from './state';

export const reducerState = createFeatureSelector<TodaysStatisticsState>(STORE_SLICE_KEY);

export const isLoading = createSelector(reducerState, (state: TodaysStatisticsState) => (state ? state.status.isLoading : true));

// TO DO: CONVERT ALL IN 'data' TO MEMOIZED ARRAY
export const ordersByTime = createSelector(reducerState, (state: TodaysStatisticsState) =>
	state && state.data && state.data.ordersByTime ? state.data.ordersByTime : null
);

export const ordersByChannel = createSelector(reducerState, (state: TodaysStatisticsState) =>
	state && state.data && state.data.ordersByChannel ? state.data.ordersByChannel : null
);

export const ordersByProduct = createSelector(reducerState, (state: TodaysStatisticsState) =>
	state && state.data && state.data.ordersByProduct ? state.data.ordersByProduct : null
);

export const topTenProducts = createSelector(reducerState, (state: TodaysStatisticsState) =>
	state && state.data && state.data.ordersByProduct ? state.data.ordersByProduct.slice(0, 10) : null
);

export const ordersByChannelWithAverages = createSelector(reducerState, (state: TodaysStatisticsState) =>
	state && state.data && state.data.ordersByChannelWithAverages ? state.data.ordersByChannelWithAverages : null
);

export const averageOrdersAtCurrentTime = createSelector(reducerState, (state: TodaysStatisticsState) =>
	state && state.data && state.data.averageOrdersAtCurrentTime ? state.data.averageOrdersAtCurrentTime : null
);

export const sos = createSelector(reducerState, (state: TodaysStatisticsState) => {
	if (state && state.data && state.data.sos) {
		const sosTableRows = state.data.sos.map((item) => {
			let total =
				item.mean_sos_waittime +
				item.mean_sos_ordertime +
				// item.mean_sos_linetime +
				item.mean_sos_paytime +
				item.mean_sos_parktime +
				// item.mean_sos_preparationtime +
				item.mean_sos_servetime;
			total = total.toFixed(1);

			return {
				label: item.daypart,
				values: [
					item.mean_sos_waittime,
					item.mean_sos_ordertime,
					item.mean_sos_linetime,
					item.mean_sos_paytime,
					// item.mean_sos_parktime,
					// item.mean_sos_preparationtime,
					item.mean_sos_servetime,
					total,
				],
			};
		});

		return sosTableRows;
	} else {
		return null;
	}
});

export const kioskSos = createSelector(reducerState, (state: TodaysStatisticsState) => {
	if (state && state.data && state.data.kioskSos) {
		const sosTableRows = state.data.kioskSos.map((item) => {
			let total =
				item.mean_sos_waittime +
				item.mean_sos_ordertime +
				item.mean_sos_linetime +
				item.mean_sos_paytime +
				item.mean_sos_parktime +
				item.mean_sos_preparationtime +
				item.mean_sos_hardservetime +
				item.mean_sos_servetime;
			total = total.toFixed(1);

			return {
				label: item.daypart,
				values: [item.mean_sos_ordertime, item.mean_sos_paytime, item.mean_sos_preparationtime, item.mean_sos_servetime, total],
			};
		});

		return sosTableRows;
	} else {
		return null;
	}
});

export const sosKIOSK = createSelector(reducerState, (state: TodaysStatisticsState) => state.data?.aggregatedKioskSos || []);

export const sosKIOSKByRestaurant = createSelector(
	reducerState,
	(state: TodaysStatisticsState) => state.data?.aggregatedKioskSosByRestaurant || {}
);

export const selectAllDatasources = createSelector(
	ordersByTime,
	ordersByChannel,
	ordersByProduct,
	topTenProducts,
	ordersByChannelWithAverages,
	averageOrdersAtCurrentTime,
	sos,
	(ordersByTime, ordersByChannel, ordersByProduct, topTenProducts, ordersByChannelWithAverages, averageOrdersAtCurrentTime, sos) => {
		return { ordersByTime, ordersByChannel, ordersByProduct, topTenProducts, ordersByChannelWithAverages, averageOrdersAtCurrentTime, sos };
	}
);

export const selectItemFromDatasource = createSelector(
	getSelectedDataSourceNameQueryParam,
	getSelectedItemNameQueryParam,
	selectAllDatasources,
	(dataSourceName, itemName, allDatasources) => {
		let dataSource = [];

		switch (dataSourceName) {
			case 'ordersByTime':
				dataSource = allDatasources.ordersByTime;
				break;

			case 'ordersByProduct':
				dataSource = allDatasources.ordersByProduct;
				break;

			case 'topTenProducts':
				dataSource = allDatasources.topTenProducts;
				break;

			case 'ordersByChannel':
				dataSource = allDatasources.ordersByChannel;
				break;

			case 'ordersByChannelWithAverages':
				dataSource = allDatasources.ordersByChannelWithAverages;
				break;

			case 'averageOrdersAtCurrentTime':
				dataSource = allDatasources.averageOrdersAtCurrentTime;
				break;

			case 'sos':
				dataSource = allDatasources.sos;
				break;
		}

		const itemData = dataSource.find((order) => {
			if (order.channel) {
				return order.channel === itemName;
			} else {
				return order.product === itemName;
			}
		});

		return itemData;
	}
);

export const channelPermoranceInPercentage = createSelector(ordersByChannel, (ordersByChannel) => {
	if (ordersByChannel && ordersByChannel.length > 0) {
		const totalCountReducer = (array) => array.reduce((a, b) => a + b);
		const partialCounts = ordersByChannel.map((item) => item.count);
		const totalCount = totalCountReducer(partialCounts);
		const onePercentage = totalCount / 100;
		const pos = {
			label: 'pos',
			percentage: 0,
		};
		const kiosk = {
			label: 'kiosk',
			percentage: 0,
		};
		const delivery = {
			label: 'delivery',
			percentage: 0,
		};
		const drive = {
			label: 'drive',
			percentage: 0,
		};
		const eatIn = {
			label: 'eat_in',
			percentage: 0,
		};
		const takeOut = {
			label: 'take_out',
			percentage: 0,
		};
		ordersByChannel.forEach((item) => {
			const itemPercentage = item.count / onePercentage;
			if (Pos.has(item.channel)) {
				pos.percentage += itemPercentage;
			} else if (Drive.has(item.channel)) {
				drive.percentage += itemPercentage;
			} else if (Kiosk.has(item.channel)) {
				kiosk.percentage += itemPercentage;
			} else if (Delivery.has(item.channel)) {
				delivery.percentage += itemPercentage;
			}
			if (Eatin.has(item.channel)) {
				eatIn.percentage += itemPercentage;
			} else {
				takeOut.percentage += itemPercentage;
			}
		});
		return [pos, kiosk, delivery, drive, eatIn, takeOut];
	} else {
		return [];
	}
});

export const revenueProgress = createSelector(
	ordersByTime,
	MyRestaurantsSelectors.longTermOrders,
	MyRestaurantsSelectors.todaysOpeningHours,
	(ordersByTime, previousDayAverageOrders, todaysOpeningHours) => {
		let revenueProgressData = {
			currentHourSeries: [],
			currentHourSeriesRange: ['10:00', '11:00'],
			todaySeries: [],
			currentSeries: [],
			averageSeries: [],
			activeHours: ['10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00'],
			horizontalSteps: [3, 2, 1, 0],
			todaysTotalRevenue: 'N/A',
			averageTotalRevenue: 'N/A',
		};
		if (todaysOpeningHours?.from?.hour != null && todaysOpeningHours?.to?.hour != null) {
			if (ordersByTime?.length || previousDayAverageOrders?.length) {
				const openingHour = todaysOpeningHours.from.hour;
				const closingHour = todaysOpeningHours.to.hour;
				const numberOfHoursOpened = closingHour - openingHour <= 0 ? 24 - openingHour + closingHour : closingHour - openingHour;

				if (numberOfHoursOpened) {
					const arrayFromNumberOfHoursOpened = Array.from(new Array(numberOfHoursOpened + 1).keys());

					if (arrayFromNumberOfHoursOpened?.length) {
						const activeHours = arrayFromNumberOfHoursOpened.map((hour) => {
							let parametisedHour = hour + openingHour;
							if (parametisedHour >= 24) {
								parametisedHour = parametisedHour - 24;
							}
							const consolidatedHour = parametisedHour < 10 ? `0${parametisedHour}` : parametisedHour;
							return `${consolidatedHour}:00`;
						});

						if (activeHours?.length) {
							let currentSeries = [];
							let averageSeries = [];
							const defaultOffset = parseInt(DateTime.now().toISO().split('+')[1]);
							let timeOffset = defaultOffset;
							if (ordersByTime && ordersByTime.length > 0) {
								currentSeries = activeHours.map((activeHour, index) => {
									return {
										label: activeHour,
										value: ordersByTime[index] ? ordersByTime[index].value.toFixed(2) : '0.00',
									};
								});
								timeOffset = ordersByTime[0]?.timestamp?.split('+')[1]
									? parseInt(ordersByTime[0]?.timestamp?.split('+')[1])
									: defaultOffset;
								currentSeries = ordersByTime
									.map((order) => {
										return {
											label: DateTime.fromISO(order.timestamp).setZone(`UTC+${timeOffset}`).toFormat('HH:mm'),
											value: order.value.toFixed(2),
										};
									})
									.filter((e) => activeHours.indexOf(e.label) > -1)
									.sort((o, p) => activeHours.indexOf(o.label) - activeHours.indexOf(p.label));
							}

							if (previousDayAverageOrders && previousDayAverageOrders.length > 0) {
								const valuesAccumulation = activeHours.map((activeHour) => {
									const hours = previousDayAverageOrders.filter((previousDayAverageOrder) =>
										DateTime.fromISO(previousDayAverageOrder.timestamp).setZone(`UTC+${timeOffset}`).toFormat('HH:mm').includes(activeHour)
									);
									const values = hours.map((hour) => hour.value);
									return values.reduce((a, b) => a + b) / values.length;
								});
								averageSeries = activeHours.map((activeHour, index) => {
									return {
										label: activeHour,
										value: valuesAccumulation[index].toFixed(2),
									};
								});
							}

							const todaysValues = currentSeries.map((item) => item.value);
							const averageValues = averageSeries.map((item) => item.value);
							const maxTodayValue = Math.max(...todaysValues);
							const maxAverageValue = Math.max(...averageValues);
							const maxValue = Math.round(Math.max(maxTodayValue, maxAverageValue) / 100) * 100;
							const horizontalSteps = [maxValue * 1.5, maxValue, maxValue / 2, 0];
							const reduceOrdersByTimeOrdersToStringValue = (series) => {
								let stringValue = 'N/A';

								const ordersByTimeReducer = (accumulator, currentItem) => accumulator + parseFloat(currentItem.value);

								if (series && series.length > 0) {
									const rawValue = series.reduce(ordersByTimeReducer, 0);
									stringValue = `${rawValue.toFixed(2)}`;
								}

								return stringValue;
							};
							// console.log(averageSeries)
							const todaysTotalRevenue = reduceOrdersByTimeOrdersToStringValue(currentSeries);
							const averageTotalRevenue = reduceOrdersByTimeOrdersToStringValue(averageSeries);
							const localDatime = getLocalZone();
							const maxHour = localDatime.hour + 1;
							const currentHourSeriesRange = [`${localDatime.hour}:00`, `${maxHour}:00`];
							const todaySeries = currentSeries.filter((item) => {
								const labelToHour = +item.label.split(':')[0];
								if (maxHour < 6) return labelToHour < 2 && labelToHour > 6;
								return labelToHour > 6 && labelToHour <= maxHour;
							});
							const currentHourSeries = currentSeries.filter((item) => currentHourSeriesRange.includes(item.label));
							revenueProgressData = {
								currentHourSeries,
								currentHourSeriesRange,
								todaySeries,
								currentSeries,
								averageSeries,
								activeHours,
								horizontalSteps,
								todaysTotalRevenue,
								averageTotalRevenue,
							};
						} else {
							console.warn('revenueProgress', { activeHours });
						}
					} else {
						console.warn('revenueProgress', { arrayFromNumberOfHoursOpened });
					}
				} else {
					console.warn('revenueProgress', { closingHour, openingHour });
				}
			} else {
				console.warn('revenueProgress', { ordersByTime, previousDayAverageOrders });
			}
		} else {
			console.warn('revenueProgress - restaurant closed - or no opening hours set', { todaysOpeningHours });
		}
		return revenueProgressData;
	}
);

export const defaultRestaurantLast15minutesOrdersTotalCount = createSelector(reducerState, (state: TodaysStatisticsState) =>
	state && state.data && state.data.last15minutesOrdersTotalCount ? state.data.last15minutesOrdersTotalCount : 0
);

export const channelLoadPerformance = createSelector(
	MyRestaurantsSelectors.defaultRestaurantTlines,
	defaultRestaurantLast15minutesOrdersTotalCount,
	(tresholds, value) => {
		if (tresholds && tresholds.length > 0 && (value !== null || value !== undefined)) {
			const maxTreshold = Math.max(...tresholds);
			const maxStep = value > maxTreshold ? value + 2 : maxTreshold + 2;
			const steps = Array.from(Array(maxStep).keys()).reverse();
			return { steps, tresholds, value };
		} else {
			return { steps: [0, 0], tresholds: [0, 0], value: 0 };
		}
	}
);

export const customerWaitingTimeTableData = createSelector(ordersByChannel, (ordersByChannel) => {
	let tableData = [];
	let averageWaitingTime = 'N/A';

	if (ordersByChannel?.length) {
		tableData = ordersByChannel.map((item) => {
			let meanServiceTime = item.mean_service_time.toFixed(2).replace('.00', '');

			if (meanServiceTime[meanServiceTime.length - 1] === '0') {
				meanServiceTime = meanServiceTime.slice(0, 1);
			}

			const tableRow = {
				label: item.channel.toLocaleLowerCase().replace(/_/g, ''),
				collumns: [item.count, meanServiceTime],
			};

			return tableRow;
		});

		const averageReducer = (array) => array.reduce((a, b) => a + b) / array.length;
		const meanServiceTimes = ordersByChannel.map((item) => item.mean_service_time);
		const averageWaitingTimeInMinutes = Math.floor(averageReducer(meanServiceTimes)) / 60;
		averageWaitingTime = averageWaitingTimeInMinutes.toFixed(1).replace('.00', '');
	}

	return { tableData, averageWaitingTime };
});

export const channelPerformanceTableData = createSelector(ordersByChannelWithAverages, (ordersByChannelWithAverages) => {
	let tableData = [];

	if (ordersByChannelWithAverages?.length) {
		tableData = [...ordersByChannelWithAverages].sort((a, b) => sortChannels(a.channel, b.channel));
		tableData = tableData.map((item) => {
			let count = item.count ? item.count.toFixed(2).replace('.00', '') : '0';
			let meancount = item.mean_count ? item.mean_count.toFixed(2).replace('.00', '') : '0';
			let trend = item.trend ? item.trend.toFixed(2).replace('.00', '') : '0';
			let value = item.value ? `${item.value.toFixed(2)} €` : '0 €';

			if (count[count.length - 1] === '0') {
				count = count.slice(0, 1);
			}
			if (meancount[meancount.length - 1] === '0') {
				meancount = meancount.slice(0, 1);
			}
			if (trend[trend.length - 1] === '0') {
				trend = trend.slice(0, 1);
			}
			if (value[value.length - 1] === '0') {
				value = value.slice(0, 1);
			}

			trend = `${Math.floor(+trend * 100)}%`;

			const tableRow = {
				label: item.channel.toLocaleLowerCase().replace(/_/g, ''),
				collumns: [count, value, meancount, trend],
			};

			return tableRow;
		});
	}

	return { tableData };
});

export const topTenProductsTableData = createSelector(topTenProducts, (topTenProducts) => {
	let tableData = [];
	if (topTenProducts?.length) {
		tableData = topTenProducts.map((item) => {
			const count = item.count ? item.count.toFixed(2).replace('.00', '') : '0';
			const value = item.value ? item.value.toFixed(2).replace('.00', '') : '0';

			const tableRow = {
				label: item.product,
				collumns: [count, value],
			};

			return tableRow;
		});
	}

	return { tableData };
});
