import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { ErrorDialogComponent } from './component/error-dialog.component';
import { ERROR_DIALOG_PROVIDER } from './service/error-dialog.provider.token';
import { ErrorDialogService } from './service/error-dialog.service';

@NgModule({
	declarations: [ErrorDialogComponent],
	imports: [CommonModule, MatDialogModule, MatButtonModule, FlexLayoutModule],
	providers: [
		{
			provide: ERROR_DIALOG_PROVIDER,
			useClass: ErrorDialogService,
		},
	],
})
export class ErrorDialogModule {}
