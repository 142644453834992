import { DateTime } from 'luxon';

// import CONFIG from '../../../../config/src/assets/configs/env/config-env.js'

/* eslint-disable prefer-const */
const deepCopyFunction = (inObject) => {
	let outObject, value, key;

	if (typeof inObject !== 'object' || inObject === null) {
		return inObject; // Return the value if inObject is not an object
	}

	// Create an array or object to hold the values
	outObject = Array.isArray(inObject) ? [] : {};

	for (key in inObject) {
		value = inObject[key];

		// Recursively (deep) copy for nested objects, including arrays
		outObject[key] = deepCopyFunction(value);
	}

	return outObject;
};

const getLocalZone = (): DateTime => {
	let dateTime: DateTime<boolean> = DateTime.local();
	if (window['__CONFIG'].apis['dki-api'].url.includes('api-bkre')) {
		// TODO: add timezone or brand to env variable and remove this
		dateTime = dateTime.setZone('UTC+4').hour === dateTime.hour ? dateTime : dateTime.setZone('UTC+4');
	}
	return dateTime;
};

export { deepCopyFunction, getLocalZone };
