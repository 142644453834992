import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'dk-error-dialog',
	templateUrl: './error-dialog.component.html',
	styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
	title: string;
	message: string;

	constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>) {}
}
