import { NameOfWeekDay } from '../entity';

export function openingHoursForSelectedDay(
	nameOfWeekDay: NameOfWeekDay,
	// TO DO: PROVIDE INTERFACE
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	openingHours?: any
): { from: { hour: number; minute: number; second: number }; to: { hour: number; minute: number; second: number } } {
	const defaultOpeningHours = {
		from: '06:00:00',
		to: '23:59:59',
	};
	const specificOpeningHours = openingHours && openingHours[nameOfWeekDay] ? openingHours[nameOfWeekDay] : undefined;
	const selectedDayOpeningHours = specificOpeningHours ? specificOpeningHours : defaultOpeningHours;
	const from = selectedDayOpeningHours.from.split(':');
	const to = selectedDayOpeningHours.to.split(':');
	const hours = {
		from: {
			hour: +from[0],
			minute: +from[1],
			second: +from[2],
		},
		to: {
			hour: +to[0],
			minute: +to[1],
			second: +to[2],
		},
	};

	return hours;
}
