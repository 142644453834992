import * as api from '@dki/api-client';

export const STORE_SLICE_KEY = 'Accounting';

export interface AccountingState {
	isLoading?: boolean;
	accountingReport: api.AccountingReportResponse | Record<string, unknown>;
	pendingReports: api.AccountingReport[];
	error: any;
}

export const ACCOUNTING_INITIAL_STATE: AccountingState = {
	isLoading: false,
	accountingReport: {},
	pendingReports: [],
	error: false,
};
