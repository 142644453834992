import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { PageContainerModule } from '../page-container/page-container.module';
import { NoAccessComponent } from './dumb-components/no-access/no-access.component';
import { ErrorRoutingModule } from './error-routing.module';

@NgModule({
	declarations: [NoAccessComponent],
	imports: [CommonModule, ErrorRoutingModule, PageContainerModule, MatButtonModule],
})
export class ErrorModule {}
