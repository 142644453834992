import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ProgressOverlayModule } from '../progress-overlay/progress-overlay.module';
import { AccountFormContainerComponent } from './host-component/account-form-container.component';

@NgModule({
	declarations: [AccountFormContainerComponent],
	imports: [CommonModule, FlexLayoutModule, ProgressOverlayModule],
	exports: [AccountFormContainerComponent],
})
export class AccountFormContainerModule {}
