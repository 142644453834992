import { ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import { MergedRoute } from './entity';

export class MergedRouterStateSerializer implements RouterStateSerializer<MergedRoute> {
	serialize(routerState: RouterStateSnapshot): MergedRoute {
		return {
			url: routerState.url,
			params: mergeRouteParams(routerState.root, (r) => r.params),
			queryParams: mergeRouteQueryParams(routerState.root, (r) => r.queryParams),
			data: mergeRouteData(routerState.root),
		};
	}
}

function mergeRouteParams(route: ActivatedRouteSnapshot, getter: (r: ActivatedRouteSnapshot) => Params): Params {
	if (route) {
		const currentParams = getter(route);
		const primaryChild = route.children.find((c) => c.outlet === 'primary') || route.firstChild;
		if (primaryChild) {
			return { ...currentParams, ...mergeRouteParams(primaryChild, getter) };
		} else {
			return {};
		}
	} else {
		return {};
	}
}

function mergeRouteQueryParams(route: ActivatedRouteSnapshot, getter: (r: ActivatedRouteSnapshot) => Params): Params {
	if (route) {
		const currentParams = getter(route);
		const primaryChild = route.children.find((c) => c.outlet === 'primary') || route.firstChild;

		if (primaryChild) {
			return { ...currentParams, ...mergeRouteParams(primaryChild, getter) };
		} else {
			return {};
		}
	} else {
		return {};
	}
}

function mergeRouteData(route: ActivatedRouteSnapshot | null): Data {
	if (route) {
		const currentData = route.data;
		const primaryChild = route.children.find((c) => c.outlet === 'primary') || route.firstChild;

		return { ...currentData, ...mergeRouteData(primaryChild) };
	} else {
		return {};
	}
}
