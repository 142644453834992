import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { RestaurantSelectionModule } from '../../features/restaurant-select/restaurant-select.module';
import { PageAsideNavigationModule } from '../page-aside-navigation/page-aside-navigation.module';
import { PageHeaderModule } from '../page-header/page-header.module';
import { ProgressOverlayModule } from '../progress-overlay/progress-overlay.module';
import { PageContainerComponent } from './host-component/page-container.component';

@NgModule({
	declarations: [PageContainerComponent],
	imports: [CommonModule, FlexLayoutModule, PageHeaderModule, PageAsideNavigationModule, ProgressOverlayModule, RestaurantSelectionModule],
	exports: [PageContainerComponent],
})
export class PageContainerModule {}
