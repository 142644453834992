export type Channel = 'DRIVE_THROUGH' | 'KIOSK_EAT_IN' | 'KIOSK_TAKE_OUT' | 'DELIVERY' | 'EAT_IN' | 'TAKE_OUT' | 'SALLE' | 'PARKING';

export const Channel = {
	DRIVETHROUGH: 'DRIVE_THROUGH' as Channel,
	KIOSKEATIN: 'KIOSK_EAT_IN' as Channel,
	KIOSKTAKEOUT: 'KIOSK_TAKE_OUT' as Channel,
	DELIVERY: 'DELIVERY' as Channel,
	EATIN: 'EAT_IN' as Channel,
	TAKEOUT: 'TAKE_OUT' as Channel,
	SALLE: 'SALLE' as Channel,
	PARKING: 'PARKING' as Channel,
};

export const Pos: Set<Channel> = new Set([Channel.EATIN, Channel.TAKEOUT, Channel.SALLE]);
export const Kiosk: Set<Channel> = new Set([Channel.KIOSKEATIN, Channel.KIOSKTAKEOUT]);
export const Drive: Set<Channel> = new Set([Channel.DRIVETHROUGH]);
export const Delivery: Set<Channel> = new Set([Channel.DELIVERY, Channel.PARKING]);
export const Eatin: Set<Channel> = new Set([Channel.KIOSKEATIN, Channel.EATIN, Channel.SALLE]);
