/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';

import { NewUserInfo, PermissionsInfo, Restaurant, SetPasswordInfo, UpdateUserInfo, UserInfo } from '../model/models';

import { BASE_PATH } from '../variables';


@Injectable()
export class UsersApiClient {

	protected basePath = 'http://localhost';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	public encoder: HttpParameterCodec;

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (configuration) {
			this.configuration = configuration;
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (typeof basePath !== 'string') {
				basePath = this.basePath;
			}
			this.configuration.basePath = basePath;
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
	}


	private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
		if (typeof value === "object" && value instanceof Date === false) {
			httpParams = this.addToHttpParamsRecursive(httpParams, value);
		} else {
			httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
		}
		return httpParams;
	}

	private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
		if (value == null) {
			return httpParams;
		}

		if (typeof value === "object") {
			if (Array.isArray(value)) {
				(value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
			} else if (value instanceof Date) {
				if (key != null) {
					httpParams = httpParams.append(key,
						(value as Date).toISOString().substr(0, 10));
				} else {
					throw Error("key may not be null if value is Date");
				}
			} else {
				Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
					httpParams, value[k], key != null ? `${key}.${k}` : k));
			}
		} else if (key != null) {
			httpParams = httpParams.append(key, value);
		} else {
			throw Error("key may not be null if value is not object or array");
		}
		return httpParams;
	}

	/**
	 * Add User Restaurant
	 * Grant the user access to the given restaurant.
	 * @param userId
	 * @param restaurantId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public addUserRestaurantV1UsersUserIdRestaurantsRestaurantIdPost(userId: number, restaurantId: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<any>;
	public addUserRestaurantV1UsersUserIdRestaurantsRestaurantIdPost(userId: number, restaurantId: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<any>>;
	public addUserRestaurantV1UsersUserIdRestaurantsRestaurantIdPost(userId: number, restaurantId: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<any>>;
	public addUserRestaurantV1UsersUserIdRestaurantsRestaurantIdPost(userId: number, restaurantId: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error('Required parameter userId was null or undefined when calling addUserRestaurantV1UsersUserIdRestaurantsRestaurantIdPost.');
		}
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling addUserRestaurantV1UsersUserIdRestaurantsRestaurantIdPost.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.post<any>(`${this.configuration.basePath}/v1/users/${encodeURIComponent(String(userId))}/restaurants/${encodeURIComponent(String(restaurantId))}`,
			null,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Create User
	 * Create a new user.
	 * @param newUserInfo
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public createUserV1UsersPost(newUserInfo: NewUserInfo, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<UserInfo>;
	public createUserV1UsersPost(newUserInfo: NewUserInfo, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<UserInfo>>;
	public createUserV1UsersPost(newUserInfo: NewUserInfo, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<UserInfo>>;
	public createUserV1UsersPost(newUserInfo: NewUserInfo, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (newUserInfo === null || newUserInfo === undefined) {
			throw new Error('Required parameter newUserInfo was null or undefined when calling createUserV1UsersPost.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.post<UserInfo>(`${this.configuration.basePath}/v1/users`,
			newUserInfo,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Delete User
	 * Delete a user.
	 * @param userId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public deleteUserV1UsersUserIdDelete(userId: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<any>;
	public deleteUserV1UsersUserIdDelete(userId: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<any>>;
	public deleteUserV1UsersUserIdDelete(userId: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<any>>;
	public deleteUserV1UsersUserIdDelete(userId: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error('Required parameter userId was null or undefined when calling deleteUserV1UsersUserIdDelete.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/users/${encodeURIComponent(String(userId))}`,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get All Available Permissions
	 * Get dictionary of all available permissions by permission name
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getAllAvailablePermissionsV1PermissionsGet(observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<{ [key: string]: PermissionsInfo; }>;
	public getAllAvailablePermissionsV1PermissionsGet(observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<{ [key: string]: PermissionsInfo; }>>;
	public getAllAvailablePermissionsV1PermissionsGet(observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<{ [key: string]: PermissionsInfo; }>>;
	public getAllAvailablePermissionsV1PermissionsGet(observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<{ [key: string]: PermissionsInfo; }>(`${this.configuration.basePath}/v1/permissions`,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get User
	 * Get a single user profile by ID.
	 * @param userId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getUserV1UsersUserIdGet(userId: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<UserInfo>;
	public getUserV1UsersUserIdGet(userId: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<UserInfo>>;
	public getUserV1UsersUserIdGet(userId: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<UserInfo>>;
	public getUserV1UsersUserIdGet(userId: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error('Required parameter userId was null or undefined when calling getUserV1UsersUserIdGet.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<UserInfo>(`${this.configuration.basePath}/v1/users/${encodeURIComponent(String(userId))}`,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Users
	 * Get all user profiles.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getUsersV1UsersGet(observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<UserInfo>>;
	public getUsersV1UsersGet(observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<UserInfo>>>;
	public getUsersV1UsersGet(observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<UserInfo>>>;
	public getUsersV1UsersGet(observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<UserInfo>>(`${this.configuration.basePath}/v1/users`,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Remove User Restaurant
	 * Revoke access to the given restarant from the user.
	 * @param userId
	 * @param restaurantId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public removeUserRestaurantV1UsersUserIdRestaurantsRestaurantIdDelete(userId: number, restaurantId: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<any>;
	public removeUserRestaurantV1UsersUserIdRestaurantsRestaurantIdDelete(userId: number, restaurantId: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<any>>;
	public removeUserRestaurantV1UsersUserIdRestaurantsRestaurantIdDelete(userId: number, restaurantId: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<any>>;
	public removeUserRestaurantV1UsersUserIdRestaurantsRestaurantIdDelete(userId: number, restaurantId: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error('Required parameter userId was null or undefined when calling removeUserRestaurantV1UsersUserIdRestaurantsRestaurantIdDelete.');
		}
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling removeUserRestaurantV1UsersUserIdRestaurantsRestaurantIdDelete.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/users/${encodeURIComponent(String(userId))}/restaurants/${encodeURIComponent(String(restaurantId))}`,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Send Invitation
	 * Send an email with a password reset token.  The token can be used to set a new password with &#x60;/v1/auth/reset-password&#x60;.
	 * @param userId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public sendInvitationV1UsersUserIdSendInvitationPost(userId: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<any>;
	public sendInvitationV1UsersUserIdSendInvitationPost(userId: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<any>>;
	public sendInvitationV1UsersUserIdSendInvitationPost(userId: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<any>>;
	public sendInvitationV1UsersUserIdSendInvitationPost(userId: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error('Required parameter userId was null or undefined when calling sendInvitationV1UsersUserIdSendInvitationPost.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.post<any>(`${this.configuration.basePath}/v1/users/${encodeURIComponent(String(userId))}/send-invitation`,
			null,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Set User Password
	 * Set a new password for a user.
	 * @param userId
	 * @param setPasswordInfo
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public setUserPasswordV1UsersUserIdPasswordPost(userId: number, setPasswordInfo: SetPasswordInfo, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<any>;
	public setUserPasswordV1UsersUserIdPasswordPost(userId: number, setPasswordInfo: SetPasswordInfo, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<any>>;
	public setUserPasswordV1UsersUserIdPasswordPost(userId: number, setPasswordInfo: SetPasswordInfo, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<any>>;
	public setUserPasswordV1UsersUserIdPasswordPost(userId: number, setPasswordInfo: SetPasswordInfo, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error('Required parameter userId was null or undefined when calling setUserPasswordV1UsersUserIdPasswordPost.');
		}
		if (setPasswordInfo === null || setPasswordInfo === undefined) {
			throw new Error('Required parameter setPasswordInfo was null or undefined when calling setUserPasswordV1UsersUserIdPasswordPost.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.post<any>(`${this.configuration.basePath}/v1/users/${encodeURIComponent(String(userId))}/password`,
			setPasswordInfo,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Update User
	 * Modify an existing user.
	 * @param userId
	 * @param updateUserInfo
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public updateUserV1UsersUserIdPatch(userId: number, updateUserInfo: UpdateUserInfo, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<UserInfo>;
	public updateUserV1UsersUserIdPatch(userId: number, updateUserInfo: UpdateUserInfo, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<UserInfo>>;
	public updateUserV1UsersUserIdPatch(userId: number, updateUserInfo: UpdateUserInfo, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<UserInfo>>;
	public updateUserV1UsersUserIdPatch(userId: number, updateUserInfo: UpdateUserInfo, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error('Required parameter userId was null or undefined when calling updateUserV1UsersUserIdPatch.');
		}
		if (updateUserInfo === null || updateUserInfo === undefined) {
			throw new Error('Required parameter updateUserInfo was null or undefined when calling updateUserV1UsersUserIdPatch.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.patch<UserInfo>(`${this.configuration.basePath}/v1/users/${encodeURIComponent(String(userId))}`,
			updateUserInfo,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Update User
	 * Modify an existing user.
	 * @param userId
	 * @param updateUserInfo
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 * @deprecated
	 */
	public updateUserV1UsersUserIdPut(userId: number, updateUserInfo: UpdateUserInfo, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<UserInfo>;
	public updateUserV1UsersUserIdPut(userId: number, updateUserInfo: UpdateUserInfo, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<UserInfo>>;
	public updateUserV1UsersUserIdPut(userId: number, updateUserInfo: UpdateUserInfo, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<UserInfo>>;
	public updateUserV1UsersUserIdPut(userId: number, updateUserInfo: UpdateUserInfo, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error('Required parameter userId was null or undefined when calling updateUserV1UsersUserIdPut.');
		}
		if (updateUserInfo === null || updateUserInfo === undefined) {
			throw new Error('Required parameter updateUserInfo was null or undefined when calling updateUserV1UsersUserIdPut.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.put<UserInfo>(`${this.configuration.basePath}/v1/users/${encodeURIComponent(String(userId))}`,
			updateUserInfo,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * User Restaurants
	 * Get all restaurants the user has access to.
	 * @param userId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public userRestaurantsV1UsersUserIdRestaurantsGet(userId: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<Restaurant>>;
	public userRestaurantsV1UsersUserIdRestaurantsGet(userId: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<Restaurant>>>;
	public userRestaurantsV1UsersUserIdRestaurantsGet(userId: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<Restaurant>>>;
	public userRestaurantsV1UsersUserIdRestaurantsGet(userId: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error('Required parameter userId was null or undefined when calling userRestaurantsV1UsersUserIdRestaurantsGet.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<Restaurant>>(`${this.configuration.basePath}/v1/users/${encodeURIComponent(String(userId))}/restaurants`,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
