import {
	IBKIngredientData,
	IBKItemCrownPrice,
	IBKItemInOrderBase,
	IBKMenuBase,
	IBKOrderElementWithCsiInfosCassandra,
	IBKOrderPayData,
	IBKProductBase,
	IBKProductFamilyData,
	IBKProductGroupData,
	IBKProductIngredientData,
	IBKPublishedOrderData,
} from "@bk/jscommondatas";

export interface ICsiLine {
	centreProfit?: string;
	lineUuid: string;
	numero: number; //0,
	horodatage: number; //1473926358915,
	libelleTarif: string; //"",
	numTarif: number; //0,
	libelle: string; //"STEACKHOUSE",
	code: string; //"",
	prix: number; //0,
	prixUnitaireCarte: number;
	qte: number; //1,
	poids: number; //0,
	unite: string; //"PIECE",
	ticketUuid: string; //"a553e3d9-4a60-4541-9428-6d9903491d1b",
	parentLineUuid?: string; //"b9cf6016-b38e-4ca2-bb0c-5e1ef8a23f27",
	typeLigne: string; //"ARTICLE",
	//  For compatibility only, will be removed
	numClef: number; //0,
	nomClef: string; //"CLEF 1",
	//  Now version
	codeManager: number;
	nomManager: string;

	codeVendeur: number; //0,
	nomVendeur: string; //"VENDEUR 1",
	libFamille: string; //"FAM 1",
	libSousFamille: string; //"SFAM 1",
	libGroupe: string; //"GROUPE 1",
	numFamille: number; //1,
	numSousFamille: number; //1,
	numGroupe: number; //1,
	libelleTVA: string; //"TVA 10%",
	numTVA: number; //1,
	tauxTVA: number; //10,
	libCaisse: string; //"127.0.0.1",
	numCaisse: number; //1,
	remiseMontantTTC: number; //0,
	remiseMontantHT: number; //0,
	remiseTaux: number; //0,
	serviceTaux: number; //0,
	serviceMode: string; //"AUCUN",
	serviceMontant: number; //0,
	montantTotalTTC: number; //0,
	montantTotalHT: number; //0,
	deviseCode: string; //"",
	deviseTaux: number; //0,
	deviseLibelle: string; //"",
	deviseMontant: number; //0,
	tag?: string; //"order_number=321&sale_id=123&cancelled_sale_reference_uid=0&destination=EAT_IN&product_id=123&product_reference_uid=123&a_la_carte_price=5.00&savings=1.00&valuemeal_reference_uid=123&valuemeal_id=123&tender_id=321&third_party_id=321&tender_reference_uid=321&tender_name=XXX&is_change=false&qualifier_third_party_id=123&qualifier_id=123&modifier_reference_uid=123&modifier_third_party_id=123&modifier_id=123",
	libelleModifier: string; //"",
	libelleQualifier: string; //"",
	change: boolean; //false

	/**
	 * The filter is utilized only for discounts to ensure what items the discount should be applied on.
	 * When there is no discount in the order, the filter shall be undefined/null.
	 *
	 * Discount filter utilizes bitmask logic:
	 * To apply the discount, the filter of a discount must be set to integer that is a power of 2,
	 * like 0, 1, 2, 4, 8 etc.
	 *
	 * Than to apply the discount on one or multiple items, the filter on the item must be set to
	 * sum of filters that should be applied, e.g. if there is one discount with the filter 1 and
	 * second discount with the filter 2 and we want to apply both the discounts on one product,
	 * this product must have filter set to 3 (1+2).
	 *
	 * Discount with filter 0 applies on all the lines.
	 */
	filter?: number;

	/**
	 * Array of line UUIDs that should be excluded from global discount. Applies to menus, products, etc.
	 */
	linesExcludedFromDiscount?: string[];
}

export interface ICsiComplement {
	cle: string;
	valeur: string;
	type: string;
	complementId: number;
}

export enum CsiComplementCle {
	uuidRepasEmploye = 'uuidRepasEmploye',
	numRepasEmploye = 'numRepasEmploye',
	originRepasEmploye = 'originRepasEmploye',
	orbNumber = 'NUM_ORB'
}

export interface ICsiTicketWithTypeDocumentAndORB extends ICsiTicket {
	typedocument: string; // COMMANDE, TICKET
	orb?: string;
}

export interface ICsiTicket {
	ticketUuid: string; //"a553e3d9-4a60-4541-9428-6d9903491d1b",
	localisation: string;
	codeVendeur: number; //0,
	nomVendeur: string; //"VENDEUR 0",
	codeOperateur: number; //0,
	nomOperateur: string; //"",
	clefEcole: boolean; //false,
	nbClient: number; //0,
	commandeNameCaisse: string; //"",
	commandeNumber: number; //-1,
	sourceTicketNameCaisse: string; //"",
	sourceTicketNumber: number; //-1,
	destinationTicketNameCaisse: string; //"",
	destinationTicketNumber: number; //-1,
	sourceTicketState: string; //"NONE",
	destinationTicketState: string; //"NONE",
	horodatage: number; //1473926359047,
	regroupementConsommation: string; //"",
	tag: string; //"order_number=321&sale_id=123&cancelled_sale_reference_uid=0&destination=EAT_IN&product_id=123&product_reference_uid=123&a_la_carte_price=5.00&savings=1.00&valuemeal_reference_uid=123&valuemeal_id=123&tender_id=321&third_party_id=321&tender_reference_uid=321&tender_name=XXX&is_change=false&qualifier_third_party_id=123&qualifier_id=123&modifier_reference_uid=123&modifier_third_party_id=123&modifier_id=123",
	dateFiscale: number; //1473926358912,
	commandeId: number; //0,
	generator: string; //"127.0.0.1",
	lines: ICsiLine[];
	complements: ICsiComplement[];
}

export interface ICsiSession {
	id: string;
	valeurTheorique: number;
	valeurEspecesTheorique: number;
	fondCaisse: number;
}

export interface ICsiUser {
	userId: number;
	userName: string;
}

export interface ICsiIsLogged {
	manager: ICsiUser;
	session: ICsiSession;
	operateur: ICsiUser;
}

export interface ICsiLineGenerator {
	getNextLineIndex(): number;
}

export interface ICsiProductWithFamilyAndGroup {
	_productGroup: IBKProductGroupData /*BKProductGroup*/
	;
	_productGroupL?: IBKProductGroupData /*BKProductGroup*/
	;
	_productFamily: IBKProductFamilyData /*BKProductFamily*/
	;
	_productSubFamily: IBKProductFamilyData /*BKProductFamily*/
	;
}

export interface ICsiProductWithProcessedIngredients {
	_processedIngredients: ICsiProductProcessedIngredient[];
}

export interface ICsiProductProcessedIngredient {
	_availableIngredients: ICsiIngredient[];
	_defaultIngredient: ICsiIngredient;
	_ingredient: IBKProductIngredientData;
}

export interface ICsiTicketSettings {
	kioskMultipayMessage: string;
	toiletCode: string;
	messageOnTicket: string;
	alertOnTicket?: string; // like messageOnTicket, but more visible
	skip?: {
		source?: boolean; // skip source line
		orb?: boolean; // skip orb line
		tableNum?: boolean; // skip table allocation number line
		payLine?: boolean;
	};
	// if set, append profit center to every line
	addProfitCenter?: string;
	brandName: string; //BrandName from '@merim/utils, see brand-name.ts
	fidelityPoints?: {
		earned: number;
		burned: number;
		balance: number;
	}
	deliveryLaterUpperCased?: boolean;
}

export interface ICsiItemWithProductGroupAndCrown {
	_productGroup: IBKProductGroupData;
	_productGroupL?: IBKProductGroupData;
	_productGroupXL?: IBKProductGroupData;
	_crown: IBKItemCrownPrice;
}

export interface ICsiDeliverLaterInfo {
	menuQte: number;
	pio: IBKItemInOrderBase /*BKProductInOrder*/
	;
}

export type ICsiOrder = IBKPublishedOrderData & ICsiLineGenerator;
export type ICsiProductInOrder = IBKProductBase & IBKItemInOrderBase & ICsiProductWithFamilyAndGroup & ICsiProductWithProcessedIngredients;
export type ICsiMenuInOrder = IBKMenuBase & IBKItemInOrderBase & ICsiItemWithProductGroupAndCrown;
export type ICsiIngredient = IBKIngredientData & ICsiItemWithProductGroupAndCrown;

// declare as export interface so mix exports it

/*******************************************************************************
 * Law Evolution And Sales Server
 ******************************************************************************/

/** Single safe account **/
export interface ILawSafeAccount {
	id: number;
	libelle: string;
	solde: number;
	version: number;
	numero: number;
	nature: string;
	tag?: string;
}

/** Wrapper used in order to validate afe accounts  **/
export interface ILawSafeAccountsWrapper {
	accounts: ILawSafeAccount[];
}

export interface ILawDeclaration {
	libfamille: string; //String	Libelle de la famille de règlement
	libelle: string; //String	Libelle du règlement
	reel: number; //Decimal	Montant réel
	remarque: string;
	tag?: string;
}

export interface ILawReceiptReglement {
	libelle: string;
	libFamille: string;
	totalTheorique: number;
	totalReel: number;
	horodatage: number;
	libCaisse: string;
	libSite: string;
	numCaisse: number;
	id: string;
	libVendeur: string;
	numVendeur: number;
	tag?: string;
}

export interface ILawReceiptDiscount {
	libelle: string;
	totalTheorique: number;
	quantiteTheorique: number;
}

export interface ILawReceipt {
	valeurTiroirTheorique: number;
	session: string;
	// TODO Restore it tiroir_PRELEVEMENTs: Array<any>;
	tiroir_REGLEMENTs: Array<ILawReceiptReglement>;
	tiroir_PRELEVEMENTs: Array<ILawReceiptReglement>;
	tiroir_REMISEs: Array<ILawReceiptDiscount>;
	id: string;
	correctionsMontant: number;
	correctionsQuantite: number;
	annulationsMontant: number;
	annulationsQuantiteTheo: number;
	recuNum?: number;
	libVendeur?: string;
	numVendeur?: number;
	libCaisse?: string;
	horodatage?: number;
	prevelementsTheoretical?: number;
	annulationsTheoretical?: number;
	correctionsTheoretical?: number;
	remisesTheoretical?: number;
	rembourssementTheoretical?: number;
	repasEmploye?: number;
	businessDate?: number; //date in format YYYYMMDD
	dateFiscale?: number; //provided by CSI, required to calculate businessDate when businessDate is missing
}

export interface ILawReceiptsWrapper {
	receipts: ILawReceipt[];
}

export interface ILawValidAccount {
	numCompte: number;
	montant: number;
	nomManager: string;
	codeManager: number;
}

export interface ILawValidAccountWrapper {
	accounts: ILawSafeAccount[];
}

export interface ILawTicketCostOrRecipe {
	numCompte: number;
	theorique: number;
	delta: number;
	constate: number;
	nomManager: string;
	codeManager: number;
	remarque: string;
	numCompteCompensation: number;
}

export interface ILawSession {
	id: string;
	age: number;
	caisse: string;
	site: string;
	centreProfit: string;
}

export interface ILawSessionsWrapper {
	sessions: ILawSession[];
}

export interface ILawTransferRule {
	description: string;
	tag: string;
	cle: number;
	mouvement: string;
	sourceLibelle: string;
	sourceNumero: number;
	destinationLibelle?: string;
}

export interface ILawTransferRulesWrapper {
	rules: ILawTransferRule[];
}

export interface ISalesServerModeReglement {
	/**
	 * 'libelle' is later copied to 'payLabel' on ticket.
	 */
	libelle: string;
	/**
	 * Note: We do not have list of valid values for this number.
	 * It comes from CSI. See request http://192.168.100.1:5000/sales/getConfigurationsPayments
	 * CSI returns list of accepted mode of payments, BK picks one, and then use it's "number property"
	 */
	number: number;
	family: string;
	changeAllowed: boolean;
	openDrawer1: boolean;
	openDrawer2: boolean;
	tag?: string;
	/**
	 * What actually comes from the API and is missing in the interface
	 */
	totalMax?: number;
	totalMin?: number;
	quantiteMax?: number;
	quantiteMin?: number;
	unitaireMax?: number;
	unitaireMin?: number;
	reel?: boolean;
	cumulChange?: boolean;
	needEventReglement?: boolean;
	initialiseDansSession?: boolean;
	compense?: boolean;
	image?: string;
	paymentAsynchrone?: boolean;
	change?: ISalesServerModeReglement;
	printWithLogoContactLess?: boolean;
	printMerchandTicket?: boolean;
	printCustomerTicket?: boolean;
}

export interface ISalesServerModeReglementList {
	code: number;
	response: ISalesServerModeReglement[];
}

/**
 * BKBOLsocal weborders table
 * (being replaced by bk-bo-local-manager-lib -> CassandraDeliveryOrder)
 */
export interface IBKWebordersLocalCassandra {
	businessday: number;
	orderuuid: string;
	sandbox: string;
	alienuuid: string;
	mdmorder: IBKPublishedOrderData;
	mdmpay: IBKOrderPayData;
	duedate: number;
	orb: number;
	csiticket: IBKOrderElementWithCsiInfosCassandra;
	csicmd: IBKOrderElementWithCsiInfosCassandra;
	/** WeborderState */
	prodstate: number;
	ticketprinted: boolean;
	itemcount: number;
	voided?: boolean;
	replacedby?: string;
	aliendisplayid?: string;
}

/**
 * BKBOLocal click&collect order table
 * (being replaced by bk-bo-local-manager-lib -> CassandraClickAndCollectOrder)
 */
export interface IBKCCOrdersLocalCassandra {
	businessday: number;
	orderuuid: string;
	sandbox: string;
	alienid: string;
	/** Order with post-conversion structure */
	mdmorder: IBKPublishedOrderData;
	state: number;
	itemcount: number;
}


export interface IBKWebOrderLocalWithScheduledDate extends IBKWebordersLocalCassandra {
	/**
	 * Timestamp when the order will be automatically sent to prod
	 *
	 * 0 if the order will never be sent automatically
	 */
	scheduledproddate: number;
}

export interface IBKBoCanOrderResult {
	canOrder: boolean;
	reasonAgainst?: string;
	details: IBKBoCanOrderResultRejectionDetail[];
}

export interface IBKBoCanOrderResultRejectionDetail {
	code: number;
	arg?: number;
	label?: string;
}
