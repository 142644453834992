import { get } from 'lodash-es';

// I would use native https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replaceAll
// but we are stuck with Legacy Chromium v43 (or something like that) where it is not yet supported
// Does not work if the char dot (.) is replaced!!!
//  This example does not work: replaceAll('1...458', '.', '') === "";
//  You need to add a backslash '\\.'.
//  This example work: replaceAll('1...458', '\\.', '') === "1458";
//  This example work: '1...458'.split('.').join('') === "1458";
// Also it does not work when there is a special char like '?'
export function replaceAll(str: string, find: string, replaceWith: string): string {
	return str.replace(new RegExp(find, 'g'), replaceWith);
}

// TODO: Replace the duplicated definition on frontend common
// Based on https://stackoverflow.com/a/26156806/552194
export function trimChar(str: string, charToRemove: string): string {
	while (str.charAt(0) === charToRemove) {
		str = str.substring(1);
	}

	while (str.charAt(str.length - 1) === charToRemove) {
		str = str.substring(0, str.length - 1);
	}

	return str.trim();
}

export function stringIsNumber(str: string): boolean {
	const num = parseInt(str);
	return Number.isFinite(num);
}

/**
 * When decimal price is in format "nnn,nn" it converts it to "nnn.nn",
 * replacing "," with ".".
 */
export function normalizePrice(price: string): string {
	return replaceAll(price, ',', '.');
}

/**
 * Returns "inputArr" sorted by "sortedValues".
 * "sortedValues" may not include all values.
 * @param sortedValues Example Array with sorted values. May include only some of the values.
 */
export function sortByExampleArray(inputArr: string[], sortedValues: string[]): string[] {
	const result: string[] = inputArr.sort((a, b) => {
		let sortedIndexA = sortedValues.indexOf(a);
		let sortedIndexB = sortedValues.indexOf(b);

		if (sortedIndexA === -1) {
			sortedIndexA = Infinity;
		}

		if (sortedIndexB === -1) {
			sortedIndexB = Infinity;
		}

		return sortedIndexA - sortedIndexB;
	});

	return result;
}

/**
 * InputArr is array of objects
 * attribute key can be composed multi key or single value key
 * @param inputArr
 * @param attributeKey
 * return sorted array alphabetically taking into account numbers in front of the items
 */
export function sortAlphabeticallyAndByCountOfProducts<T>(inputArr: T[], attributeKey: string): T[] {
	return inputArr
		.sort((a: any, b: any) => {
			const aText = get(a, attributeKey).trim();
			const bText = get(b, attributeKey).trim();
			const aWithoutQuantity = aText.replace(/^x[0-9]+/, '').trim();
			const bWithoutQuantity = bText.replace(/^x[0-9]+/, '').trim();

			// no quantity present in the beginning of string
			// we just sort alphabetically
			if (aWithoutQuantity === aText && bWithoutQuantity === bText) {
				return aText.localeCompare(bText);
			}

			// they both have quantities, so we compare the whole string
			// and they are same product
			if (aWithoutQuantity !== aText && bWithoutQuantity !== bText && aWithoutQuantity === bWithoutQuantity) {
				return aText.localeCompare(bText);
			}

			// one or both of the strings are with quantities
			return aWithoutQuantity.localeCompare(bWithoutQuantity);
		});
}

export function escapeRegExp(inputString: string): string {
	return inputString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
