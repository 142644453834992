/**
 * Different states of an order
 */
export enum BKOrderState {
	ORDER_STATE_OPEN = 0,
	ORDER_STATE_PAYING = 1,
	ORDER_STATE_PAID = 2,
	ORDER_STATE_PREPARING = 3,
	/**
	 * First bump on ORBp
	 */
	ORDER_STATE_PREPARED = 4,
	/**
	 * Second bump on ORBp
	 */
	ORDER_STATE_DELIVERED = 5,
	ORDER_STATE_DISMISSED = 6,
}
