import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';

import { ApplicationModuleSections } from '@libs/dash/core/entity';
import { FeatureMapService } from '@libs/dash/guards';

import { AuthStoreSelectors, AuthStoreState, RootStoreState, RouterStoreSelectors } from '@libs/dash/store';
import { ConfigurationService } from '@merim/utils';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'dk-page-aside-navigation',
	templateUrl: './page-aside-navigation.component.html',
	styleUrls: ['./page-aside-navigation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageAsideNavigationComponent implements OnInit {
	ApplicationModuleSections = ApplicationModuleSections;
	expanded = false;
	reportsFeatureEnabled = false;
	collapsedAsideCssClass = 'dk-collapsed-aside';
	expandedAsideCssClass = 'dk-expanded-aside';
	featureMap = this.fm.featureMap;

	hasAccountingPermission$ = this._store.pipe(
		select(AuthStoreSelectors.getCurrentUser),
		map((user: AuthStoreState.CurrentUser) => {
			const accountingLevel = user?.permissions.filter(
				(permission) => !!permission.name && permission.name.toLowerCase() === 'accounting'
			)[0]?.level;
			const isAvailable = !!accountingLevel && accountingLevel.toLowerCase() !== 'none';
			return isAvailable || user.is_admin;
		})
	);

	i18n$ = combineLatest([
		this._store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam)),
		this._translocoService.selectTranslateObject('navigation-bar'),
	]).pipe(
		map(([language, texts]) => {
			return {
				language,
				texts,
			};
		})
	);

	@HostBinding('class') hostCssClass = this.collapsedAsideCssClass;

	constructor(
		private _translocoService: TranslocoService,
		private _store: Store<RootStoreState>,
		private readonly configurationService: ConfigurationService,
		private fm: FeatureMapService
	) {}

	toggleAside(): void {
		this.expanded = !this.expanded;

		this.setHostCssClass();
	}

	private setHostCssClass(): void {
		this.hostCssClass = this.expanded ? this.expandedAsideCssClass : this.collapsedAsideCssClass;
	}

	ngOnInit() {
		this.reportsFeatureEnabled = this.configurationService.hasFeature('reports');
	}
}
