import * as api from '@dki/api-client';

import { Range } from '@libs/dash/core/entity';
import { DateTime } from 'luxon';

export const STORE_SLICE_KEY = 'SafeDeposit';

export interface SafeDepositState {
	data: Array<api.SafeRecord> | any;
	isLoading?: boolean;
	from?: DateTime;
	to?: DateTime;
}

export const SAFE_DEPOSIT_INITIAL_STATE: SafeDepositState = {
	data: [],
	from: DateTime.now().startOf(Range.Week),
	to: DateTime.now().endOf(Range.Week),
};
