<mat-form-field appearance="fill">
	<mat-label>Restaurants</mat-label>
	@if (!multiSelectionEnabled) {
		<mat-select (selectionChange)="selectRestaurant($event)" [(ngModel)]="selectedRestaurant">
			<div class="mat-select-search">
				<mat-icon>search</mat-icon>
				<input matInput [placeholder]="(i18n$ | async).search" type="text" [(ngModel)]="searchQuery"/>
			</div>
			@for (restaurant of restaurantList$ | async; track restaurant) {
				<mat-option [value]="restaurant" [class.filtered-out]="isHidden(restaurant.name)">
					{{ restaurant.name }}
				</mat-option>
			}
		</mat-select>
	}
	@if (multiSelectionEnabled) {
		<mat-select
			(selectionChange)="multiSelectRestaurants($event)"
			[(ngModel)]="multiSelectedRestaurants"
			[(value)]="multiSelectedRestaurants"
			multiple
			required="true"
			id="multi-rest-selector"
		>
			<div class="sticky-top">
				<div class="mat-select-search">
					<mat-icon>search</mat-icon>
					<input matInput [placeholder]="(i18n$ | async).search" type="text" [(ngModel)]="searchQuery"/>
				</div>
				<div class="selected-restaurants">
					@for (restaurant of multiSelectedRestaurants; track restaurant) {
						<span (click)="deSelect(restaurant)" class="rest-opt"> {{ restaurant && restaurant.name }}
            </span>
					}
				</div>
			</div>
			@for (restaurant of restaurantList$ | async; track restaurant) {
				<mat-option [value]="restaurant" [class.filtered-out]="isHidden(restaurant.name)" [class.selected]="isSelected(restaurant)">
					{{ restaurant.name }}
				</mat-option>
			}
		</mat-select>
	}
</mat-form-field>
