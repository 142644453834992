import * as api from '@dki/api-client';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { getListOfMyRestaurants } from '../my-restaurants/selectors';
import { SessionHistoryState, STORE_SLICE_KEY } from './state';

export const reducerState = createFeatureSelector<SessionHistoryState>(STORE_SLICE_KEY);

export const sessionHistory = createSelector(reducerState, (state) => state.data);

export const isLoading = createSelector(reducerState, (state) => state.isLoading);

export const sessionsSynth = createSelector(sessionHistory, getListOfMyRestaurants, (data: Array<api.PosRecord>, myRestaurants) => {
	const synth: {
		restaurant: string;
		cashier: string;
		sessionCount: string;
		revenue: {
			declared: number;
			discount: number;
			percent: number;
			real: number;
			gap: number;
		};
		cancels: {
			amount: number;
			percent: number;
			count: number;
		};
		corrections: {
			amount: number;
			percent: number;
			count: number;
		};
		refund: 0;
		withdrawn: 0;
		hidden: boolean;
	}[] = [];
	const groupedData = groupObjectsByEmpl(data);
	Object.values(groupedData).forEach((grouped) => {
		const synthEntry = calculatePosSummary(grouped);
		synthEntry.restaurant = grouped[0].restaurantId;
		synthEntry.cashier = grouped[0].employee.login;
		synth.push(synthEntry);
	});
	synth.forEach((e) => {
		e.restaurant = myRestaurants.find((resto) => resto.id === e.restaurant)?.name;
		e.revenue.gap = e.revenue.declared - e.revenue.real;
		e.revenue.percent = e.revenue.discount / e.revenue.declared;
		e.cancels.percent = e.cancels.amount / e.revenue.declared;
		e.hidden = false;
	});
	return synth.sort((x, y) => {
		const firstCharX = x.cashier?.charAt(0).toLowerCase() || 0;
		const firstCharY = y.cashier?.charAt(0).toLowerCase() || 0;
		return firstCharX > firstCharY ? 1 : firstCharX < firstCharY ? -1 : 0;
	});
});

export const range = createSelector(reducerState, (state) => ({
	from: state.from,
	to: state.to,
}));

function groupObjectsByEmpl(objectsArray: api.PosRecord[]): { [key: string]: api.PosRecord[] } {
	const result = {};

	objectsArray.forEach((object) => {
		const keyValue = object.employee.login;
		if (result[keyValue]) {
			result[keyValue].push(object);
		} else {
			result[keyValue] = [object];
		}
	});

	return result;
}

function calculatePosSummary(arr) {
	return arr.reduce(
		(summary, obj) => {
			obj.entries.forEach((entry: api.PosEntry) => {
				summary.revenue.declared += entry.amount.theoretical;
				summary.revenue.real += entry.amount.real;
			});

			summary.sessionCount += 1;
			summary.cancels.amount += obj.sessionDetail.cancellation;
			summary.refund += obj.sessionDetail.refund;
			summary.revenue.discount += obj.sessionDetail.discount;
			summary.withdrawn += obj.sessionDetail.withdrawn;

			return summary;
		},
		{
			sessionCount: 0,
			revenue: {
				declared: 0,
				real: 0,
				discount: 0,
			},
			cancels: {
				amount: 0,
				percent: 0,
				count: 0,
			},
			refund: 0,
			withdrawn: 0,
			corrections: {
				amount: 0,
				percent: 0,
				count: 0,
			},
		}
	);
}
