declare const process: any;

export class Shar3dUtils {
	/***************************************************************************
	 * Array manipulation
	 **************************************************************************/
	public static arrayToRecordByNumber<V>(a: V[], keyer: (v: V) => number): Record<number, V> {
		const r: Record<number, V> = {};
		a.forEach((v) => (r[keyer(v)] = v));
		return r;
	}

	public static arrayToRecordByString<V>(a: V[], keyer: (v: V) => string): Record<string, V> {
		const r: Record<string, V> = {};
		a.forEach((v) => (r[keyer(v)] = v));
		return r;
	}

	/***************************************************************************
	 * AngularJS compatibility
	 **************************************************************************/

	/**
	 * @ngdoc function
	 * @name angular.isUndefined
	 * @module ng
	 * @kind function
	 *
	 * @description
	 * Determines if a reference is undefined.
	 *
	 * @param {*} value Reference to check.
	 * @returns {boolean} True if `value` is undefined.
	 */
	public static isUndefined<T>(value: any): value is undefined {
		return typeof value === 'undefined';
	}

	public static isset<T>(v: T | null | undefined): v is T {
		return Shar3dUtils.isDefined(v) && v !== null;
	}

	/**
	 * @ngdoc function
	 * @name angular.isDefined
	 * @module ng
	 * @kind function
	 *
	 * @description
	 * Determines if a reference is defined.
	 *
	 * @param {*} value Reference to check.
	 * @returns {boolean} True if `value` is defined.
	 */
	public static isDefined<T>(value: T | null | undefined): value is T | null {
		return typeof value !== 'undefined';
	}

	/**
	 * @ngdoc function
	 * @name angular.isNumber
	 * @module ng
	 * @kind function
	 *
	 * @description
	 * Determines if a reference is a `Number`.
	 *
	 * This includes the "special" numbers `NaN`, `+Infinity` and `-Infinity`.
	 *
	 * If you wish to exclude these then you can use the native
	 * [`isFinite'](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/isFinite)
	 * method.
	 *
	 * @param {*} value Reference to check.
	 * @returns {boolean} True if `value` is a `Number`.
	 */
	public static isNumber(value: any): value is number {
		return typeof value === 'number';
	}

	/**
	 * Determines if a reference is a finite number.
	 */
	public static isFiniteNumber(value: any): value is number {
		return Shar3dUtils.isNumber(value) && isFinite(value);
	}

	/**
	 * Determines if a reference is an integer number.
	 */
	public static isIntegerNumber(value: any): value is number {
		return Shar3dUtils.isNumber(value) && isFinite(value) && Math.floor(value) === value;
	}

	/**
	 * @ngdoc function
	 * @name angular.isString
	 * @module ng
	 * @kind function
	 *
	 * @description
	 * Determines if a reference is a `String`.
	 *
	 * @param {*} value Reference to check.
	 * @returns {boolean} True if `value` is a `String`.
	 */
	public static isString(value: any): value is string {
		return typeof value === 'string';
	}

	/**
	 * @ngdoc function
	 * @name angular.isObject
	 * @module ng
	 * @kind function
	 *
	 * @description
	 * Determines if a reference is an `Object`. Unlike `typeof` in JavaScript, `null`s are not
	 * considered to be objects. Note that JavaScript arrays are objects.
	 *
	 * @param {*} value Reference to check.
	 * @returns {boolean} True if `value` is an `Object` but not `null`.
	 */
	public static isObject<T extends object>(value: any): value is T {
		// http://jsperf.com/isobject4
		return value !== null && typeof value === 'object';
	}

	/**
	 * @ngdoc function
	 * @name angular.isArray
	 * @module ng
	 * @kind function
	 *
	 * @description
	 * Determines if a reference is an `Array`.
	 *
	 * @param {*} value Reference to check.
	 * @returns {boolean} True if `value` is an `Array`.
	 */
	public static isArray<T>(arr: any): arr is T[] {
		return Array.isArray(arr) || arr instanceof Array;
	}

	/**
	 * Find the index of an element in an array
	 */
	public static findIndex<T>(source: T[], predicate: (t: T) => boolean): number {
		for (let index = 0; index < source.length; index++) {
			if (predicate(source[index])) {
				return index;
			}
		}
		return -1;
	}

	/***************************************************************************
	 * Flag utils
	 **************************************************************************/

	public static flagsToArray(mask: number, flagVals: number[]): number[] {
		const a: number[] = [];
		flagVals.forEach((val) => {
			if ((mask & val) > 0) {
				a.push(val);
			}
		});
		return a;
	}

	public static flagsFromArray(vals: number[], flagVals: number[], initialValue = 0): number {
		let a: number = initialValue;
		flagVals.forEach((val) => {
			if (Shar3dUtils.contains(vals, val)) {
				a |= val;
			}
		});
		return a;
	}

	/**
	 * Convert a flag to a mask
	 */
	public static flagToMask(flag: number): number {
		return 2 ** flag;
	}

	/**
	 * Test if a flag is set
	 */
	public static flagsTest(val: number, flagVal: number): boolean {
		return (val & flagVal) > 0;
	}

	/**
	 * Set a flag is doSet is true ( default value )
	 */
	public static flagsSet(val: number, flagVal: number, doSet = true): number {
		return doSet ? val | flagVal : val;
	}

	public static flagsUnset(val: number, flagVal: number): number {
		return val & ~flagVal;
	}

	/**
	 * convert a value to a mask value and test it
	 * @param value
	 * @param mask
	 */
	public static valueInMaskTest(value: number, mask: number): boolean {
		const v = Shar3dUtils.flagToMask(value);
		return (v & mask) > 0;
	}

	/**
	 * Utility function used in order to create a property reader
	 */
	public static createStringPropertyReader(pname: string): (o: object) => string | null {
		return (o: any): string | null => {
			return Shar3dUtils.readPropertyString(o, pname);
			//            //  Check for the object to be defined
			//            if (Shar3dUtils.isUndefined(o) || o === null ){
			//                return  null;
			//            }
			//            let a:any = o[pname];
			//            if (Shar3dUtils.isUndefined(a) || !Shar3dUtils.isString(a)){
			//                return  null;
			//            }
			//            return a;
		};
	}

	/**
	 * Read a property which should be a number
	 */
	public static readPropertyString(o: any, pname: string): string | null {
		//  Check for the object to be defined
		if (Shar3dUtils.isUndefined(o) || o === null) {
			return null;
		}
		const a: any = o[pname];
		if (Shar3dUtils.isUndefined(a) || !Shar3dUtils.isString(a)) {
			return null;
		}
		return a;
	}

	/**
	 * Read a property which should be a number
	 */
	public static readPropertyNumber(o: any, pname: string): number {
		//  Check for the object to be defined
		if (Shar3dUtils.isUndefined(o) || o === null) {
			return Number.NaN;
		}
		const a: any = o[pname];
		if (Shar3dUtils.isUndefined(a) || !Shar3dUtils.isNumber(a)) {
			return Number.NaN;
		}
		return a;
	}

	/***************************************************************************
	 * UnderscoreJS compatibility
	 **************************************************************************/

	/**
	 * Now function (returns ms)
	 */
	public static readonly now: () => number =
		Date.now ||
		function (): number {
			return new Date().getTime();
		};

	/*
	 * Returns a list of numbers from start (inclusive) to stop (exclusive)
	 */
	public static range(start: number, stop: number, step = 1): number[] {
		const range: number[] = [];
		for (let i = start; i < stop; i += step) {
			range.push(i);
		}
		return range;
	}

	/**
	 * Find the index of an element in an array
	 */
	public static indexOf<T>(source: T[] | null | undefined, el: T): number {
		return (source || []).indexOf(el);
	}

	/**
	 * Find an item
	 */
	public static find<T>(list: T[] | null | undefined, predicate: (t: T) => boolean): T | undefined {
		if (!list) {
			list = [];
		}
		for (let i = 0; i < list.length; i++) {
			if (predicate(list[i])) {
				return list[i];
			}
		}
		return undefined;
	}

	/**
	 * Filter some elements in the array
	 */
	public static filter<T>(source: T[] | null | undefined, predicate: (t: T) => boolean): T[] {
		const result: T[] = [];
		(source || []).forEach(function (t: T) {
			if (predicate(t)) {
				result.push(t);
			}
		});
		return result;
	}

	/**
	 * Replace an element in an array
	 * @param array list to work on
	 * @param elementToPlaceInArray function for creating the element to place in the array,
	 * t: the original element that valid the predicate, i: the index of the original element
	 * @param predicate Trust test iterator function for each element in `array`.
	 * @return True if the element was found and replace in the list, otherwise false.
	 */
	public static replaceInArray<T>(array: T[], elementToPlaceInArray: (t: T, i: number) => T, predicate: (t: T) => boolean): boolean {
		// Get the index of the element in the array
		const indexFound: number = Shar3dUtils.findIndex(array, predicate);
		// Check index
		if (indexFound < 0) {
			return false; // No element replaced
		}
		// The element was found, so replace it
		array.splice(indexFound, 1, elementToPlaceInArray(array[indexFound], indexFound));
		return true;
	}

	/**
	 * Flattens a nested array, the array will only be flattened a single level.
	 */
	public static flatten<T>(arrays: T[][]): T[] {
		return (arrays || []).reduce((a, b) => a.concat(b), []);
	}

	/**
	 * Return the union of several arrays ( exclude the duplicated )
	 */
	public static union2<T>(a: T[], b: T[]): T[] {
		const result: T[] = a.slice();
		b.forEach(function (t: T) {
			if (a.indexOf(t) < 0) {
				result.push(t);
			}
		});
		return result;
	}

	/**
	 * Union of multiple arrays
	 */
	public static union<T>(...arrays: Array<T>[]): T[] {
		const result: T[] = [];
		arrays.forEach((aa) => {
			aa.forEach((t) => {
				if (result.indexOf(t) < 0) {
					result.push(t);
				}
			});
		});
		return result;
	}

	/**
	 * Returns true if any of the values in the list pass the iterator truth test. Short-circuits and
	 * stops traversing the list if a true element is found. Delegates to the native method some, if present.
	 * @param list Truth test against all elements within this list.
	 * @param iterator Trust test iterator function for each element in `list`.
	 * @param context `this` object in `iterator`, optional.
	 * @return True if any elements passed the truth test, otherwise false.
	 **/
	public static some<T>(list: T[], iterator: (t: T) => boolean): boolean {
		for (let i = 0; i < list.length; i++) {
			if (iterator(list[i])) {
				return true;
			}
		}
		return false;
	}

	/**
	 * Produces a new array of values by mapping each value in list through a transformation function
	 * (iterator). If the native map method exists, it will be used instead. If list is a JavaScript
	 * object, iterator's arguments will be (value, key, object).
	 * @param list Maps the elements of this array.
	 * @param iterator Map iterator function for each element in `list`.
	 * @param context `this` object in `iterator`, optional.
	 * @return The mapped array result.
	 **/
	public static map<T, TResult>(list: T[], iterator: (t: T) => TResult): TResult[] {
		const a: TResult[] = [];
		list.forEach((t) => {
			a.push(iterator(t));
		});
		return a;
	}

	/**
	 * Convert a list into another list via a convert function
	 * Get ride of undefined values
	 */
	public static mapWithOnlyDefinedValues<T, TResult>(list: T[], iterator: (t: T) => TResult | undefined): TResult[] {
		return Shar3dUtils.filter<TResult | undefined>(
			Shar3dUtils.map<T, TResult | undefined>(list, (i) => iterator(i)), // 1) Convert into result items
			(resultItem: TResult | undefined): resultItem is TResult => Shar3dUtils.isDefined(resultItem) // 2) Get ride of undefined values
		) as TResult[];
	}

	/**
	 * Compute the intersection of two arrays
	 */
	public static intersection<T>(a: T[], b: T[]): T[] {
		const result: T[] = [];
		b.forEach(function (t: T) {
			if (a.indexOf(t) >= 0) {
				result.push(t);
			}
		});
		return result;
	}

	/**
	 * Compute the difference between two arrays
	 */
	public static difference<T>(a: T[], b: T[]): T[] {
		const result: T[] = [];
		a.forEach(function (t: T) {
			if (b.indexOf(t) < 0) {
				result.push(t);
			}
		});
		/*b.forEach(function(t: T) {
		 if (a.indexOf(t) < 0) {
		 result.push(t);
		 }
		 });*/
		return result;
	}

	/**
	 * Return the a array without elements in the b array
	 */
	public static without<T>(a: T[], b: T[] | T): T[] {
		const result: T[] = [];
		a.forEach(function (t: T) {
			if (Shar3dUtils.isArray(b)) {
				if (b.indexOf(t) < 0) {
					result.push(t);
				}
			} else {
				if (b !== t) {
					result.push(t);
				}
			}
		});
		return result;
	}

	/**
	 * Check for the array to contain an element
	 */
	public static contains<T>(a: T[] | null | undefined, b: T): boolean {
		return (a || []).indexOf(b) >= 0;
	}

	/**
	 * Return a shallow copy of an array order by a specific property
	 */
	public static sortBy<T, K extends keyof T>(a: T[], propertyOrFun: K | ((item: T) => string | number | boolean), order: 'asc' | 'desc' = 'asc'): T[] {
		const isAscOrder: boolean = order === 'asc';
		const isPropertyName: boolean = Shar3dUtils.isString(propertyOrFun);
		const getPropertyValue = (item: T): string | number | boolean => {
			return isPropertyName ? item[propertyOrFun as K] : (propertyOrFun as (item: T) => any)(item);
		};
		return Shar3dUtils.cloneArray(a || []).sort((a: T, b: T) => {
			const valueFromA = getPropertyValue(a);
			const valueFromB = getPropertyValue(b);
			if (valueFromA < valueFromB) {
				return isAscOrder ? -1 : 1;
			} else if (valueFromA > valueFromB) {
				return isAscOrder ? 1 : -1;
			} else {
				return 0;
			}
		});
	}

	/**
	 * Helper method to sort by multiple fields using multiple simple sortFuncs
	 */
	public static sortMultiple<T>(sourceArray: T[], ...sortFns: Array<(a: T, b: T) => number>): T[] {
		return sourceArray.sort((a: T, b: T) => {
			// apply each sort methods one by one
			let res = 0;
			for (const sortFn of sortFns) {
				res = sortFn(a, b);
				if (res != 0) return res;
			}

			return res;
		});
	}

	private static has(obj: any, path: string): boolean {
		return obj != null && Object.prototype.hasOwnProperty.call(obj, path);
	}

	public static keys(obj: any): string[] {
		if (!Shar3dUtils.isObject(obj)) return [];
		if (Object.keys) return Object.keys(obj);
		const keys: string[] = [];
		for (const key in obj) if (Shar3dUtils.has(obj, key)) keys.push(key);
		// Ahem, IE < 9.
		//if (hasEnumBug) collectNonEnumProps(obj, keys);
		return keys;
	}

	public static values<T>(obj: any | { [k: string]: T }): T[] {
		const keys = Shar3dUtils.keys(obj);
		const length = keys.length;
		const values = Array(length);
		for (let i = 0; i < length; i++) {
			values[i] = obj[keys[i]];
		}
		return values;
	}

	/**
	 * Clone an array
	 */
	public static cloneArray<T>(a: T[] | null | undefined): T[] {
		return (a || []).slice(0);
	}

	/**
	 * Randomize array element order in-place.
	 * Using Durstenfeld shuffle algorithm.
	 */
	public static shuffleArray<T>(array: T[]): void {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			const temp = array[i];
			array[i] = array[j];
			array[j] = temp;
		}
	}

	//        /**
	//     * Return the a array without elements in the b ar    ray
	//         */
	//    public static without<T>(a: T[], b: T): T[    ] {
	//        let result: T[] =     [];
	//        a.forEach(function (t: T    ) {
	//            if (b !== a    ) {
	//                result.push(    t);
	//                }
	//            });
	//        return resu    lt;
	//    }

	/***************************************************************************
	 * Previous version compatibility
	 **************************************************************************/

	public static getTrailingInteger(s: string): number {
		let i = 1;
		while (i < s.length) {
			const ss: string = s.substr(-1 * i);
			if (isNaN(parseInt(ss))) break;
			i++;
		}
		//  Fix
		i--;
		//  Check for found
		if (i <= 0) return 0;
		//  Parse and return
		return parseInt(s.substr(-1 * i));
	}

	/**
	 * Remove the /topic/ prefix from the string if it starts with it
	 */
	public static cleanupTopicPrefix(topic: string): string {
		//  Simple initial check, do not throw an error
		if (Shar3dUtils.isUndefined(topic) || topic === null) {
			return '';
		}
		const t = '/topic/';
		if (topic.substr(0, t.length) === t) {
			return topic.substr(t.length);
		} else {
			return topic;
		}
	}

	/**
	 * This filter is used in order to exclude private, underscored, "dollared" elements from JSON serialization
	 */
	public static JSONFilterPrivate<T>(key: string | number, value: T): T | undefined {
		//        if (angular.isUndefined(key))
		//            return undefined;
		//        if (angular.isUndefined(key.substr))
		//            return value;
		const start: string = key.toString().substr(0, 1);
		if (start === '$' || start === '_') return undefined;
		else return value;
	}

	/**
	 * This filter is used in order to exclude private elements from serialization
	 */
	public static JSONFilterNull<T>(key: string | number, value: T): T | undefined {
		if (Shar3dUtils.isUndefined(key)) return undefined;
		if (value === null) return undefined;
		else return value;
	}

	/**
	 * Cleanup the null values
	 */
	public static cleanupNullValue<T>(a: T): T {
		return JSON.parse(JSON.stringify(a, Shar3dUtils.JSONFilterNull));
	}

	/**
	 * Round to 5 digit
	 */
	public static round5(n: number): number {
		return Math.round(10000 * n) / 10000;
	}

	/**
	 * Round to 2 digit
	 */
	public static round2(n: number): number {
		return Math.round(100 * n) / 100;
	}

	/**
	 * Prepend a 0 if required
	 */
	public static prepend0(s: string, len = 2): string {
		const diff: number = len - s.length;
		if (diff <= 0) {
			return s;
		}
		return '0'.repeat(diff) + s;
	}

	/**
	 * Format a price
	 */
	public static formatPrice(n: number, separator = '.'): string {
		const positiveValue: number = Math.abs(n);
		const isNegativePrice: boolean = n < 0;
		const entier: number = Math.floor(positiveValue);
		const cents: number = Math.round((positiveValue - entier) * 100);
		return (isNegativePrice ? '-' : '') + entier.toString() + separator + Shar3dUtils.prepend0(cents.toString());
	}

	/** Encode an object to its urlencoded version  **/
	public static object2URLEncoded(obj: { [key: string]: null | undefined | string | number }): string {
		const a: string[] = [];
		for (const key in obj) {
			if (Shar3dUtils.isDefined(obj[key]) && obj[key] !== null && typeof obj[key] !== 'object') {
				a.push(Shar3dUtils.encodeURIComponent(key) + '=' + Shar3dUtils.encodeURIComponent(obj[key]!.toString()));
			}
		}
		return a.join('&');
	}

	/** opposite to object2URLEncoded - Decode string to object from its urlencoded version  **/
	public static URLEncoded2Object(input: string): { [key: string]: string | number } {
		if (!input) return {};
		const pairs = input.split('&');
		let result: { [key: string]: string | number } = {};
		pairs.forEach(pair => {
			if (pair) {
				const [key, value] = pair.split('=');
				result[decodeURIComponent(key)] = isNaN(Number(decodeURIComponent(value))) ? decodeURIComponent(value) : Number(decodeURIComponent(value));
			}
		});
		return result;
	}

	// convert the passed param object to URI query parameters and append them to the given uri (the uri could already contain params)
	public static appendAndComposeQueryParameters(baseuri: string, params: { [key: string]: string | number }): string {
		const queryParamsStr = Shar3dUtils.object2URLEncoded(params);
		return Shar3dUtils.appendQueryParameters(baseuri, queryParamsStr);
	}

	// append query params the given uri (the uri could already contain params)
	public static appendQueryParameters(baseuri: string, queryParamsStr: string): string {
		const prependChar = baseuri.indexOf('?') >= 0 ? '&' : '?';
		if (!queryParamsStr) return baseuri; // no params, return uri as is

		return baseuri + prependChar + Shar3dUtils.ltrim(queryParamsStr, prependChar);
	}

	public static appendQueryParameter(query: string, key: string, value: string): string {
		query += query ? '&' : '';
		query += Shar3dUtils.encodeURIComponent(key) + '=' + Shar3dUtils.encodeURIComponent(value);
		return query;
	}

	/**
	 * Join two or more web path together (appends slashes)
	 */
	public static joinWebPath(...parts: string[]): string {
		let fullpath = '';
		for (let i = 0; i < parts.length; i++) {
			fullpath += (i > 0 && parts[i] !== undefined && parts[i] != '/' ? (parts[i].startsWith('/') ? '' : '/') : '') + Shar3dUtils.rtrim(parts[i], '/');
		}

		return fullpath;
	}

	/**
	 * Wrapper for both browser and node
	 */
	public static encodeURIComponent(s: string): string {
		return encodeURIComponent ? encodeURIComponent(s) : Shar3dUtils.strictEncodeURIComponent(s);
	}

	/**
	 * Strict implementation for encoding
	 */
	public static strictEncodeURIComponent(s: string): string {
		//console.log("Using strict component");
		//return s.replace(/[!'()*]/g, x => `%${x.charCodeAt(0).toString(16).toUpperCase()}`);
		return s.replace(/[\W]/g, (x: string, args: any[]): string => {
			const n: number = x.codePointAt(0) || 0;
			//console.log("N is "+n);
			if (n <= 0x7f) {
				return `%${n.toString(16).toUpperCase()}`;
			} else if (n >= 0x80 && n <= 0x7ff) {
				const b0: number = (n & 0x3f) | 0x80;
				const b1: number = ((n >> 6) & 0x1f) | 0xc0;
				return `%${b1.toString(16).toUpperCase()}%${b0.toString(16).toUpperCase()}`;
			} else if (n >= 0x800 && n <= 0x0fff) {
				//  11100000 101xxxxx 10xxxxxx
				const b0: number = (n & 0x3f) | 0x80;
				const b1: number = ((n >> 6) & 0x1f) | 0xa0;
				const b2 = 0xe0;
				return `%${b2.toString(16).toUpperCase()}%${b1.toString(16).toUpperCase()}%${b0.toString(16).toUpperCase()}`;
			} else if (n >= 0x1000 && n <= 0x1fff) {
				//  11100001 10xxxxxx 10xxxxxx
				const b0: number = (n & 0x3f) | 0x80;
				const b1: number = ((n >> 6) & 0x3f) | 0x80;
				const b2 = 0xe1;
				return `%${b2.toString(16).toUpperCase()}%${b1.toString(16).toUpperCase()}%${b0.toString(16).toUpperCase()}`;
			} else if (n >= 0x2000 && n <= 0x3fff) {
				//  1110001x 10xxxxxx 10xxxxxx
				const b0: number = (n & 0x3f) | 0x80;
				const b1: number = ((n >> 6) & 0x3f) | 0x80;
				const b2: number = ((n >> 12) & 1) | 0xe2;
				return `%${b2.toString(16).toUpperCase()}%${b1.toString(16).toUpperCase()}%${b0.toString(16).toUpperCase()}`;
			} else if (n >= 0x4000 && n <= 0x7fff) {
				//  111001xx 10xxxxxx 10xxxxxx
				const b0: number = (n & 0x3f) | 0x80;
				const b1: number = ((n >> 6) & 0x3f) | 0x80;
				const b2: number = ((n >> 12) & 3) | 0xe4;
				return `%${b2.toString(16).toUpperCase()}%${b1.toString(16).toUpperCase()}%${b0.toString(16).toUpperCase()}`;
			} else if (n >= 0x8000 && n <= 0xbfff) {
				//  111010xx 10xxxxxx 10xxxxxx
				const b0: number = (n & 0x3f) | 0x80;
				const b1: number = ((n >> 6) & 0x3f) | 0x80;
				const b2: number = ((n >> 12) & 3) | 0xe8;
				return `%${b2.toString(16).toUpperCase()}%${b1.toString(16).toUpperCase()}%${b0.toString(16).toUpperCase()}`;
			} else if (n >= 0xc000 && n <= 0xcfff) {
				//  11101100 10xxxxxx 10xxxxxx
				const b0: number = (n & 0x3f) | 0x80;
				const b1: number = ((n >> 6) & 0x3f) | 0x80;
				const b2 = 0xec;
				return `%${b2.toString(16).toUpperCase()}%${b1.toString(16).toUpperCase()}%${b0.toString(16).toUpperCase()}`;
			} else if (n >= 0xd000 && n <= 0xd7ff) {
				//  11101101 100xxxxx 10xxxxxx
				const b0: number = (n & 0x3f) | 0x80;
				const b1: number = ((n >> 6) & 0x3f) | 0x80;
				const b2 = 0xed;
				return `%${b2.toString(16).toUpperCase()}%${b1.toString(16).toUpperCase()}%${b0.toString(16).toUpperCase()}`;
			} else if (n >= 0xe000 && n <= 0xffff) {
				//  1110111x 10xxxxxx 10xxxxxx
				const b0: number = (n & 0x3f) | 0x80;
				const b1: number = ((n >> 6) & 0x3f) | 0x80;
				const b2: number = ((n >> 12) & 1) | 0xee;
				return `%${b2.toString(16).toUpperCase()}%${b1.toString(16).toUpperCase()}%${b0.toString(16).toUpperCase()}`;
			} else {
				throw new Error('Unhandled code');
			}

			//            if( n < 256 ){
			//                return `%${n.toString(16).toUpperCase()}`;
			//            }else{
			//                let h:number = (n & 0xFF00)>>8;
			//                let l:number = n &0xFF;
			//                return `%${h.toString(16).toUpperCase()}%${l.toString(16).toUpperCase()}`;
			//            }
			//return null;//`%${x.charCodeAt(0).toString(16).toUpperCase()}`;
		});
	}

	/** Get some variables on the query string  **/
	public static getQueryVariable(variable: string): string | null {
		if (window.location.search === '') return null;
		const query = window.location.search.substring(1);
		const vars = query.split('&');
		for (let i = 0; i < vars.length; i++) {
			const pair = vars[i].split('=');
			if (pair[0] === variable) {
				return decodeURIComponent(pair[1]);
			}
		}
		return null;
	}

	/**
	 * Check for a string to be a query string
	 */
	public static isQueryString(query: string): boolean {
		return query.indexOf('=') > 0;
	}

	/**
	 * Get the query variables
	 */
	public static getQueryVariables(query: string): { [key: string]: string } {
		const args: { [key: string]: string } = {};
		const vars = query.split('&');
		for (let i = 0; i < vars.length; i++) {
			const pair = vars[i].split('=');
			if (pair.length !== 2) continue;
			args[pair[0]] = decodeURIComponent(pair[1]);
		}
		return args;
	}

	/**
	 * Get value from query string by key. Returns undefined if key not found.
	 */
	public static getQueryVariableByKey(query: string, key: string): string | undefined {
		const variables = Shar3dUtils.getQueryVariables(query);
		if(key in variables){
			return variables[key];
		}
		return undefined;
	}

	/**
	 * Simply return a resolved promise
	 */
	public static resolvedPromise<T>(value: T): Promise<T> {
		return new Promise((resolve, reject) => resolve(value));
	}

	/**
	 * Simply return a rejected promise
	 */
	public static rejectedPromise<T>(): Promise<T> {
		return new Promise<T>((resolve, reject) => reject());
	}

	/**
	 * Return True in a browser
	 */
	public static isBrowser(): boolean {
		return typeof window !== 'undefined' && typeof window.document !== 'undefined';
	}

	/**
	 * Return true in a node app
	 */
	public static isNode(): boolean {
		//return !Shar3dUtils.isBrowser();
		return typeof process !== 'undefined' && process.versions != null && process.versions.node != null;
	}

	//    public static getObjectValues<T>(o: { [key: string]: T }): Arr    ay<T> {
	//        let k: Array<string> = _.k    eys(o);
	//        let a: Array<T    > = [];
	//        for (let i = 0; i < k.lengt    h; i++)
	//            a.push(o[    k[i]]);
	//        re    turn a;
	//    }

	/***************************************************************************
	 * UUID generation with some encryption enhancment
	 **************************************************************************/

	/**
	 * Generate a UUID using the most efficient algo available
	 */
	public static generateUUID(): string {
		if (typeof window !== 'undefined' && typeof window.crypto !== 'undefined') {
			return Shar3dUtils.generateUUIDCrypto();
		} else {
			return Shar3dUtils.generateUUIDDefault();
		}
	}

	/**
	 * Generate a UUID
	 * @returns {String}
	 */
	public static generateUUIDDefault(): string {
		let d = new Date().getTime();
		const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			const r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
		});
		return uuid;
	}

	/**
	 * Generate the UUID using the crypto API if it exists
	 */
	public static generateUUIDCrypto(): string {
		// get sixteen unsigned 8 bit random values
		const u: Uint8Array = <Uint8Array>window.crypto.getRandomValues(new Uint8Array(16));
		// set the version bit to v4
		u[6] = (u[6] & 0x0f) | 0x40;
		// set the variant bit to "don't care" (yes, the RFC
		// calls it that)
		u[8] = (u[8] & 0xbf) | 0x80;
		// hex encode them and add the dashes
		let uid = '';
		uid += Shar3dUtils.intToHex(u[0]);
		uid += Shar3dUtils.intToHex(u[1]);
		uid += Shar3dUtils.intToHex(u[2]);
		uid += Shar3dUtils.intToHex(u[3]);
		uid += '-';

		uid += Shar3dUtils.intToHex(u[4]);
		uid += Shar3dUtils.intToHex(u[5]);
		uid += '-';

		uid += Shar3dUtils.intToHex(u[6]);
		uid += Shar3dUtils.intToHex(u[7]);
		uid += '-';

		uid += Shar3dUtils.intToHex(u[8]);
		uid += Shar3dUtils.intToHex(u[9]);
		uid += '-';

		uid += Shar3dUtils.intToHex(u[10]);
		uid += Shar3dUtils.intToHex(u[11]);
		uid += Shar3dUtils.intToHex(u[12]);
		uid += Shar3dUtils.intToHex(u[13]);
		uid += Shar3dUtils.intToHex(u[14]);
		uid += Shar3dUtils.intToHex(u[15]);

		return uid;
	}

	/**
	 * Converts an integer to its base16 counterpart, min and max symbols can be set
	 */
	public static intToHex(sourceN: number, minSymbols = 2, maxSymbols = 2) {
		// convert to hex (can be any length, we have not checked input)
		const str = sourceN.toString(16);

		return Shar3dUtils.prepend0(str, minSymbols) // prepend non-significant zeros
			.substring(0, maxSymbols); // crop the number if too high
	}

	/**
	 * Checks validity of input UUID (RFC4122) and optionally check its version.
	 *
	 * @param uuid      UUID string to check
	 * @param version   Version number that the UUID should match (possible values are 1 trough 5)*
	 *
	 * @throws Error if the requested version is not supported
	 */
	public static isUUID(uuid: string, version: number | null = null): boolean {
		if (version !== null && (version < 1 || version > 5)) {
			throw new Error('Unsupported UUID version v' + version);
		}

		return (
			uuid.length === 36 && // check length
			(version ? uuid.charAt(14) === String(version) : true) && // check version if provided
			/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid)
		); // check valid uuid
	}

	/***************************************************************************
	 * Base64 encoding/decoding - DEPRECATED - USE BASE64 CLASS IN GLOBAL-ENTITIES
	 **************************************************************************/

	/** Translation dictionary  **/
	public static readonly BASE64_DICT: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
	public static readonly BASE64_URL_DICT: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_='; // USAGE NOT FOUND ANYWHERE

	/**
	 * Convert a Base64 to the web
	 */
	public static convertBase64ToURL(s: string): string {
		return s.replace('+', '-').replace('/', '_');
	}

	/**
	 * Convert a Base64 from the web
	 */
	public static convertBase64FromURL(s: string): string {
		return s.replace('-', '+').replace('_', '/');
	}

	/**
	 * String utilities
	 */

	/**
	 * Strip whitespace - or other characters - from the beginning of a string
	 *
	 * Took from: https://github.com/sergejmueller/ltrim/blob/master/index.js
	 *
	 * @param  {String} str   Input string
	 * @param  {String} chars Character(s) to strip [optional]
	 * @return {String} str   Modified string
	 */
	public static ltrim(str: string, chars: string | null | undefined) {
		// Empty string?
		if (!str) {
			return '';
		}

		// Remove whitespace
		if (!chars) {
			return str.replace(/^\s+/, '');
		}

		// Convert to string
		chars = chars.toString();

		// Set vars
		let i = 0;
		const letters = str.split(''),
			count = letters.length;

		// Loop letters
		for (i; i < count; i++) {
			if (chars.indexOf(letters[i]) === -1) {
				return str.substring(i);
			}
		}

		return str;
	}

	/**
	 * Strip whitespace - or other characters - from the end of a string
	 *
	 * Took from: https://github.com/sergejmueller/rtrim/blob/master/index.js
	 *
	 * @param  {String} str   Input string
	 * @param  {String} chars Character(s) to strip [optional]
	 * @return {String} str   Modified string
	 */
	public static rtrim(str: string, chars: string | null | undefined): string {
		// Empty string?
		if (!str) {
			return '';
		}

		// Remove whitespace if chars arg is empty
		if (!chars) {
			return str.replace(/\s+$/, '');
		}

		// Convert to string
		chars = chars.toString();

		// Set vars
		const letters = str.split('');
		let i = letters.length - 1;

		// Loop letters
		for (i; i >= 0; i--) {
			if (chars.indexOf(letters[i]) === -1) {
				return str.substring(0, i + 1);
			}
		}

		return str;
	}

	/**
	 * Trigger a timeout and resolve once timeout finished
	 * @param ms
	 */
	public static wait(ms: number): Promise<void> {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	/**
	 * Safe execution of a promise
	 * @param p
	 * @param defaultResult
	 */
	public static async safe<T>(p: Promise<T>, defaultResult: T): Promise<T> {
		try {
			return await p;
		} catch (e) {
			return defaultResult;
		}
	}

	/**
	 * Convert string with special chars to simple [A-z0-9_] string
	 * (by default, replaces special chars with underscore (_))
	 */
	public static simpleString(str: string, replaceWith = '_'): string {
		return str.replace(/[^A-z0-9]/gi, replaceWith);
	}

	public static BUSINESS_DAY_START_MS = 6 * 60 * 60 * 1000; // 6 a.m

	/**
	 * Get the current businessday
	 *
	 * @param refDate timestamp (ms)
	 */
	public static getBusinessday(refDate?: number): number {
		refDate = refDate || Date.now();

		// get business date
		const d = new Date(refDate - Shar3dUtils.BUSINESS_DAY_START_MS);

		// convert to integer businessday format
		return (
			d.getFullYear() * 100 * 100 + // add year -> 20180000
			(d.getMonth() + 1) * 100 + // add month -> 20181100
			d.getDate()
		); // add day-of-month -> 20181122
	}

	public static businessdayToTime(refBusinessday: number): number {
		const year = parseInt(String(refBusinessday).substring(0, 4));
		const month = parseInt(String(refBusinessday).substr(4, 2));
		const day = parseInt(String(refBusinessday).substr(6, 2));
		const date = new Date(Shar3dUtils.BUSINESS_DAY_START_MS);
		date.setFullYear(year, month - 1, day);

		return date.getTime();
	}

	/*
	 * Get bday from year, month and day.
	 *
	 * year is fullyear, eg 2020
	 * month is 0-indexed 0 to 11
	 * day is 1-indexd, 1 to 31
	 */
	public static getBusinessdayYMD(year: number, month: number, day: number) {
		const d = new Date(Shar3dUtils.BUSINESS_DAY_START_MS);
		d.setFullYear(year, month, day);
		return Shar3dUtils.getBusinessday(d.getTime());
	}

	public static getClosestLocalMidnightTimestamp(timestamp: number): number {
		var date = new Date(timestamp);

		if (date.getHours() < 12) {
			date.setHours(0, 0, 0, 0);
		} else {
			date.setHours(24, 0, 0, 0);
		}
		return date.getTime();
	}
}

export default Shar3dUtils;
