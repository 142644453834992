import * as api from '@dki/api-client';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy } from 'lodash-es';

import { SafeDepositState, STORE_SLICE_KEY } from './state';

export const reducerState = createFeatureSelector<SafeDepositState>(STORE_SLICE_KEY);

export const safeRecords = createSelector(reducerState, (state) => state.data);
export const isLoading = createSelector(reducerState, (state) => state.isLoading);

export const safeRecordsSummary = createSelector(safeRecords, (records: Array<api.SafeRecord>) => {
	if (records && records.length) {
		const recordsByEmployee = groupBy(records, 'manager.login') as any;
		Object.keys(recordsByEmployee).forEach((manager) => {
			const current = recordsByEmployee[manager];
			const total = current.reduce((acc, obj) => {
				return obj.entries.reduce((entryAcc, entry) => {
					return entryAcc + entry.amount.real;
				}, acc);
			}, 0);
			recordsByEmployee[manager] = {
				total,
				...groupAndSumEntries(current),
			};
		});
		removeZeroProperties(recordsByEmployee);
		return recordsByEmployee;
	}
	return {};
});

export const range = createSelector(reducerState, (state) => ({
	from: state.from,
	to: state.to,
}));

function groupAndSumEntries(data) {
	return data.reduce((result, obj) => {
		obj.entries.reduce((acc, entry) => {
			const label = entry.label;
			const realAmount = entry.amount.real;
			const theoreticalAmount = entry.amount.theoretical;
			const gap = realAmount - theoreticalAmount;

			if (acc[label]) {
				acc[label].real += realAmount;
				acc[label].theoretical += theoreticalAmount;
				acc[label].gap += gap;
			} else {
				acc[label] = {
					real: realAmount,
					theoretical: theoreticalAmount,
					gap: gap,
				};
			}

			return acc;
		}, result);

		return result;
	}, {});
}

function removeZeroProperties(obj) {
	const values = Object.values(obj);
	const properties = Object.keys(values[0]);

	for (const prop of properties) {
		const isZero = values.every((value) => value[prop] && value[prop].real === 0);

		if (isZero) {
			for (const value of values) {
				delete value[prop];
			}
		}
	}
}
