import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { RouterModule } from '@angular/router';

import { PipesModule } from '../pipes/pipes.module';
import { ChartSuplementTableComponent } from './host-component/chart-suplement-table.component';

@NgModule({
	declarations: [ChartSuplementTableComponent],
	imports: [CommonModule, RouterModule, MatGridListModule, PipesModule],
	exports: [ChartSuplementTableComponent],
})
export class ChartSuplementTableModule {}
