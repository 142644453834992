import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';

@NgModule({
	declarations: [],
	imports: [],
	exports: [
		MatProgressSpinnerModule,
		MatDatepickerModule,
		MatInputModule,
		MatTableModule,
		MatButtonModule,
		MatChipsModule,
		MatExpansionModule,
	],
})
export class SharedMaterialModule {}
