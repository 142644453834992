import { HttpErrorResponse } from '@angular/common/http';
import * as api from '@dki/api-client';
import { createAction, props } from '@ngrx/store';
import { DateTime } from 'luxon';

import { STORE_SLICE_KEY } from './state';

export const getSessionHistory = createAction(`[ ${STORE_SLICE_KEY} / GET ] -> Session History`, props<{ from: DateTime; to: DateTime }>());
export const getSessionHistorySuccess = createAction(
	`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> Session History`,
	props<{
		data: Array<api.PosRecord>;
	}>()
);
export const getSessionHistoryFail = createAction(
	`[ ${STORE_SLICE_KEY} / GET FAIL ] -> Session History`,
	props<{
		error: HttpErrorResponse;
	}>()
);
