import { HttpErrorResponse } from '@angular/common/http';
import * as dkiApi from '@dki/api-client';
import * as gapApi from '@gap/api-client';
import { createAction, props } from '@ngrx/store';

import { authKey } from './state';

export const login = createAction(`[${authKey}] -> Login`, props<{ userName: string; password: string }>());

export const loginSuccess = createAction(`[${authKey}] -> Login Success`, props<{ accessToken: dkiApi.OAuth2AccessToken }>());

export const loginFail = createAction(`[${authKey}] -> Login Failure`, props<{ error: HttpErrorResponse | null }>());

export const logout = createAction(`[${authKey}] -> Logout`);

export const logoutSuccess = createAction(`[${authKey}] -> Logout Success`);

export const logoutFail = createAction(`[${authKey}] -> Logout Failure`, props<{ error: HttpErrorResponse | null }>());

export const requestPasswordRenewal = createAction(`[${authKey}] -> Request Password Renewal`, props<{ email: string }>());

export const requestPasswordRenewalSuccess = createAction(`[${authKey}] -> Request Password Renewal Success`);

export const requestPasswordRenewalFail = createAction(
	`[${authKey}] -> Request Password Renewal Failure`,
	props<{
		error: HttpErrorResponse | null;
	}>()
);

export const passwordSetup = createAction(`[${authKey}] -> Password Setup`, props<{ password: string }>());

export const passwordSetupSuccess = createAction(`[${authKey}] -> Password Setup Success`);

export const passwordSetupFail = createAction(`[${authKey}] -> Password Setup Failure`, props<{ error: HttpErrorResponse | null }>());

export const refreshSessionFromCookie = createAction(
	`[${authKey}] -> Refresh Session From Cookie`,
	props<{
		accessToken: string;
		url: string;
	}>()
);

export const refreshSessionFromCookieSuccess = createAction(
	`[${authKey}] -> Refresh Session From Cookie Success`,
	props<{
		userInfo: gapApi.UserInfo;
		url: string;
	}>()
);

export const refreshSessionFromCookieFail = createAction(
	`[${authKey}] -> Refresh Session From Cookie Failure`,
	props<{
		error: HttpErrorResponse | null;
	}>()
);

export const getCurrentUserProfile = createAction(`[${authKey}] -> Get User Profile`, props<{ accessToken: dkiApi.OAuth2AccessToken }>());

export const getCurrentUserProfileSuccess = createAction(
	`[${authKey}] -> Get User Profile Success`,
	props<{
		userInfo: gapApi.UserInfo;
	}>()
);

export const getCurrentUserProfileFail = createAction(
	`[${authKey}] -> Get User Profile Failure`,
	props<{
		error: HttpErrorResponse | null;
	}>()
);

export const getCurrentUserProfileSourced = createAction(`[${authKey}] -> Get User Profile while sourced`);
