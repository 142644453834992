import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SafeDepositEffects } from './effects';
import { safeDepositReducer } from './reducer';
import { STORE_SLICE_KEY } from './state';

@NgModule({
	declarations: [],
	imports: [CommonModule, StoreModule.forFeature(STORE_SLICE_KEY, safeDepositReducer), EffectsModule.forFeature([SafeDepositEffects])],
	providers: [SafeDepositEffects],
})
export class SafeDepositStoreModule {}
