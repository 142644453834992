@if (buttons?.negative) {
	<button id="dk-negative-action-button" mat-raised-button (click)="emitNegativeAction()">
		{{ buttons.negative.label }}
	</button>
}

@if (buttons?.positive) {
	@if (!buttons?.positive?.routerLink) {
		<button
			mat-raised-button
			type="submit"
			[disabled]="buttons.positive.disabled"
			(click)="emitPositiveAction()"
		>
			{{ buttons.positive.label }}
		</button>
	}
	@if (buttons?.positive?.routerLink) {
		<button
			mat-raised-button
			type="submit"
			[disabled]="buttons.positive.disabled"
			[routerLink]="buttons?.positive?.routerLink"
			queryParamsHandling="merge"
		>
			{{ buttons.positive.label }}
		</button>
	}
}
