<a class="dk-logo-link">
	<div class="logo"></div>
</a>

<button class="dk-aside-toggle" (click)="toggleAside()">
	<mat-icon [svgIcon]="'arrow'"></mat-icon>
</button>

@if (i18n$ | async; as i18n) {
	<ul class="dk-navlinks">
		@if (featureMap.sidebar.globalOverview) {
			<li class="dk-navlink">
				<a [routerLink]="['/' + ApplicationModuleSections.GLOBAL_OVERVIEW]" routerLinkActive="dk-active-link">
					<mat-icon [svgIcon]="'pie'"></mat-icon>
					<span class="dl-navlink-label"> {{ i18n.texts['global-overview'] }} </span>
				</a>
			</li>
		}
		@if (reportsFeatureEnabled && featureMap.sidebar.sales) {
			<li class="dk-navlink">
				<a [routerLink]="['/' + ApplicationModuleSections.SALES_REPORTS]" routerLinkActive="dk-active-link">
					<mat-icon [svgIcon]="'reports'"></mat-icon>
					<span class="dl-navlink-label"> {{ i18n.texts['reports'] }} </span>
				</a>
			</li>
		}
		@if (featureMap.sidebar.history) {
			<li class="dk-navlink">
				<a [routerLink]="['/' + ApplicationModuleSections.HISTORY_LOGS]" routerLinkActive="dk-active-link">
					<mat-icon [svgIcon]="'history'"></mat-icon>
					<span class="dl-navlink-label"> {{ i18n.texts['history-logs'] }} </span>
				</a>
			</li>
		}
		@if (reportsFeatureEnabled && featureMap.sidebar.dtt) {
			<li class="dk-navlink">
				<a [routerLink]="['/' + ApplicationModuleSections.DTT_EXPORT]" routerLinkActive="dk-active-link">
					<mat-icon [svgIcon]="'garage'" id="dtt"></mat-icon>
					<span class="dl-navlink-label"> {{ i18n.texts['reports'] }} </span>
				</a>
			</li>
		}
		@if (reportsFeatureEnabled && featureMap.sidebar.sos) {
			<li class="dk-navlink">
				<a [routerLink]="['/' + ApplicationModuleSections.TOS]" routerLinkActive="dk-active-link">
					<mat-icon id="sos">sos</mat-icon>
					<span class="dl-navlink-label"> {{ i18n.texts['reports'] }} </span>
				</a>
			</li>
		}
		@if (featureMap.sidebar.accounting && hasAccountingPermission$ | async) {
			<li class="dk-navlink">
				<a [routerLink]="['/' + ApplicationModuleSections.ACCOUNTING]" routerLinkActive="dk-active-link">
					<mat-icon [svgIcon]="'accounting'"></mat-icon>
					<span class="dl-navlink-label"> {{ i18n.texts['accounting'] }} </span>
				</a>
			</li>
		}
		@if (featureMap.sidebar.performance) {
			<li class="dk-navlink">
				<a [routerLink]="['/' + ApplicationModuleSections.PERFORMANCE]" routerLinkActive="dk-active-link">
					<mat-icon [svgIcon]="'performance'"></mat-icon>
					<span class="dl-navlink-label"> {{ i18n.texts['performance'] }} </span>
				</a>
			</li>
		}
		<li class="dk-navlink dk-settings-button">
			<a [routerLink]="['/' + ApplicationModuleSections.SETTINGS]" routerLinkActive="dk-active-link">
				<mat-icon [svgIcon]="'settings'"></mat-icon>
				<span class="dl-navlink-label"> {{ i18n.texts['settings'] }} </span>
			</a>
		</li>
		<li class="dk-navlink dk-logout-button" routerLinkActive="dk-active-link">
			<a [routerLink]="['/' + ApplicationModuleSections.ACCOUNT + '/logout']">
				<mat-icon [svgIcon]="'logout'"></mat-icon>
				<span class="dl-navlink-label"> {{ i18n.texts['logout'] }} </span>
			</a>
		</li>
		<li class="dk-version">
			<ui-manifest-app-version [showLabel]="expanded" [showTopBorder]="true"></ui-manifest-app-version>
		</li>
	</ul>
}
