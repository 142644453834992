<div class="dki-gauge-meter">
	<svg
		version="1.1"
		id="gauge-meter"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 400 400"
		style="enable-background: new 0 0 400 400"
		xml:space="preserve"
	>
		<path
			fill="#E9EDF2"
			d="M67.19,243.36c-3.96-12.94-6.1-26.69-6.1-40.93c0-77.11,62.51-139.61,139.61-139.61
   s139.61,62.51,139.61,139.61c0,14.58-2.24,28.63-6.38,41.84l46,15.14c6.3-18.87,9.71-39.06,9.71-60.05
   c0-104.75-84.92-189.67-189.67-189.67S10.3,94.61,10.3,199.36c0,21.08,3.44,41.35,9.79,60.29L67.19,243.36z"
		/>
		<path
			fill="#82B97C"
			d="M43.7,86.2l-5.71-3.46C14.09,115.7,0,156.2,0,200.02c0,21.5,3.39,42.21,9.67,61.62
   c0.05,0.16,0.11,0.33,0.16,0.49c2.04-0.73,4.4-1.58,6.88-2.48c-6.25-18.99-9.64-39.28-9.64-60.36
   C7.07,157.08,20.69,117.97,43.7,86.2z"
		/>
		<path fill="#EAB64D" d="M43.7,86.2c18.32-25.25,42.6-45.88,70.78-59.83l-3.24-5.57C82.07,35.26,56.95,56.61,37.99,82.74L43.7,86.2z"/>
		<path
			fill="#FD792F"
			d="M285.02,18.94C259.21,6.8,230.38,0,199.97,0c-31.87,0-61.98,7.46-88.72,20.72l-0.02,0.08l3.21,5.52
   c25.78-12.77,54.81-19.96,85.53-19.96c29.44,0,57.34,6.61,82.31,18.4L285.02,18.94z"
		/>
		<path
			fill="#E14C5E"
			d="M285.02,18.94L285.02,18.94l-2.74,5.81c65.39,30.89,110.65,97.43,110.65,174.55c0,21.01-3.37,41.23-9.58,60.16
   l6.89,2.41c6.33-19.48,9.76-40.26,9.76-61.85C400,119.96,352.96,50.9,285.02,18.94z"
		/>
	</svg>
</div>

<div class="dki-gauge-ponter" [style.transform]="pointerRotation">
	<svg
		version="1.1"
		id="gauge-ponter"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 400 400"
		style="enable-background: new 0 0 400 400"
		xml:space="preserve"
	>
		<path
			d="M200,185.58c-7.28,0-13.29,5.4-14.26,12.42H1v4h184.74c0.98,7.01,6.98,12.42,14.26,12.42c7.96,0,14.42-6.45,14.42-14.42
   S207.96,185.58,200,185.58z M200,213.5c-6.77,0-12.37-5-13.34-11.5h2.38c0.94,5.2,5.48,9.14,10.96,9.14
   c6.15,0,11.14-4.99,11.14-11.14s-4.99-11.14-11.14-11.14c-5.47,0-10.01,3.95-10.96,9.14h-2.38c0.97-6.5,6.56-11.5,13.34-11.5
   c7.46,0,13.5,6.04,13.5,13.5S207.46,213.5,200,213.5z"
		/>
	</svg>
</div>

<div class="dki-gauge-value">
	{{ value }} min.
</div>
