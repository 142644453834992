import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';

import { LanguageSwitcherComponent } from '../language-switcher/language-switcher.component';
import { PageHeaderComponent } from './host-component/page-header.component';

@NgModule({
	declarations: [PageHeaderComponent, LanguageSwitcherComponent],
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,
		FormsModule,
		FlexLayoutModule,
		MatButtonModule,
		MatIconModule,
		MatDatepickerModule,
		MatInputModule,
		MatNativeDateModule,
		MatSelectModule,
	],
	exports: [PageHeaderComponent],
})
export class PageHeaderModule {}
