import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApplicationModuleSections } from '@libs/dash/core/entity';
import { FeatureMapService } from '@libs/dash/guards';
import { AuthStoreSelectors, State } from '@libs/dash/store';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { hasAccessPermission } from '../../../../core/utils/functions';
import { PageContainerComponent } from '../../../page-container/host-component/page-container.component';

@Component({
	selector: 'dk-no-access',
	templateUrl: './no-access.component.html',
	styleUrls: ['./no-access.component.scss'],
})
export class NoAccessComponent implements AfterViewInit, OnDestroy {
	viewData$ = this.locale.selectTranslateObject('errors');
	userSubscription$: Subscription;
	user$ = this._store.pipe(select(AuthStoreSelectors.getCurrentUser));
	@ViewChild('page_container') containerComponent: PageContainerComponent;

	constructor(
		private route: ActivatedRoute,
		private locale: TranslocoService,
		private router: Router,
		private _store: Store<State>,
		private fm: FeatureMapService
	) {}

	ngAfterViewInit(): void {
		this.containerComponent.enableRestaurantSelection = false;
		this.userSubscription$ = this.user$.subscribe((user) => {
			if (user.is_admin || hasAccessPermission(user.permissions)) {
				// redirect out of this page
				this.router.navigate([this.fm.featureMap.homepage]);
			}
		});
	}

	logout() {
		this.router.navigate(['/' + ApplicationModuleSections.ACCOUNT + '/logout']);
	}

	ngOnDestroy(): void {
		this.userSubscription$.unsubscribe();
	}
}
