export enum LanguageKey {
	CZ = 'CZ',
	DE = 'DE',
	EN = 'EN',
	ES = 'ES',
	FR = 'FR',
	HU = 'HU',
	IT = 'IT',
	PL = 'PL',
	RU = 'RU',
	RM = 'RM',
	SK = 'SK',
	UK = 'UK',
}

export enum LanguageISOCode {
	CZ = 'cz',
	DE = 'de',
	EN = 'en',
	ES = 'es',
	FR = 'fr',
	HU = 'hu',
	IT = 'it',
	PL = 'pl',
	RU = 'ru',
	RM = 'rm',
	SK = 'sk',
	UK = 'uk',
}

export enum LanguageLabels {
	CZ = 'čeština',
	DE = 'Deutsch',
	EN = 'English',
	ES = 'Español',
	FR = 'française',
	HU = 'magyar',
	IT = 'Italiano',
	PL = 'polszczyzna',
	RU = 'русский',
	RM = 'Rumantsch',
	SK = 'Slovenščina',
	UK = 'Українська',
}

export enum RegionKey {
	AT = 'at',
	AU = 'au',
	BY = 'by',
	CZ = 'cz',
	DE = 'de',
	ES = 'es',
	FR = 'fr',
	HU = 'hu',
	IT = 'it',
	NZ = 'nz',
	PL = 'pl',
	RU = 'ru',
	RM = 'rm',
	SK = 'sk',
	UA = 'ua',
	UK = 'uk',
	US = 'us',
}

export enum i18nKey {
	TITLE = 'title',
	SUBTITLE = 'subtitle',
	CARDS = 'cards',
	ACTION_BUTTONS = 'action-buttons',
	BUTTONS = 'buttons',
	BUTTON_LABEL = 'buttonLabel',
	HEADER = 'header',
	FOOTER = 'footer',
	PUBLIC = 'public',
	ACCOUNT = 'account',
	LOGOUT = 'logout',
	LOGIN = 'login',
	HOME = 'home',
	FAQ = 'faq',
	TERMS_OF_USE = 'terms-of-use',
	CONTACT = 'contact',
	DOCUMENTATION = 'documentation',
	PROFILE = 'profile',
	PARTNERS_MANAGEMENT = 'partners-management',
	WELCOME = 'welcome',
	PAGE_NOT_FOUND = 'page-not-found',
	BACK_TO_LIST = 'back-to-list',
	SAVE_CHANGES = 'save-changes',
	ADD_NEW = 'add-new',
	ADD_APPLICATION = 'add-application',
	REISSUE_CREDENTIALS = 're-issue-credentials',
	DELETE_COMPANY = 'delete-company',
	DELETE_APPLICATION = 'delete-application',
	PARTNERS = 'partners',
}
