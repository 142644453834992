import { Component, Input } from '@angular/core';

@Component({
	selector: 'dk-gauge',
	templateUrl: './gauge.component.html',
	styleUrls: ['./gauge.component.scss'],
})
export class GaugeComponent {
	@Input() value = 0; // minutes

	public get pointerRotation(): string {
		return `rotate(${this.value * 33.333}deg)`;
	}
}
