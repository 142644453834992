import { Action, createReducer, on } from '@ngrx/store';

import { AuthStoreActions } from '../auth';
import * as MyRestaurantsActions from './actions';
import { MY_RESTAURANT_INITIAL_STATE, MyRestaurantsState } from './state';

export const myRestaurantsReducer = createReducer<MyRestaurantsState, Action>(
	MY_RESTAURANT_INITIAL_STATE,
	on(MyRestaurantsActions.getList, MyRestaurantsActions.updateMyDefaultRestaurant, MyRestaurantsActions.getLongTermOrders, (state) => ({
		...state,
	})),
	on(MyRestaurantsActions.getListSuccess, (state, action) => ({
		...state,
		listOfMyRestaurants: action.listOfMyRestaurants,
		isLoading: true,
	})),
	on(MyRestaurantsActions.getLongTermOrdersSuccess, (state, action) => ({
		...state,
		longTermOrders: action.longTermOrders,
		isLoading: false,
	})),
	on(AuthStoreActions.logoutSuccess, () => MY_RESTAURANT_INITIAL_STATE),
	// TO DO: UTILISE ERROR HANDLING
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	on(MyRestaurantsActions.getListFail, (state, { error }) => ({ ...state, isLoading: false })),
	on(MyRestaurantsActions.setRestaurant, (state, action) => {
		return {
			...state,
			mySelectedRestaurant: action.mySelectedRestaurant,
		};
	}),
	on(MyRestaurantsActions.setMultiRestaurants, (state, action) => {
		return {
			...state,
			mySelectedRestaurants: action.mySelectedRestaurants,
		};
	}),
	on(MyRestaurantsActions.enableMultiSelection, (state, action) => {
		return {
			...state,
			isMultiSelectionEnabled: action.enabler,
		};
	}),
	on(MyRestaurantsActions.getPersistedRestaurants, (state, action) => {
		return {
			...state,
			isLoading: false,
		};
	})
);
