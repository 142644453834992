@if (tableRowLabels$ | async; as tableRowLabels) {
	@if (bars && bars.length > 0) {
		@for (bar of bars; track bar; let index = $index) {
			<div class="dki-horizontal-bar">
				<div class="dki-horizontal-bar-label">
					{{ tableRowLabels[bar.label] }}
				</div>
				<div class="dki-horizontal-bar-value-wrapper">
					<div class="dki-horizontal-bar-value" [style.width]="barPercent(bar.percentage)"></div>
					<div class="dki-horizontal-bar-numeric-value" [style.width]="barWidth(bar.percentage)">
						{{ bar.percentage | number: '1.2-2' }}%
					</div>
				</div>
			</div>
		}
	} @else {
		<div class="dki-horizontal-bar-error-message">
			No data.
		</div>
	}
}
