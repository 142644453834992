import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MergedRouteReducerState } from './entity';
import { routerStateConfig } from './router-store.module';

export const getRouterReducerState = createFeatureSelector<MergedRouteReducerState>(routerStateConfig.stateKey);
export const getMergedRoute = createSelector(getRouterReducerState, (routerReducerState) =>
	routerReducerState ? routerReducerState.state : null
);
export const getParams = createSelector(getMergedRoute, (route) => (route ? route.params : null));
export const getCurrentLanguageParam = createSelector(getParams, (params) => (params ? params.language : null));
export const getUrl = createSelector(getMergedRoute, (route) => route.url.substring(0, route.url.indexOf('?')));
export const getQueryParams = createSelector(getMergedRoute, (route) => (route && route.queryParams ? route.queryParams : null));

export const getSelectedDashboardItemDetailQueryParams = createSelector(getQueryParams, (queryParams) => {
	if (queryParams && queryParams['data-source-name'] && queryParams['item-name'] && queryParams['time-range']) {
		return {
			['data-source-name']: queryParams['data-source-name'],
			['item-name']: queryParams['item-name'],
			['time-range']: queryParams['time-range'],
		};
	} else {
		return null;
	}
});

export const getSelectedDataSourceNameQueryParam = createSelector(getQueryParams, (queryParams) =>
	queryParams && queryParams['data-source-name'] ? queryParams['data-source-name'] : null
);

export const getSelectedItemNameQueryParam = createSelector(getQueryParams, (queryParams) =>
	queryParams && queryParams['item-name'] ? queryParams['item-name'] : null
);

export const getSetNewPasswordTokenQueryParam = createSelector(getQueryParams, (queryParams) =>
	queryParams && queryParams['token'] ? queryParams['token'] : null
);
