<div class="dk-hero-image"></div>

<div class="dk-account-form-wrapper">
	<div class="logo"></div>

	<ng-content></ng-content>
</div>

@if (isLoading) {
	<dk-progress-overlay></dk-progress-overlay>
}
