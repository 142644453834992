import { Component, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
	selector: 'dk-horizontal-bars',
	templateUrl: './horizontal-bars.component.html',
	styleUrls: ['./horizontal-bars.component.scss'],
})
export class HorizontalBarsComponent {
	@Input() bars: { label: string; percentage: number }[] = [];
	tableRowLabels$ = this._translocoService.selectTranslateObject('table-row-labels');

	constructor(private _translocoService: TranslocoService) {}

	barWidth(barPercentage: number): string {
		const percent = barPercentage.toFixed(2);
		return `calc(${percent}% - 24px`;
	}

	barPercent(barPercentage: number): string {
		return `${barPercentage}%`;
	}
}
