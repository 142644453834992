import { Period, PERIODS, IPeriodData, TimeStep, IAggregateData, ISOSItem, PERIODS_ORDER, IRestaurantData } from '@libs/dash/core/entity';

function initializePeriodData(period: Period, periodInfo: (typeof PERIODS)[Period]): IPeriodData {
	return {
		period,
		...periodInfo,
		count: 0,
		total_revenue: 0,
		[TimeStep.PreparationTime]: 0,
		[TimeStep.ServeTime]: 0,
		[TimeStep.DepartTime]: 0,
		mean_revenue: 0,
	};
}

function updatePeriodData(periodData: IPeriodData | IAggregateData, item: ISOSItem): void {
	periodData.count += item.count || 0;
	periodData.total_revenue += item.total_revenue || 0;
	periodData[TimeStep.PreparationTime] += (item.mean_sos_preparationtime || 0) * (item.count || 0);
	periodData[TimeStep.ServeTime] += (item.mean_sos_servetime || 0) * (item.count || 0);
}

function calculateAverages(data: IPeriodData | IAggregateData): void {
	if (data.count > 0) {
		if ('mean_revenue' in data) {
			data.mean_revenue = data.total_revenue / data.count;
		}
		data[TimeStep.PreparationTime] /= data.count;
		data[TimeStep.ServeTime] /= data.count;
	}
}

export function aggregateKioskSosData(data: ISOSItem[]): IPeriodData[] {
	const result: { [key in Period]?: IPeriodData } = {};
	const total: IAggregateData = {
		count: 0,
		total_revenue: 0,
		[TimeStep.PreparationTime]: 0,
		[TimeStep.ServeTime]: 0,
		[TimeStep.DepartTime]: 0,
	};

	data.forEach((item) => {
		const periodInfo = PERIODS[item.day_part];
		if (periodInfo) {
			if (!result[item.day_part]) {
				result[item.day_part] = initializePeriodData(item.day_part, periodInfo);
			}
			updatePeriodData(result[item.day_part]!, item);
			updatePeriodData(total, item);
		}
	});

	Object.values(result).forEach(calculateAverages);
	calculateAverages(total);

	return Object.values(result).sort((a, b) => PERIODS_ORDER.indexOf(a.period) - PERIODS_ORDER.indexOf(b.period));
}

export function aggregateKioskSosDataByRestaurant(data: ISOSItem[]): { [key: string]: IRestaurantData } {
	const result: { [key: string]: IRestaurantData } = {};

	data.forEach((item) => {
		const periodInfo = PERIODS[item.day_part];
		if (periodInfo) {
			const restaurantId = item.restaurant_id || 'unknown';
			if (!result[restaurantId]) {
				result[restaurantId] = {
					periods: Object.fromEntries(PERIODS_ORDER.map((period) => [period, initializePeriodData(period, PERIODS[period])])),
					total: {
						count: 0,
						total_revenue: 0,
						[TimeStep.PreparationTime]: 0,
						[TimeStep.ServeTime]: 0,
						[TimeStep.DepartTime]: 0,
					},
				};
			}
			updatePeriodData(result[restaurantId].periods[item.day_part]!, item);
			updatePeriodData(result[restaurantId].total, item);
		}
	});

	Object.values(result).forEach((restaurantData) => {
		Object.values(restaurantData.periods).forEach(calculateAverages);
		calculateAverages(restaurantData.total);
	});

	return result;
}
