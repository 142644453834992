import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

import { DkDataTableComponent } from './component/dk-data-table.component';

@NgModule({
	declarations: [DkDataTableComponent],
	imports: [CommonModule, RouterModule, MatButtonModule],
	exports: [DkDataTableComponent],
})
export class DkDataTableModule {}
