import { HttpErrorResponse } from '@angular/common/http';
import * as api from '@dki/api-client';
import { createAction, props } from '@ngrx/store';

import { ChannelOrders } from '../../core/entity/interfaces';
import { STORE_SLICE_KEY } from './state';

export const getOrdersByTime = createAction(`[ ${STORE_SLICE_KEY} / GET ] -> Orders By Time`, props<{ from: string; to: string }>());
export const getOrdersByTimeSuccess = createAction(
	`[${STORE_SLICE_KEY} / GET SUCCESS ] -> Orders By Time`,
	props<{
		ordersByTime: api.OrdersByTime[];
	}>()
);
export const getOrdersByTimeFail = createAction(
	`[${STORE_SLICE_KEY} / GET FAIL ] -> Orders By Time`,
	props<{
		error: HttpErrorResponse | null;
	}>()
);

export const getOrdersByProduct = createAction(`[${STORE_SLICE_KEY} / GET ] -> Orders By Product`, props<{ from: string; to: string }>());
export const getOrdersByProductSuccess = createAction(
	`[${STORE_SLICE_KEY} / GET SUCCESS ] -> Orders By Product`,
	props<{
		ordersByProduct: api.ProductOrders[];
	}>()
);
export const getOrdersByProductFail = createAction(
	`[${STORE_SLICE_KEY} / GET FAIL ] -> Orders By Product`,
	props<{
		error: HttpErrorResponse | null;
	}>()
);

export const getOrdersByChannel = createAction(`[${STORE_SLICE_KEY} / GET ] -> Orders By Channel`, props<{ from: string; to: string }>());
export const getOrdersByChannelSuccess = createAction(
	`[${STORE_SLICE_KEY} / GET SUCCESS ] -> Orders By Channel Success`,
	props<{
		ordersByChannel: ChannelOrders[];
	}>()
);
export const getOrdersByChannelFail = createAction(
	`[${STORE_SLICE_KEY} / GET FAIL ] -> Orders By Channel`,
	props<{
		error: HttpErrorResponse | null;
	}>()
);

export const getLast15minutesOrdersTotalCount = createAction(`[${STORE_SLICE_KEY} / GET ] -> Last 15 Minutes Orders Total Count`);
export const getLast15minutesOrdersTotalCountSuccess = createAction(
	`[${STORE_SLICE_KEY} / GET SUCCESS ] -> Last 15 Minutes Orders Total Count`,
	props<{ last15minutesOrdersTotalCount: number }>()
);
export const getLast15minutesOrdersTotalCountFail = createAction(
	`[${STORE_SLICE_KEY} / GET FAIL ] -> Last 15 Minutes Orders Total Count`,
	props<{ error: HttpErrorResponse | null }>()
);

export const getAverageOrdersAtCurrentTime = createAction(
	`[ ${STORE_SLICE_KEY} / GET ] -> Average Orders At Current Time`,
	props<{
		from: string;
		to: string;
	}>()
);
export const getAverageOrdersAtCurrentTimeSuccess = createAction(
	`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> Average Orders At Current Time`,
	props<{ averageOrdersAtCurrentTime: api.OrdersByTime[] }>()
);
export const getAverageOrdersAtCurrentTimelFail = createAction(
	`[ ${STORE_SLICE_KEY} / GET FAIL ] -> Average Orders At Current Time`,
	props<{
		error: HttpErrorResponse | null;
	}>()
);

export const getOrdersByChannelWithAverages = createAction(
	`[${STORE_SLICE_KEY} / GET ] -> Orders By Channel With Averages`,
	props<{
		from: string;
		to: string;
	}>()
);
export const getOrdersByChannelWithAveragesSuccess = createAction(
	`[${STORE_SLICE_KEY} / GET SUCCESS ] -> Orders By Channel With Averages`,
	props<{
		ordersByChannelWithAverages: api.ChannelOrdersWithAverages[];
	}>()
);
export const getOrdersByChannelWithAveragesFail = createAction(
	`[${STORE_SLICE_KEY} / GET FAIL ] -> Orders By Channel With Averages`,
	props<{
		error: HttpErrorResponse | null;
	}>()
);

export const getSos = createAction(`[${STORE_SLICE_KEY} / GET ] -> SOS`, props<{ from: string; to: string }>());
export const getSosSuccess = createAction(`[${STORE_SLICE_KEY} / GET SUCCESS ] -> SOS`, props<{ sos: any }>());
export const getSosFail = createAction(`[${STORE_SLICE_KEY} / GET FAIL ] -> SOS`, props<{ error: HttpErrorResponse | null }>());

export const getKioskSos = createAction(`[${STORE_SLICE_KEY} / GET ] -> Kiosk SOS`, props<{ from: string; to: string }>());
export const getKioskSosSuccess = createAction(`[${STORE_SLICE_KEY} / GET SUCCESS ] -> Kiosk SOS`, props<{ sos: any }>());
export const getKioskSosFail = createAction(`[${STORE_SLICE_KEY} / GET FAIL ] -> Kiosk SOS`, props<{ error: HttpErrorResponse | null }>());
