import { AvailableLangs } from '@ngneat/transloco/lib/types';

import { ApplicationModuleSections, LanguageISOCode, LanguageLabels, LocaleKey, RegionKey } from '../../enums';
import { Locale } from '../../interfaces';

export const DEFAULT_LOCALE_LANGUAGE_KEY = LanguageISOCode.FR;
export const DEFAULT_LOCALE_REGION_KEY = RegionKey.FR;

export const FALLBACK_LANGUAGE_KEY = LanguageISOCode.EN;

export const DEFAULT_LOCALE: Locale = {
	[LocaleKey.LANGUAGE]: DEFAULT_LOCALE_LANGUAGE_KEY,
	[LocaleKey.REGION]: DEFAULT_LOCALE_REGION_KEY,
};

export const DEFAULT_LOCALE_STRING = `${DEFAULT_LOCALE[LocaleKey.LANGUAGE]}-${DEFAULT_LOCALE[LocaleKey.REGION]}`;

export const DEFAULT_INITIAL_ROUTE = `${ApplicationModuleSections.ACCOUNT}/login`;

export const REDIRECT_URL = `redirect`;

export const AVAILABLE_LANGUAGES: AvailableLangs = [
	{ id: LanguageISOCode.FR, label: LanguageLabels.FR },
	{ id: LanguageISOCode.EN, label: LanguageLabels.EN },
];
