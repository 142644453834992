import { BKDeliveryModeEnum } from "@bk/jscommondatas";
import { SalesChannelOption } from "../enums";


export function mapDeliveryModeToSalesChannelOption(deliveryMode: BKDeliveryModeEnum): SalesChannelOption {
	switch (deliveryMode) {
		case BKDeliveryModeEnum.DELIVERY_LOCAL:
			return SalesChannelOption.EatIn;
		case BKDeliveryModeEnum.DELIVERY_TAKE_OVER:
			return SalesChannelOption.TakeAway;
		case BKDeliveryModeEnum.DELIVERY_BK:
			return SalesChannelOption.DeliveryBK;
		case BKDeliveryModeEnum.JUST_EAT:
			return SalesChannelOption.JustEat;
		case BKDeliveryModeEnum.UBER_EAT:
			return SalesChannelOption.UberEats;
		case BKDeliveryModeEnum.DELIVEROO:
			return SalesChannelOption.Deliveroo;
		case BKDeliveryModeEnum.ZOT:
			return SalesChannelOption.ZOT;
		case BKDeliveryModeEnum.LYVEAT:
			return SalesChannelOption.Lyveat;
		case BKDeliveryModeEnum.PLEASE:
			return SalesChannelOption.Please;
		default: {
			const errMessage = `mapDeliveryModeToSalesChannelOption(): Unexpected deliveryMode: ${deliveryMode}`;
			console.error(errMessage);
			throw new Error(errMessage);
		}
	}
}
