import * as api from '@dki/api-client';
import { ChannelOrders, IPeriodData, IRestaurantData } from '@libs/dash/core/entity';

export const STORE_SLICE_KEY = 'Todays Statistics';

export interface TodaysStatisticsState {
	data?: {
		ordersByTime: api.OrdersByTime[];
		ordersByProduct: api.ProductOrders[];
		ordersByChannel: ChannelOrders[];
		ordersByChannelWithAverages: api.ChannelOrdersWithAverages[];
		averageOrdersAtCurrentTime: api.OrdersByTime[];
		ordersByRestaurant: Record<string, api.OrdersByTime[]>;
		last15minutesOrdersTotalCount: number;
		sos: any;
		kioskSos: any;
		aggregatedKioskSos: IPeriodData[];
		aggregatedKioskSosByRestaurant: { [key: string]: IRestaurantData };
	};
	status: {
		isLoading: boolean;
	};
}

export const TODAYS_STATISTICS_INITIAL_STATE: TodaysStatisticsState = {
	data: {
		ordersByTime: [],
		ordersByProduct: [],
		ordersByChannel: [],
		ordersByChannelWithAverages: [],
		averageOrdersAtCurrentTime: [],
		ordersByRestaurant: {},
		last15minutesOrdersTotalCount: 0,
		sos: [],
		kioskSos: [],
		aggregatedKioskSos: [],
		aggregatedKioskSosByRestaurant: {},
	},
	status: {
		isLoading: true,
	},
};
