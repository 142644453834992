import * as api from '@dki/api-client';

import {
	openingHoursForSelectedDay,
	QUERY_PARAM_RESTO_UUID,
	QUERY_PARAM_RESTO_UUID_HYPHEN,
	timeRangeFromOpeningHours,
	weekday,
} from '@libs/dash/shared';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { DateTime } from 'luxon';
import { MyRestaurantsState, STORE_SLICE_KEY } from './state';

export const reducerState = createFeatureSelector<MyRestaurantsState>(STORE_SLICE_KEY);

export const getListOfMyRestaurants = createSelector(reducerState, (state: MyRestaurantsState) =>
	state ? state.listOfMyRestaurants : null
);

export const getIsLoading = createSelector(reducerState, (state: MyRestaurantsState) => state.isLoading);

export const getDefaultRestaurant = createSelector(
	reducerState,
	getListOfMyRestaurants,
	(state: MyRestaurantsState, getListOfMyRestaurants) => {
		return state.mySelectedRestaurant
			? state.mySelectedRestaurant
			: getListOfMyRestaurants && getListOfMyRestaurants.length > 0
				? getListOfMyRestaurants[0]
				: void 0;
	}
);

export const getSelectedRestaurants = createSelector(
	reducerState,
	getDefaultRestaurant,
	getListOfMyRestaurants,
	(state: MyRestaurantsState, restaurant) =>
		state.mySelectedRestaurants ? (state.mySelectedRestaurants.length > 0 ? state.mySelectedRestaurants : [restaurant]) : [restaurant]
);

export const getSelectedRestaurantsIds = createSelector(
	reducerState,
	getSelectedRestaurants,
	(state: MyRestaurantsState, selectedRestaurants) => {
		return selectedRestaurants.map((restaurant) => restaurant?.id);
	}
);

export const isMultiSelectionEnabled = createSelector(reducerState, (state: MyRestaurantsState) => state.isMultiSelectionEnabled);

export const getDefaultRestaurantId = createSelector(reducerState, (state: MyRestaurantsState) => {
	const defaultRestaurant = state.mySelectedRestaurant
		? state.mySelectedRestaurant
		: state.listOfMyRestaurants && state.listOfMyRestaurants.length > 0
			? state.listOfMyRestaurants[0]
			: null;
	return defaultRestaurant?.id;
});

export const todaysOpeningHours = createSelector(getDefaultRestaurant, (defaultRestaurant: api.Restaurant) => {
	const defaultOpeningHours: typeof openingHours = {
		from: {
			hour: 6,
			minute: 0,
			second: 0,
		},
		to: {
			hour: 23,
			minute: 59,
			second: 59,
		},
	};
	const nameOfWeekDay = weekday();
	const openingHours = openingHoursForSelectedDay(nameOfWeekDay, defaultRestaurant?.opening_times);
	if (openingHours && openingHours.from && openingHours.to) {
		return openingHours;
	} else {
		return defaultOpeningHours;
	}
});

export const restaurantIsOpened = createSelector(getDefaultRestaurant, (defaultRestaurant: api.Restaurant) => {
	if (defaultRestaurant?.opening_times) {
		const date = DateTime.local();
		const nameOfWeekDay = weekday();
		const openingHours = openingHoursForSelectedDay(nameOfWeekDay, defaultRestaurant.opening_times);
		const timeRange = timeRangeFromOpeningHours(openingHours);
		const openingHourDiffFromNow = date.offset - timeRange.from.diff(date, 'minutes').minutes;
		return openingHourDiffFromNow > 0;
	} else {
		return false;
	}
});

export const lastSixWeeksRegardingOpeningTimes = createSelector(todaysOpeningHours, () => {
	const date = DateTime.utc();
	const dayAgoFrom = date.minus({ weeks: 6 });
	const dayAgoTo = date.minus({ days: 1 });
	const normalizedDayAgoFrom = dayAgoFrom.toISO({ suppressMilliseconds: true });
	const normalizedDayAgoTo = dayAgoTo.toISO({ suppressMilliseconds: true });
	return { from: normalizedDayAgoFrom, to: normalizedDayAgoTo };
});

export const last15minutesRegardingOpeningTimes = createSelector(getDefaultRestaurant, () => {
	const now = DateTime.utc();
	const dayAgoFrom = now.minus({ minutes: 15 });
	const normalizedDayAgoFrom = dayAgoFrom.toISO({ suppressMilliseconds: true });
	const normalizedDayAgoTo = now.toISO({ suppressMilliseconds: true });
	return { from: normalizedDayAgoFrom, to: normalizedDayAgoTo };
});

export const defaultRestaurantTlines = createSelector(getDefaultRestaurant, (defaultRestaurant: api.Restaurant) => {
	return defaultRestaurant && defaultRestaurant.line_thresholds ? defaultRestaurant.line_thresholds : [1, 2];
});

export const longTermOrders = createSelector(reducerState, (state: MyRestaurantsState) =>
	state && state.longTermOrders ? state.longTermOrders : null
);

export const isLoadingPersistedRestaurant = createSelector(
	reducerState,
	getDefaultRestaurantId,
	(state: MyRestaurantsState, defaultId: string) => {
		const persistedId = localStorage.getItem('fav_restaurant');
		if (!persistedId) {
			return false;
		}
		if (window.location.href.includes(QUERY_PARAM_RESTO_UUID) || window.location.href.includes(QUERY_PARAM_RESTO_UUID_HYPHEN)) {
			const urlParams = new URLSearchParams(window.location.search);
			const id = urlParams.get(QUERY_PARAM_RESTO_UUID) || urlParams.get(QUERY_PARAM_RESTO_UUID_HYPHEN);
			return !(defaultId === id);
		}
		return !(defaultId === persistedId);
	}
);

export const isLoadingPersistedRestaurants = createSelector(getSelectedRestaurantsIds, (restaurantsID) => {
	const persistedIds = localStorage.getItem('fav_restaurants');
	if (!persistedIds) {
		return false;
	}
	return !isEqual(JSON.parse(persistedIds), restaurantsID);
});
