@if (viewData$ | async; as viewData) {
	<h4>{{ viewData.i18n.title }}</h4>
	<table>
		<thead>
		<tr>
			<th>{{ viewData.i18n.tableHeaderCells.daypart }}</th>
			<th>{{ viewData.i18n.tableHeaderCells.waittime }}</th>
			<th>{{ viewData.i18n.tableHeaderCells.ordertime }}</th>
			<th>{{ viewData.i18n.tableHeaderCells.linetime }}</th>
			<th>{{ viewData.i18n.tableHeaderCells.paytime }}</th>
			<!-- <th>{{ viewData.i18n.tableHeaderCells.parktime}}</th> -->
			<!-- <th>{{ viewData.i18n.tableHeaderCells.preparationtime }}</th> -->
			<th>{{ viewData.i18n.tableHeaderCells.servetime }}</th>
			<th>{{ viewData.i18n.tableHeaderCells.total }}</th>
		</tr>
		</thead>
		<tbody>
			@for (item of viewData.sos; track item) {
				<tr>
					<td>
						{{ viewData.i18n.daypartLabels[item.label] }}
					</td>
					@for (value of item.values; track value) {
						<td>
							{{ value }}
						</td>
					}
				</tr>
			}
		</tbody>
	</table>
} @else {
	<div class="dk-in-progress-spinner-container">
		<mat-spinner></mat-spinner>
	</div>
}

