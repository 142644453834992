@if (title) {
	<span>
    {{ title }}
  </span>
}

@if (editButton) {
	<button class="dk-logout-button" mat-icon-button>
		<mat-icon [svgIcon]="'pen'"></mat-icon>
	</button>
}


@if (localeText$ | async; as i18n) {
	<div class="flex-block">
		<ng-content></ng-content>
		@if (datePicker) {
			<div class="dk-date-picker-container">
				<mat-form-field appearance="fill" id="overview-picker">
					<input [formControl]="date" [matDatepicker]="picker" [max]="maxDate" matInput/>
					<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
					<mat-datepicker #picker>
						<mat-datepicker-actions>
							<button (click)="setDateToday();picker.close();" mat-button class="today-btn">{{ i18n.today }}</button>
							<button mat-button matDatepickerCancel>{{ i18n.cancel }}</button>
							<button mat-raised-button color="primary" matDatepickerApply>{{ i18n.apply }}</button>
						</mat-datepicker-actions>
					</mat-datepicker>
				</mat-form-field>
			</div>
		}
		<dk-language-switcher></dk-language-switcher>
	</div>
}

<!--<mat-form-field appearance='fill'>-->
<!--	<mat-select [(value)]='selectedLanguageCode'>-->
<!--		<mat-option *ngFor='let language of languages' [value]='language.id'>-->
<!--			{{language.label}}-->
<!--		</mat-option>-->
<!--	</mat-select>-->
<!--</mat-form-field>-->
