import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as api from '@dki/api-client';

import { QUERY_PARAM_RESTO_UUID } from '@libs/dash/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { formatDateISO, getRestoUuidFromMap } from '../../core/utils/functions';
import { AuthStoreActions } from '../auth';
import { RootStoreState } from '../state';
import * as MyRestaurantsActions from './actions';
import * as MyRestaurantsSelectors from './selectors';

@Injectable()
export class MyRestaurantsEffects {
	// TO DO:
	// REFACTOR APP INITIATION AND DATA RETRIEVAL
	getList = createEffect(() =>
		this._actions.pipe(
			ofType(MyRestaurantsActions.getList, AuthStoreActions.getCurrentUserProfileSuccess, AuthStoreActions.refreshSessionFromCookieSuccess),
			withLatestFrom(this.route.queryParams, this.route.paramMap),
			mergeMap(([_action, queryParams, queryMap]) => {
				let routeRestaurantUUID =
					getRestoUuidFromMap(queryParams) || getRestoUuidFromMap(queryMap) || sessionStorage.getItem(QUERY_PARAM_RESTO_UUID);
				if (_action && _action['url']) {
					// coming from refresh session on cookie & probably a redirect from 3rd party
					const restoUuidRegex = /resto-uuid=([^&]+)|resto_uuid=([^&]+)/;
					const matches = _action['url'].match(restoUuidRegex);
					routeRestaurantUUID = matches ? matches[1] || matches[2] : routeRestaurantUUID;
				}
				return this._myApiClient.myRestaurantsV1MyRestaurantsGet().pipe(
					map(
						(listOfMyRestaurants: api.Restaurant[]) => {
							if (routeRestaurantUUID) {
								return MyRestaurantsActions.getListSuccess({
									listOfMyRestaurants: listOfMyRestaurants.filter((res) => res.id === routeRestaurantUUID),
								});
							} else
								return MyRestaurantsActions.getListSuccess({
									listOfMyRestaurants,
								});
						},
						catchError((error: HttpErrorResponse) => of(MyRestaurantsActions.getListFail({ error })))
					)
				);
			})
		)
	);

	getPersistedRestaurants = createEffect(() =>
		this._actions.pipe(
			ofType(MyRestaurantsActions.getListSuccess),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getListOfMyRestaurants))),
			tap(([, listOfMyRestaurants]) => {
				const favoriteRestaurantId = localStorage.getItem('fav_restaurant');
				if (favoriteRestaurantId) {
					const parsedFavoriteRest = listOfMyRestaurants.filter((el) => el.id === favoriteRestaurantId)[0];
					if (parsedFavoriteRest) {
						this._store.dispatch(MyRestaurantsActions.setRestaurant({ mySelectedRestaurant: parsedFavoriteRest }));
					}
				}
				const favoriteRestaurants = JSON.parse(localStorage.getItem('fav_restaurants'));
				if (favoriteRestaurants) {
					const parsedFavoriteRestaurants = listOfMyRestaurants.filter((el) => favoriteRestaurants.includes(el.id));
					if (parsedFavoriteRestaurants && parsedFavoriteRestaurants.length > 0) {
						this._store.dispatch(MyRestaurantsActions.setMultiRestaurants({ mySelectedRestaurants: parsedFavoriteRestaurants }));
					}
				}
				if (listOfMyRestaurants.length < 1) {
					this.router.navigate(['/error']);
				}
			}),
			map(() => MyRestaurantsActions.getPersistedRestaurants())
		)
	);

	updateMyDefaultRestaurant = createEffect(() =>
		this._actions.pipe(
			ofType(MyRestaurantsActions.updateMyDefaultRestaurant),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			mergeMap(([action, defaultRestaurantId]) =>
				this._myApiClient.updateMyRestaurantV1MyRestaurantsRestaurantIdPut(defaultRestaurantId, action.restaurantSettings).pipe(
					map(
						() => {
							this._snackBar.open('Settings updated!', '', {
								duration: 2000,
							});

							return MyRestaurantsActions.getList();
						},
						catchError((error: HttpErrorResponse) =>
							of(
								MyRestaurantsActions.updateMyDefaultRestaurantFail({
									error,
								})
							)
						)
					)
				)
			)
		)
	);

	getLongTermOrders = createEffect(() =>
		this._actions.pipe(
			ofType(MyRestaurantsActions.getLongTermOrders),
			withLatestFrom(
				this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId)),
				this._store.pipe(select(MyRestaurantsSelectors.lastSixWeeksRegardingOpeningTimes))
			),
			mergeMap(([, defaultRestaurantId, lastSixWeeksRegardingOpeningTimes]) =>
				this._dataApiClient
					.averageOrdersByTimeV1DataAverageOrdersByTimeGet(
						defaultRestaurantId,
						false,
						null,
						null,
						formatDateISO(lastSixWeeksRegardingOpeningTimes.from),
						formatDateISO(lastSixWeeksRegardingOpeningTimes.to)
					)
					.pipe(
						map(
							(longTermOrders: api.OrdersByTime[]) => {
								return MyRestaurantsActions.getLongTermOrdersSuccess({
									longTermOrders,
								});
							},
							catchError((error: HttpErrorResponse) =>
								of(
									MyRestaurantsActions.getLongTermOrdersFail({
										error,
									})
								)
							)
						)
					)
			)
		)
	);

	constructor(
		private _actions: Actions<Action>,
		private _myApiClient: api.MyApiClient,
		private _restaurantsApiClient: api.RestaurantsApiClient,
		private _dataApiClient: api.DataApiClient,
		private _store: Store<RootStoreState>,
		private _snackBar: MatSnackBar,
		private route: ActivatedRoute,
		private router: Router
	) {}
}
