import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

import { RESTAURANT_SELECT_FACADE } from './facade/restaurant-select-facade.injection.token';
import { RestaurantSelectFacade } from './facade/restaurant-select-facade.service';
import { RestaurantSelectRoutingModule } from './restaurant-select-routing.module';
import { RestaurantSelectComponent } from './view-component/restaurant-select.component';

@NgModule({
	declarations: [RestaurantSelectComponent],
	imports: [CommonModule, RestaurantSelectRoutingModule, MatSelectModule, FormsModule, MatIconModule],
	exports: [RestaurantSelectComponent],
	providers: [
		{
			provide: RESTAURANT_SELECT_FACADE,
			useClass: RestaurantSelectFacade,
		},
	],
})
export class RestaurantSelectionModule {}
