import * as api from '@dki/api-client';

export const PERFORMANCE_KEY = 'Performance';

export interface PerformanceState {
	forecast: { [key: string]: api.BusinessDayProductsForecast };
	mod: Record<string, any>;
	hourlySales: api.HourlySales | Record<string, any>;
	pastHourlySales: api.HourlySales | Record<string, any>;
	loading: boolean;
}

export const PERFORMANCE_INITIAL_STATE: PerformanceState = {
	mod: {},
	forecast: {},
	hourlySales: {},
	pastHourlySales: {},
	loading: false,
};
