/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';

import { RestaurantOwner, RestaurantOwnerInput, RestaurantOwnerUpdateInput } from '../model/models';

import { BASE_PATH } from '../variables';


@Injectable()
export class OwnersApiClient {

	protected basePath = 'http://localhost';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	public encoder: HttpParameterCodec;

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (configuration) {
			this.configuration = configuration;
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (typeof basePath !== 'string') {
				basePath = this.basePath;
			}
			this.configuration.basePath = basePath;
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
	}


	private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
		if (typeof value === "object" && value instanceof Date === false) {
			httpParams = this.addToHttpParamsRecursive(httpParams, value);
		} else {
			httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
		}
		return httpParams;
	}

	private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
		if (value == null) {
			return httpParams;
		}

		if (typeof value === "object") {
			if (Array.isArray(value)) {
				(value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
			} else if (value instanceof Date) {
				if (key != null) {
					httpParams = httpParams.append(key,
						(value as Date).toISOString().substr(0, 10));
				} else {
					throw Error("key may not be null if value is Date");
				}
			} else {
				Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
					httpParams, value[k], key != null ? `${key}.${k}` : k));
			}
		} else if (key != null) {
			httpParams = httpParams.append(key, value);
		} else {
			throw Error("key may not be null if value is not object or array");
		}
		return httpParams;
	}

	/**
	 * Create Owner
	 * Create a new owner.
	 * @param restaurantOwnerInput
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public createOwnerV1OwnersPost(restaurantOwnerInput: RestaurantOwnerInput, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<RestaurantOwner>;
	public createOwnerV1OwnersPost(restaurantOwnerInput: RestaurantOwnerInput, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<RestaurantOwner>>;
	public createOwnerV1OwnersPost(restaurantOwnerInput: RestaurantOwnerInput, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<RestaurantOwner>>;
	public createOwnerV1OwnersPost(restaurantOwnerInput: RestaurantOwnerInput, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantOwnerInput === null || restaurantOwnerInput === undefined) {
			throw new Error('Required parameter restaurantOwnerInput was null or undefined when calling createOwnerV1OwnersPost.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.post<RestaurantOwner>(`${this.configuration.basePath}/v1/owners`,
			restaurantOwnerInput,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Delete Owners
	 * Delete owners.
	 * @param ownerId Ids of owners to be deleted.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public deleteOwnersV1OwnersDelete(ownerId: Array<number>, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<any>;
	public deleteOwnersV1OwnersDelete(ownerId: Array<number>, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<any>>;
	public deleteOwnersV1OwnersDelete(ownerId: Array<number>, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<any>>;
	public deleteOwnersV1OwnersDelete(ownerId: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (ownerId === null || ownerId === undefined) {
			throw new Error('Required parameter ownerId was null or undefined when calling deleteOwnersV1OwnersDelete.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (ownerId) {
			ownerId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'owner_id');
			})
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/owners`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Owner
	 * Get a single owner by id.
	 * @param ownerId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getOwnerV1OwnersOwnerIdGet(ownerId: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<RestaurantOwner>;
	public getOwnerV1OwnersOwnerIdGet(ownerId: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<RestaurantOwner>>;
	public getOwnerV1OwnersOwnerIdGet(ownerId: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<RestaurantOwner>>;
	public getOwnerV1OwnersOwnerIdGet(ownerId: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (ownerId === null || ownerId === undefined) {
			throw new Error('Required parameter ownerId was null or undefined when calling getOwnerV1OwnersOwnerIdGet.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<RestaurantOwner>(`${this.configuration.basePath}/v1/owners/${encodeURIComponent(String(ownerId))}`,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Owners
	 * Get a list of all available owners.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getOwnersV1OwnersGet(observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<RestaurantOwner>>;
	public getOwnersV1OwnersGet(observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<RestaurantOwner>>>;
	public getOwnersV1OwnersGet(observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<RestaurantOwner>>>;
	public getOwnersV1OwnersGet(observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<RestaurantOwner>>(`${this.configuration.basePath}/v1/owners`,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Update Owner
	 * Update owner.
	 * @param ownerId
	 * @param restaurantOwnerUpdateInput
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public updateOwnerV1OwnersOwnerIdPatch(ownerId: number, restaurantOwnerUpdateInput: RestaurantOwnerUpdateInput, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<RestaurantOwner>;
	public updateOwnerV1OwnersOwnerIdPatch(ownerId: number, restaurantOwnerUpdateInput: RestaurantOwnerUpdateInput, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<RestaurantOwner>>;
	public updateOwnerV1OwnersOwnerIdPatch(ownerId: number, restaurantOwnerUpdateInput: RestaurantOwnerUpdateInput, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<RestaurantOwner>>;
	public updateOwnerV1OwnersOwnerIdPatch(ownerId: number, restaurantOwnerUpdateInput: RestaurantOwnerUpdateInput, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (ownerId === null || ownerId === undefined) {
			throw new Error('Required parameter ownerId was null or undefined when calling updateOwnerV1OwnersOwnerIdPatch.');
		}
		if (restaurantOwnerUpdateInput === null || restaurantOwnerUpdateInput === undefined) {
			throw new Error('Required parameter restaurantOwnerUpdateInput was null or undefined when calling updateOwnerV1OwnersOwnerIdPatch.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.patch<RestaurantOwner>(`${this.configuration.basePath}/v1/owners/${encodeURIComponent(String(ownerId))}`,
			restaurantOwnerUpdateInput,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
