import { DateTime } from 'luxon';

// TO DO: PROVIDE INTERFACE
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function timeRangeFromOpeningHours(openingHoursForSelectedDay: any, date?: DateTime): any {
	const dateTime = date ? date : DateTime.utc();

	const from = dateTime.set({
		hour: openingHoursForSelectedDay.from.hour,
		minute: openingHoursForSelectedDay.from.minute,
		second: 0,
		millisecond: 0,
	});

	const to = dateTime.set({
		hour: openingHoursForSelectedDay.to.hour,
		minute: openingHoursForSelectedDay.to.minute,
		second: 0,
		millisecond: 0,
	});

	return { from, to };
}
