import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SosTileComponent } from './component/sos-tile.component';

@NgModule({
	declarations: [SosTileComponent],
	imports: [CommonModule, MatProgressSpinnerModule],
	exports: [SosTileComponent],
})
export class SosTileModule {}
