// This is called SalesChannel in RAP/DSS

export enum BKOrderSourceEnum {
	ORDER_SOURCE_CASH_MACHINE = 0,
	ORDER_SOURCE_KIOSK = 1,
	/**
	 * Special mode of CASH_MACHINE
	 */
	ORDER_SOURCE_DRIVE = 2,
	/**
	 * special mode of CASH_MACHINE
	 */
	ORDER_SOURCE_WALK = 3,
	ORDER_SOURCE_CLICK_AND_COLLECT = 4,
	/***
	 * This is probably UberEats
	 */
	ORDER_SOURCE_HOME_DELIVERY = 5,
	ORDER_SOURCE_THIRD_PARTY_DELIVERY = 6,
	ORDER_SOURCE_PMS = 7,
	//  For init purpose
	ORDER_SOURCE_UNKNOWN = -1,
}

export enum BKOrderSourceMask {
	ORDER_SOURCE_NONE = 0,
	ORDER_SOURCE_CASH_MACHINE = 2 ** BKOrderSourceEnum.ORDER_SOURCE_CASH_MACHINE,
	ORDER_SOURCE_KIOSK = 2 ** BKOrderSourceEnum.ORDER_SOURCE_KIOSK,
	ORDER_SOURCE_DRIVE = 2 ** BKOrderSourceEnum.ORDER_SOURCE_DRIVE,
	ORDER_SOURCE_WALK = 2 ** BKOrderSourceEnum.ORDER_SOURCE_WALK,
	ORDER_SOURCE_CLICK_AND_COLLECT = 2 ** BKOrderSourceEnum.ORDER_SOURCE_CLICK_AND_COLLECT,
	ORDER_SOURCE_HOME_DELIVERY = 2 ** BKOrderSourceEnum.ORDER_SOURCE_HOME_DELIVERY,
	ORDER_SOURCE_THIRD_PARTY_DELIVERY = 2 ** BKOrderSourceEnum.ORDER_SOURCE_THIRD_PARTY_DELIVERY,
}
