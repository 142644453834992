import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HistoricStatisticsEffects } from './effects';
import { historicStatisticsReducer } from './reducer';
import { STORE_SLICE_KEY } from './state';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(STORE_SLICE_KEY, historicStatisticsReducer),
		EffectsModule.forFeature([HistoricStatisticsEffects]),
	],
	providers: [HistoricStatisticsEffects],
})
export class HistoricStatisticsStoreModule {}
