import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TodaysStatisticsEffects } from './effects';
import { todaysStatisticsReducer } from './reducer';
import { STORE_SLICE_KEY } from './state';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(STORE_SLICE_KEY, todaysStatisticsReducer),
		EffectsModule.forFeature([TodaysStatisticsEffects]),
	],
	providers: [TodaysStatisticsEffects],
})
export class TodaysStatisticsStoreModule {}
