import * as api from '@dki/api-client';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';

import { PERFORMANCE_KEY, PerformanceState } from './state';

export const reducerState = createFeatureSelector<PerformanceState>(PERFORMANCE_KEY);
// export const reducerState = createFeatureSelector<PerformanceState>(PERFORMANCE_KEY);

export const isLoading = createSelector(reducerState, (state) => state.loading);
export const performanceForecast = createSelector(reducerState, (state) => state.forecast);
export const predictionPerTimeInterval = createSelector(performanceForecast, (forecast) => {
	const currentDay = DateTime.now().toFormat('yyyy-MM-dd');
	if (forecast[currentDay]) {
		const timedPredictions = forecast[currentDay].detail as Array<any>;
		const currentMin = parseInt(DateTime.now().toFormat('mm'));
		const currentHour = parseInt(DateTime.now().toFormat('HH'));
		const nearestMin = Math.floor(currentMin / 15) * 15;
		const currentTimeInterval = `${currentHour}:${nearestMin}`;
		return timedPredictions.filter((e) => e.datetime.includes(currentTimeInterval))[0];
	}
	return {};
});
export const predictedAccumulated = createSelector(performanceForecast, (forceast) => {
	const currentTimestamp = DateTime.now().toMillis();
	let totalPredictedAcc = 0;
	Object.values(forceast).forEach((dayForecast: api.BusinessDayProductsForecast) => {
		Object.values(dayForecast.detail).forEach((detail) => {
			// sum BKGameInOrderDataImpl;
			totalPredictedAcc += DateTime.fromISO(detail.datetime).toMillis() < currentTimestamp ? detail.revenue?.prediction || 0 : 0;
		});
	});
	return totalPredictedAcc;
});

export const hourlySales = createSelector(reducerState, (state) => state.hourlySales);
export const pastHourlySales = createSelector(reducerState, (state) => state.pastHourlySales);
export const currentRevenuePerTimeInterval = createSelector(hourlySales, (sales) => {
	const currentTimeSpan = getTimespan(false);
	if (!sales.by_time) {
		return {};
	}
	return sales.by_time[currentTimeSpan];
});

export const pastRevenuePerTimeInterval = createSelector(pastHourlySales, (sales) => {
	if (!sales.by_time) {
		return {};
	}
	const currentTimeSpan = getTimespan(true);
	return sales.by_time[currentTimeSpan];
});

export const currentTotalRevenue = createSelector(hourlySales, (sales) => {
	if (!sales.by_time) {
		return 0;
	}
	return Object.values(sales.by_time as { [key: string]: api.OrdersByChannelWithCumulativeValue }).reduce(
		(sum, salesPerTime: api.OrdersByChannelWithCumulativeValue) => sum + salesPerTime.total.value,
		0
	);
});

export const pastTotalRevenue = createSelector(pastHourlySales, (sales) => {
	return sales.total;
});

export const totalPredictedRevenue = createSelector(performanceForecast, (forecast) => {
	let revenue = 0;
	Object.values(forecast).forEach((day) => {
		revenue += day.detail.reduce((sum, detail) => sum + (detail.revenue?.prediction || 0), 0);
	});
	return revenue;
});

export const salesPerChannel = createSelector(hourlySales, (sales) => {
	if (!sales.by_time) {
		return null;
	}
	const channels = {};
	Object.entries(sales.total.by_channel as { [key: string]: api.OrdersWithCumulativeValue }).forEach((e) => {
		if (e[1].count) {
			channels[e[0]] = Math.round((e[1].count * 1000) / sales.total.total.count) / 10;
		}
	});
	const values = Object.values(channels) as Array<number>;
	// error correction, if sum is not 100, add the missing percent to the highest;
	const sum: number = values.reduce((acc: number, value: number) => acc + value, 0);
	const rest = Math.round((100 - sum) * 10) / 10;
	const maxIndex = values.indexOf(Math.max(...values));
	values[maxIndex] = Math.round(rest + values[maxIndex] * 10) / 10;
	return { channels: Object.keys(channels), values };
});

export const revenueByPrediction = createSelector(hourlySales, performanceForecast, (sales, forecast) => {
	if (!sales.by_time || !forecast) {
		return null;
	}
	const forecastData: {
		[key: string]: {
			prediction: number;
			revenue?: number;
		};
	} = {};
	const predictionTimes = [];
	Object.values(forecast).forEach((day) => {
		day.detail.forEach((detail) => {
			const label = DateTime.fromISO(detail.datetime).toFormat('HH:mm');
			predictionTimes.push(detail.datetime);
			forecastData[label] = { prediction: Math.round(detail.revenue?.prediction) || 0 };
		});
	});
	// get the index of last time span index in prediction times array
	let lastTimeSpanIndex = predictionTimes.indexOf(getTimespan(false));
	lastTimeSpanIndex = lastTimeSpanIndex < 0 ? predictionTimes.length : lastTimeSpanIndex;
	predictionTimes.forEach((time) => {
		const label = DateTime.fromISO(time).toFormat('HH:mm');
		forecastData[label].revenue = Math.round(sales.by_time[time]?.total?.value) || 0;
	});
	return {
		times: Object.keys(forecastData),
		// slice the revenue graph to match the current time
		revenue: Object.values(forecastData)
			.map((e) => e.revenue)
			.slice(0, lastTimeSpanIndex),
		prediction: Object.values(forecastData).map((e) => e.prediction),
	};
});

const getTimespan = (past: boolean) => {
	const currentMin = parseInt(DateTime.now().toFormat('mm'));
	const currentHour = parseInt(DateTime.now().toFormat('HH'));
	const nearestMin = Math.floor(currentMin / 15) * 15;
	const currentTimeSpan = DateTime.now()
		.set({
			hour: currentHour,
			minute: nearestMin,
			second: 0,
		})
		.minus({ year: past ? 1 : 0 })
		.toISO()
		.replace(/\.\d+/, '');
	return currentTimeSpan;
};
