enum LabelOrder {
	CB,
	ESPECES,
	ANCV,
	CRT,
	RENDU,
	'NON RENDU',
	TPE_MOBILE,
	DELIVEROO,
	UBER_EATS,
	JUST_EAT,
}

const getLabelOrder = (channel: string) => {
	return LabelOrder[channel] == undefined ? 100 : LabelOrder[channel];
};

export const sortLabels = (a, b) => {
	return getLabelOrder(a) - getLabelOrder(b);
};
