import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { AccountingReportApiClient } from './api/accounting-report-api-client.service';
import { AuthApiClient } from './api/auth-api-client.service';
import { DataApiClient } from './api/data-api-client.service';
import { ExportApiClient } from './api/export-api-client.service';
import { ForecastingApiClient } from './api/forecasting-api-client.service';
import { GAPApiClient } from './api/gap-api-client.service';
import { LegacyApiClient } from './api/legacy-api-client.service';
import { MonitoringApiClient } from './api/monitoring-api-client.service';
import { MyApiClient } from './api/my-api-client.service';
import { OwnersApiClient } from './api/owners-api-client.service';
import { PaymentMethodsApiClient } from './api/payment-methods-api-client.service';
import { PricesApiClient } from './api/prices-api-client.service';
import { ReportsApiClient } from './api/reports-api-client.service';
import { RestaurantsApiClient } from './api/restaurants-api-client.service';
import { UsersApiClient } from './api/users-api-client.service';
import { Configuration } from './configuration';

@NgModule({
	imports: [],
	declarations: [],
	exports: [],
	providers: [
		AccountingReportApiClient,
		AuthApiClient,
		DataApiClient,
		ExportApiClient,
		ForecastingApiClient,
		GAPApiClient,
		LegacyApiClient,
		MonitoringApiClient,
		MyApiClient,
		OwnersApiClient,
		PaymentMethodsApiClient,
		PricesApiClient,
		ReportsApiClient,
		RestaurantsApiClient,
		UsersApiClient]
})
export class ApiModule {
	public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
		return {
			ngModule: ApiModule,
			providers: [{provide: Configuration, useFactory: configurationFactory}]
		};
	}

	constructor(@Optional() @SkipSelf() parentModule: ApiModule,
							@Optional() http: HttpClient) {
		if (parentModule) {
			throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
		}
		if (!http) {
			throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
				'See also https://github.com/angular/angular/issues/20575');
		}
	}
}
