/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type PaymentMethodEnum =
	'Paper cash'
	| 'Bank checks'
	| 'CB PROXY'
	| 'CB SC'
	| 'Restaurant voucher'
	| 'Connecs APETIZ'
	| 'Connecs CHEQ DEJ'
	| 'Connecs EDENRED'
	| 'Connecs SODEXO'
	| 'ANCV'
	| 'Delivery partner PLEASE'
	| 'Delivery partner UBER EATS'
	| 'Delivery partner LYVEATS'
	| 'Delivery partner ZOT LIVRAISON'
	| 'Rendu'
	| 'Non rendu';

export const PaymentMethodEnum = {
	PaperCash: 'Paper cash' as PaymentMethodEnum,
	BankChecks: 'Bank checks' as PaymentMethodEnum,
	CbProxy: 'CB PROXY' as PaymentMethodEnum,
	CbSc: 'CB SC' as PaymentMethodEnum,
	RestaurantVoucher: 'Restaurant voucher' as PaymentMethodEnum,
	ConnecsApetiz: 'Connecs APETIZ' as PaymentMethodEnum,
	ConnecsCheqDej: 'Connecs CHEQ DEJ' as PaymentMethodEnum,
	ConnecsEdenred: 'Connecs EDENRED' as PaymentMethodEnum,
	ConnecsSodexo: 'Connecs SODEXO' as PaymentMethodEnum,
	Ancv: 'ANCV' as PaymentMethodEnum,
	DeliveryPartnerPlease: 'Delivery partner PLEASE' as PaymentMethodEnum,
	DeliveryPartnerUberEats: 'Delivery partner UBER EATS' as PaymentMethodEnum,
	DeliveryPartnerLyveats: 'Delivery partner LYVEATS' as PaymentMethodEnum,
	DeliveryPartnerZotLivraison: 'Delivery partner ZOT LIVRAISON' as PaymentMethodEnum,
	Rendu: 'Rendu' as PaymentMethodEnum,
	NonRendu: 'Non rendu' as PaymentMethodEnum
};

