import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap, take, withLatestFrom } from 'rxjs/operators';

import { getAccessToken } from '../../store/auth/selectors';
import { State } from '../../store/router/state';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
	constructor(
		private store: Store<State>,
		private route: ActivatedRoute
	) {}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.store.pipe(select(getAccessToken)).pipe(
			take(1),
			withLatestFrom(this.route.queryParams),
			mergeMap(([accessToken, queryParams]) => {
				const auth_token = queryParams.auth_token ? queryParams.auth_token : accessToken;
				const req = auth_token
					? request.clone({
							headers: request.headers.set('Authorization', 'Bearer ' + auth_token),
						})
					: request.clone();

				return next.handle(req).pipe(
					catchError((error: HttpErrorResponse) => {
						// if (error.status === HttpErrorResponseTypes.UNAUTHORIZED) {
						//   this.authService.redirectToAuthServerWithLogout();
						// }

						return throwError(error);
					})
				);
			})
		);
	}
}
