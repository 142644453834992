import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AccountingState, STORE_SLICE_KEY } from './state';

export const reducerState = createFeatureSelector<AccountingState>(STORE_SLICE_KEY);

export const accountingReport = createSelector(reducerState, (state: AccountingState) =>
	state && state.accountingReport ? state.accountingReport : {}
);

export const pendingReports = createSelector(reducerState, (state: AccountingState) =>
	state && state.pendingReports ? state.pendingReports : []
);

export const isLoading = createSelector(reducerState, (state: AccountingState) => state && state.isLoading);

export const hasError = createSelector(reducerState, (state: AccountingState) => state && state.error);
