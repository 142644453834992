import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stringToNumber' })
export class StringToNumberPipe implements PipeTransform {
	transform(inputString: string | number): string | number {
		const decimalNumberPattern = /^\d+\.\d{0,2}$/;

		if (typeof inputString === 'string') {
			if (decimalNumberPattern.test(inputString)) {
				const numberComposites = inputString.split('.');
				const decimals = numberComposites[1];

				inputString = /^0*$/.test(decimals) ? numberComposites[0] : inputString;
			}
		}

		return inputString;
	}
}
