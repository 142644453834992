import { HttpErrorResponse } from '@angular/common/http';
import * as api from '@dki/api-client';
import { createAction, props } from '@ngrx/store';
import { DateTime } from 'luxon';

import { STORE_SLICE_KEY } from './state';

export const getAccountingReport = createAction(`[ ${STORE_SLICE_KEY} / GET ] -> Accounting Report`, props<{ businessDate: DateTime }>());
export const getAccountingReportSuccess = createAction(
	`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> Accounting Report`,
	props<{
		accountingReport: api.AccountingReportResponse;
	}>()
);
export const getAccountingReportFail = createAction(
	`[ ${STORE_SLICE_KEY} / GET FAIL ] -> Accounting Report`,
	props<{
		error: HttpErrorResponse;
	}>()
);

export const getPendingReports = createAction(`[ ${STORE_SLICE_KEY} / GET ] -> Pending Accounting Report`);
export const getPendingReportsSuccess = createAction(
	`[ ${STORE_SLICE_KEY} / GET SUCCESS] -> Pending Accounting Report`,
	props<{
		pendingReports: Array<api.AccountingReport>;
	}>()
);
export const getPendingReportsFail = createAction(
	`[ ${STORE_SLICE_KEY} / GET FAIL] -> Pending Accounting Report`,
	props<{
		error: HttpErrorResponse;
	}>()
);

export const confirmReport = createAction(`[ ${STORE_SLICE_KEY} / GET ] -> Confirm Accounting Report`, props<{ businessDate: DateTime }>());
export const confirmReportSuccess = createAction(`[ ${STORE_SLICE_KEY} / GET SUCCESS] -> Confirm Accounting Report`);
export const confirmReportFail = createAction(
	`[ ${STORE_SLICE_KEY} / GET FAIL] -> Confirm Accounting Report`,
	props<{
		error: HttpErrorResponse;
	}>()
);

export const updateReport = createAction(
	`[ ${STORE_SLICE_KEY} / GET ] -> Update Accounting Report`,
	props<{
		businessDate: DateTime;
		values: { [key: string]: number };
	}>()
);
export const updateReportSuccess = createAction(
	`[ ${STORE_SLICE_KEY} / GET SUCCESS] -> Update Accounting Report`,
	props<{
		businessDate: DateTime;
	}>()
);
export const updateReportFail = createAction(
	`[ ${STORE_SLICE_KEY} / GET FAIL] -> Update Accounting Report`,
	props<{
		error: HttpErrorResponse;
	}>()
);
