import * as api from '@dki/api-client';

import { ChannelOrders } from '../../core/entity/interfaces';

export const STORE_SLICE_KEY = 'Historic Statistics';

export interface HistoricStatisticsState {
	data?: {
		ordersByTime: api.OrdersByTime[];
		ordersByProduct: api.ProductOrders[];
		ordersByChannel: ChannelOrders[];
		ordersByChannelWithAverages: api.ChannelOrdersWithAverages[];
		averageOrdersAtCurrentTime: api.OrdersByTime[];
		last15minutesOrdersTotalCount: number;
		sos: any;
		kioskSos: any;
	};
	status: {
		isLoading: boolean;
	};
}

export const HISTORIC_STATISTICS_INITIAL_STATE: HistoricStatisticsState = {
	data: {
		ordersByTime: [],
		ordersByProduct: [],
		ordersByChannel: [],
		ordersByChannelWithAverages: [],
		averageOrdersAtCurrentTime: [],
		last15minutesOrdersTotalCount: 0,
		sos: [],
		kioskSos: [],
	},
	status: {
		isLoading: true,
	},
};
