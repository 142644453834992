import { Action, createReducer, on } from '@ngrx/store';

import * as Actions from './actions';
import { PERFORMANCE_INITIAL_STATE, PerformanceState } from './state';

export const PerformanceReducer = createReducer<PerformanceState, Action>(
	PERFORMANCE_INITIAL_STATE,
	on(Actions.getForecasting, (state, _action) => ({ ...state, loading: true })),
	on(Actions.getForecastingFail, (state, _action) => ({
		...state,
		loading: false,
		error: _action.error,
	})),
	on(Actions.getForecastingSuccess, (state, action) => ({
		...state,
		forecast: action.forecast,
		loading: false,
		error: false,
	})),
	on(Actions.getOrdersByTimeAndChannel, (state, _action) => ({ ...state, loading: true })),
	on(Actions.getOrdersByTimeAndChannelFail, (state, _action) => ({
		...state,
		loading: false,
		error: _action.error,
	})),
	on(Actions.getOrdersByTimeAndChannelSuccess, (state, action) => ({
		...state,
		hourlySales: action.hourlySales,
		loading: false,
		error: false,
	})),
	on(Actions.getPasOrdersByTimeAndChannelSuccess, (state, action) => {
		return {
			...state,
			pastHourlySales: action.hourlySales,
			loading: false,
			error: false,
		};
	})
);
