import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MyRestaurantsEffects } from './effects';
import { myRestaurantsReducer } from './reducer';
import { STORE_SLICE_KEY } from './state';

@NgModule({
	declarations: [],
	imports: [CommonModule, StoreModule.forFeature(STORE_SLICE_KEY, myRestaurantsReducer), EffectsModule.forFeature([MyRestaurantsEffects])],
	providers: [MyRestaurantsEffects],
})
export class MyRestaurantsStoreModule {}
