import { Component, Input } from '@angular/core';

@Component({
	selector: 'dk-account-form-container',
	templateUrl: './account-form-container.component.html',
	styleUrls: ['./account-form-container.component.scss'],
})
export class AccountFormContainerComponent {
	@Input() isLoading: boolean;
}
