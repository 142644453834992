import * as api from '@dki/api-client';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

import { ReportsState, STORE_SLICE_KEY } from './state';

export const reducerState = createFeatureSelector<ReportsState>(STORE_SLICE_KEY);

export const isLoadingHourlySales = createSelector(reducerState, (state) => state.status.hourlySales?.isLoading);
export const isLoadingOperatingReport = createSelector(reducerState, (state) => state.status.operatingReport?.isLoading);
export const isLoadingWeeklyReport = createSelector(reducerState, (state) => state.status.weeklyReport?.isLoading);
export const isLoadingProductsReport = createSelector(reducerState, (state) => state.status.productsReport?.isLoading);
export const isLoadingEmployeeMeals = createSelector(reducerState, (state) => state.status.employeeMealsReport?.isLoading);
export const isLoadingDiscountsReport = createSelector(reducerState, (state) => state.status.discountsReport?.isLoading);
export const isLoadingDttReport = createSelector(reducerState, (state) => state.status.dttReport?.isLoading);
export const isLoadingFinancialMovements = createSelector(reducerState, (state) => state.status.financialMovements?.isLoading);

export const hourlySales = createSelector(reducerState, (state) => {
	const hourlySales = state.data?.hourlySales;
	const QUARTERS_COUNT_PER_DAY = 96;
	// if more than 1 day, fuse the report into 1 day format
	if (hourlySales?.by_time && Object.keys(hourlySales.by_time).length > QUARTERS_COUNT_PER_DAY) {
		const computedHourlySales = { by_time: {}, total: hourlySales.total };
		const times = Object.keys(hourlySales.by_time);
		const cumul = { by_channel: {}, total: 0 };
		for (let i = 0; i < QUARTERS_COUNT_PER_DAY; i++) {
			let day = 0;
			while (times[i + day * QUARTERS_COUNT_PER_DAY]) {
				const currentQuarter = computedHourlySales.by_time[times[i]];
				if (currentQuarter) {
					for (const [channel, channelData] of Object.entries(currentQuarter.by_channel)) {
						const channelDataNextDayPerQuarter = hourlySales.by_time[times[i + day * QUARTERS_COUNT_PER_DAY]].by_channel[channel];
						channelData['count'] += channelDataNextDayPerQuarter.count;
						channelData['value'] += channelDataNextDayPerQuarter.value;
						channelData['mean_value'] = channelData['value'] / channelData['count'];
						cumul.by_channel[channel] ??= 0;
						cumul.by_channel[channel] += channelData['value'];
						channelData['cumulative_value'] = cumul.by_channel[channel];
					}
					currentQuarter.total ??= {
						count: 0,
						value: 0,
						cumulative_value: 0,
						mean_value: 0,
					};
					currentQuarter.total.count += hourlySales.by_time[times[i + day * QUARTERS_COUNT_PER_DAY]].total.count;
					currentQuarter.total.value += hourlySales.by_time[times[i + day * QUARTERS_COUNT_PER_DAY]].total.value;
					cumul.total += currentQuarter.total.value;
					currentQuarter.total.cumulative_value = cumul.total;
					currentQuarter.total.mean_value = currentQuarter.total.value / currentQuarter.total.count;
					computedHourlySales.by_time[times[i]] = currentQuarter;
				} else {
					computedHourlySales.by_time[times[i]] = cloneDeep(hourlySales.by_time[times[i + day * QUARTERS_COUNT_PER_DAY]]);
				}
				day++;
			}
		}
		return computedHourlySales;
	}
	return hourlySales;
});
export const operatingReport = createSelector(reducerState, (state) => state.data?.operatingReport);
export const weeklyReport = createSelector(reducerState, (state) => state.data?.weeklyReport);
export const productsReport = createSelector(reducerState, (state) => state.data?.productsReport);
export const employeeMealsReport = createSelector(reducerState, (state) => state.data?.employeeMealsReport);
export const discountsReport = createSelector(reducerState, (state) => state.data?.discountsReport);
export const dttReport = createSelector(reducerState, (state) => state.data?.dttReport);
export const financialMovements = createSelector(reducerState, (state) => state.data?.financialMovements);
export const financialMovementsComputed = createSelector(financialMovements, (financialMovements) => {
	if (financialMovements) {
		const computedFinancialMovements = {
			entries: [],
			depenses: [],
			sorties: [],
		};
		financialMovements.forEach((tx) => {
			switch (tx.operation.type) {
				case api.SafeOperationType.Depense:
					computedFinancialMovements.depenses.push(tx);
					break;
				case api.SafeOperationType.Recette:
					computedFinancialMovements.entries.push(tx);
					break;
				default:
					// case api.SafeOperationType.Raz:
					computedFinancialMovements.sorties.push(tx);
					break;
			}
		});
		return computedFinancialMovements as {
			entries: api.SafeTransaction[];
			depenses: api.SafeTransaction[];
			sorties: api.SafeTransaction[];
		};
	}
	return null;
});

export const vouchersReport = createSelector(reducerState, (state) => state.data?.vouchersReport);

export const isLoadingVouchersReport = createSelector(reducerState, (state) => state.status.vouchersReport.isLoading);

export const weeklyReportParams = createSelector(reducerState, (state) => state.status?.weeklyReport?.weekNumber);
