// import statements

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PerformanceEffects } from './effects';
import { PerformanceReducer } from './reducer';
import { PERFORMANCE_KEY } from './state';

@NgModule({
	declarations: [],
	imports: [CommonModule, StoreModule.forFeature(PERFORMANCE_KEY, PerformanceReducer), EffectsModule.forFeature([PerformanceEffects])],
	providers: [PerformanceEffects],
})
export class PerformanceStoreModule {}
