import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { UiCommonModule } from '../manifest-app-version/manifest-app-version.module';

import { PageAsideNavigationComponent } from './host-component/page-aside-navigation.component';

@NgModule({
	declarations: [PageAsideNavigationComponent],
	imports: [CommonModule, RouterModule, UiCommonModule, FlexLayoutModule, MatButtonModule, MatIconModule],
	exports: [PageAsideNavigationComponent],
})
export class PageAsideNavigationModule {}
