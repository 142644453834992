import { IBKProductIngredientSelectedData } from './myBKCommonDatas';
import { BKIngredientAmountEnum, BKIngredientAmountTypeEnum } from './myBKCommonEnums';
import { ICsiMenuInOrder, ICsiProductProcessedIngredient } from './myBKSchemasCsi';

export enum ItemInOrderState {
	'ACTIVE',
	'DELETED_BEFORE_KITCHEN',
	'DELETED_AFTER_KITCHEN',
	'DELETED_AFTER_ORDER_SUB_TOTAL',
}

export class CsiResponses {
	/** No errors */
	public static get STATUS_OK(): number {
		return 2;
	}

	/** Error with description in response */
	public static get STATUS_ERREUR(): number {
		return 0;
	}

	/** The object does not exist */
	public static get STATUS_INEXISTANT(): number {
		return 3;
	}

	public static get STATUS_PAYMENT_CAPTURE_FAILED(): number {
		return 500;
	}

	/** The ticket is not yet sold out.
	 * Note: Alternate name: STATUS_TICKET_NO_BALANCE
	 */
	public static get STATUS_TICKET_NON_SOLDE(): number {
		return 1001;
	}

	/**
	 * The ticket is sold out without giving change.
	 * Note: Alternate name: STATUS_TICKET_BALANCE
	 */
	public static get STATUS_TICKET_SOLDE(): number {
		return 1002;
	}

	/** The ticket is sold out with change
	 * Note: I have no idea what that means ...
	 * Note: Alternate name: STATUS_TICKET_RENDERING
	 * */
	public static get STATUS_TICKET_RENDU(): number {
		return 1003;
	}

	/** A user is already logged in */
	public static get STATUS_USER_ALREADY_LOGGED(): number {
		return 2001;
	}

	/** Receipt creation impossible */
	public static get STATUS_MAKE_RECU_IMPOSSIBLE(): number {
		return 2002;
	}

	/** Impossible to print the receipt */
	public static get STATUS_PRINT_RECU_IMPOSSIBLE(): number {
		return 2003;
	}

	/** No logged in users */
	public static get STATUS_USER_ALREADY_UNLOGGED(): number {
		return 2004;
	}

	/** This is not a CB regulation  */
	public static get STATUS_NOT_CB_PAYMENT(): number {
		return 3001;
	}

	/** CB test not implemented */
	public static get STATUS_TEST_CB_UNAVAILABLE(): number {
		return 3002;
	}

	/** Login to CB terminal not possible */
	public static get STATUS_CB_LOGIN_IMPOSSIBLE(): number {
		return 3003;
	}

	/** Out-of-terminal configuration number */
	public static get STATUS_CB_CONFIGURATION_PROBLEM(): number {
		return 3004;
	}

	/** Object could not be decoded */
	public static get OBJECT_JSON_INVALIDE(): number {
		return 10004;
	}

	// Not sure if this is ever returned by CSI, it is not in their documentation
	public static get STATUS_WITHDRAW_INVALIDE(): number {
		return 10007;
	}

	/**
	 * Translate the result code for a login to an enum
	 */
	public static translateLoginResponseCode(code: number): CsiLoginResultEnum {
		switch (code) {
			case CsiResponses.STATUS_OK:
				return CsiLoginResultEnum.OK;
			case CsiResponses.STATUS_ERREUR:
				return CsiLoginResultEnum.ERROR;
			case CsiResponses.STATUS_USER_ALREADY_LOGGED:
				return CsiLoginResultEnum.ALREADY_LOGGED;
			case CsiResponses.STATUS_MAKE_RECU_IMPOSSIBLE:
				return CsiLoginResultEnum.MAKE_RECU_ERROR;
			case CsiResponses.STATUS_PRINT_RECU_IMPOSSIBLE:
				return CsiLoginResultEnum.PRINT_RECU_ERROR;
			default:
				return CsiLoginResultEnum.ERROR;
		}
	}
}

export class CsiState {
	public static get NONE(): string {
		return 'NONE';
	}

	public static get CANCELLATION(): string {
		return 'CANCELLATION';
	}
}

export class CsiUnit {
	public static get PIECE(): string {
		return 'PIECE'; /*  'Un';*/
	}

	public static get KG(): string {
		return 'KG'; /*'Kg';*/
	}
}

export class CsiLineTextFormatConstants {
	public static get STYLE_NORMAL(): string {
		return 'NORMAL';
	}

	public static get STYLE_BOLD(): string {
		return 'BOLD';
	}

	public static get STYLE_UNDERLINE(): string {
		return 'UNDERLINE';
	}

	public static get SIZE_NORMAL(): string {
		return 'NORMAL';
	}

	/** Double width */
	public static get SIZE_WX2(): string {
		return 'WX2';
	}

	/** Double height */
	public static get SIZE_HX2(): string {
		return 'HX2';
	}

	/** Double width and double height */
	public static get SIZE_WX2HX2(): string {
		return 'WX2HX2';
	}

	public static get SIZE_WX3HX3(): string {
		return 'WX3HX3';
	}

	public static get SIZE_WX5HX5(): string {
		return 'WX5HX5';
	}

	public static get ALIGN_LEFT(): string {
		return 'LEFT';
	}

	public static get ALIGN_RIGHT(): string {
		return 'RIGHT';
	}

	public static get ALIGN_CENTER(): string {
		return 'CENTER';
	}

	public static get SEPARATOR_NONE(): string {
		return 'NONE';
	}

	public static get DOCUMENT_ALL(): string {
		return 'ALL';
	}

	public static get DOCUMENT_TICKET(): string {
		return 'TICKET';
	}

	public static get DOCUMENT_ORDER(): string {
		return 'ORDER';
	}

	public static readonly DOCUMENT_EMPLOYEE_MEAL: string = 'recuemploye';
}

export interface ICsiLineTextFormat {
	text: string; //	Le texte à imprimer
	style?: string /*	String	Le style du texte :
	 -	normal
	 -	bold (gras)
	 -	underline (souligné)
	 Valeur par défaut : normal*/
	;
	size?: string /*	String	La taille du texte :
	 -	normal
	 -	wx2 (double largeur)
	 -	hx2 (double hauteur)
	 -	wx2hx2 (double largeur et double hauteur)
	 Valeur par défaut : normal*/
	;
	align?: string /*	String	L’alignement du texte :
	 -	left (gauche)
	 -	right (droite)
	 -	center (centré)
	 Valeur par défaut : left*/
	;
	separator?: string /*	String	Permet d’imprimer une ligne sur toute la largeur du ticket :
	 -	none (pas de ligne)
	 -	0
	 -	1
	 -	2
	 -	3
	 -	4
	 -	5
	 Valeur par défaut : none*/
	;
	lineBreakBefore?: number; //	Integer	Le nombre de saut de ligne avant le texte ou le séparateur (doit être compris entre 0 et 10)
	lineBreakAfter?: number; //	Integer	Le nombre de saut de ligne après le texte ou le séparateur (doit être compris entre 0 et 10)
	qrcode?: string; //	String	Le contenu du qr-code à imprimer (le qr-code sera toujours centré)
	document?: string /*	String	Permet de choisir sur quel type de document imprimer :
	 -	all
	 -	order
	 -	ticket*/
	;
	title?: string; //	String	Permet d’ajouter un titre avant le texte, ce titre sera automatiquement centré et en double largeur et double hauteur
	imageUrl?: string;
}

export class CsiBKPNs {
	public static get MODIFIER_ADD(): string {
		return '7714';
	}

	public static get MODIFIER_NO(): string {
		return '7715';
	}

	public static get MODIFIER_HEAVY(): string {
		return '7716';
	}

	public static get MODIFIER_LITE(): string {
		return '7717';
	}

	public static get MODIFIER_ONLY(): string {
		return '7718';
	}

	public static get MODIFIER_PLAIN(): string {
		return '7719';
	}

	public static get MODIFIER_HEAVY_ALL(): string {
		return '7720';
	}

	public static get MODIFIER_LIGHT_ALL(): string {
		return '7721';
	}

	public static get MISC_FOOD(): string {
		return '7414';
	}

	public static get BUNDLE_MEAL(): string {
		return '7591';
	}

	public static get COMBO_MEAL(): string {
		return '7592';
	}

	public static get KIDS_MEAL(): string {
		return '7600';
	}

	public static get NON_ACTIVE(): string {
		return '7880';
	}

	public static get CONDIMENT_TOPPING(): string {
		return '7884';
	}

	public static getMenuBKPN(mio: ICsiMenuInOrder /*BKMenuInOrder*/): string {
		if (mio._kidsMenu) return CsiBKPNs.KIDS_MEAL;
		return mio._isBundle ? CsiBKPNs.BUNDLE_MEAL : CsiBKPNs.COMBO_MEAL;
	}

	/**
	 * Get infos about a modifier
	 */
	public static getModifierInfos(
		ingredientData: IBKProductIngredientSelectedData,
		productIngredientData: ICsiProductProcessedIngredient /*BKProductProcessedIngredient*/
	): { label: string; bkpn: string; plumod: number } | null {
		//  First check for the Quantity
		if (ingredientData.qty <= 0) {
			return {
				label: 'No',
				bkpn: CsiBKPNs.MODIFIER_NO,
				plumod: 100
			};
		}
		//  Check for the mode
		if (productIngredientData._ingredient.amountType === BKIngredientAmountTypeEnum.NO_LIGHT_NORMAL_HEAVY) {
			if (ingredientData.amount === BKIngredientAmountEnum.LIGHT) {
				return {
					label: 'Lite',
					bkpn: CsiBKPNs.MODIFIER_LITE,
					plumod: 300
				};
			} else if (ingredientData.amount === BKIngredientAmountEnum.NORMAL) {
				return {
					label: 'Plain',
					bkpn: CsiBKPNs.MODIFIER_PLAIN,
					plumod: 0
				};
			} else if (ingredientData.amount === BKIngredientAmountEnum.HEAVY) {
				return {
					label: 'Heavy',
					bkpn: CsiBKPNs.MODIFIER_HEAVY,
					plumod: 400
				};
			} else {
				return null;
			}
		} else {
			//  Quantity should be modified
			if (ingredientData.qty === ingredientData.initQty) return null;
			//  Check for light
			if (ingredientData.qty < ingredientData.initQty)
				return {
					label: 'Lite',
					bkpn: CsiBKPNs.MODIFIER_LITE,
					plumod: 300
				};
			else
				return {
					label: 'Add',
					bkpn: CsiBKPNs.MODIFIER_ADD,
					plumod: 200
				};
		}
	}
}

export class CsiLines {
	public static get ARTICLE(): string {
		return 'ARTICLE';
	}

	public static get STATISTIQUES(): string {
		return 'STATISTIQUES';
	}

	public static get COMMENTAIRE(): string {
		return 'COMMENTAIRE';
	}

	public static get REGLEMENT(): string {
		return 'REGLEMENT';
	}

	public static get REMISE_TAUX(): string {
		return 'REMISE_TAUX';
	}

	public static get REMISE_MONTANT(): string {
		return 'REMISE_MONTANT';
	}

	public static get REPAS_EMPLOYE(): string {
		return 'REPAS_EMPLOYE';
	}

	public static get DATE(): string {
		return 'DATE';
	}

	public static get RENDU_MONNAIE(): string {
		return 'RENDU_MONNAIE';
	}

	public static get QR_CODE(): string {
		return 'QR_CODE';
	}

	public static get HEADER_TEXT(): string {
		return 'HEADER_TEXT';
	}

	public static get FOOTER_TEXT(): string {
		return 'FOOTER_TEXT';
	}

	public static get MIDDLE_TEXT(): string {
		return 'MIDDLE_TEXT';
	}

	public static get COUPON_TEXT(): string {
		return 'COUPON_TEXT';
	}

	public static get VALUEMEAL(): string {
		return 'VALUEMEAL';
	}

	public static get MODIFIER_QUALIFIER(): string {
		return 'MODIFIER_QUALIFIER';
	}

	public static get ARTICLE_DELETE_BEFORE_KITCHEN_SCREEN(): string {
		return 'ARTICLE_DELETE_BEFORE_KITCHEN_SCREEN';
	}

	public static get ARTICLE_DELETE_AFTER_KITCHEN_SCREEN(): string {
		return 'ARTICLE_DELETE_AFTER_KITCHEN_SCREEN';
	}

	public static get ARTICLE_DELETE_AFTER_ORDER_SUB_TOTAL(): string {
		return 'ARTICLE_DELETE_AFTER_ORDER_SUB_TOTAL';
	}

	public static get VALUEMEAL_DELETE_BEFORE_KITCHEN_SCREEN(): string {
		return 'VALUEMEAL_DELETE_BEFORE_KITCHEN_SCREEN';
	}

	public static get VALUEMEAL_DELETE_AFTER_KITCHEN_SCREEN(): string {
		return 'VALUEMEAL_DELETE_AFTER_KITCHEN_SCREEN';
	}

	public static get VALUEMEAL_DELETE_AFTER_ORDER_SUB_TOTAL(): string {
		return 'VALUEMEAL_DELETE_AFTER_ORDER_SUB_TOTAL';
	}

	public static get MODIFIER_QUALIFIER_DELETE_BEFORE_KITCHEN_SCREEN(): string {
		return 'MODIFIER_QUALIFIER_DELETE_BEFORE_KITCHEN_SCREEN';
	}

	public static get MODIFIER_QUALIFIER_DELETE_AFTER_KITCHEN_SCREEN(): string {
		return 'MODIFIER_QUALIFIER_DELETE_AFTER_KITCHEN_SCREEN';
	}

	public static get MODIFIER_QUALIFIER_DELETE_AFTER_ORDER_SUB_TOTAL(): string {
		return 'MODIFIER_QUALIFIER_DELETE_AFTER_ORDER_SUB_TOTAL';
	}

	public static get REMISE_DELETE_BEFORE_KITCHEN_SCREEN(): string {
		return 'REMISE_DELETE_BEFORE_KITCHEN_SCREEN';
	}

	public static get REMISE_DELETE_AFTER_KITCHEN_SCREEN(): string {
		return 'REMISE_DELETE_AFTER_KITCHEN_SCREEN';
	}

	public static get REMISE_DELETE_AFTER_ORDER_SUB_TOTAL(): string {
		return 'REMISE_DELETE_AFTER_ORDER_SUB_TOTAL';
	}
}

export class CsiDocument {
	public static get COMMANDE(): string {
		return 'COMMANDE';
	}

	public static get TICKET(): string {
		return 'TICKET';
	}

	public static get RECU_PRELEVEMENT(): string {
		return 'RECU_PRELEVEMENT';
	}

	public static get RECU_SESSION_OUVERTE(): string {
		return 'RECU_SESSION_OUVERTE';
	}

	public static get RECU_FIN_SESSION(): string {
		return 'RECU_FIN_SESSION';
	}

	public static get FACTURE(): string {
		return 'FACTURE';
	}

	public static get NOTE(): string {
		return 'NOTE';
	}

	public static get RECU_FIN_SESSION_DECLARE(): string {
		return 'RECU_FIN_SESSION_DECLARE';
	}

	public static get RECU_PRELEVEMENT_DECLARE(): string {
		return 'RECU_PRELEVEMENT_DECLARE';
	}

	public static get RECU_REPRISE_SESSION(): string {
		return 'RECU_REPRISE_SESSION';
	}

	public static get RECU_FIN_SESSION_ANNULEE(): string {
		return 'RECU_FIN_SESSION_ANNULEE';
	}
}

/**
 * Result of login
 */
export enum CsiLoginResultEnum {
	ERROR = 0,
	OK = 1,
	ALREADY_LOGGED = 2,
	MAKE_RECU_ERROR = 3,
	PRINT_RECU_ERROR = 4,
}

/**
 * Printer status
 */
export enum CsiPrinterStatusEnum {
	OK = 2,
	OFFLINE = 2001,
	NO_PAPER = 2002,
	COVER_OPENED = 2003,
	PAPER_NEAR_EMPTY = 2004,
	NO_STATUS = 2,
}

/**
 * Drawer status
 */
export enum CsiDrawerStatusEnum {
	OPENED = 2005,
	CLOSED = 2006,
	UNKNOWN = 2,
}

export enum CsiItemTypeEnum {
	UNKNOWN = 0,
	PRODUCT,
	MENU,
	FEE_WITH_FLEXIBLE_PRICE,
}

export enum CsiTagEnum {
	KDS_PRODUCTS_COUNT = 'kds_products_count',
	POSITION_IN_ORDER = 'position_in_order',
	PRODUCT_ID = 'product_id'
}


