import { IBKItemPriceInfo, IBKProductFamilyData } from "@bk/jscommondatas";
import { round5 } from "@merim/utils";

export type ItemProdFamily = {
	_productSubFamily: IBKProductFamilyData | null,
	_productFamily: IBKProductFamilyData | null
}

export function updatePriceWithVat(priceInfo: IBKItemPriceInfo, newVatPercent: number): IBKItemPriceInfo {
	const newVatAmount: number = round5((priceInfo.ttc / (100 + newVatPercent)) * newVatPercent);
	const updatedPriceInfo: IBKItemPriceInfo = {
		ttc: priceInfo.ttc,
		pc: newVatPercent,
		tva: newVatAmount,
		ht: priceInfo.ttc - newVatAmount
	};
	return updatedPriceInfo;
}

export function isItemIncludedInVatAlignment(item: ItemProdFamily): boolean {
	const itemFamilyGroup: IBKProductFamilyData | null = item?._productSubFamily || item?._productFamily;
	return !!itemFamilyGroup?.includeInVatAlignment;
}

export function isAlignedVatUsable(item: ItemProdFamily, alignedVat: number, defaultVat: number): boolean {
	if (!alignedVat) {
		return false;
	}
	return isItemIncludedInVatAlignment(item) && alignedVat > 0 && alignedVat !== defaultVat;
}
