@if (showLabel) {
	<span>
    version:
  </span>
	<span>
    &nbsp;
  </span>
}

<span>
  {{ $currentAppVesrion | async }}
</span>
