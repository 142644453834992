import { Action, createReducer, on } from '@ngrx/store';

import { AuthStoreActions } from '../auth';
import * as Actions from './actions';
import { HISTORIC_STATISTICS_INITIAL_STATE, HistoricStatisticsState } from './state';

export const historicStatisticsReducer = createReducer<HistoricStatisticsState, Action>(
	HISTORIC_STATISTICS_INITIAL_STATE,
	on(
		Actions.getOrdersByTime,
		Actions.getOrdersByProduct,
		Actions.getOrdersByChannelWithAverages,
		Actions.getOrdersByChannel,
		Actions.getOrdersByChannelWithAverages,
		Actions.getSos,
		Actions.getKioskSos,
		(state) => ({
			...state,
			status: { isLoading: true },
		})
	),
	on(Actions.getOrdersByTimeSuccess, (state, action) => {
		return {
			...state,
			data: {
				...state.data,
				ordersByTime: action.ordersByTime,
			},
			status: { isLoading: false },
		};
	}),
	on(Actions.getAverageOrdersAtCurrentTimeSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			averageOrdersAtCurrentTime: action.averageOrdersAtCurrentTime,
		},
		status: { isLoading: false },
	})),
	on(Actions.getOrdersByProductSuccess, (state, action) => {
		return {
			...state,
			data: {
				...state.data,
				ordersByProduct: action.ordersByProduct,
			},
			status: { isLoading: false },
		};
	}),
	on(Actions.getOrdersByChannelSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			ordersByChannel: action.ordersByChannel,
		},
		status: { isLoading: false },
	})),
	on(Actions.getOrdersByChannelWithAveragesSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			ordersByChannelWithAverages: action.ordersByChannelWithAverages,
		},
		status: { isLoading: false },
	})),
	on(Actions.getSosSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			sos: action.sos,
		},
		status: { isLoading: false },
	})),
	on(Actions.getKioskSosSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			kioskSos: action.sos,
		},
		status: { isLoading: false },
	})),
	on(AuthStoreActions.logout, () => HISTORIC_STATISTICS_INITIAL_STATE)
);
