import { DatePeriod, Period, TimeStep, ValueType } from '../../enums';

export const PERIODS_ORDER: Period[] = [
	Period.Morning,
	Period.Lunch,
	Period.Afternoon,
	Period.Dinner,
	Period.Evening,
	Period.Night,
	Period.PeakNight,
];

export const THRESHOLD_TOTAL = 120;
export const THRESHOLD_INDIVIDUAL = 355;

export const PERIODS: Record<Period, { start: number; end: number }> = {
	[Period.Morning]: { start: 7, end: 12 },
	[Period.Lunch]: { start: 12, end: 14 },
	[Period.Afternoon]: { start: 14, end: 19 },
	[Period.Dinner]: { start: 19, end: 21 },
	[Period.Evening]: { start: 21, end: 23 },
	[Period.Night]: { start: 23, end: 4 },
	[Period.PeakNight]: { start: 4, end: 7 },
};

export const SERVICE_OBJECTIVES: Record<ValueType, { timeStep: TimeStep; objective: number; threshold: number }> = {
	[ValueType.Preparation]: { timeStep: TimeStep.PreparationTime, objective: 1, threshold: THRESHOLD_INDIVIDUAL },
	[ValueType.Depart]: { timeStep: TimeStep.ServeTime, objective: 1, threshold: 60 },
	[ValueType.Total]: { timeStep: TimeStep.Total, objective: 2, threshold: 120 },
};

export const DATE_PERIODS_ORDER: DatePeriod[] = [DatePeriod.Today, DatePeriod.Week, DatePeriod.Month, DatePeriod.Year];
