import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VerticalTresholdBarComponent } from './component/vertical-treshold-bar.component';

@NgModule({
	declarations: [VerticalTresholdBarComponent],
	imports: [CommonModule],
	exports: [VerticalTresholdBarComponent],
})
export class VerticalTresholdBarModule {}
