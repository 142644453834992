export const COMMA = ',';
export const DECIMAL_SEPARATOR = '.';

export function getRandomNumber(min: number, max: number): number {
	const minInteger = Math.ceil(min);
	const maxInteger = Math.floor(max);
	return Math.floor(Math.random() * (maxInteger - minInteger + 1)) + minInteger;
}

/** Round number to 4 decimal places */
// Do not get confused by the name, the result has actually just 4 decimals.
// But it is used like this everywhere in the ABProd Legacy
// because who cares about numeric precision, when you are in Javascript, anyway?
export function round5(n: number): number {
	return Math.round(10000 * n) / 10000;
}

/**
 * Rounds input number to set number of decimal point.
 * Must not return more decimal points than required.
 * By default rounds to 0 decimal points.
 * @param inputNumber
 * @param decimalPoints
 * @returns
 */
export function round(inputNumber: number, decimalPoints: number = 0): number {
	const helper = Math.pow(10, decimalPoints);
	const result = Math.round(inputNumber * helper) / helper;
	const fixedDecimals = decimalPoints < 0 ? 0 : decimalPoints;
	return parseFloat(result.toFixed(fixedDecimals));
}

export function isOdd(value: number): boolean {
	return value % 2 === 1;
}

/**
 * Gets key of minimal numeric value in object
 * @param obj
 */
export function getKeyWithMinimalValueFromObject(obj: Record<string, number>): string {
	const orbpsInArray = Object.keys(obj);
	return orbpsInArray.reduce((key, v) => obj[v] < obj[key] ? v : key, orbpsInArray?.[0]);
}

/**
 * Trims 123.3456 to 123.34 when maxAllowedDecimalPlaces=2
 */
export function trimDecimalPlaces(value: number, maxAllowedDecimalPlaces: number): number {
	const patternDecimalLimit = new RegExp('^\\d+\\' + DECIMAL_SEPARATOR + '?\\d{0,' + maxAllowedDecimalPlaces + '}$');
	const isValidDecimal = patternDecimalLimit.test(value.toString());

	if (isValidDecimal) {
		return value;
	} else {
		const arr = value.toString().split(DECIMAL_SEPARATOR);
		const allowedDecimals = arr[1].substring(0, maxAllowedDecimalPlaces);
		const strResult = `${arr[0]}${DECIMAL_SEPARATOR}${allowedDecimals}`;
		const result = parseFloat(strResult);
		return result;
	}
}
