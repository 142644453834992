/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';

import {
	DailyRevenues,
	DailySales,
	DiscountsReport,
	DkiBeClickhouseAnalyticsSosDttReportAggregation,
	EmployeeMealReport,
	HourlySales,
	OperatingReport,
	OutputFormatType,
	PosRecord,
	ProductReport,
	SafeRecord,
	SafeTransaction,
	ShoppingListItem,
	SosDttReport,
	SourceDrive,
	Synthesis2Report,
	SynthesisReport,
	Transaction,
	WeekDay,
	WeeklyReport,
	WeeklyVouchersReport
} from '../model/models';

import { BASE_PATH } from '../variables';


@Injectable()
export class ReportsApiClient {

	protected basePath = 'http://localhost';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	public encoder: HttpParameterCodec;

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (configuration) {
			this.configuration = configuration;
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (typeof basePath !== 'string') {
				basePath = this.basePath;
			}
			this.configuration.basePath = basePath;
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
	}


	private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
		if (typeof value === "object" && value instanceof Date === false) {
			httpParams = this.addToHttpParamsRecursive(httpParams, value);
		} else {
			httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
		}
		return httpParams;
	}

	private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
		if (value == null) {
			return httpParams;
		}

		if (typeof value === "object") {
			if (Array.isArray(value)) {
				(value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
			} else if (value instanceof Date) {
				if (key != null) {
					httpParams = httpParams.append(key,
						(value as Date).toISOString().substr(0, 10));
				} else {
					throw Error("key may not be null if value is Date");
				}
			} else {
				Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
					httpParams, value[k], key != null ? `${key}.${k}` : k));
			}
		} else if (key != null) {
			httpParams = httpParams.append(key, value);
		} else {
			throw Error("key may not be null if value is not object or array");
		}
		return httpParams;
	}

	/**
	 * Daily Sales Total Revenue
	 * Get hourly sales report.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public dailySalesTotalRevenueV1ReportsDailySalesTotalRevenueGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<DailyRevenues>;
	public dailySalesTotalRevenueV1ReportsDailySalesTotalRevenueGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<DailyRevenues>>;
	public dailySalesTotalRevenueV1ReportsDailySalesTotalRevenueGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<DailyRevenues>>;
	public dailySalesTotalRevenueV1ReportsDailySalesTotalRevenueGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling dailySalesTotalRevenueV1ReportsDailySalesTotalRevenueGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<DailyRevenues>(`${this.configuration.basePath}/v1/reports/daily-sales-total-revenue`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Daily Sales
	 * Get hourly sales report.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public dailySalesV1ReportsDailySalesGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<DailySales>;
	public dailySalesV1ReportsDailySalesGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<DailySales>>;
	public dailySalesV1ReportsDailySalesGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<DailySales>>;
	public dailySalesV1ReportsDailySalesGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling dailySalesV1ReportsDailySalesGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<DailySales>(`${this.configuration.basePath}/v1/reports/daily-sales`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Discounts Report
	 * Get Discounts report.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getDiscountsReportV1ReportsDiscountsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<DiscountsReport>;
	public getDiscountsReportV1ReportsDiscountsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<DiscountsReport>>;
	public getDiscountsReportV1ReportsDiscountsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<DiscountsReport>>;
	public getDiscountsReportV1ReportsDiscountsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling getDiscountsReportV1ReportsDiscountsGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<DiscountsReport>(`${this.configuration.basePath}/v1/reports/discounts`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Employee Meals Report
	 * Get Safe Sessions report.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getEmployeeMealsReportV1ReportsEmployeeMealsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<EmployeeMealReport>;
	public getEmployeeMealsReportV1ReportsEmployeeMealsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<EmployeeMealReport>>;
	public getEmployeeMealsReportV1ReportsEmployeeMealsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<EmployeeMealReport>>;
	public getEmployeeMealsReportV1ReportsEmployeeMealsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling getEmployeeMealsReportV1ReportsEmployeeMealsGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<EmployeeMealReport>(`${this.configuration.basePath}/v1/reports/employee-meals`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Operating Report
	 * Get operating report.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getOperatingReportV1ReportsOperatingGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<OperatingReport>;
	public getOperatingReportV1ReportsOperatingGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<OperatingReport>>;
	public getOperatingReportV1ReportsOperatingGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<OperatingReport>>;
	public getOperatingReportV1ReportsOperatingGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling getOperatingReportV1ReportsOperatingGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<OperatingReport>(`${this.configuration.basePath}/v1/reports/operating`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Pos Sessions Report
	 * Get POS Sessions report.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getPosSessionsReportV1ReportsPosSessionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<PosRecord>>;
	public getPosSessionsReportV1ReportsPosSessionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<PosRecord>>>;
	public getPosSessionsReportV1ReportsPosSessionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<PosRecord>>>;
	public getPosSessionsReportV1ReportsPosSessionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling getPosSessionsReportV1ReportsPosSessionsGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<PosRecord>>(`${this.configuration.basePath}/v1/reports/pos-sessions`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Product Report
	 * Get operating report.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getProductReportV1ReportsProductsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<ProductReport>;
	public getProductReportV1ReportsProductsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<ProductReport>>;
	public getProductReportV1ReportsProductsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<ProductReport>>;
	public getProductReportV1ReportsProductsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling getProductReportV1ReportsProductsGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<ProductReport>(`${this.configuration.basePath}/v1/reports/products`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Safe Sessions Report
	 * Get Safe Sessions report.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getSafeSessionsReportV1ReportsSafeSessionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<SafeRecord>>;
	public getSafeSessionsReportV1ReportsSafeSessionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<SafeRecord>>>;
	public getSafeSessionsReportV1ReportsSafeSessionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<SafeRecord>>>;
	public getSafeSessionsReportV1ReportsSafeSessionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling getSafeSessionsReportV1ReportsSafeSessionsGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<SafeRecord>>(`${this.configuration.basePath}/v1/reports/safe-sessions`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Safe Transactions Report
	 * Get Safe Transactions report.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getSafeTransactionsReportV1ReportsSafeTransactionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<SafeTransaction>>;
	public getSafeTransactionsReportV1ReportsSafeTransactionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<SafeTransaction>>>;
	public getSafeTransactionsReportV1ReportsSafeTransactionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<SafeTransaction>>>;
	public getSafeTransactionsReportV1ReportsSafeTransactionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling getSafeTransactionsReportV1ReportsSafeTransactionsGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<SafeTransaction>>(`${this.configuration.basePath}/v1/reports/safe-transactions`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Shopping List Report
	 * Get operating report.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getShoppingListReportV1ReportsShoppingListGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<ShoppingListItem>>;
	public getShoppingListReportV1ReportsShoppingListGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<ShoppingListItem>>>;
	public getShoppingListReportV1ReportsShoppingListGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<ShoppingListItem>>>;
	public getShoppingListReportV1ReportsShoppingListGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling getShoppingListReportV1ReportsShoppingListGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<ShoppingListItem>>(`${this.configuration.basePath}/v1/reports/shopping-list`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Sos Dtt Report
	 * Get Discounts report.
	 * @param restaurantId Restaurant UUID
	 * @param aggregation
	 * @param weekday
	 * @param sourceType Source type of sos transactions COD or Tablet
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getSosDttReportV1ReportsDttGet(restaurantId: Array<string>, aggregation?: Set<DkiBeClickhouseAnalyticsSosDttReportAggregation>, weekday?: Set<WeekDay>, sourceType?: SourceDrive, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<SosDttReport>>;
	public getSosDttReportV1ReportsDttGet(restaurantId: Array<string>, aggregation?: Set<DkiBeClickhouseAnalyticsSosDttReportAggregation>, weekday?: Set<WeekDay>, sourceType?: SourceDrive, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<SosDttReport>>>;
	public getSosDttReportV1ReportsDttGet(restaurantId: Array<string>, aggregation?: Set<DkiBeClickhouseAnalyticsSosDttReportAggregation>, weekday?: Set<WeekDay>, sourceType?: SourceDrive, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<SosDttReport>>>;
	public getSosDttReportV1ReportsDttGet(restaurantId: Array<string>, aggregation?: Set<DkiBeClickhouseAnalyticsSosDttReportAggregation>, weekday?: Set<WeekDay>, sourceType?: SourceDrive, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling getSosDttReportV1ReportsDttGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (aggregation) {
			aggregation.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'aggregation');
			})
		}
		if (weekday) {
			weekday.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'weekday');
			})
		}
		if (sourceType !== undefined && sourceType !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>sourceType, 'source_type');
		}
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}
		if (format !== undefined && format !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>format, 'format');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<SosDttReport>>(`${this.configuration.basePath}/v1/reports/dtt`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Synthesis2 Report
	 * Get Safe Sessions report.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getSynthesis2ReportV1ReportsSynthesis2Get(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Synthesis2Report>;
	public getSynthesis2ReportV1ReportsSynthesis2Get(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Synthesis2Report>>;
	public getSynthesis2ReportV1ReportsSynthesis2Get(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Synthesis2Report>>;
	public getSynthesis2ReportV1ReportsSynthesis2Get(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling getSynthesis2ReportV1ReportsSynthesis2Get.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Synthesis2Report>(`${this.configuration.basePath}/v1/reports/synthesis2`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Synthesis Report
	 * Get Safe Sessions report.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getSynthesisReportV1ReportsSynthesisGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<SynthesisReport>;
	public getSynthesisReportV1ReportsSynthesisGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<SynthesisReport>>;
	public getSynthesisReportV1ReportsSynthesisGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<SynthesisReport>>;
	public getSynthesisReportV1ReportsSynthesisGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling getSynthesisReportV1ReportsSynthesisGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<SynthesisReport>(`${this.configuration.basePath}/v1/reports/synthesis`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Weekly Report
	 * Get operating report.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getWeeklyReportV1ReportsWeeklyGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<WeeklyReport>;
	public getWeeklyReportV1ReportsWeeklyGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<WeeklyReport>>;
	public getWeeklyReportV1ReportsWeeklyGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<WeeklyReport>>;
	public getWeeklyReportV1ReportsWeeklyGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling getWeeklyReportV1ReportsWeeklyGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<WeeklyReport>(`${this.configuration.basePath}/v1/reports/weekly`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	    /**
     * Get Weekly Vouchers Report
     * Get operating report.
     * @param restaurantId Restaurant UUID
     * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
     * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
     * @param businessdayFrom Businessday in the ISO 8601 format  
     * @param businessdayTo Businessday in the ISO 8601 format  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
			public getWeeklyVouchersReportV1ReportsWeeklyVouchersGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<WeeklyVouchersReport>;
			public getWeeklyVouchersReportV1ReportsWeeklyVouchersGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<WeeklyVouchersReport>>;
			public getWeeklyVouchersReportV1ReportsWeeklyVouchersGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<WeeklyVouchersReport>>;
			public getWeeklyVouchersReportV1ReportsWeeklyVouchersGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
					if (restaurantId === null || restaurantId === undefined) {
							throw new Error('Required parameter restaurantId was null or undefined when calling getWeeklyVouchersReportV1ReportsWeeklyVouchersGet.');
					}
	
					let queryParameters = new HttpParams({encoder: this.encoder});
					if (restaurantId) {
							restaurantId.forEach((element) => {
									queryParameters = this.addToHttpParams(queryParameters,
										<any>element, 'restaurant_id');
							})
					}
					if (from !== undefined && from !== null) {
						queryParameters = this.addToHttpParams(queryParameters,
							<any>from, 'from');
					}
					if (to !== undefined && to !== null) {
						queryParameters = this.addToHttpParams(queryParameters,
							<any>to, 'to');
					}
					if (businessdayFrom !== undefined && businessdayFrom !== null) {
						queryParameters = this.addToHttpParams(queryParameters,
							<any>businessdayFrom, 'businessday_from');
					}
					if (businessdayTo !== undefined && businessdayTo !== null) {
						queryParameters = this.addToHttpParams(queryParameters,
							<any>businessdayTo, 'businessday_to');
					}
	
					let headers = this.defaultHeaders;
	
					let credential: string | undefined;
					// authentication (APIKeyCookie) required
					credential = this.configuration.lookupCredential('APIKeyCookie');
					if (credential) {
					}
	
					// authentication (HTTPBasic) required
					credential = this.configuration.lookupCredential('HTTPBasic');
					if (credential) {
							headers = headers.set('Authorization', 'Basic ' + credential);
					}
	
					// authentication (OAuth2PasswordBearer) required
					credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
					if (credential) {
							headers = headers.set('Authorization', 'Bearer ' + credential);
					}
	
					let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
					if (httpHeaderAcceptSelected === undefined) {
							// to determine the Accept header
							const httpHeaderAccepts: string[] = [
									'application/json'
							];
							httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
					}
					if (httpHeaderAcceptSelected !== undefined) {
							headers = headers.set('Accept', httpHeaderAcceptSelected);
					}
	
	
					let responseType: 'text' | 'json' = 'json';
					if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
							responseType = 'text';
					}
	
					return this.httpClient.get<WeeklyVouchersReport>(`${this.configuration.basePath}/v1/reports/weekly-vouchers`,
							{
									params: queryParameters,
									responseType: <any>responseType,
									withCredentials: this.configuration.withCredentials,
									headers: headers,
									observe: observe,
									reportProgress: reportProgress
							}
					);
			}

	/**
	 * Hourly Sales
	 * Get hourly sales report.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public hourlySalesV1ReportsHourlySalesGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HourlySales>;
	public hourlySalesV1ReportsHourlySalesGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<HourlySales>>;
	public hourlySalesV1ReportsHourlySalesGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<HourlySales>>;
	public hourlySalesV1ReportsHourlySalesGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling hourlySalesV1ReportsHourlySalesGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<HourlySales>(`${this.configuration.basePath}/v1/reports/hourly-sales`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Transaction
	 * Get transaction.
	 * @param uuid
	 * @param restaurantId Restaurant UUID
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public transactionV1ReportsTransactionUuidGet(uuid: string, restaurantId: Array<string>, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Transaction>;
	public transactionV1ReportsTransactionUuidGet(uuid: string, restaurantId: Array<string>, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Transaction>>;
	public transactionV1ReportsTransactionUuidGet(uuid: string, restaurantId: Array<string>, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Transaction>>;
	public transactionV1ReportsTransactionUuidGet(uuid: string, restaurantId: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (uuid === null || uuid === undefined) {
			throw new Error('Required parameter uuid was null or undefined when calling transactionV1ReportsTransactionUuidGet.');
		}
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling transactionV1ReportsTransactionUuidGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Transaction>(`${this.configuration.basePath}/v1/reports/transaction/${encodeURIComponent(String(uuid))}`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Transactions
	 * Get transactions.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public transactionsV1ReportsTransactionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<Transaction>>;
	public transactionsV1ReportsTransactionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<Transaction>>>;
	public transactionsV1ReportsTransactionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<Transaction>>>;
	public transactionsV1ReportsTransactionsGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling transactionsV1ReportsTransactionsGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<Transaction>>(`${this.configuration.basePath}/v1/reports/transactions`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
