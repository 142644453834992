/**
 * Merim GAP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type WeborderChannel = 'CLICK_COLLECT' | 'DELIVEROO' | 'DELIVERY_BK' | 'JUST_EAT' | 'UBER_EATS' | 'ZOT' | 'PLEASE' | 'LYVEAT';

export const WeborderChannel = {
    ClickCollect: 'CLICK_COLLECT' as WeborderChannel,
    Deliveroo: 'DELIVEROO' as WeborderChannel,
    DeliveryBk: 'DELIVERY_BK' as WeborderChannel,
    JustEat: 'JUST_EAT' as WeborderChannel,
    UberEats: 'UBER_EATS' as WeborderChannel,
    Zot: 'ZOT' as WeborderChannel,
    Please: 'PLEASE' as WeborderChannel,
    Lyveat: 'LYVEAT' as WeborderChannel
};

