import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

import { ActionButtonsComponent } from './component/action-buttons.component';

@NgModule({
	declarations: [ActionButtonsComponent],
	imports: [CommonModule, RouterModule, MatButtonModule],
	exports: [ActionButtonsComponent],
})
export class ActionButtonsModule {}
