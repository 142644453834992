/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';

import {
	MonitoringDeviceRole,
	MonitoringOverall,
	MonitoringRestaurantInfo,
	MonitoringRestaurantInput,
	MonitoringRestaurantsInfo
} from '../model/models';

import { BASE_PATH } from '../variables';


@Injectable()
export class MonitoringApiClient {

	protected basePath = 'http://localhost';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	public encoder: HttpParameterCodec;

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (configuration) {
			this.configuration = configuration;
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (typeof basePath !== 'string') {
				basePath = this.basePath;
			}
			this.configuration.basePath = basePath;
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
	}


	private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
		if (typeof value === "object" && value instanceof Date === false) {
			httpParams = this.addToHttpParamsRecursive(httpParams, value);
		} else {
			httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
		}
		return httpParams;
	}

	private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
		if (value == null) {
			return httpParams;
		}

		if (typeof value === "object") {
			if (Array.isArray(value)) {
				(value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
			} else if (value instanceof Date) {
				if (key != null) {
					httpParams = httpParams.append(key,
						(value as Date).toISOString().substr(0, 10));
				} else {
					throw Error("key may not be null if value is Date");
				}
			} else {
				Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
					httpParams, value[k], key != null ? `${key}.${k}` : k));
			}
		} else if (key != null) {
			httpParams = httpParams.append(key, value);
		} else {
			throw Error("key may not be null if value is not object or array");
		}
		return httpParams;
	}

	/**
	 * Add Device Roles
	 * Add new device role/s.
	 * @param monitoringDeviceRole
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public addDeviceRolesV1MonitoringDeviceRolesPost(monitoringDeviceRole: Array<MonitoringDeviceRole>, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<MonitoringDeviceRole>>;
	public addDeviceRolesV1MonitoringDeviceRolesPost(monitoringDeviceRole: Array<MonitoringDeviceRole>, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<MonitoringDeviceRole>>>;
	public addDeviceRolesV1MonitoringDeviceRolesPost(monitoringDeviceRole: Array<MonitoringDeviceRole>, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<MonitoringDeviceRole>>>;
	public addDeviceRolesV1MonitoringDeviceRolesPost(monitoringDeviceRole: Array<MonitoringDeviceRole>, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (monitoringDeviceRole === null || monitoringDeviceRole === undefined) {
			throw new Error('Required parameter monitoringDeviceRole was null or undefined when calling addDeviceRolesV1MonitoringDeviceRolesPost.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.post<Array<MonitoringDeviceRole>>(`${this.configuration.basePath}/v1/monitoring/device-roles`,
			monitoringDeviceRole,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Delete Device Roles
	 * Delete registered device role/s.
	 * @param deviceRole Device role.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public deleteDeviceRolesV1MonitoringDeviceRolesDelete(deviceRole: Array<string>, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<MonitoringDeviceRole>>;
	public deleteDeviceRolesV1MonitoringDeviceRolesDelete(deviceRole: Array<string>, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<MonitoringDeviceRole>>>;
	public deleteDeviceRolesV1MonitoringDeviceRolesDelete(deviceRole: Array<string>, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<MonitoringDeviceRole>>>;
	public deleteDeviceRolesV1MonitoringDeviceRolesDelete(deviceRole: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (deviceRole === null || deviceRole === undefined) {
			throw new Error('Required parameter deviceRole was null or undefined when calling deleteDeviceRolesV1MonitoringDeviceRolesDelete.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (deviceRole) {
			deviceRole.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'device_role');
			})
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.delete<Array<MonitoringDeviceRole>>(`${this.configuration.basePath}/v1/monitoring/device-roles`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Device Roles
	 * Get all registered device roles with description.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getDeviceRolesV1MonitoringDeviceRolesGet(observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<MonitoringDeviceRole>>;
	public getDeviceRolesV1MonitoringDeviceRolesGet(observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<MonitoringDeviceRole>>>;
	public getDeviceRolesV1MonitoringDeviceRolesGet(observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<MonitoringDeviceRole>>>;
	public getDeviceRolesV1MonitoringDeviceRolesGet(observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<MonitoringDeviceRole>>(`${this.configuration.basePath}/v1/monitoring/device-roles`,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Restaurant
	 * Get monitoring information on a single restaurant.
	 * @param restaurantId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getRestaurantV1MonitoringRestaurantsRestaurantIdGet(restaurantId: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<MonitoringRestaurantInfo>;
	public getRestaurantV1MonitoringRestaurantsRestaurantIdGet(restaurantId: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<MonitoringRestaurantInfo>>;
	public getRestaurantV1MonitoringRestaurantsRestaurantIdGet(restaurantId: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<MonitoringRestaurantInfo>>;
	public getRestaurantV1MonitoringRestaurantsRestaurantIdGet(restaurantId: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling getRestaurantV1MonitoringRestaurantsRestaurantIdGet.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<MonitoringRestaurantInfo>(`${this.configuration.basePath}/v1/monitoring/restaurants/${encodeURIComponent(String(restaurantId))}`,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Restaurants
	 * Get monitoring information of all accessible restaurants.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getRestaurantsV1MonitoringRestaurantsGet(observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<MonitoringRestaurantsInfo>;
	public getRestaurantsV1MonitoringRestaurantsGet(observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<MonitoringRestaurantsInfo>>;
	public getRestaurantsV1MonitoringRestaurantsGet(observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<MonitoringRestaurantsInfo>>;
	public getRestaurantsV1MonitoringRestaurantsGet(observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<MonitoringRestaurantsInfo>(`${this.configuration.basePath}/v1/monitoring/restaurants`,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Status
	 * Get overall status of devices and restaurants.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public statusV1MonitoringStatusGet(observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<MonitoringOverall>;
	public statusV1MonitoringStatusGet(observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<MonitoringOverall>>;
	public statusV1MonitoringStatusGet(observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<MonitoringOverall>>;
	public statusV1MonitoringStatusGet(observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<MonitoringOverall>(`${this.configuration.basePath}/v1/monitoring/status`,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Store Monitoring Data
	 * Store monitoring data provided by Shar3dMonitor running in restaurant. Can handle GZIP encoded payload if proper \&#39;Content-Encoding\&#39;: \&#39;gzip\&#39; header is set, otherwise expects JSON.
	 * @param monitoringRestaurantInput
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public storeMonitoringDataV1MonitoringDataCollectPost(monitoringRestaurantInput: MonitoringRestaurantInput, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<any>;
	public storeMonitoringDataV1MonitoringDataCollectPost(monitoringRestaurantInput: MonitoringRestaurantInput, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<any>>;
	public storeMonitoringDataV1MonitoringDataCollectPost(monitoringRestaurantInput: MonitoringRestaurantInput, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<any>>;
	public storeMonitoringDataV1MonitoringDataCollectPost(monitoringRestaurantInput: MonitoringRestaurantInput, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (monitoringRestaurantInput === null || monitoringRestaurantInput === undefined) {
			throw new Error('Required parameter monitoringRestaurantInput was null or undefined when calling storeMonitoringDataV1MonitoringDataCollectPost.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.post<any>(`${this.configuration.basePath}/v1/monitoring/data-collect`,
			monitoringRestaurantInput,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Update Device Roles
	 * Update registered device role/s descriptions.
	 * @param monitoringDeviceRole
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public updateDeviceRolesV1MonitoringDeviceRolesPatch(monitoringDeviceRole: Array<MonitoringDeviceRole>, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<MonitoringDeviceRole>>;
	public updateDeviceRolesV1MonitoringDeviceRolesPatch(monitoringDeviceRole: Array<MonitoringDeviceRole>, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<MonitoringDeviceRole>>>;
	public updateDeviceRolesV1MonitoringDeviceRolesPatch(monitoringDeviceRole: Array<MonitoringDeviceRole>, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<MonitoringDeviceRole>>>;
	public updateDeviceRolesV1MonitoringDeviceRolesPatch(monitoringDeviceRole: Array<MonitoringDeviceRole>, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (monitoringDeviceRole === null || monitoringDeviceRole === undefined) {
			throw new Error('Required parameter monitoringDeviceRole was null or undefined when calling updateDeviceRolesV1MonitoringDeviceRolesPatch.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.patch<Array<MonitoringDeviceRole>>(`${this.configuration.basePath}/v1/monitoring/device-roles`,
			monitoringDeviceRole,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Update Vpn Ip Address
	 * Store new announced VPN address for the restaurant.
	 * @param uuid
	 * @param ip
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public updateVpnIpAddressV1MonitoringUpdateVpnAddressGet(uuid: string, ip: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<any>;
	public updateVpnIpAddressV1MonitoringUpdateVpnAddressGet(uuid: string, ip: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<any>>;
	public updateVpnIpAddressV1MonitoringUpdateVpnAddressGet(uuid: string, ip: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<any>>;
	public updateVpnIpAddressV1MonitoringUpdateVpnAddressGet(uuid: string, ip: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (uuid === null || uuid === undefined) {
			throw new Error('Required parameter uuid was null or undefined when calling updateVpnIpAddressV1MonitoringUpdateVpnAddressGet.');
		}
		if (ip === null || ip === undefined) {
			throw new Error('Required parameter ip was null or undefined when calling updateVpnIpAddressV1MonitoringUpdateVpnAddressGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (uuid !== undefined && uuid !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>uuid, 'uuid');
		}
		if (ip !== undefined && ip !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>ip, 'ip');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<any>(`${this.configuration.basePath}/v1/monitoring/update-vpn-address`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
