/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';

import { RestaurantInfo, RestaurantInput, UpdateRestaurantInfo } from '../model/models';

import { BASE_PATH } from '../variables';


@Injectable()
export class RestaurantsApiClient {

	protected basePath = 'http://localhost';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	public encoder: HttpParameterCodec;

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (configuration) {
			this.configuration = configuration;
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (typeof basePath !== 'string') {
				basePath = this.basePath;
			}
			this.configuration.basePath = basePath;
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
	}


	private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
		if (typeof value === "object" && value instanceof Date === false) {
			httpParams = this.addToHttpParamsRecursive(httpParams, value);
		} else {
			httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
		}
		return httpParams;
	}

	private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
		if (value == null) {
			return httpParams;
		}

		if (typeof value === "object") {
			if (Array.isArray(value)) {
				(value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
			} else if (value instanceof Date) {
				if (key != null) {
					httpParams = httpParams.append(key,
						(value as Date).toISOString().substr(0, 10));
				} else {
					throw Error("key may not be null if value is Date");
				}
			} else {
				Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
					httpParams, value[k], key != null ? `${key}.${k}` : k));
			}
		} else if (key != null) {
			httpParams = httpParams.append(key, value);
		} else {
			throw Error("key may not be null if value is not object or array");
		}
		return httpParams;
	}

	/**
	 * Create Restaurant
	 * Create a new restaurant.
	 * @param restaurantInput
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public createRestaurantV1RestaurantsPost(restaurantInput: RestaurantInput, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<RestaurantInfo>;
	public createRestaurantV1RestaurantsPost(restaurantInput: RestaurantInput, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<RestaurantInfo>>;
	public createRestaurantV1RestaurantsPost(restaurantInput: RestaurantInput, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<RestaurantInfo>>;
	public createRestaurantV1RestaurantsPost(restaurantInput: RestaurantInput, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantInput === null || restaurantInput === undefined) {
			throw new Error('Required parameter restaurantInput was null or undefined when calling createRestaurantV1RestaurantsPost.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.post<RestaurantInfo>(`${this.configuration.basePath}/v1/restaurants`,
			restaurantInput,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Delete Restaurants
	 * Delete restaurants.
	 * @param restaurantId UUIDs of restaurants to be deleted.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public deleteRestaurantsV1RestaurantsDelete(restaurantId: Array<string>, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<any>;
	public deleteRestaurantsV1RestaurantsDelete(restaurantId: Array<string>, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<any>>;
	public deleteRestaurantsV1RestaurantsDelete(restaurantId: Array<string>, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<any>>;
	public deleteRestaurantsV1RestaurantsDelete(restaurantId: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling deleteRestaurantsV1RestaurantsDelete.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/restaurants`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Restaurant Cities
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getRestaurantCitiesV1CitiesGet(observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<string>>;
	public getRestaurantCitiesV1CitiesGet(observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<string>>>;
	public getRestaurantCitiesV1CitiesGet(observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<string>>>;
	public getRestaurantCitiesV1CitiesGet(observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/v1/cities`,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Restaurant
	 * Get a single restaurant by UUID.
	 * @param restaurantUuid
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getRestaurantV1RestaurantsRestaurantUuidGet(restaurantUuid: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<RestaurantInfo>;
	public getRestaurantV1RestaurantsRestaurantUuidGet(restaurantUuid: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<RestaurantInfo>>;
	public getRestaurantV1RestaurantsRestaurantUuidGet(restaurantUuid: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<RestaurantInfo>>;
	public getRestaurantV1RestaurantsRestaurantUuidGet(restaurantUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantUuid === null || restaurantUuid === undefined) {
			throw new Error('Required parameter restaurantUuid was null or undefined when calling getRestaurantV1RestaurantsRestaurantUuidGet.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<RestaurantInfo>(`${this.configuration.basePath}/v1/restaurants/${encodeURIComponent(String(restaurantUuid))}`,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Get Restaurants
	 * Get a list of all available restaurants.
	 * @param city Return only restaurants from this city.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getRestaurantsV1RestaurantsGet(city?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<RestaurantInfo>>;
	public getRestaurantsV1RestaurantsGet(city?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<RestaurantInfo>>>;
	public getRestaurantsV1RestaurantsGet(city?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<RestaurantInfo>>>;
	public getRestaurantsV1RestaurantsGet(city?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (city) {
			city.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'city');
			})
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<RestaurantInfo>>(`${this.configuration.basePath}/v1/restaurants`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Update Restaurant
	 * Update restaurant.
	 * @param restaurantUuid
	 * @param updateRestaurantInfo
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public updateRestaurantV1RestaurantsRestaurantUuidPatch(restaurantUuid: string, updateRestaurantInfo: UpdateRestaurantInfo, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<RestaurantInfo>;
	public updateRestaurantV1RestaurantsRestaurantUuidPatch(restaurantUuid: string, updateRestaurantInfo: UpdateRestaurantInfo, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<RestaurantInfo>>;
	public updateRestaurantV1RestaurantsRestaurantUuidPatch(restaurantUuid: string, updateRestaurantInfo: UpdateRestaurantInfo, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<RestaurantInfo>>;
	public updateRestaurantV1RestaurantsRestaurantUuidPatch(restaurantUuid: string, updateRestaurantInfo: UpdateRestaurantInfo, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantUuid === null || restaurantUuid === undefined) {
			throw new Error('Required parameter restaurantUuid was null or undefined when calling updateRestaurantV1RestaurantsRestaurantUuidPatch.');
		}
		if (updateRestaurantInfo === null || updateRestaurantInfo === undefined) {
			throw new Error('Required parameter updateRestaurantInfo was null or undefined when calling updateRestaurantV1RestaurantsRestaurantUuidPatch.');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		// to determine the Content-Type header
		const consumes: string[] = [
			'application/json'
		];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			headers = headers.set('Content-Type', httpContentTypeSelected);
		}

		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.patch<RestaurantInfo>(`${this.configuration.basePath}/v1/restaurants/${encodeURIComponent(String(restaurantUuid))}`,
			updateRestaurantInfo,
			{
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
