import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as api from '@dki/api-client';

import { Range } from '@libs/dash/core/entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { formatDateISO } from '../../core/utils/functions';
import { MyRestaurantsSelectors } from '../my-restaurants';
import { RootStoreState } from '../state';
import * as SessionHistoryActions from './actions';

@Injectable()
export class SessionsHistoryEffects {
	getSessionHistory = createEffect(() =>
		this._actions.pipe(
			ofType(SessionHistoryActions.getSessionHistory),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			filter(([, defaultRestaurantId]) => !!defaultRestaurantId),
			// distinctUntilChanged((prev, curr) => {
			// 	return prev[0].from.equals(curr[0].from) && prev[0].to.equals(curr[0].to) && prev[1] === curr[1];
			// }),
			mergeMap(([a, defaultRestaurantId]) => {
				return this._sessionsHistoryApiClient
					.getPosSessionsReportV1ReportsPosSessionsGet(
						[defaultRestaurantId],
						null,
						null,
						formatDateISO(a.from.startOf(Range.Day).startOf(Range.Second).toISO()),
						formatDateISO(a.to.endOf(Range.Day).startOf(Range.Second).toISO())
					)
					.pipe(
						map(
							(data) => SessionHistoryActions.getSessionHistorySuccess({ data }),
							catchError((error: HttpErrorResponse) => of(SessionHistoryActions.getSessionHistoryFail({ error })))
						)
					);
			})
		)
	);

	constructor(
		private _actions: Actions,
		private _sessionsHistoryApiClient: api.ReportsApiClient,
		private _store: Store<RootStoreState>
	) {}
}
