/**
 * @csiClassName ENTETE
 */
export interface ICsiExport_entete {
	aRRIVEEs: ICsiExport_arrivee[];
	/**
	 * @s3dLong
	 **/
	age: number;
	cASHDECLARATIONs: ICsiExport_cashdeclaration[];
	cOMMANDEs: ICsiExport_commande[];
	canceled: boolean;
	categorie: string;
	certificat: string;
	climat: string;
	commandeGenerator: string;
	contact: ICsiExport_contact;
	dEBITEURs: ICsiExport_debiteur[];
	dEPARTs: ICsiExport_depart[];
	dateFiscale: string;
	/**
	 * @s3dInteger
	 **/
	duration: number;
	emetteurAdresse: string;
	emetteurCodeNAF: string;
	emetteurCodePostal: string;
	emetteurNumTVA: string;
	emetteurPays: string;
	emetteurSiret: string;
	emetteurSociete: string;
	emetteurVille: string;
	etat: ICsiExport_etat;
	export: boolean;
	/**
	 * @s3dLong
	 **/
	factureFiscale: number;
	factureFiscaleGenerator: string;
	factureGenerator: string;
	fichier: string;
	/**
	 * @s3dDouble
	 **/
	gT_ANNUL: number;
	/**
	 * @s3dDouble
	 **/
	gT_VENTES: number;
	/**
	 * @s3dInteger
	 **/
	gUIDduTicketAnnule: number;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	importManager: boolean;
	isTransfere: boolean;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	lUNCHTVAs: ICsiExport_lunchtva[];
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	/**
	 * @s3dInteger
	 **/
	ligneCount: number;
	localisation: ICsiExport_elocalisation;
	mAJORATIONs: ICsiExport_majoration[];
	/**
	 * @s3dDouble
	 **/
	montantHt: number;
	/**
	 * @s3dDouble
	 **/
	montantTtc: number;
	/**
	 * @s3dDouble
	 **/
	montantTva: number;
	/**
	 * @s3dInteger
	 **/
	nbCouverts: number;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dLong
	 **/
	numFacture: number;
	/**
	 * @s3dInteger
	 **/
	numLigneTotal: number;
	/**
	 * @s3dInteger
	 **/
	numObjet: number;
	/**
	 * @s3dLong
	 **/
	numOpenCommande: number;
	/**
	 * @s3dLong
	 **/
	numProforma: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dLong
	 **/
	numTicket: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	oUVERTURETIROIRs: ICsiExport_ouverturetiroir[];
	operation: ICsiExport_eoperation;
	/**
	 * @s3dInteger
	 **/
	printCount: number;
	profit: string;
	proformaGenerator: string;
	rEGLEMENTs: ICsiExport_reglement[];
	rEMISEs: ICsiExport_remise[];
	repasComplet: boolean;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	sTATISTIQUESs: ICsiExport_statistiques[];
	service: string;
	sessionVentes: string;
	signature: string;
	tIPs: ICsiExport_tip[];
	tVAs: ICsiExport_tva[];
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketAnnulation: ICsiExport_entete;
	ticketAnnule: ICsiExport_entete;
	/**
	 * @s3dLong
	 **/
	ticketFiscal: number;
	ticketFiscalGenerator: string;
	ticketGenerator: string;
	ticketTransfert: ICsiExport_entete;
	ticketsAnnules: ICsiExport_entete[];
	ticketsTransferes: ICsiExport_entete[];
	/**
	 * @s3dDouble
	 **/
	totalNetRemiseEtMajore: number;
	trainingMode: boolean;
	/**
	 * @s3dInteger
	 **/
	transfereVers: number;
	type: ICsiExport_entity_type;
	typeObjet: ICsiExport_typeobjet;
	/**
	 * @s3dInteger
	 **/
	version: number;
	versionCaisse: string;
}

export enum ICsiExport_typeobjet {
	TABLE_RESTO,
	COMPTE,
	TICKET,
	COMPTECOMMANDE,
	COMMANDE,
}

/**
 * @csiClassName REGLEMENT
 */
export interface ICsiExport_reglement {
	canceled: boolean;
	change: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libFamille: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	libelle: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numFamille: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parentEnteteReglement: ICsiExport_entete;
	profit: string;
	/**
	 * @s3dDouble
	 **/
	quantite: number;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	/**
	 * @s3dDouble
	 **/
	totalValue: number;
	trainingMode: boolean;
	/**
	 * @s3dDouble
	 **/
	valeurUnitaire: number;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName ARRIVEE
 */
export interface ICsiExport_arrivee {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	operation: ICsiExport_eoperation;
	parentEnteteArrivee: ICsiExport_entete;
	profit: string;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	trainingMode: boolean;
	traite: boolean;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName STATISTIQUES
 */
export interface ICsiExport_statistiques {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libFamille: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	libelle: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numFamille: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parentEnteteStat: ICsiExport_entete;
	profit: string;
	/**
	 * @s3dDouble
	 **/
	quantite: number;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dDouble
	 **/
	valeur: number;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName COMMANDE
 */
export interface ICsiExport_commande {
	aRTICLECOMMANDEs: ICsiExport_articlecommande[];
	aRTICLEs: ICsiExport_article[];
	cOMMENTAIRELIBREs: ICsiExport_commentairelibre[];
	cOMMENTAIREs: ICsiExport_commentaire[];
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	iNVOICEs: ICsiExport_invoice[];
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	lUNCHRECEIPTs: ICsiExport_lunchreceipt[];
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	localisation: ICsiExport_elocalisation;
	mAJORATIONs: ICsiExport_majoration[];
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	operation: ICsiExport_eoperation;
	parentEnteteCommande: ICsiExport_entete;
	profit: string;
	rEMISEs: ICsiExport_remise[];
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	sUPPRESSIONs: ICsiExport_suppression[];
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName DEPART
 */
export interface ICsiExport_depart {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	operation: ICsiExport_eoperation;
	parentEnteteDepart: ICsiExport_entete;
	profit: string;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	trainingMode: boolean;
	traite: boolean;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName MAJORATION
 */
export interface ICsiExport_majoration {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libFamille: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	libelle: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numFamille: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parentArticleMajoration: ICsiExport_article;
	parentCommandeMajoration: ICsiExport_commande;
	parentEnteteMajoration: ICsiExport_entete;
	profit: string;
	/**
	 * @s3dDouble
	 **/
	quantite: number;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	/**
	 * @s3dDouble
	 **/
	taux: number;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dDouble
	 **/
	valeur: number;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName REMISE
 */
export interface ICsiExport_remise {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libFamille: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	libelle: string;
	localisation: ICsiExport_elocalisation;
	nature: ICsiExport_etypeline;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numFamille: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parentArticleRemise: ICsiExport_article;
	parentCommandeRemise: ICsiExport_commande;
	parentEnteteRemise: ICsiExport_entete;
	profit: string;
	/**
	 * @s3dDouble
	 **/
	quantite: number;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	/**
	 * @s3dDouble
	 **/
	taux: number;
	ticketGenerator: string;
	/**
	 * @s3dDouble
	 **/
	totalHT: number;
	/**
	 * @s3dDouble
	 **/
	totalTTC: number;
	trainingMode: boolean;
	/**
	 * @s3dDouble
	 **/
	valeur: number;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName TVA
 */
export interface ICsiExport_tva {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	libelle: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parentArticleTVA: ICsiExport_article;
	parentEnteteTVA: ICsiExport_entete;
	profit: string;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	/**
	 * @s3dDouble
	 **/
	taux: number;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dDouble
	 **/
	valeurHT: number;
	/**
	 * @s3dDouble
	 **/
	valeurTTC: number;
	/**
	 * @s3dDouble
	 **/
	valeurTVA: number;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName TIP
 */
export interface ICsiExport_tip {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	operation: ICsiExport_eoperation;
	parent_ENTETE_TIP: ICsiExport_entete;
	profit: string;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	tipName: string;
	/**
	 * @s3dDouble
	 **/
	tipRate: number;
	/**
	 * @s3dDouble
	 **/
	tipValue: number;
	trainingMode: boolean;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName CONTACT
 */
export interface ICsiExport_contact {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	listEmail: string[];
	listTelephone: string[];
	localisation: ICsiExport_elocalisation;
	nom: string;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parentEnteteContact: ICsiExport_entete;
	prenom: string;
	profit: string;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	situation: ICsiExport_situation;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	titre: string;
	trainingMode: boolean;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName LUNCHTVA
 */
export interface ICsiExport_lunchtva {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	libelle: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parentEnteteLunchTVA: ICsiExport_entete;
	profit: string;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	/**
	 * @s3dDouble
	 **/
	taux: number;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dDouble
	 **/
	valeurHT: number;
	/**
	 * @s3dDouble
	 **/
	valeurTTC: number;
	/**
	 * @s3dDouble
	 **/
	valeurTVA: number;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName DEBITEUR
 */
export interface ICsiExport_debiteur {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	libelle: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parent_ENTETE_DEBITEUR: ICsiExport_entete;
	profit: string;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName CASHDECLARATION
 */
export interface ICsiExport_cashdeclaration {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libFamille: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	libelle: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numFamille: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parent_ENTETE_CASHDECLARATION: ICsiExport_entete;
	profit: string;
	/**
	 * @s3dDouble
	 **/
	quantite: number;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dDouble
	 **/
	valeurUnitaire: number;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName OUVERTURETIROIR
 */
export interface ICsiExport_ouverturetiroir {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	operation: ICsiExport_eoperation;
	parent_ENTETE_OUVERTURETIROIR: ICsiExport_entete;
	profit: string;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

export enum ICsiExport_entity_type {
	ENCAISSE,
	ANNULATION,
	ANNULATION_TABLE,
	TRANSFERT,
	TRANSFERT_REGLEMENT,
	ENTREE_CAISSE,
	SORTIE_CAISSE,
	DECLARATION_CAISSE,
	OUVERTURE_TIROIR,
	ARRIVEE,
	DEPART,
	NON_GERE,
	OUVERT,
	TICKET_ANNULÉ,
}

export enum ICsiExport_etat {
	OUVERT,
	FERME,
}

/**
 * This enum values are validated by Csi on their side.
 * Possible names are: [DRIVE_THROUGH, KIOSK_EAT_IN, SALLE_EAT_IN, TAKE_OUT, SALLE, PARKING, EAT_IN, SALLE_TAKE_OUT, DELIVERY, EXTERIEUR, KIOSK_TAKE_OUT, PICKUP].
 * If not compliant, error is thrown: "Can not deserialize value of type eu.cashsystemes.starmanager.ELOCALISATION"
 */
export enum ICsiExport_elocalisation {
	EAT_IN = 'EAT_IN',
	TAKE_OUT = 'TAKE_OUT',
	DRIVE_THROUGH = 'DRIVE_THROUGH',
	DELIVERY = 'DELIVERY',
	PICKUP = 'PICKUP',
	TABLE_SERVICE = 'SALLE',
	KIOSK_EAT_IN = 'KIOSK_EAT_IN',
	KIOSK_TAKE_OUT = 'KIOSK_TAKE_OUT',
	CC_TAKE_OUT = TAKE_OUT,
	CC_EAT_IN = EAT_IN,
	CC_PARKING = 'PARKING',
	CC_DRIVE_THROUGH = DRIVE_THROUGH,
	CC_TABLE_SERVICE = TABLE_SERVICE,
	WALK = 'EXTERIEUR',
}

export enum ICsiExport_eoperation {
	VENTE,
	HOTEL,
	ACHAT,
	INTERNE,
	PAIEMENT,
	REMBOURSEMENT,
	TRANSFERT,
	TEMPORAIRE,
	DUPLICATA,
}

/**
 * @csiClassName ARTICLE
 */
export interface ICsiExport_article {
	aNNULATIONs: ICsiExport_annulation[];
	canceled: boolean;
	climat: string;
	/**
	 * @s3dDouble
	 **/
	coutMatiere: number;
	dateFiscale: string;
	/**
	 * @s3dDouble
	 **/
	discountOnLineAmount: number;
	/**
	 * @s3dInteger
	 **/
	discountOnLineId: number;
	discountOnLineName: string;
	/**
	 * @s3dDouble
	 **/
	discountOnStotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	discountOnStotalId: number;
	discountOnStotalName: string;
	/**
	 * @s3dDouble
	 **/
	discountOnTotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	discountOnTotalId: number;
	discountOnTotalName: string;
	dontPrintOnReport: boolean;
	encaisse: boolean;
	estAnnule: boolean;
	estMajore: boolean;
	estOffert: boolean;
	estRemise: boolean;
	estSoumisATVA: boolean;
	estStockTraite: boolean;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	horodatageEncaisse: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	iddesti: number;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libFamille: string;
	libGroupe: string;
	libSite: string;
	libSousFamille: string;
	libTVA: string;
	libTarif: string;
	libVendeur: string;
	libZ2: string;
	libelle2emeLang: string;
	libelle3emeLang: string;
	libelle: string;
	libelleModifier: string;
	libelleQualifier: string;
	localisation: ICsiExport_elocalisation;
	mAJORATIONs: ICsiExport_majoration[];
	/**
	 * @s3dDouble
	 **/
	marge: number;
	/**
	 * @s3dDouble
	 **/
	montantTva: number;
	nature: ICsiExport_etypeline;
	noTurnover: boolean;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numFamille: number;
	/**
	 * @s3dInteger
	 **/
	numGroupe: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numSousFamille: number;
	/**
	 * @s3dInteger
	 **/
	numTVA: number;
	/**
	 * @s3dInteger
	 **/
	numTarif: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	oFFERTs: ICsiExport_offert[];
	operation: ICsiExport_eoperation;
	parentCommandeArticle: ICsiExport_commande;
	parentLineUuid: string;
	/**
	 * @s3dDouble
	 **/
	prixTotalNet: number;
	/**
	 * @s3dDouble
	 **/
	prixTotalNetHT: number;
	/**
	 * @s3dDouble
	 **/
	prixTotalNetRemiseEtMajore: number;
	/**
	 * @s3dDouble
	 **/
	prixUnitaireTTC: number;
	profit: string;
	/**
	 * @s3dDouble
	 **/
	quantite: number;
	/**
	 * @s3dDouble
	 **/
	quantiteNette: number;
	/**
	 * @s3dDouble
	 **/
	quantiteOPCB: number;
	/**
	 * @s3dDouble
	 **/
	quantiteStock: number;
	rEMISEs: ICsiExport_remise[];
	reference: string;
	/**
	 * @s3dDouble
	 **/
	remiseEtMajorationTotale: number;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dDouble
	 **/
	surchargeOnLineAmount: number;
	/**
	 * @s3dInteger
	 **/
	surchargeOnLineId: number;
	surchargeOnLineName: string;
	/**
	 * @s3dDouble
	 **/
	surchargeOnStotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	surchargeOnStotalId: number;
	surchargeOnStotalName: string;
	/**
	 * @s3dDouble
	 **/
	surchargeOnTotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	surchargeOnTotalId: number;
	surchargeOnTotalName: string;
	tVAs: ICsiExport_tva[];
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	/**
	 * @s3dDouble
	 **/
	tauxTva: number;
	ticketGenerator: string;
	trainingMode: boolean;
	vATFREEs: ICsiExport_vatfree[];
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName LUNCHRECEIPT
 */
export interface ICsiExport_lunchreceipt {
	canceled: boolean;
	climat: string;
	/**
	 * @s3dDouble
	 **/
	coutMatiere: number;
	dateFiscale: string;
	/**
	 * @s3dDouble
	 **/
	discountOnLineAmount: number;
	/**
	 * @s3dInteger
	 **/
	discountOnLineId: number;
	discountOnLineName: string;
	/**
	 * @s3dDouble
	 **/
	discountOnStotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	discountOnStotalId: number;
	discountOnStotalName: string;
	/**
	 * @s3dDouble
	 **/
	discountOnTotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	discountOnTotalId: number;
	discountOnTotalName: string;
	dontPrintOnReport: boolean;
	encaisse: boolean;
	estAnnule: boolean;
	estMajore: boolean;
	estOffert: boolean;
	estRemise: boolean;
	estSoumisATVA: boolean;
	estStockTraite: boolean;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	horodatageEncaisse: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	iddesti: number;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libFamille: string;
	libGroupe: string;
	libSite: string;
	libSousFamille: string;
	libTVA: string;
	libTarif: string;
	libVendeur: string;
	libZ2: string;
	libelle2emeLang: string;
	libelle3emeLang: string;
	libelle: string;
	libelleModifier: string;
	libelleQualifier: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dDouble
	 **/
	marge: number;
	/**
	 * @s3dDouble
	 **/
	montantTva: number;
	nature: ICsiExport_etypeline;
	noTurnover: boolean;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numFamille: number;
	/**
	 * @s3dInteger
	 **/
	numGroupe: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numSousFamille: number;
	/**
	 * @s3dInteger
	 **/
	numTVA: number;
	/**
	 * @s3dInteger
	 **/
	numTarif: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parentCommandeLunchReceipt: ICsiExport_commande;
	parentLineUuid: string;
	/**
	 * @s3dDouble
	 **/
	prixTotalNet: number;
	/**
	 * @s3dDouble
	 **/
	prixTotalNetHT: number;
	/**
	 * @s3dDouble
	 **/
	prixTotalNetRemiseEtMajore: number;
	/**
	 * @s3dDouble
	 **/
	prixUnitaireTTC: number;
	profit: string;
	/**
	 * @s3dDouble
	 **/
	quantite: number;
	/**
	 * @s3dDouble
	 **/
	quantiteNette: number;
	/**
	 * @s3dDouble
	 **/
	quantiteOPCB: number;
	/**
	 * @s3dDouble
	 **/
	quantiteStock: number;
	reference: string;
	/**
	 * @s3dDouble
	 **/
	remiseEtMajorationTotale: number;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dDouble
	 **/
	surchargeOnLineAmount: number;
	/**
	 * @s3dInteger
	 **/
	surchargeOnLineId: number;
	surchargeOnLineName: string;
	/**
	 * @s3dDouble
	 **/
	surchargeOnStotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	surchargeOnStotalId: number;
	surchargeOnStotalName: string;
	/**
	 * @s3dDouble
	 **/
	surchargeOnTotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	surchargeOnTotalId: number;
	surchargeOnTotalName: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	/**
	 * @s3dDouble
	 **/
	tauxTva: number;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName ARTICLECOMMANDE
 */
export interface ICsiExport_articlecommande {
	canceled: boolean;
	climat: string;
	/**
	 * @s3dDouble
	 **/
	coutMatiere: number;
	dateFiscale: string;
	/**
	 * @s3dDouble
	 **/
	discountOnLineAmount: number;
	/**
	 * @s3dInteger
	 **/
	discountOnLineId: number;
	discountOnLineName: string;
	/**
	 * @s3dDouble
	 **/
	discountOnStotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	discountOnStotalId: number;
	discountOnStotalName: string;
	/**
	 * @s3dDouble
	 **/
	discountOnTotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	discountOnTotalId: number;
	discountOnTotalName: string;
	dontPrintOnReport: boolean;
	encaisse: boolean;
	estAnnule: boolean;
	estMajore: boolean;
	estOffert: boolean;
	estRemise: boolean;
	estSoumisATVA: boolean;
	estStockTraite: boolean;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	horodatageEncaisse: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	iddesti: number;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libFamille: string;
	libGroupe: string;
	libSite: string;
	libSousFamille: string;
	libTVA: string;
	libTarif: string;
	libVendeur: string;
	libZ2: string;
	libelle2emeLang: string;
	libelle3emeLang: string;
	libelle: string;
	libelleModifier: string;
	libelleQualifier: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dDouble
	 **/
	marge: number;
	/**
	 * @s3dDouble
	 **/
	montantTva: number;
	nature: ICsiExport_etypeline;
	noTurnover: boolean;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numFamille: number;
	/**
	 * @s3dInteger
	 **/
	numGroupe: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numSousFamille: number;
	/**
	 * @s3dInteger
	 **/
	numTVA: number;
	/**
	 * @s3dInteger
	 **/
	numTarif: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	oFFERTs: ICsiExport_offertcommande[];
	operation: ICsiExport_eoperation;
	parentCommandeArticleCommande: ICsiExport_commande;
	parentLineUuid: string;
	/**
	 * @s3dDouble
	 **/
	prixTotalNet: number;
	/**
	 * @s3dDouble
	 **/
	prixTotalNetHT: number;
	/**
	 * @s3dDouble
	 **/
	prixTotalNetRemiseEtMajore: number;
	/**
	 * @s3dDouble
	 **/
	prixUnitaireTTC: number;
	profit: string;
	/**
	 * @s3dDouble
	 **/
	quantite: number;
	/**
	 * @s3dDouble
	 **/
	quantiteNette: number;
	/**
	 * @s3dDouble
	 **/
	quantiteOPCB: number;
	/**
	 * @s3dDouble
	 **/
	quantiteStock: number;
	reference: string;
	/**
	 * @s3dDouble
	 **/
	remiseEtMajorationTotale: number;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dDouble
	 **/
	surchargeOnLineAmount: number;
	/**
	 * @s3dInteger
	 **/
	surchargeOnLineId: number;
	surchargeOnLineName: string;
	/**
	 * @s3dDouble
	 **/
	surchargeOnStotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	surchargeOnStotalId: number;
	surchargeOnStotalName: string;
	/**
	 * @s3dDouble
	 **/
	surchargeOnTotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	surchargeOnTotalId: number;
	surchargeOnTotalName: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	/**
	 * @s3dDouble
	 **/
	tauxTva: number;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName COMMENTAIRE
 */
export interface ICsiExport_commentaire {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	libelle: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parentCommandeCommentaire: ICsiExport_commande;
	profit: string;
	/**
	 * @s3dDouble
	 **/
	quantite: number;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	trainingMode: boolean;
}

/**
 * @csiClassName COMMENTAIRELIBRE
 */
export interface ICsiExport_commentairelibre {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	libelle: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	operation: ICsiExport_eoperation;
	parentCommandeCommentaireLibre: ICsiExport_commande;
	profit: string;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	trainingMode: boolean;
}

/**
 * @csiClassName SUPPRESSION
 */
export interface ICsiExport_suppression {
	canceled: boolean;
	climat: string;
	/**
	 * @s3dDouble
	 **/
	coutMatiere: number;
	dateFiscale: string;
	/**
	 * @s3dDouble
	 **/
	discountOnLineAmount: number;
	/**
	 * @s3dInteger
	 **/
	discountOnLineId: number;
	discountOnLineName: string;
	/**
	 * @s3dDouble
	 **/
	discountOnStotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	discountOnStotalId: number;
	discountOnStotalName: string;
	/**
	 * @s3dDouble
	 **/
	discountOnTotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	discountOnTotalId: number;
	discountOnTotalName: string;
	dontPrintOnReport: boolean;
	encaisse: boolean;
	estAnnule: boolean;
	estMajore: boolean;
	estOffert: boolean;
	estRemise: boolean;
	estSoumisATVA: boolean;
	estStockTraite: boolean;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	horodatageEncaisse: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	iddesti: number;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libFamille: string;
	libGroupe: string;
	libSite: string;
	libSousFamille: string;
	libTVA: string;
	libTarif: string;
	libVendeur: string;
	libZ2: string;
	libelle2emeLang: string;
	libelle3emeLang: string;
	libelle: string;
	libelleModifier: string;
	libelleQualifier: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dDouble
	 **/
	marge: number;
	/**
	 * @s3dDouble
	 **/
	montantTva: number;
	nature: ICsiExport_etypeline;
	noTurnover: boolean;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numFamille: number;
	/**
	 * @s3dInteger
	 **/
	numGroupe: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numSousFamille: number;
	/**
	 * @s3dInteger
	 **/
	numTVA: number;
	/**
	 * @s3dInteger
	 **/
	numTarif: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parentCommandeSuppression: ICsiExport_commande;
	parentLineUuid: string;
	/**
	 * @s3dDouble
	 **/
	prixTotalNet: number;
	/**
	 * @s3dDouble
	 **/
	prixTotalNetHT: number;
	/**
	 * @s3dDouble
	 **/
	prixTotalNetRemiseEtMajore: number;
	/**
	 * @s3dDouble
	 **/
	prixUnitaireTTC: number;
	profit: string;
	/**
	 * @s3dDouble
	 **/
	quantite: number;
	/**
	 * @s3dDouble
	 **/
	quantiteNette: number;
	/**
	 * @s3dDouble
	 **/
	quantiteOPCB: number;
	/**
	 * @s3dDouble
	 **/
	quantiteStock: number;
	reference: string;
	/**
	 * @s3dDouble
	 **/
	remiseEtMajorationTotale: number;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dDouble
	 **/
	surchargeOnLineAmount: number;
	/**
	 * @s3dInteger
	 **/
	surchargeOnLineId: number;
	surchargeOnLineName: string;
	/**
	 * @s3dDouble
	 **/
	surchargeOnStotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	surchargeOnStotalId: number;
	surchargeOnStotalName: string;
	/**
	 * @s3dDouble
	 **/
	surchargeOnTotalAmount: number;
	/**
	 * @s3dInteger
	 **/
	surchargeOnTotalId: number;
	surchargeOnTotalName: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	/**
	 * @s3dDouble
	 **/
	tauxTva: number;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName INVOICE
 */
export interface ICsiExport_invoice {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libTVA: string;
	libVendeur: string;
	libZ2: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numFacture: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numTVA: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	operation: ICsiExport_eoperation;
	parent_COMMANDE_INVOICE: ICsiExport_commande;
	profit: string;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dDouble
	 **/
	valeur: number;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

export enum ICsiExport_etypeline {
	ARTICLE,
	STATISTIQUES,
	COMMENTAIRE,
	REGLEMENT,
	REMISE_TAUX,
	DATE,
	RENDU_MONNAIE,
	QR_CODE,
	HEADER_TEXT,
	FOOTER_TEXT,
	MIDDLE_TEXT,
	ARTICLE_DELETE_BEFORE_KITCHEN_SCREEN,
	ARTICLE_DELETE_AFTER_KITCHEN_SCREEN,
	ARTICLE_DELETE_AFTER_ORDER_SUB_TOTAL,
	REMISE_TAUX_DELETE_BEFORE_KITCHEN_SCREEN,
	REMISE_TAUX_DELETE_AFTER_KITCHEN_SCREEN,
	REMISE_TAUX_DELETE_AFTER_ORDER_SUB_TOTAL,
	VALUEMEAL,
	VALUEMEAL_DELETE_BEFORE_KITCHEN_SCREEN,
	VALUEMEAL_DELETE_AFTER_KITCHEN_SCREEN,
	VALUEMEAL_DELETE_AFTER_ORDER_SUB_TOTAL,
	MODIFIER_QUALIFIER,
	MODIFIER_QUALIFIER_DELETE_BEFORE_KITCHEN_SCREEN,
	MODIFIER_QUALIFIER_DELETE_AFTER_KITCHEN_SCREEN,
	MODIFIER_QUALIFIER_DELETE_AFTER_ORDER_SUB_TOTAL,
	COUPON_TEXT,
	REMISE_MONTANT,
	REMISE_MONTANT_DELETE_BEFORE_KITCHEN_SCREEN,
	REMISE_MONTANT_DELETE_AFTER_KITCHEN_SCREEN,
	REMISE_MONTANT_DELETE_AFTER_ORDER_SUB_TOTAL,
}

/**
 * @csiClassName SITUATION
 */
export interface ICsiExport_situation {
	adresse: string;
	canceled: boolean;
	climat: string;
	codePostal: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	operation: ICsiExport_eoperation;
	parentContactSituation: ICsiExport_contact;
	profit: string;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	trainingMode: boolean;
	type: string;
	/**
	 * @s3dInteger
	 **/
	version: number;
	ville: string;
}

/**
 * @csiClassName VATFREE
 */
export interface ICsiExport_vatfree {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	libelle: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numLigne: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parentArticleVATFREE: ICsiExport_article;
	profit: string;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	/**
	 * @s3dDouble
	 **/
	taux: number;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dDouble
	 **/
	valeurHT: number;
	/**
	 * @s3dDouble
	 **/
	valeurTTC: number;
	/**
	 * @s3dDouble
	 **/
	valeurTVA: number;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName OFFERT
 */
export interface ICsiExport_offert {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	libelle: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parentArticleOffert: ICsiExport_article;
	profit: string;
	/**
	 * @s3dDouble
	 **/
	quantite: number;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dDouble
	 **/
	valeur: number;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName ANNULATION
 */
export interface ICsiExport_annulation {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	libelle: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parentArticleAnnulation: ICsiExport_article;
	profit: string;
	/**
	 * @s3dDouble
	 **/
	quantite: number;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dDouble
	 **/
	valeur: number;
	/**
	 * @s3dInteger
	 **/
	version: number;
}

/**
 * @csiClassName OFFERTCOMMANDE
 */
export interface ICsiExport_offertcommande {
	canceled: boolean;
	climat: string;
	dateFiscale: string;
	etat: ICsiExport_etat;
	gUIDProg: string;
	generateurZ2: string;
	generateurZ3: string;
	generateurZ4: string;
	horodatage: string;
	id: string;
	/**
	 * @s3dInteger
	 **/
	jour: number;
	libCaisse: string;
	libSite: string;
	libVendeur: string;
	libZ2: string;
	libelle: string;
	localisation: ICsiExport_elocalisation;
	/**
	 * @s3dInteger
	 **/
	numCaisse: number;
	/**
	 * @s3dInteger
	 **/
	numQuart: number;
	/**
	 * @s3dLong
	 **/
	numService: number;
	/**
	 * @s3dInteger
	 **/
	numVendeur: number;
	/**
	 * @s3dLong
	 **/
	numZ2: number;
	/**
	 * @s3dLong
	 **/
	numZ3: number;
	/**
	 * @s3dLong
	 **/
	numZ4: number;
	/**
	 * @s3dInteger
	 **/
	numero: number;
	operation: ICsiExport_eoperation;
	parentArticleCommandeOffert: ICsiExport_articlecommande;
	profit: string;
	/**
	 * @s3dDouble
	 **/
	quantite: number;
	/**
	 * @s3dInteger
	 **/
	restaurantId: number;
	roomName: string;
	service: string;
	sessionVentes: string;
	/**
	 * @s3dInteger
	 **/
	tableNumber: number;
	tag: string;
	ticketGenerator: string;
	trainingMode: boolean;
	/**
	 * @s3dDouble
	 **/
	valeur: number;
	/**
	 * @s3dInteger
	 **/
	version: number;
}
